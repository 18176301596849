import React from 'react';
import { withStyles } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '5rem',
        height: 400,
        width: '95%'
    },
    h3: {
        color: '#595652'
    }
}

const tickFormatter = (tick) => {
    const date = new Date(tick)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');

    return `${day}-${month}`
}

const colors = ['lightgray', '#7b8be0'];

const StatisticsChart = ({ classes: { container, h3 }, data }) =>
    (
        <div className={container}>
            <h3 className={h3}>Liczba nowych pakietów z ostatnich 30 dni</h3>

            <ResponsiveContainer width="80%" height="100%">
                <BarChart
                    width={1600}
                    height={400}
                    data={data}
                    barCategoryGap={8}
                >
                    <CartesianGrid strokeDasharray="5 5" />
                    <Tooltip cursor={{fill: 'gray'}}/>
                    <XAxis
                        dataKey="day"
                        dy={12}
                        angle={-90}
                        tickMargin={10}
                        height={50}
                        tickFormatter={tickFormatter}
                        tick={{fontWeight: '600'}}
                    />
                    <YAxis tick={{fontWeight: '600'}} />
                    <Bar dataKey="count" fill="#8884d8" name='Liczba pakietów' >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % 2]} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    )

export default withStyles(styles)(StatisticsChart);
