/* eslint-disable chai-friendly/no-unused-expressions */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { endpoint, localStorageKey, listTypes, waterpipesAll } from '../../utils/constants';
import AutocompleteBar from '../Tables/AutocompleteBar';
import { axiosInstance } from '../../utils/axiosInstance';
import { sortItemsAlphabetically } from '../../utils/helpers';

const WaterpipeList = ({ waterpipeId, setWaterpipeId, setWaterpipeSign, listType, setFetchError }) => {
    const [waterpipesList, setWaterpipesList] = useState([]);
    const [selectedWaterpipe, setSelectedWaterpipe] = useState('');
    const [waterpipeData, setWaterpipeData] = useState([]);

    const getWaterpipesNameList = () => {
        const sortedList = sortItemsAlphabetically(waterpipeData);
        if (listType === listTypes.TEST_LIST) {
            const sortedListWithAll = sortedList.filter(waterpipe => waterpipe !== waterpipesAll.name);

            sortedListWithAll.unshift(waterpipesAll.name);
            return sortedListWithAll;
        }
        return sortedList;
    };

    const setSelectedWaterpipeId = waterPipeName => {
        const waterpipe = waterpipeData.filter(waterpipe => waterpipe.name === waterPipeName)[0];
        if (waterpipe) {
            setWaterpipeId(waterpipe.id);
            setWaterpipeSign(waterpipe.sign);
        }
    };

    const getWaterpipesList = () => {
        axiosInstance
            .get(endpoint.PACKAGES, {
                params: {
                    chunk: 1,
                    page: 1,
                },
            })
            .then(content => {
                const { data } = content.data.filters.waterpipes;
                if (listType === listTypes.TEST_LIST) {
                    data.push(waterpipesAll);
                }
                setWaterpipeData(data);
            })
            .catch(err => {
                console.error('Error while fetching waterpipes', err);
                setFetchError && setFetchError(err);
            });
    };

    useEffect(() => {
        getWaterpipesList();
    }, []);

    useEffect(() => {
        if (waterpipesList.length) {
            if (waterpipeId !== null) {
                setSelectedWaterpipe(
                    waterpipeData?.filter(waterpipe => waterpipe.id === waterpipeId)[0]?.name
                );
            } else {
                setSelectedWaterpipe(waterpipeData[0].name);
            }
        }
    }, [waterpipesList, waterpipeId]);

    useEffect(() => {
        if (waterpipeData.length) {
            setWaterpipesList(getWaterpipesNameList());
            localStorage.setItem(localStorageKey.WATERPIPES_LIST, JSON.stringify(waterpipeData));
        }
    }, [waterpipeData]);

    useEffect(() => {
        setSelectedWaterpipeId(selectedWaterpipe);
    }, [selectedWaterpipe]);

    return (
        <AutocompleteBar
            label="Wodociąg"
            placeholder="Wszystkie"
            options={waterpipesList}
            handleFilter={setSelectedWaterpipe}
            value={selectedWaterpipe}
            isWaterpipe
        />
    );
};

export default WaterpipeList;
