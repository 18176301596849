/* eslint-disable chai-friendly/no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import { localStorageKey } from '../../utils/constants';
import docsContext from '../../context/DocsContext';

const styles = () => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '75px',
        flexWrap: 'wrap',
        marginBottom: '-.05rem',
        marginRight: '1rem',
    },
});

const theme = createTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiInput: {
            input: {
                paddingBottom: '.8rem',
            },
        },
    },
});

const TableSearchBar = ({
    searchValue,
    clearSearchField,
    clearedSearchField,
    isTable,
    handleSearch,
    classes,
    placeholder,
    value,
}) => {
    const [inputValue, setInputValue] = useState('');
    const docsProps = useContext(docsContext);

    useEffect(() => {
        if (isTable) {
            setInputValue(docsProps[searchValue]);
        }
    }, [isTable && docsProps[searchValue]]);

    useEffect(() => {
        !isTable && setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (clearSearchField) {
            setInputValue('');
            localStorage.removeItem(localStorageKey.DOCS_SEARCH_COMMUNICATOR);
            localStorage.removeItem(localStorageKey.DOCS_SEARCH_PACKAGE_ID);
            clearedSearchField();
        }
    }, [clearSearchField]);

    const onInputChange = event => {
        setInputValue(event.target.value);
    };

    const doSearch = e => {
        const isEnterPressed = e.key === 'Enter';
        isEnterPressed && handleSearch(inputValue, isEnterPressed);
    };

    return (
        <div className={classes.container}>
            <MuiThemeProvider theme={theme}>
                <TextField
                    className={classes.margin}
                    label={placeholder || 'Szukaj'}
                    onChange={onInputChange}
                    value={inputValue}
                    onKeyDown={doSearch}
                />
            </MuiThemeProvider>
        </div>
    );
};

TableSearchBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableSearchBar);
