import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router';
import { getToken } from '../../utils/helpers';
import background from '../../assets/img/smartmetering.jpg';

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto',
    },
    textField: {
        marginLeft: '1rem',
        marginRight: '1rem',
        background: 'white',
        width: '300px',
        marginTop: '0.5rem',
    },
    lightButton: {
        margin: '1rem 1rem 0 1rem',
        textTransform: 'none',
        backgroundColor: '#63b7ce',
        borderRadius: 0,
        fontSize: '1.1875em',
        width: '300px',
    },
    darkButton: {
        margin: '1rem',
        textTransform: 'none',
        backgroundColor: '#067c9c',
        borderRadius: 0,
        fontSize: '1.1875em',
        width: '300px',
    },
    input: {
        color: 'black',
    },
    mainHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
    },
    mainHeader: {
        marginTop: '15rem',
    },
    backgroundPic: {
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        height: '100%',
    },
};

class LoginPage extends Component {
    state = {
        login: '',
        password: '',
    };

    logIn = isExternal => {
        const { login, password } = this.state;
        this.props.handleLogin(login, password, isExternal);
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.logIn();
        }
    };

    loginHandler = e => {
        this.setState({ login: e.target.value });
    };

    passwordHandler = e => {
        this.setState({ password: e.target.value });
    };

    render() {
        const { classes } = this.props;

        if (getToken()) {
            return <Redirect from="/login" to="/" />;
        }

        return (
            <section className={classes.backgroundPic}>
                <div className={classes.mainHeaderContainer}>
                    <h1 className={classes.mainHeader}>Smartmetering24 (Collector)</h1>
                </div>
                <form
                    className={classes.container}
                    noValidate
                    autoComplete="off"
                    onKeyPress={this.handleKeyPress}>
                    <TextField
                        id="filled-name-input"
                        label="Nazwa użytkownika"
                        className={classes.textField}
                        type="name"
                        name="name"
                        autoComplete="name"
                        margin="normal"
                        variant="filled"
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true,
                        }}
                        value={this.state.login}
                        onChange={this.loginHandler}
                    />
                    <TextField
                        id="filled-password-input"
                        label="Hasło"
                        className={classes.textField}
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        variant="filled"
                        InputProps={{
                            className: classes.input,
                            disableUnderline: true,
                        }}
                        value={this.state.password}
                        onChange={this.passwordHandler}
                    />
                    <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        className={classes.lightButton}
                        onClick={() => this.logIn(false)}>
                        Zaloguj
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        className={classes.darkButton}
                        onClick={() => this.logIn(true)}>
                        Zaloguj przez PRONAL
                    </Button>
                </form>
            </section>
        );
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginPage);
