import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { TextValidator } from 'react-material-ui-form-validator';
import { convertHexToDec } from '../../../../utils/helpers';

const styles = () => ({
    textValidator: {
        width: '20vw',
        background: '#e7e7e7',
    },
});

class DecimalV10 extends PureComponent {
    state = {
        value: '',
        isFocus: false,
    };

    componentDidMount() {
        const { hexValue } = this.props;
        this.setState({ value: hexValue });
    }

    componentDidUpdate(prevProps) {
        const { hexValue, isBCD } = this.props;

        if (prevProps.hexValue !== hexValue && !this.state.isFocus) {
            if (hexValue === '') {
                this.setState({ value: hexValue });
            } else {
                this.setState({
                    value: isBCD() ? hexValue : convertHexToDec(hexValue),
                });
            }
        }
    }

    onChangeHandler = e => {
        const { handleChange } = this.props;
        const changedValue = e.target.value;
        this.setState(
            {
                value: changedValue,
            },
            () => {
                handleChange(this.convertDecToHex(this.state.value));
            }
        );
    };

    convertDecToHex = decValue => {
        const { isBCD } = this.props;
        if (decValue === '') {
            return decValue;
        }
        return isBCD() ? decValue : Number(decValue).toString(16);
    };

    onFocusHandler = () => {
        this.setState(state => ({ isFocus: !state.isFocus }));
    };

    render() {
        const { value } = this.state;
        const { classes, label } = this.props;
        return (
            <TextValidator
                className={classes.textValidator}
                variant="outlined"
                value={value}
                onChange={this.onChangeHandler}
                label={`${label}[DECIMAL]`}
                onFocus={this.onFocusHandler}
                onBlur={this.onFocusHandler}
            />
        );
    }
}

export default withStyles(styles)(DecimalV10);
