/* eslint-disable default-param-last */
/* eslint-disable chai-friendly/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { axiosInstance, cancelToken } from '../../utils/axiosInstance';
import { convertToPolish, errorHandling, getUrlLastPart } from '../../utils/helpers';
import { localStorageKey, endpoint, reportTypes, sessionStorageKey } from '../../utils/constants';
import { DocsProvider } from '../../context/DocsContext';
import TableReports from '../Tables/TableReports';

const Docs = props => {
    const REPORTS_OPTIONS = [
        reportTypes.REPORTS_PL,
        reportTypes.ALARMS_PL,
        reportTypes.SUMMARIES_PL,
        reportTypes.MINUTE_REPORTS_PL,
        reportTypes.CONFIGS_PL,
        reportTypes.CON_CONFIGS_PL,
        reportTypes.CON_MAPS_PL,
    ];
    const DEFAULT_OPTIONS_FOR_DOCS = {
        reportType: '',
        page: 1,
        chunk: localStorage.getItem(localStorageKey.PAGINATION_SIZE_TABLE_REPORTS) ?? 50,
        communicator: localStorage.getItem(localStorageKey.DOCS_SEARCH_COMMUNICATOR) ?? '',
        packageId: localStorage.getItem(localStorageKey.DOCS_SEARCH_PACKAGE_ID) ?? '',
        waterpipeId: '',
    };
    const history = useHistory();
    const { match } = props;
    const [optionsForGetDocsData, setOptionsForGetDocsData] = useState(DEFAULT_OPTIONS_FOR_DOCS);
    const [isLoading, setIsLoading] = useState(false);
    const [isServerError, setIsServerError] = useState(false);
    const [cancelHttpReq] = useState(cancelToken.source());
    const [reportTypeData, setReportTypeData] = useState({
        header: [],
        data: [],
    });
    const [clearSearchField, setClearSearchField] = useState(false);
    const [waterpipe, setWaterpipe] = useState('');
    const [searchCommunicator, setSearchCommunicator] = useState('');
    const [searchPackageId, setSearchPackageId] = useState('');
    const currentLocation = useLocation();

    useEffect(() => {
        getPipelineName();
        reportTypeView(getUrlLastPart(), getWaterPipeId(), getSelectedCommunicator(), getSelectedPackageId());
        setUrl();
        return () => {
            cancelHttpReq.cancel();
            sessionStorage.removeItem(sessionStorageKey.SELECTED_PACKAGE_ID);
            sessionStorage.removeItem(sessionStorageKey.IS_USER_ENTERED_FROM_PACKAGES_LINK);
        };
    }, []);

    useEffect(() => {
        optionsForGetDocsData.waterpipeId && getDocsData(optionsForGetDocsData);
    }, [optionsForGetDocsData]);

    useEffect(() => {
        localStorage.setItem(localStorageKey.DOCS_REPORT_TYPE, convertToPolish(getUrlLastPart()));
    }, [currentLocation]);

    const getDocsData = options => {
        const { reportType, communicator, packageId, chunk, page, waterpipeId } = options;
        setIsLoading(true);
        axiosInstance(`api/${reportType}`, {
            params: {
                communicator,
                packageId,
                chunk,
                page,
                waterpipeId,
            },
            cancelToken: cancelHttpReq.token,
        })
            .then(resp => {
                setReportTypeData({
                    data: resp.data.data,
                    headers: resp.data.headers,
                });
            })
            .catch(err => {
                errorHandling(err);
            });
    };

    const getPipelineName = () => {
        const machedEndpoint = `${endpoint.WATERPIPE}/${match.params.id}`;
        setIsLoading(true);
        axiosInstance(machedEndpoint, {
            cancelToken: cancelHttpReq.token,
        })
            .then(resp => {
                setWaterpipe(resp.data.name);
            })
            .catch(err => {
                if (err.message === undefined) {
                    return;
                }
                if (err.response.status === 401) {
                    history.push(`/login`);
                }
                if (err.message) {
                    setIsServerError(true);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const getWaterPipeId = () => {
        if (match && match.params.id) {
            return match.params.id;
        }
        return null;
    };

    const getSelectedCommunicator = () => {
        const docsSearchCommunicator = localStorage.getItem(localStorageKey.DOCS_SEARCH_COMMUNICATOR);

        if (docsSearchCommunicator) {
            setSearchCommunicator(docsSearchCommunicator);
            return docsSearchCommunicator;
        }
        return '';
    };

    const getSelectedPackageId = () => {
        const docsSearchPackageId = localStorage.getItem(localStorageKey.DOCS_SEARCH_PACKAGE_ID);
        if (docsSearchPackageId) {
            setSearchPackageId(docsSearchPackageId);
            return docsSearchPackageId;
        }
        return '';
    };

    const handleSearchCommunicator = (phrase = '', isEnterPressed = false) => {
        if (isEnterPressed) {
            localStorage.setItem(localStorageKey.DOCS_SEARCH_COMMUNICATOR, phrase);
            setSearchCommunicator(phrase);
            setOptionsForGetDocsData(prev => ({
                ...prev,
                communicator: phrase,
                page: 1,
            }));
        }
    };

    const handleSearchPackageId = (phrase = '', isEnterPressed = false) => {
        if (isEnterPressed) {
            localStorage.setItem(localStorageKey.DOCS_SEARCH_PACKAGE_ID, phrase);
            setSearchPackageId(phrase);
            setOptionsForGetDocsData(prev => ({
                ...prev,
                packageId: phrase,
                page: 1,
            }));
        }
    };

    const setUrl = () => {
        if (
            typeof window !== 'undefined' &&
            !window.location.href.includes(`${match.url}/${getUrlLastPart()}`)
        ) {
            history.push(`${match.url}/${getUrlLastPart()}`);
        }
    };

    const reportTypeView = (
        reportType,
        waterpipeId = optionsForGetDocsData.waterpipeId,
        communicator,
        packageId
    ) => {
        if (reportType !== localStorage.getItem(localStorageKey.DOCS_REPORT_TYPE)) {
            const localStorageCommunicator = localStorage.getItem(localStorageKey.DOCS_SEARCH_COMMUNICATOR);
            if (localStorageCommunicator) {
                communicator = localStorageCommunicator;
            }
            const localStoragePackageId = localStorage.getItem(localStorageKey.DOCS_SEARCH_PACKAGE_ID);
            if (localStoragePackageId) {
                packageId = localStoragePackageId;
            }

            switch (reportType) {
                case reportTypes.REPORTS:
                    history.push(`${match.url}/${reportTypes.REPORTS}`);
                    localStorage.setItem(localStorageKey.DOCS_REPORT_TYPE, reportType);
                    setOptionsForGetDocsData(prev => ({
                        ...prev,
                        reportType: reportTypes.REPORTS,
                        page: 1,
                        waterpipeId,
                        communicator,
                        packageId,
                    }));
                    break;
                case reportTypes.ALARMS:
                    history.push(`${match.url}/${reportTypes.ALARMS}`);

                    localStorage.setItem(localStorageKey.DOCS_REPORT_TYPE, reportType);
                    setOptionsForGetDocsData(prev => ({
                        ...prev,
                        reportType: reportTypes.ALARMS,
                        page: 1,
                        waterpipeId,
                        communicator,
                        packageId,
                    }));
                    break;
                case reportTypes.SUMMARIES:
                    history.push(`${match.url}/${reportTypes.SUMMARIES}`);

                    localStorage.setItem(localStorageKey.DOCS_REPORT_TYPE, reportType);
                    setOptionsForGetDocsData(prev => ({
                        ...prev,
                        reportType: reportTypes.SUMMARIES,
                        page: 1,
                        waterpipeId,
                        communicator,
                        packageId,
                    }));
                    break;
                case reportTypes.MINUTE_REPORTS:
                    history.push(`${match.url}/${reportTypes.MINUTE_REPORTS}`);
                    localStorage.setItem(localStorageKey.DOCS_REPORT_TYPE, reportType);
                    setOptionsForGetDocsData(prev => ({
                        ...prev,
                        reportType: reportTypes.MINUTE_REPORTS,
                        page: 1,
                        waterpipeId,
                        communicator,
                        packageId,
                    }));
                    break;
                case reportTypes.CONFIGS:
                    history.push(`${match.url}/${reportTypes.CONFIGS}`);
                    localStorage.setItem(localStorageKey.DOCS_REPORT_TYPE, reportType);
                    setOptionsForGetDocsData(prev => ({
                        ...prev,
                        reportType: reportTypes.CONFIGS,
                        page: 1,
                        waterpipeId,
                        communicator,
                        packageId,
                    }));
                    break;
                case reportTypes.CON_MAPS:
                    history.push(`${match.url}/${reportTypes.CON_MAPS}`);
                    localStorage.setItem(localStorageKey.DOCS_REPORT_TYPE, reportType);
                    setOptionsForGetDocsData(prev => ({
                        ...prev,
                        reportType: reportTypes.CON_MAPS,
                        page: 1,
                        waterpipeId,
                        communicator,
                        packageId,
                    }));
                    break;
                case reportTypes.CON_CONFIGS:
                    history.push(`${match.url}/${reportTypes.CON_CONFIGS}`);
                    localStorage.setItem(localStorageKey.DOCS_REPORT_TYPE, reportType);
                    setOptionsForGetDocsData(prev => ({
                        ...prev,
                        reportType: reportTypes.CON_CONFIGS,
                        page: 1,
                        waterpipeId,
                        communicator,
                        packageId,
                    }));
                    break;
                default:
                    console.error('wrong report type passed to arguments in reportTypeView');
            }
        }
    };

    const changePaginationAndChunkSize = event => {
        const localStorageCommunicator = localStorage.getItem(localStorageKey.DOCS_SEARCH_COMMUNICATOR);
        const localStoragePackageId = localStorage.getItem(localStorageKey.DOCS_SEARCH_PACKAGE_ID);

        setOptionsForGetDocsData(prev => ({
            ...prev,
            chunk: event.target.value,
            page: 1,
            communicator: localStorageCommunicator ?? '',
            packageId: localStoragePackageId ?? '',
        }));
    };

    const handleChangePage = page => {
        localStorage.setItem(localStorageKey.DOCS_PAGE, page);
        setOptionsForGetDocsData(prev => ({ ...prev, page }));
    };

    const getTableHeader = (waterpipeType = '') =>
        `${convertToPolish(optionsForGetDocsData.reportType)} - Wodociąg (${waterpipeType})`;

    const handleResetFieldsButton = () => {
        localStorage.removeItem(localStorageKey.DOCS_SEARCH_COMMUNICATOR);
        sessionStorage.removeItem(sessionStorageKey.SELECTED_PACKAGE_COMMUNICATOR);
        localStorage.removeItem(localStorageKey.DOCS_SEARCH_PACKAGE_ID);
        sessionStorage.removeItem(sessionStorageKey.SELECTED_PACKAGE_ID);
        setOptionsForGetDocsData(prev => ({
            ...prev,
            communicator: '',
            packageId: '',
        }));
        setClearSearchField(true);
        setSearchCommunicator('');
        setSearchPackageId('');
    };

    const providerProps = {
        options: REPORTS_OPTIONS,
        handleFilter: reportTypeView,
        clearedSearchField: () => setClearSearchField(false),
        clearSearchField,
        handleSearchCommunicator: (phrase, isEnterPressed) =>
            handleSearchCommunicator(phrase, isEnterPressed),
        handleSearchPackageId: (phrase, isEnterPressed) => handleSearchPackageId(phrase, isEnterPressed),
        handleResetFieldsButton,
        changePageHandler: handleChangePage,
        reportType: optionsForGetDocsData.reportType,
        selectedPage: optionsForGetDocsData.page,
        loadData: setOptionsForGetDocsData,
        changePaginationAndChunkSize,
        chunk: optionsForGetDocsData.chunk,
        searchCommunicator,
        searchPackageId,
        setIsLoading: bool => setIsLoading(bool),
    };

    return (
        <DocsProvider value={providerProps}>
            <Switch>
                <Route
                    exact
                    path={`/pipeline/:id/${reportTypes.REPORTS}`}
                    render={() => (
                        <TableReports
                            headerText={getTableHeader(waterpipe)}
                            content={reportTypeData}
                            isServerError={isServerError}
                            isComponentMounted={isLoading}
                            match={match}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/pipeline/:id/${reportTypes.ALARMS}`}
                    render={() => (
                        <>
                            <TableReports
                                headerText={getTableHeader(waterpipe)}
                                content={reportTypeData}
                                isServerError={isServerError}
                                isComponentMounted={isLoading}
                                match={match}
                            />
                        </>
                    )}
                />
                <Route
                    exact
                    path={`/pipeline/:id/${reportTypes.SUMMARIES}`}
                    render={() => (
                        <TableReports
                            headerText={getTableHeader(waterpipe)}
                            content={reportTypeData}
                            isServerError={isServerError}
                            isComponentMounted={isLoading}
                            match={match}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/pipeline/:id/${reportTypes.MINUTE_REPORTS}`}
                    render={() => (
                        <TableReports
                            headerText={getTableHeader(waterpipe)}
                            content={reportTypeData}
                            isServerError={isServerError}
                            isComponentMounted={isLoading}
                            match={match}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/pipeline/:id/${reportTypes.CONFIGS}`}
                    render={() => (
                        <TableReports
                            headerText={getTableHeader(waterpipe)}
                            content={reportTypeData}
                            isServerError={isServerError}
                            isComponentMounted={isLoading}
                            match={match}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/pipeline/:id/${reportTypes.CON_MAPS}`}
                    render={() => (
                        <TableReports
                            headerText={getTableHeader(waterpipe)}
                            content={reportTypeData}
                            isServerError={isServerError}
                            isComponentMounted={isLoading}
                            match={match}
                        />
                    )}
                />
                <Route
                    exact
                    path={`/pipeline/:id/${reportTypes.CON_CONFIGS}`}
                    render={() => (
                        <TableReports
                            headerText={getTableHeader(waterpipe)}
                            content={reportTypeData}
                            isServerError={isServerError}
                            isComponentMounted={isLoading}
                            match={match}
                        />
                    )}
                />
            </Switch>
        </DocsProvider>
    );
};

export default Docs;
