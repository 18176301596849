/* eslint-disable radix */
/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-named-default */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { default as MaterialUITable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import ParseButton from './ParseButton';
import MultiLineField from './MultiLineField';
import Reading from './Reading';
import Flow from './Flow';
import DisplayError from '../DisplayError/DisplayError';
import { isAdminRole, isAsterisk, isEncryptedPackage } from '../../utils/helpers';
import packagesContext from '../../context/PackagesContext';
import PopupModal from '../PopupModal/PopupModal';
import { endpoint, localStorageKey, reportTypes } from '../../utils/constants';
import { axiosInstance, cancelToken } from '../../utils/axiosInstance';
import { messages } from '../../data/Messages';

const styles = () => ({
    root: {
        marginTop: '2rem',
        marginBottom: '2rem',
        border: 'none',
    },
    table: {
        minWidth: 700,
        wordBreak: 'break-all',
    },
    row: {
        textAlign: 'left',
    },
    tableBody: {
        border: '1px solid #c5cbd6',
    },
    tableRow: {
        border: 'none',
        textAlign: 'left',
        padding: '1rem',
    },
    cell: {
        border: '1px solid #c5cbd6',
        padding: '0.2rem',
        textAlign: 'center',
        fontSize: '.75rem',
    },
    cellLinks: {
        display: 'block',
        paddingTop: '.2rem',
    },
    cellReadings: {
        textAlign: 'right',
    },
    head: {
        textAlign: 'center',
        padding: '0',
        backgroundColor: '#b2a997',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        fontSize: '.75rem',
    },
    cellWithMinWidth: {
        minWidth: '5rem',
        padding: '1rem',
    },
    loadingOverlay: {
        height: '6rem',
    },
    textLeft: {
        textAlign: 'left',
    },
    textRight: {
        textAlign: 'right',
    },
    button: {
        width: '40%',
        height: '20px',
        fontSize: '.5rem',
    },
    buttonDecryption: {
        maxWidth: '12px',
        minWidth: '12px',
        marginTop: '-5px',
    },
});

class Table extends Component {
    state = {
        protocolV11DecryptedData: '',
        isThrobberActive: false,
    };

    static contextType = packagesContext;

    componentDidUpdate(prevProps) {
        const { isThrobberActive } = this.props;
        if (prevProps.isThrobberActive !== isThrobberActive) {
            this.setState({ isThrobberActive });
        }
    }

    parseCell(key, row) {
        const { buttonDecryption } = this.props.classes;
        const flowKey = 0;
        function isShowIcon() {
            return row.protocol !== null && row.status !== 'decrypt_error';
        }

        if (isEncryptedPackage(row.data) && key === 'data') {
            const cellData = row[key];
            return (
                <>
                    {cellData}
                    <span>
                        <Button
                            className={buttonDecryption}
                            onClick={() => this.handlePackageDecryption(cellData)}>
                            {isShowIcon() && <NoEncryptionIcon style={{ fontSize: '1rem' }} />}
                        </Button>
                    </span>
                </>
            );
        }
        if (key === 'created_at') {
            const date = row[key];
            return (
                <div>
                    <div>{date?.slice(0, 10)}</div>
                    <div>{date?.slice(11, 16)}</div>
                </div>
            );
        }
        if (key === 'readings') {
            return (
                <MultiLineField>
                    {row[key].map(reading => (
                        <Reading key={reading.id} row={reading} />
                    ))}
                </MultiLineField>
            );
        }
        if (key === 'flows') {
            return (
                <MultiLineField>
                    total: {row[key].total}
                    {Object.entries(row[key])
                        .filter(flow => flow[flowKey] !== 'total')
                        .map(flow => (
                            <Flow key={flow[flowKey]} row={flow} />
                        ))}
                </MultiLineField>
            );
        }
        if (key === 'offline') {
            return this.processOffColumn(row);
        }
        if (key === 'protocols') {
            return row[key].map(protocol => <div key={protocol.id}>{protocol.name}</div>);
        }
        if (key === 'document_type' && row.hasOwnProperty('waterpipe')) {
            return this.getExactWaterpipeLink(row);
        }
        if (key === 'status') {
            if (row[key] === 'parser_error') {
                return (
                    <Link
                        onClick={() => this.setParserErrorLinkClicked(row.data, row.created_at)}
                        to={`error/${row.id}`}>
                        {row[key]}
                    </Link>
                );
            }

            if (row[key] === 'untrusted_gateway') {
                return (
                    <Link
                        onClick={() => this.setParserErrorLinkClicked(row.data, row.created_at)}
                        to={`error/${row.id}`}>
                        {row[key]}
                    </Link>
                );
            }

            return row[key];
        }
        if (key === 'sim') {
            return row[key] === '' && row.isTest ? <p>TEST</p> : row[key];
        }
        return row[key];
    }

    getCells = (row, isBlue = false) => {
        const { showParseColumn, showWaterpipeLink, classes, headers } = this.props;
        const setBlueRow = () => {
            const selectedPackageId = sessionStorage.getItem('selectedPackageId');
            isBlue = isBlue || parseInt(selectedPackageId) === row.package_id;
            return isBlue && { style: { background: 'rgba(22, 0, 255, .3)' } };
        };

        return (
            <TableRow key={row.id} className={classes.tableRow}>
                {Object.keys(headers).map((key, index) => {
                    if (this.isHideAdminColumnsView(key)) {
                        return null;
                    }
                    if (index === 1) {
                        return (
                            <React.Fragment key={row.id + key}>
                                <TableCell
                                    data-cy={`${key}`}
                                    className={[
                                        key === 'readings' && classes.cellReadings,
                                        classes.cell,
                                    ].join(' ')}
                                    {...setBlueRow()}>
                                    {this.parseCell(key, row)}
                                </TableCell>
                                {showWaterpipeLink !== undefined && (
                                    <TableCell
                                        data-cy={`${key}`}
                                        className={[
                                            key === 'readings' && classes.cellReadings,
                                            classes.cell,
                                        ].join(' ')}
                                        {...setBlueRow()}>
                                        <Link
                                            className={classes.cellLinks}
                                            to={`/pipeline/${row.id}/${reportTypes.REPORTS}`}>
                                            {reportTypes.REPORTS_PL}
                                        </Link>

                                        <Link
                                            className={classes.cellLinks}
                                            to={`/pipeline/${row.id}/${reportTypes.MINUTE_REPORTS}`}>
                                            {reportTypes.MINUTE_REPORTS_PL}
                                        </Link>
                                        <Link
                                            className={classes.cellLinks}
                                            to={`/pipeline/${row.id}/${reportTypes.ALARMS}`}>
                                            {reportTypes.ALARMS_PL}
                                        </Link>

                                        <Link
                                            className={classes.cellLinks}
                                            to={`/pipeline/${row.id}/${reportTypes.SUMMARIES}`}>
                                            {reportTypes.SUMMARIES_PL}
                                        </Link>
                                        <Link
                                            className={classes.cellLinks}
                                            to={`/pipeline/${row.id}/${reportTypes.CONFIGS}`}>
                                            {reportTypes.CONFIGS_PL}
                                        </Link>
                                        <Link
                                            className={classes.cellLinks}
                                            to={`/pipeline/${row.id}/${reportTypes.CON_CONFIGS}`}>
                                            {reportTypes.CON_CONFIGS_PL}
                                        </Link>
                                        <Link
                                            className={classes.cellLinks}
                                            to={`/pipeline/${row.id}/${reportTypes.CON_MAPS}`}>
                                            {reportTypes.CON_MAPS_PL}
                                        </Link>
                                    </TableCell>
                                )}
                            </React.Fragment>
                        );
                    }

                    return (
                        <TableCell
                            data-cy={`${key}`}
                            className={[
                                key === 'readings' && classes.cellReadings,
                                classes.cell,
                                key === 'data' && classes.textLeft,
                                key === 'debug_reset_time' && classes.textRight,
                            ].join(' ')}
                            key={row.id + key}
                            {...setBlueRow()}>
                            {this.parseCell(key, row)}
                        </TableCell>
                    );
                })}

                {showParseColumn !== undefined && isAdminRole() ? (
                    <TableCell className={classes.cell} {...setBlueRow()}>
                        <ParseButton isVisible={row.status === 'new'} id={row.id} />
                    </TableCell>
                ) : null}

                {showWaterpipeLink !== undefined && isAdminRole() && (
                    <TableCell className={classes.cell}>
                        <Link to={`/pipeline/edit/${row.id}`}>Edycja</Link>
                    </TableCell>
                )}
            </TableRow>
        );
    };

    setParserErrorLinkClicked = (parserErrorPackageData, parserErrorPackageDateCreated) => {
        localStorage.setItem(localStorageKey.IS_PARSER_ERROR_LINK_CLICKED, true);
        localStorage.setItem(localStorageKey.PARSER_ERROR_PACKAGE_DATA, parserErrorPackageData);
        localStorage.setItem(
            localStorageKey.PARSER_ERROR_PACKAGE_DATE_CREATED,
            parserErrorPackageDateCreated
        );
    };

    isHideAdminColumnsView = key => {
        const adminColumns = ['db_login', 'db_password', 'db_host', 'db_name'];
        return adminColumns.includes(key) && !isAdminRole();
    };

    getWaterpipeId = waterpipeName => {
        const waterpipesData = this.context.waterpipes;
        if (waterpipesData) {
            const exactWaterpipeData = waterpipesData.find(
                i => i.name?.toLowerCase() === waterpipeName?.toLowerCase()
            );
            return exactWaterpipeData && exactWaterpipeData.id;
        }
        return null;
    };

    getExactWaterpipeLink = row => {
        const waterpipeId = this.getWaterpipeId(isAsterisk(row.waterpipe));
        if (row.status === 'parser_error' || row.status === 'duplicate') {
            return <p>{row.document_type}</p>;
        }
        if (row.protocol === 'V2' || row.status !== 'done') {
            return null;
        }

        return (
            <Link
                to={`pipeline/${waterpipeId}/${this.getReportTypeLink(row.document_type)}`}
                onClick={() => this.handleSelectPackageReportLink(row.id, row.communicator)}>
                {row.document_type}
            </Link>
        );
    };

    processOffColumn = row => {
        if (row.offline === 1) {
            return (
                <Checkbox
                    id="off"
                    color="default"
                    checked
                    inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                />
            );
        }
        if (row.offline === 2) {
            return 'OFF';
        }
        if (row.offline === 0) {
            return (
                <a
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        this.context.isPackageInLiveObject(row.id);
                    }}>
                    sprawdź
                </a>
            );
        }
        return '';
    };

    getReportTypeLink = reportType => {
        switch (reportType) {
            case reportTypes.ALARM:
                return reportTypes.ALARMS;
            case reportTypes.MINUTE:
                return reportTypes.MINUTE_REPORTS;
            case reportTypes.CONFIG:
                return reportTypes.CONFIGS;
            case reportTypes.REPORT:
                return reportTypes.REPORTS;
            case reportTypes.CON_MAP:
                return reportTypes.CON_MAPS;
            case reportTypes.CON_CONFIG:
                return reportTypes.CON_CONFIGS;
            default:
                return reportTypes.SUMMARIES;
        }
    };

    handleSelectPackageReportLink = (selectedPackageId, selectedPackageCommunicator) => {
        sessionStorage.setItem('selectedPackageId', selectedPackageId);
        localStorage.setItem(localStorageKey.DOCS_SEARCH_COMMUNICATOR, selectedPackageCommunicator);
        sessionStorage.setItem('isUserEnteredFromPackagesLink', 'true');
    };

    handlePackageDecryption = cellData => {
        this.cancelHttpReq = cancelToken.source();
        this.handleThrobber(true);
        axiosInstance
            .post(
                endpoint.DECRYPT_PACKAGE,
                {
                    data: cellData,
                },
                {
                    cancelToken: this.cancelHttpReq.token,
                }
            )
            .then(({ data: { data: data } }) => {
                this.handleThrobber(false);
                this.setState({ protocolV11DecryptedData: data });
            })
            .catch(error => {
                console.error('errorDecodeV11', error);
                this.handleThrobber(false);
            });
    };

    handleThrobber = isThrobberActive => {
        this.setState({ isThrobberActive });
    };

    handleClearProtocolV11DecryptedData = () => {
        this.setState({ protocolV11DecryptedData: '' });
    };

    render() {
        const { classes, showParseColumn, showWaterpipeLink, headers, content, headMinWidth, isServerError } =
            this.props;

        const { isThrobberActive } = this.state;

        if (isServerError) {
            return <DisplayError />;
        }
        const rows = content.map((row, i) => {
            const isTenthRow = () => {
                const isProtocolData = () =>
                    Object.keys(row).includes('protocol') && Object.keys(row).includes('document_type');

                return isProtocolData() && (i + 1) % 10 === 0;
            };
            return this.getCells(row, isTenthRow());
        });

        const headClasses = headMinWidth ? [classes.head, classes.cellWithMinWidth].join(' ') : classes.head;

        return headers && content ? (
            <Paper className={classes.root}>
                <LoadingOverlay
                    active={isThrobberActive}
                    spinner
                    text="Proszę czekać..."
                    styles={{
                        content: base => ({
                            ...base,
                            marginLeft: '50%',
                            margin: '4%',
                            transform: 'translateX(-50%)',
                            color: 'rgba(0, 0, 0, 0.54)',
                        }),
                    }}>
                    <MaterialUITable className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                {Object.keys(headers).map((header, index) => {
                                    if (this.isHideAdminColumnsView(header)) {
                                        return null;
                                    }

                                    return (
                                        <React.Fragment key={index}>
                                            <TableCell className={headClasses} data-cy={`${header}`}>
                                                {headers[header].split(' ').map(part =>
                                                    messages[header] ? (
                                                        <Tooltip
                                                            key={part}
                                                            title={messages[header]}
                                                            placement="top">
                                                            <div> {part} </div>
                                                        </Tooltip>
                                                    ) : (
                                                        <div key={part}> {part} </div>
                                                    )
                                                )}
                                            </TableCell>
                                            {index === 1 && showWaterpipeLink !== undefined && (
                                                <TableCell data-cy={`${header}`} className={headClasses}>
                                                    Raporty
                                                </TableCell>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                {showParseColumn !== undefined && isAdminRole() && (
                                    <TableCell className={headClasses}>
                                        {!isThrobberActive ? 'Parsuj' : ''}
                                    </TableCell>
                                )}
                                {showWaterpipeLink !== undefined && isAdminRole() && (
                                    <TableCell className={headClasses}>Edycja</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>{rows}</TableBody>
                    </MaterialUITable>
                </LoadingOverlay>
                <PopupModal
                    protocolV11DecryptedData={this.state.protocolV11DecryptedData}
                    handleClearProtocolV11DecryptedData={this.handleClearProtocolV11DecryptedData}
                />
            </Paper>
        ) : (
            <div>Fetching data...</div>
        );
    }
}

Table.propTypes = {
    classes: PropTypes.object.isRequired,
    headers: PropTypes.object.isRequired,
    content: PropTypes.array.isRequired,
};

export default withStyles(styles)(Table);
