import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core';
import StatisticsTable from './StatisticsTable';
import StatisticsChart from './StatisticsChart';
import { axiosInstance, cancelHttpReq } from '../../utils/axiosInstance';
import { endpoint } from '../../utils/constants';
import DisplayError from '../DisplayError/DisplayError';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}

const Statistics = ({ classes: {container} }) => {
    const [data, setData] = useState([])
    const [headers, setHeaders] = useState([])
    const [chartData, setChartData] = useState([])
    const [isServerError, setIsServerError] = useState(false)
    const [isUnauthorized, setIsUnauthorized] = useState(false)

    useEffect(() => {
        axiosInstance(endpoint.STATISTICS, {
            cancelToken: cancelHttpReq.token
        })
            .then((content) => {
                setData(content?.data?.data)
                setHeaders(content?.data?.headers)
                setChartData(content?.data?.chartData)
            })
            .catch((err) => {
                if (err?.response?.data?.message === 'Unauthenticated.') {
                    setIsUnauthorized(true)
                    setIsServerError(true)
                } else {
                    setIsServerError(true)
                }
            })
    }, [])


    return (
        <div className={container}>
            {!isServerError ? (
                <>
                    <StatisticsTable data={data} headers={headers} />
                    <StatisticsChart data={chartData} />
                </>
            ) : (
                <DisplayError isUnauthorized={isUnauthorized} />
            )}
        </div>
    )
}

export default withStyles(styles)(Statistics)
