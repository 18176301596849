import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import { axiosInstance } from '../../../../utils/axiosInstance';
import { endpoint, labels, snackbarMsg, snackbarVariant } from '../../../../utils/constants';
import PopupModal from '../../../PopupModal/PopupModal';

const useStyles = makeStyles({
    textFieldContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    textFieldInput: {
        width: '90vw',
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        width: '29.7rem',
    },
    icon: {
        fontSize: '1rem',
        marginLeft: '.6rem',
    },
});

const EncryptV11 = ({ handleEncryptedV11Value, handleSnackbar }) => {
    const { buttonsContainer, textFieldContainer, textFieldInput, icon } = useStyles();

    const [valueToEncryptOrDecrypt, setValueToEncryptOrDecrypt] = useState('');
    const [protocolV11DecryptedData, setProtocolV11DecryptedData] = useState('');

    const handleValue = e => {
        const { value } = e.target;
        setValueToEncryptOrDecrypt(value);
    };

    const handleEncryptPackage = () => {
        axiosInstance
            .post(endpoint.ENCRYPT_PACKAGE, {
                data: valueToEncryptOrDecrypt,
            })
            .then(res => {
                const encryptedPackage = res.data.data;
                handleEncryptedV11Value(encryptedPackage);
                handleSnackbar(true, snackbarMsg.PACKAGE_HAS_BEEN_ENCRYPTED, '', snackbarVariant.SUCCESS);
            })
            .catch(err => {
                console.error('errEncryptV11', err);
                handleSnackbar(true, snackbarMsg.ERROR, err.message, snackbarVariant.ERROR);
            });
    };

    const handleDecryptPackage = () => {
        axiosInstance
            .post(endpoint.DECRYPT_PACKAGE, {
                data: valueToEncryptOrDecrypt,
            })
            .then(res => {
                const decryptedPackage = res.data.data;
                setProtocolV11DecryptedData(decryptedPackage);
            })
            .catch(err => {
                console.error('errEncryptV11', err);
                handleSnackbar(true, snackbarMsg.ERROR, err.message, snackbarVariant.ERROR);
            });
    };

    const handleResetFieldsButton = () => {
        setValueToEncryptOrDecrypt('');
    };

    const handleClearProtocolV11DecryptedData = () => {
        setProtocolV11DecryptedData('');
    };
    return (
        <article className={textFieldContainer}>
            <TextField
                label={labels.PASTE_PACKAGE_DATA}
                multiline={true}
                minRows={2}
                maxRows={4}
                variant="outlined"
                value={valueToEncryptOrDecrypt}
                onChange={handleValue}
                className={textFieldInput}
                data-cy="test-package"
            />
            <div className={buttonsContainer}>
                <Button variant="contained" color="primary" onClick={handleEncryptPackage}>
                    Zakoduj pakiet <EnhancedEncryptionIcon className={icon} />
                </Button>
                <Button variant="contained" color="primary" onClick={handleDecryptPackage}>
                    Dekoduj pakiet <NoEncryptionIcon className={icon} />
                </Button>
                <Button variant="contained" color="secondary" onClick={handleResetFieldsButton}>
                    reset
                </Button>
            </div>
            <PopupModal
                protocolV11DecryptedData={protocolV11DecryptedData}
                handleClearProtocolV11DecryptedData={handleClearProtocolV11DecryptedData}
            />
        </article>
    );
};

export default EncryptV11;
