import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import ProtocolTypeConverter from './ProtocolTypeConverter/ProtocolTypeConverter';
import { toLittleEndian } from '../../../utils/helpers';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

export const summaryBodyV10fieldsToLittleEndian = [
    'alertSummaryReportID',
    'magneticAlertReportsCounter',
    'mechanicalAlertReportsCounter',
    'onDemandAlertReportsCounter',
    'lastSabotageCounterState',
    'summedUpAlertSummaryAmount',
    'summedUpAlertOccurrence',
    'firstAlertDuration',
    'secondAlertDuration',
    'thirdAlertDuration',
];

class SummaryBodyV10 extends Component {
    state = {
        alertCause: '',
        alertDay: '',
        alertHour: '',
        alertMinute: '',
        alertSecond: '',
        alertSummaryReportID: '',
        magneticAlertReportsCounter: '',
        mechanicalAlertReportsCounter: '',
        onDemandAlertReportsCounter: '',
        lastSabotageCounterState: '',
        radioTransmitterSabotageState: '',
        hubSabotageState: '',
        summedUpAlertSummaryAmount: '',
        summedUpAlertOccurrence: '',
        radioTransmitterPrealarmsAmount: '',
        firstAlertOccurrenceYear: '',
        firstAlertOccurrenceMonth: '',
        firstAlertOccurrenceDay: '',
        firstAlertOccurrenceHour: '',
        firstAlertOccurrenceMinute: '',
        firstAlertOccurrenceSecond: '',
        firstAlertDuration: '',
        secondAlertOccurrenceYear: '',
        secondAlertOccurrenceMonth: '',
        secondAlertOccurrenceDay: '',
        secondAlertOccurrenceHour: '',
        secondAlertOccurrenceMinute: '',
        secondAlertOccurrenceSecond: '',
        secondAlertDuration: '',
        thirdAlertOccurrenceYear: '',
        thirdAlertOccurrenceMonth: '',
        thirdAlertOccurrenceDay: '',
        thirdAlertOccurrenceHour: '',
        thirdAlertOccurrenceMinute: '',
        thirdAlertOccurrenceSecond: '',
        thirdAlertDuration: '',
        lastSabotageResolveYear: '',
        lastSabotageResolveMonth: '',
        lastSabotageResolveDay: '',
        lastSabotageResolveHour: '',
        lastSabotageResolveMinute: '',
        lastSabotageResolveSecond: '',
        reserve: '',
    };

    componentDidMount() {
        this.props.onSetProtocolType('W');
    }

    componentDidUpdate(prevProps) {
        const { schemeV10SummaryBody, selectedScheme, clearSelectedScheme } = this.props;
        if (
            schemeV10SummaryBody &&
            Object.keys(schemeV10SummaryBody).length > 0 &&
            prevProps.schemeV10SummaryBody !== schemeV10SummaryBody
        ) {
            this.setState(schemeV10SummaryBody);
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
        }
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value.toUpperCase() }, () =>
            this.props.getPackagesData(
                this.setLittleEndianInLivePreview({ ...this.state }),
                `packageBody${this.props.number}`
            )
        );
    };

    onSubmit = () => {
        this.props.saveSelectedFormState(this.props.number, this.state);
        return false;
    };

    clearState = () => {
        this.setState({
            alertCause: '',
            alertDay: '',
            alertHour: '',
            alertMinute: '',
            alertSecond: '',
            alertSummaryReportID: '',
            magneticAlertReportsCounter: '',
            mechanicalAlertReportsCounter: '',
            onDemandAlertReportsCounter: '',
            lastSabotageCounterState: '',
            radioTransmitterSabotageState: '',
            hubSabotageState: '',
            summedUpAlertSummaryAmount: '',
            summedUpAlertOccurrence: '',
            radioTransmitterPrealarmsAmount: '',
            firstAlertOccurrenceYear: '',
            firstAlertOccurrenceMonth: '',
            firstAlertOccurrenceDay: '',
            firstAlertOccurrenceHour: '',
            firstAlertOccurrenceMinute: '',
            firstAlertOccurrenceSecond: '',
            firstAlertDuration: '',
            secondAlertOccurrenceYear: '',
            secondAlertOccurrenceMonth: '',
            secondAlertOccurrenceDay: '',
            secondAlertOccurrenceHour: '',
            secondAlertOccurrenceMinute: '',
            secondAlertOccurrenceSecond: '',
            secondAlertDuration: '',
            thirdAlertOccurrenceYear: '',
            thirdAlertOccurrenceMonth: '',
            thirdAlertOccurrenceDay: '',
            thirdAlertOccurrenceHour: '',
            thirdAlertOccurrenceMinute: '',
            thirdAlertOccurrenceSecond: '',
            thirdAlertDuration: '',
            lastSabotageResolveYear: '',
            lastSabotageResolveMonth: '',
            lastSabotageResolveDay: '',
            lastSabotageResolveHour: '',
            lastSabotageResolveMinute: '',
            lastSabotageResolveSecond: '',
            reserve: '',
        });
    };

    setLittleEndianInLivePreview = value => {
        summaryBodyV10fieldsToLittleEndian.forEach(e => {
            value[e] = toLittleEndian(value[e]);
        });
        return value;
    };

    render() {
        const { classes, isCleared } = this.props;
        const {
            alertCause,
            alertDay,
            alertHour,
            alertMinute,
            alertSecond,
            alertSummaryReportID,
            magneticAlertReportsCounter,
            mechanicalAlertReportsCounter,
            onDemandAlertReportsCounter,
            lastSabotageCounterState,
            radioTransmitterSabotageState,
            hubSabotageState,
            summedUpAlertSummaryAmount,
            summedUpAlertOccurrence,
            radioTransmitterPrealarmsAmount,
            firstAlertOccurrenceYear,
            firstAlertOccurrenceMonth,
            firstAlertOccurrenceDay,
            firstAlertOccurrenceHour,
            firstAlertOccurrenceMinute,
            firstAlertOccurrenceSecond,
            firstAlertDuration,
            secondAlertOccurrenceYear,
            secondAlertOccurrenceMonth,
            secondAlertOccurrenceDay,
            secondAlertOccurrenceHour,
            secondAlertOccurrenceMinute,
            secondAlertOccurrenceSecond,
            secondAlertDuration,
            thirdAlertOccurrenceYear,
            thirdAlertOccurrenceMonth,
            thirdAlertOccurrenceDay,
            thirdAlertOccurrenceHour,
            thirdAlertOccurrenceMinute,
            thirdAlertOccurrenceSecond,
            thirdAlertDuration,
            lastSabotageResolveYear,
            lastSabotageResolveMonth,
            lastSabotageResolveDay,
            lastSabotageResolveHour,
            lastSabotageResolveMinute,
            lastSabotageResolveSecond,
            reserve,
        } = this.state;
        return (
            <div>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertCause}
                        onChange={value => this.handleChange('alertCause', value)}
                        label="Przyczyna alarmu: "
                        labelHex="wartości [0x4D, 0x47, 0x4E, 0x53, 0x4F]"
                        validators={['alertCause']}
                        interval={['4D', '47', '4E', '53', '4F']}
                        length={2}
                        dataCy="alertCause"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertDay}
                        onChange={value => this.handleChange('alertDay', value)}
                        label="Dzień powstania alarmu BCD: "
                        labelHex="przedział [0x01 - 0x31]"
                        validators={['isDay']}
                        interval={[1, 31]}
                        length={2}
                        dataCy="alertDay"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertHour}
                        onChange={value => this.handleChange('alertHour', value)}
                        label="Godzina powstania alarmu BCD: "
                        labelHex="przedział [0x00 - 0x23]"
                        validators={['isHour']}
                        interval={[0, 23]}
                        length={2}
                        dataCy="alertHour"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertMinute}
                        onChange={value => this.handleChange('alertMinute', value)}
                        label="Minuta powstania alarmu BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isMinute']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="alertMinute"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertSecond}
                        onChange={value => this.handleChange('alertSecond', value)}
                        label="Sekunda powstania alarmu BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isSecond']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="alertSecond"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertSummaryReportID}
                        onChange={value => this.handleChange('alertSummaryReportID', value)}
                        label="ID raportu alarmowego (dla którego zostało utworzone to podsumowanie): "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['alertSummaryReportID']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="alertSummaryReportID"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={magneticAlertReportsCounter}
                        onChange={value => this.handleChange('magneticAlertReportsCounter', value)}
                        label="Licznik ilości raportów alarmu magnetycznego (dla momentu utworzenia podsumowania): "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['magneticAlertReportsCounter']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="magneticAlertReportsCounter"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={mechanicalAlertReportsCounter}
                        onChange={value => this.handleChange('mechanicalAlertReportsCounter', value)}
                        label="Licznik ilości raportów alarmu mechanicznego (dla momentu utworzenia podsumowania): "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['mechanicalAlertReportsCounter']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="mechanicalAlertReportsCounter"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={onDemandAlertReportsCounter}
                        onChange={value => this.handleChange('onDemandAlertReportsCounter', value)}
                        label="Licznik ilości raportów na żądanie (dla momentu utworzenia podsumowania): "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['onDemandAlertReportsCounter']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="onDemandAlertReportsCounter"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastSabotageCounterState}
                        onChange={value => this.handleChange('lastSabotageCounterState', value)}
                        label="Stan licznika na godz. ustąpienia ostatniego sabotażu [0,001 m3]: "
                        labelHex="przedział (0 - 999 999 999) [0x00000000 - 3B9AC9FF]"
                        validators={['lastSabotageCounterState']}
                        interval={['00000000', '3B9AC9FF']}
                        length={8}
                        dataCy="lastSabotageCounterState"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={radioTransmitterSabotageState}
                        onChange={value => this.handleChange('radioTransmitterSabotageState', value)}
                        label="Przepływ / stan sabotaży w nadajniku radiowym: "
                        labelHex="przedział [0x00 - 0x1F]"
                        validators={['radioTransmitterSabotageState']}
                        interval={['00', '1F', '78']}
                        length={2}
                        dataCy="radioTransmitterSabotageState"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={hubSabotageState}
                        onChange={value => this.handleChange('hubSabotageState', value)}
                        label="Stan sabotaży w koncentrat. bit0-zdjęcie pokrywki, bit1-oderwanie od ściany: "
                        labelHex="przedział [0x00 - 0x03]"
                        validators={['hubSabotageState']}
                        interval={['00', '03', '78']}
                        length={2}
                        dataCy="hubSabotageState"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={summedUpAlertSummaryAmount}
                        onChange={value => this.handleChange('summedUpAlertSummaryAmount', value)}
                        label="Ilość wystąpień podsumowywanego alarmu pomiędzy wygenerowaniem raportu alarmowego i bieżącego podsumowania: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['summedUpAlertSummaryAmount']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="summedUpAlertSummaryAmount"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={summedUpAlertOccurrence}
                        onChange={value => this.handleChange('summedUpAlertOccurrence', value)}
                        label="Łączny czas występowania alarmów od wygenerowania raportu alarmowego do utworzenia bieżącego podsumowania [min]: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['summedUpAlertOccurrence']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="summedUpAlertOccurrence"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={radioTransmitterPrealarmsAmount}
                        onChange={value => this.handleChange('radioTransmitterPrealarmsAmount', value)}
                        label="Liczba prealarmów w nadajniku radiowym od początku doby: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['radioTransmitterPrealarmsAmount']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="radioTransmitterPrealarmsAmount"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={firstAlertOccurrenceYear}
                        onChange={value => this.handleChange('firstAlertOccurrenceYear', value)}
                        label="Rok wystąpienia alarmu 1 - BCD: "
                        labelHex="przedział [0x00 - 0x99]"
                        validators={['isYear']}
                        interval={[0, 99]}
                        length={2}
                        dataCy="firstAlertOccurrenceYear"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={firstAlertOccurrenceMonth}
                        onChange={value => this.handleChange('firstAlertOccurrenceMonth', value)}
                        label="Miesiąc wystąpienia alarmu 1 - BCD: "
                        labelHex="przedział [0x01 - 0x12]"
                        validators={['isMonth']}
                        interval={[1, 12]}
                        length={2}
                        dataCy="firstAlertOccurrenceMonth"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={firstAlertOccurrenceDay}
                        onChange={value => this.handleChange('firstAlertOccurrenceDay', value)}
                        label="Dzień wystąpienia alarmu 1 - BCD: "
                        labelHex="przedział [0x01 - 0x31]"
                        validators={['isDay']}
                        interval={[1, 31]}
                        length={2}
                        dataCy="firstAlertOccurrenceDay"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={firstAlertOccurrenceHour}
                        onChange={value => this.handleChange('firstAlertOccurrenceHour', value)}
                        label="Godzina wystąpienia alarmu 1 - BCD: "
                        labelHex="przedział [0x00 - 0x23]"
                        validators={['isHour']}
                        interval={[0, 23]}
                        length={2}
                        dataCy="firstAlertOccurrenceHour"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={firstAlertOccurrenceMinute}
                        onChange={value => this.handleChange('firstAlertOccurrenceMinute', value)}
                        label="Minuta wystąpienia alarmu 1 - BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isMinute']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="firstAlertOccurrenceMinute"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={firstAlertOccurrenceSecond}
                        onChange={value => this.handleChange('firstAlertOccurrenceSecond', value)}
                        label="Sekunda wystąpienia alarmu 1 - BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isSecond']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="firstAlertOccurrenceSecond"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={firstAlertDuration}
                        onChange={value => this.handleChange('firstAlertDuration', value)}
                        label="Czas trwania alarmu 1 [sek]: "
                        labelHex="przedział (0 - 4 294 967 295) [0x00000000 - 0xFFFFFFFF]"
                        validators={['firstAlertDuration']}
                        interval={['00000000', 'FFFFFFFF']}
                        length={8}
                        dataCy="firstAlertDuration"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={secondAlertOccurrenceYear}
                        onChange={value => this.handleChange('secondAlertOccurrenceYear', value)}
                        label="Rok wystąpienia alarmu 2 - BCD: "
                        labelHex="przedział [0x00 - 0x99]"
                        validators={['isYear']}
                        interval={[0, 99]}
                        length={2}
                        dataCy="secondAlertOccurrenceYear"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={secondAlertOccurrenceMonth}
                        onChange={value => this.handleChange('secondAlertOccurrenceMonth', value)}
                        label="Miesiąc wystąpienia alarmu 2 - BCD: "
                        labelHex="przedział [0x01 - 0x12]"
                        validators={['isMonth']}
                        interval={[1, 12]}
                        length={2}
                        dataCy="secondAlertOccurrenceMonth"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={secondAlertOccurrenceDay}
                        onChange={value => this.handleChange('secondAlertOccurrenceDay', value)}
                        label="Dzień wystąpienia alarmu 2 - BCD: "
                        labelHex="przedział [0x01 - 0x31]"
                        validators={['isDay']}
                        interval={[1, 31]}
                        length={2}
                        dataCy="secondAlertOccurrenceDay"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={secondAlertOccurrenceHour}
                        onChange={value => this.handleChange('secondAlertOccurrenceHour', value)}
                        label="Godzina wystąpienia alarmu 2 - BCD: "
                        labelHex="przedział [0x00 - 0x23]"
                        validators={['isHour']}
                        interval={[0, 23]}
                        length={2}
                        dataCy="secondAlertOccurrenceHour"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={secondAlertOccurrenceMinute}
                        onChange={value => this.handleChange('secondAlertOccurrenceMinute', value)}
                        label="Minuta wystąpienia alarmu 2 - BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isMinute']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="secondAlertOccurrenceMinute"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={secondAlertOccurrenceSecond}
                        onChange={value => this.handleChange('secondAlertOccurrenceSecond', value)}
                        label="Sekunda wystąpienia alarmu 2 - BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isSecond']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="secondAlertOccurrenceSecond"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={secondAlertDuration}
                        onChange={value => this.handleChange('secondAlertDuration', value)}
                        label="Czas trwania alarmu 2 [sek]: "
                        labelHex="przedział (0 - 4 294 967 295) [0x00000000 - 0xFFFFFFFF]"
                        validators={['secondAlertDuration']}
                        interval={['00000000', 'FFFFFFFF']}
                        length={8}
                        dataCy="secondAlertDuration"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={thirdAlertOccurrenceYear}
                        onChange={value => this.handleChange('thirdAlertOccurrenceYear', value)}
                        label="Rok wystąpienia alarmu 3 - BCD: "
                        labelHex="przedział [0x00 - 0x99]"
                        validators={['isYear']}
                        interval={[0, 99]}
                        length={2}
                        dataCy="thirdAlertOccurrenceYear"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={thirdAlertOccurrenceMonth}
                        onChange={value => this.handleChange('thirdAlertOccurrenceMonth', value)}
                        label="Miesiąc wystąpienia alarmu 3 - BCD: "
                        labelHex="przedział [0x01 - 0x12]"
                        validators={['isMonth']}
                        interval={[1, 12]}
                        length={2}
                        dataCy="thirdAlertOccurrenceMonth"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={thirdAlertOccurrenceDay}
                        onChange={value => this.handleChange('thirdAlertOccurrenceDay', value)}
                        label="Dzień wystąpienia alarmu 3 - BCD: "
                        labelHex="przedział [0x01 - 0x31]"
                        validators={['isDay']}
                        interval={[1, 31]}
                        length={2}
                        dataCy="thirdAlertOccurrenceDay"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={thirdAlertOccurrenceHour}
                        onChange={value => this.handleChange('thirdAlertOccurrenceHour', value)}
                        label="Godzina wystąpienia alarmu 3 - BCD: "
                        labelHex="przedział [0x00 - 0x23]"
                        validators={['isHour']}
                        interval={[0, 23]}
                        length={2}
                        dataCy="thirdAlertOccurrenceHour"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={thirdAlertOccurrenceMinute}
                        onChange={value => this.handleChange('thirdAlertOccurrenceMinute', value)}
                        label="Minuta wystąpienia alarmu 3 - BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isMinute']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="thirdAlertOccurrenceMinute"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={thirdAlertOccurrenceSecond}
                        onChange={value => this.handleChange('thirdAlertOccurrenceSecond', value)}
                        label="Sekunda wystąpienia alarmu 3 - BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isSecond']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="thirdAlertOccurrenceSecond"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={thirdAlertDuration}
                        onChange={value => this.handleChange('thirdAlertDuration', value)}
                        label="Czas trwania alarmu 3 [sek]: "
                        labelHex="przedział (0 - 4 294 967 295) [0x00000000 - 0xFFFFFFFF]"
                        validators={['thirdAlertDuration']}
                        interval={['00000000', 'FFFFFFFF']}
                        length={8}
                        dataCy="thirdAlertDuration"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastSabotageResolveYear}
                        onChange={value => this.handleChange('lastSabotageResolveYear', value)}
                        label="Rok ustąpienia ostatniego sabotażu: - BCD: "
                        labelHex="przedział [0x00 - 0x99]"
                        validators={['isYear']}
                        interval={[0, 99]}
                        length={2}
                        dataCy="lastSabotageResolveYear"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastSabotageResolveMonth}
                        onChange={value => this.handleChange('lastSabotageResolveMonth', value)}
                        label="Miesiąc ustąpienia ostatniego sabotażu: - BCD: "
                        labelHex="przedział [0x01 - 0x12]"
                        validators={['isMonth']}
                        interval={[1, 12]}
                        length={2}
                        dataCy="lastSabotageResolveMonth"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastSabotageResolveDay}
                        onChange={value => this.handleChange('lastSabotageResolveDay', value)}
                        label="Dzień ustąpienia ostatniego sabotażu: - BCD: "
                        labelHex="przedział [0x01 - 0x31]"
                        validators={['isDay']}
                        interval={[1, 31]}
                        length={2}
                        dataCy="lastSabotageResolveDay"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastSabotageResolveHour}
                        onChange={value => this.handleChange('lastSabotageResolveHour', value)}
                        label="Godzina ustąpienia ostatniego sabotażu: - BCD: "
                        labelHex="przedział [0x00 - 0x23]"
                        validators={['isHour']}
                        interval={[0, 23]}
                        length={2}
                        dataCy="lastSabotageResolveHour"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastSabotageResolveMinute}
                        onChange={value => this.handleChange('lastSabotageResolveMinute', value)}
                        label="Minuta ustąpienia ostatniego sabotażu: - BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isMinute']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="lastSabotageResolveMinute"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastSabotageResolveSecond}
                        onChange={value => this.handleChange('lastSabotageResolveSecond', value)}
                        label="Sekunda ustąpienia ostatniego sabotażu: - BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isSecond']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="lastSabotageResolveSecond"
                    />

                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={reserve}
                        onChange={e => this.handleChange('reserve', e.target.value)}
                        label="Rezerwa 'x': 37 wartości hex [0x78]"
                        validators={['matchRegexp:^(78){37}$']}
                        errorMessages={['Niepoprawny format']}
                        data-cy="reserve"
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        value="Submit"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

export default withStyles(styles)(SummaryBodyV10);
