import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

class HeaderV8 extends Component {
    state = {
        protocolType: '',
        transmissionChannel: '',
        customerNumber: '',
        hubNumber: '',
        data: '',
        GSMLevel: '',
        GSMOrGPRSwitchOnAmount: '',
        GSMOrGPRProperCommunicationAmount: '',
        hubSwitchOnTimeForGSMModule: '',
        hubSwitchOnRadioModule: '',
        buffersReportAmount: '',
        hubPurpose: '',
        hubFirmWareID: '',
        communicationFrequencyBand: '',
        hubTemperature: '',
        batteryHubPercentageLevel: '',
        hubBatteryVoltage: '',
        GSMLevelOnFirstReportProviding: '',
        measuringChannelNum: '',
        hubOrPipelineTransmitterNum: '',
        radioTransmitterStabilisedVoltage: '',
        studyingState: '',
        firstSolenoidSignalQuality: '',
        secondSolenoidSignalQuality: '',
        thirdSolenoidSignalQuality: '',
        transmitterPurpose: '',
        radioTransmitterFirmwareID: '',
        radioTransmitterTemperature: '',
        radioTransmitterBatteryUsage: '',
        radioTransmitterBatteryVoltage: '',
    };

    componentDidMount() {
        this.setHeaderProtocolType();
    }

    componentDidUpdate(prevProps) {
        const { protocolType, schemeV8Header, selectedScheme, clearSelectedScheme } = this.props;
        if (prevProps.protocolType !== protocolType) {
            this.setHeaderProtocolType();
        }
        if (prevProps.schemeV8Header !== schemeV8Header) {
            this.setState({ ...schemeV8Header });
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
            this.setHeaderProtocolType();
        }
    }

    handleChange = (key, event) => {
        this.setState({ [key]: event.target.value }, () => {
            this.props.getPackagesData(this.state, 'header');
        });
    };

    onSubmit = () => {
        this.props.saveHeaderFormState(this.state);
        return false;
    };

    setHeaderProtocolType = () => {
        const { protocolType } = this.props;
        this.setState({ protocolType });
        this.handleChange('protocolType', {
            target: { value: protocolType },
        });
    };

    clearState = () => {
        this.setState({
            protocolType: '',
            transmissionChannel: '',
            customerNumber: '',
            hubNumber: '',
            data: '',
            GSMLevel: '',
            GSMOrGPRSwitchOnAmount: '',
            GSMOrGPRProperCommunicationAmount: '',
            hubSwitchOnTimeForGSMModule: '',
            hubSwitchOnRadioModule: '',
            buffersReportAmount: '',
            hubPurpose: '',
            hubFirmWareID: '',
            communicationFrequencyBand: '',
            hubTemperature: '',
            batteryHubPercentageLevel: '',
            hubBatteryVoltage: '',
            GSMLevelOnFirstReportProviding: '',
            measuringChannelNum: '',
            hubOrPipelineTransmitterNum: '',
            radioTransmitterStabilisedVoltage: '',
            studyingState: '',
            firstSolenoidSignalQuality: '',
            secondSolenoidSignalQuality: '',
            thirdSolenoidSignalQuality: '',
            transmitterPurpose: '',
            radioTransmitterFirmwareID: '',
            radioTransmitterTemperature: '',
            radioTransmitterBatteryUsage: '',
            radioTransmitterBatteryVoltage: '',
        });
    };

    render() {
        const { classes } = this.props;
        const {
            protocolType,
            transmissionChannel,
            customerNumber,
            hubNumber,
            data,
            GSMLevel,
            GSMOrGPRSwitchOnAmount,
            GSMOrGPRProperCommunicationAmount,
            hubSwitchOnTimeForGSMModule,
            hubSwitchOnRadioModule,
            buffersReportAmount,
            hubPurpose,
            hubFirmWareID,
            communicationFrequencyBand,
            hubTemperature,
            batteryHubPercentageLevel,
            hubBatteryVoltage,
            GSMLevelOnFirstReportProviding,
            measuringChannelNum,
            hubOrPipelineTransmitterNum,
            radioTransmitterStabilisedVoltage,
            studyingState,
            firstSolenoidSignalQuality,
            secondSolenoidSignalQuality,
            thirdSolenoidSignalQuality,
            transmitterPurpose,
            radioTransmitterFirmwareID,
            radioTransmitterTemperature,
            radioTransmitterBatteryUsage,
            radioTransmitterBatteryVoltage,
        } = this.state;

        return (
            <div>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <TextValidator
                        label="Rodzaj Protokołu: 1 znak [0,6-9,A-Z,a-z] np. b"
                        className={classes.margin}
                        value={protocolType}
                        validators={['matchRegexp:^([6-9]|[A-Za-z]|[0]?)$']}
                        variant="outlined"
                        errorMessages={['Niepoprawny format']}
                        required
                        disabled
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={transmissionChannel}
                        onChange={e => this.handleChange('transmissionChannel', e)}
                        label="Nr kanału transmisyjnego: 1 znak [1-5] np. 1"
                        validators={['matchRegexp:^([1-5]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="transmissionChannel"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={customerNumber}
                        onChange={e => this.handleChange('customerNumber', e)}
                        label="Numer odbiorcy: 1 znak [0-9,a-z,A-Z,inne znaki ASCII] np. 1"
                        validators={['matchRegexp:^(.){1}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="customerNumber"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubNumber}
                        onChange={e => this.handleChange('hubNumber', e)}
                        label="Numer komunikatora: 8 znaków [00000000-9999999] np. 12345678"
                        validators={['matchRegexp:^([0-9]{8})$']}
                        errorMessages={['Niepoprawny format']}
                        data-cy="hubNumber"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={data}
                        onChange={e => this.handleChange('data', e)}
                        label="Rok, miesiąc: 4 znaki [0-9] np. 1908"
                        validators={['matchRegexp:^([0-9]{2})(-?)(1[0-2]|0[1-9])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="data"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMLevel}
                        onChange={e => this.handleChange('GSMLevel', e)}
                        label="Poziom GSM: 1 znak [0-9,A-W] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-W]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMLevel"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMOrGPRSwitchOnAmount}
                        onChange={e => this.handleChange('GSMOrGPRSwitchOnAmount', e)}
                        label="Ilość wszystkich włączeń modułu GSM/GPRS: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMOrGPRSwitchOnAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMOrGPRProperCommunicationAmount}
                        onChange={e => this.handleChange('GSMOrGPRProperCommunicationAmount', e)}
                        label="Ilość poprawnych komunikacji przez moduł GSM/GPRS: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMOrGPRProperCommunicationAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubSwitchOnTimeForGSMModule}
                        onChange={e => this.handleChange('hubSwitchOnTimeForGSMModule', e)}
                        label="Czas włączenia modułu GSM/GPRS w koncentratorze [min]: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubSwitchOnTimeForGSMModule"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubSwitchOnRadioModule}
                        onChange={e => this.handleChange('hubSwitchOnRadioModule', e)}
                        label="Czas włączenia modułu radiowego w koncentratorze [min]: 5 znaków [00000-99999] np. 12345"
                        validators={['matchRegexp:^([0-9]{5})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubSwitchOnRadioModule"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={buffersReportAmount}
                        onChange={e => this.handleChange('buffersReportAmount', e)}
                        label="Ilość raportów w buforze: 3 znaki [000-999] np. 123"
                        validators={['matchRegexp:^([000-999]){3}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="buffersReportAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubPurpose}
                        onChange={e => this.handleChange('hubPurpose', e)}
                        label="Przeznaczenie koncentratora: antena świnka, PCB, konektor, zalany, itp.: 1 znak [wszystkie znaki widzialne ASCII - 0x21 - 0x7E] np. 1"
                        validators={['matchRegexp:^[^ ]{1}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubPurpose"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubFirmWareID}
                        onChange={e => this.handleChange('hubFirmWareID', e)}
                        label="Firmware ID koncetratora: 4 znaki [wszystkie znaki widzialne ASCII - 0x20 - 0x7E] np. 1aA2"
                        validators={['matchRegexp:^(.){4}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubFirmWareID"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={communicationFrequencyBand}
                        onChange={e => this.handleChange('communicationFrequencyBand', e)}
                        label="Pasmo częstotliwościowe komunikacji z siecią GSM: 1 znak [' ',0-3] np. 1"
                        validators={['matchRegexp:^([ 0-9])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="communicationFrequencyBand"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubTemperature}
                        onChange={e => this.handleChange('hubTemperature', e)}
                        label="Temperatura koncentratora: 1 znak [0-9,a-z,A-Z] np. 1"
                        validators={['matchRegexp:^([0-9A-Za-z])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubTemperature"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={batteryHubPercentageLevel}
                        onChange={e => this.handleChange('batteryHubPercentageLevel', e)}
                        label="Procentowy stan baterii koncentratora: 1 znak [A,0-9] np. 1"
                        validators={['matchRegexp:^([0-9A])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="batteryHubPercentageLevel"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubBatteryVoltage}
                        onChange={e => this.handleChange('hubBatteryVoltage', e)}
                        label="Napięcie baterii koncentratora: 1 znak [0-9,a-p,A-Z] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-Z]|[a-p])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubBatteryVoltage"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMLevelOnFirstReportProviding}
                        onChange={e => this.handleChange('GSMLevelOnFirstReportProviding', e)}
                        label="Poziom GSM przy pierwszej próbie wysłania bieżącego raportu: 1 znak [0-9,A-W] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-W])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMLevelOnFirstReportProviding"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={measuringChannelNum}
                        onChange={e => this.handleChange('measuringChannelNum', e)}
                        label="Nr kanału pomiarowego: 1 znak [0,1] np. 1 "
                        validators={['matchRegexp:^([0-1])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="measuringChannelNum"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubOrPipelineTransmitterNum}
                        onChange={e => this.handleChange('hubOrPipelineTransmitterNum', e)}
                        label="Nr nadajnika radiowego na wodomierzu / nr koncentratora: 8 znaków ['43000000-43999999'lub '44000000-44999999'] np. 12345678 "
                        validators={['matchRegexp:^([43000000-43999999]|[44000000-44999999]){8}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubOrPipelineTransmitterNum"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={radioTransmitterStabilisedVoltage}
                        onChange={e => this.handleChange('radioTransmitterStabilisedVoltage', e)}
                        label="Napięcie stabilizowane nadajnika radiowego: 1 znak [0-9,a-p,A-ZxX] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-Z]|[a-px])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="radioTransmitterStabilisedVoltage"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={studyingState}
                        onChange={e => this.handleChange('studyingState', e)}
                        label="Stan 'uczenia się': 1 znak [0-9,a-z,A-O,xX] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-OX]|[a-z])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="studyingState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firstSolenoidSignalQuality}
                        onChange={e => this.handleChange('firstSolenoidSignalQuality', e)}
                        label="Jakość sygnału cewki 1: 1 znak [0-9,a-z,A-Z,@,#,$,%,^,&,*,?,<] np. 1"
                        validators={['matchRegexp:^([0-9A-Za-z@#$%^&*?<])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firstSolenoidSignalQuality"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={secondSolenoidSignalQuality}
                        onChange={e => this.handleChange('secondSolenoidSignalQuality', e)}
                        label="Jakość sygnału cewki 2: 1 znak [0-9,a-z,A-Z,@,#,$,%,^,&,*,?,<] np. 1"
                        validators={['matchRegexp:^([0-9A-Za-z@#$%^&*?<])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="secondSolenoidSignalQuality"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={thirdSolenoidSignalQuality}
                        onChange={e => this.handleChange('thirdSolenoidSignalQuality', e)}
                        label="Jakość sygnału cewki 3: 1 znak [0-9,a-z,A-Z,@,#,$,%,^,&,*,?,<] np. 1"
                        validators={['matchRegexp:^([0-9A-Za-z@#$%^&*?<])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="thirdSolenoidSignalQuality"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={transmitterPurpose}
                        onChange={e => this.handleChange('transmitterPurpose', e)}
                        label="Przeznaczenie nadajnika: antena ceramiczna, PCB..., zalany, niezalany...: 1 znak [0-9,a-z,A-Z] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-Z]|[a-z])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="transmitterPurpose"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={radioTransmitterFirmwareID}
                        onChange={e => this.handleChange('radioTransmitterFirmwareID', e)}
                        label="Firmware ID nadajnika radiowego: 4 znaki [0-9,a-z,A-Z,*,#] np. #12A"
                        validators={['matchRegexp:^([0-9A-Za-z*#]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="radioTransmitterFirmwareID"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={radioTransmitterTemperature}
                        onChange={e => this.handleChange('radioTransmitterTemperature', e)}
                        label="Temperatura nadajnika radiowego: 1 znak [0-9,a-z,A-Z] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-Z]|[a-z])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="radioTransmitterTemperature"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={radioTransmitterBatteryUsage}
                        onChange={e => this.handleChange('radioTransmitterBatteryUsage', e)}
                        label="Zużycie baterii nadajnika radiowego [mAh]: 4 znaki [0-9,A-FxX] np. 1234"
                        validators={['matchRegexp:^([0-9]|[A-FxX]){4}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="radioTransmitterBatteryUsage"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={radioTransmitterBatteryVoltage}
                        onChange={e => this.handleChange('radioTransmitterBatteryVoltage', e)}
                        label="Napięcie baterii nadajnika radiowego: 1 znak [0-9,a-p,A-Z] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-ZX]|[a-p])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="radioTransmitterBatteryVoltage"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        value="Submit"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

HeaderV8.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderV8);
