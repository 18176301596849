import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

class AlertBodyV9 extends React.Component {
    state = {
        alertCause: '',
        alertDate: '',
        alertReportID: '',
        magneticAlertReportsCounter: '',
        mechanicalAlertReportsCounter: '',
        onDemandAlertReportsCounter: '',
        alertCounterState: '',
        sabotagesState: '',
        reserve1: '',
        firmWareID: '',
        IMEI: '',
        IMSI: '',
        ICCID: '',
        GSMLevelOnFirstReport: '',
        reserve2: '',
    };

    componentDidMount() {
        const { schemeV9AlertBody, onSetProtocolType } = this.props;
        onSetProtocolType('C');
        this.setState({ ...schemeV9AlertBody });
    }

    componentDidUpdate(prevProps) {
        const { schemeV9AlertBody, selectedScheme, clearSelectedScheme } = this.props;
        if (
            schemeV9AlertBody &&
            Object.keys(schemeV9AlertBody).length > 0 &&
            prevProps.schemeV9AlertBody !== schemeV9AlertBody
        ) {
            this.setState(schemeV9AlertBody);
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
        }
    }

    handleChange = (key, event) => {
        this.setState({ [key]: event.target.value }, () =>
            this.props.getPackagesData(this.state, `packageBody${this.props.number}`)
        );
    };

    onSubmit = () => {
        this.props.saveSelectedFormState(this.props.number, this.state);
        return false;
    };

    clearState = () => {
        this.setState({
            alertCause: '',
            alertDate: '',
            alertReportID: '',
            magneticAlertReportsCounter: '',
            mechanicalAlertReportsCounter: '',
            onDemandAlertReportsCounter: '',
            alertCounterState: '',
            sabotagesState: '',
            reserve1: '',
            firmWareID: '',
            IMEI: '',
            IMSI: '',
            ICCID: '',
            GSMLevelOnFirstReport: '',
            reserve2: '',
        });
    };

    render() {
        const { classes } = this.props;
        const {
            alertCause,
            alertDate,
            alertReportID,
            magneticAlertReportsCounter,
            mechanicalAlertReportsCounter,
            onDemandAlertReportsCounter,
            alertCounterState,
            sabotagesState,
            reserve1,
            firmWareID,
            IMEI,
            IMSI,
            ICCID,
            GSMLevelOnFirstReport,
            reserve2,
        } = this.state;
        return (
            <div className={classes.root}>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertCause}
                        onChange={e => this.handleChange('alertCause', e)}
                        label="Przyczyna alarmu: 1 znak [G,M,N] np. G"
                        validators={['matchRegexp:^([G]|[N]|[M])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertCause"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertDate}
                        onChange={e => this.handleChange('alertDate', e)}
                        label="Dzień, godz., min., sek. powstania alarmu: 8 znaków [0-9] np.12345678"
                        validators={['matchRegexp:^([0-9]{8})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertReportID}
                        onChange={e => this.handleChange('alertReportID', e)}
                        label="ID raportu alarmowego: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertReportID"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={magneticAlertReportsCounter}
                        onChange={e => this.handleChange('magneticAlertReportsCounter', e)}
                        label="Licznik ilości raportów alarmu magnetycznego: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="magneticAlertReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={mechanicalAlertReportsCounter}
                        onChange={e => this.handleChange('mechanicalAlertReportsCounter', e)}
                        label="Licznik ilości raportów alarmu mechanicznego: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="mechanicalAlertReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={onDemandAlertReportsCounter}
                        onChange={e => this.handleChange('onDemandAlertReportsCounter', e)}
                        label="Licznik ilości raportów na żądanie: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="onDemandAlertReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertCounterState}
                        onChange={e => this.handleChange('alertCounterState', e)}
                        label="Stan licznika na godz. powstania alarmu [0,001 m3]: 9 znaków [000000000-999999999] np. 123456789"
                        validators={['matchRegexp:^([0-9]{9})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertCounterState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotagesState}
                        onChange={e => this.handleChange('sabotagesState', e)}
                        label="Stan sabotaży: 1 znak [+,-,m,M,g,G,d,D] np. m"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotagesState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={reserve1}
                        onChange={e => this.handleChange('reserve1', e)}
                        label="Rezerwa: 10 znaków [x] np. xxxxxxxxxx"
                        validators={['matchRegexp:^([x]{10})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="reserve1"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firmWareID}
                        onChange={e => this.handleChange('firmWareID', e)}
                        label="Wersja firmware: 8 znaków [0-9,a-z,A-Z] np. 12345678"
                        validators={['matchRegexp:^(([0-9A-Za-z]){8})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firmWareIDBody"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={IMEI}
                        onChange={e => this.handleChange('IMEI', e)}
                        label="IMEI: 15 znaków [0-9,a-z,A-Z] np. 123456789012345"
                        validators={['matchRegexp:^([0-9A-Za-z]{15})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="IMEI"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={IMSI}
                        onChange={e => this.handleChange('IMSI', e)}
                        label="IMSI: 15 znaków [0-9,a-z,A-Z] np. 123456789012345"
                        validators={['matchRegexp:^([0-9A-Za-z]{15})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="IMSI"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={ICCID}
                        onChange={e => this.handleChange('ICCID', e)}
                        label="ICCID: 20 znaków [0-9,a-z,A-Z] np. 12345abcdeABCDE12345"
                        validators={['matchRegexp:^([0-9A-Za-z]{20})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="ICCID"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMLevelOnFirstReport}
                        onChange={e => this.handleChange('GSMLevelOnFirstReport', e)}
                        label="poziom GSM przy pierwszej próbie wysłania bieżącego raportu: 1 znak [0-9,A-W] np. 1"
                        validators={['matchRegexp:^([0-9A-W])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMLevelOnFirstReport"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={reserve2}
                        onChange={e => this.handleChange('reserve2', e)}
                        label="Rezerwa: 17 znaków [x] np. xxxxxxxxxxxxxxxxx"
                        validators={['matchRegexp:^([x]{17})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="reserve2"
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

AlertBodyV9.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlertBodyV9);
