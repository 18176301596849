/* eslint-disable camelcase */
import React, { Component } from 'react';
import { axiosInstance, cancelToken } from '../../utils/axiosInstance';
import { getToken, getVisualDividedNumber } from '../../utils/helpers';
import CountButtonResult from '../CountButtonResult/CountButtonResult';
import { endpoint, waterpipesAll } from '../../utils/constants';

class CountPackages extends Component {
    state = {
        countedPackages: 'Pakiety są zliczane...',
        isCounting: false,
    };

    componentDidMount() {
        this.cancelHttpReq = cancelToken.source();
    }

    componentDidUpdate(prevProps) {
        const { selectedWaterpipe, selectedStatus, protocol, id, data, document_type, sim, com } = this.props;

        if (
            prevProps.selectedWaterpipe !== selectedWaterpipe ||
            prevProps.selectedStatus !== selectedStatus ||
            prevProps.protocol !== protocol ||
            prevProps.id !== id ||
            prevProps.data !== data ||
            prevProps.document_type !== document_type ||
            prevProps.sim !== sim ||
            prevProps.com !== com
        ) {
            this.setState({ isCounting: false });
        }
    }

    componentWillUnmount() {
        if (this.cancelHttpReq !== undefined) {
            this.cancelHttpReq.cancel();
        }
    }

    getCountedAmountPackages = () => {
        const {
            selectedWaterpipe,
            selectedStatus,
            protocol,
            history,
            id,
            data,
            document_type,
            sim,
            com,
            isCountDuplicate,
            isCountShowOnlyOffline,
        } = this.props;

        this.setState({
            countedPackages: 'Pakiety są zliczane...',
            isCounting: true,
        });

        const selectWaterpipes = () => {
            if (selectedWaterpipe === waterpipesAll.name) {
                return '';
            }
            return selectedWaterpipe;
        };

        axiosInstance
            .get(endpoint.PACKAGES, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
                params: {
                    waterpipe: selectWaterpipes(),
                    status: selectedStatus,
                    count: true,
                    protocol,
                    id,
                    data,
                    document_type,
                    sim,
                    communicator: com,
                    duplicate_hide: isCountDuplicate,
                    offline: isCountShowOnlyOffline ? 2 : null,
                },

                cancelToken: this.cancelHttpReq.token,
            })
            .then(content => {
                this.setState({
                    countedPackages: <strong>{getVisualDividedNumber(content.data.count)}</strong>,
                });
            })
            .catch(err => {
                this.setState({
                    countedPackages: `Błąd serwera`,
                });
                if (err.message === undefined) {
                    return;
                }
                if (err.response.status === 401 && history !== undefined) {
                    history.push(`/login`);
                }
            });
    };

    render() {
        const { isCounting, countedPackages } = this.state;
        return (
            <CountButtonResult
                getCountedAmountPackages={this.getCountedAmountPackages}
                isCounting={isCounting}
                countedList={countedPackages}
            />
        );
    }
}

export default CountPackages;
