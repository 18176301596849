/* eslint-disable chai-friendly/no-unused-expressions */
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import React, { useState, useEffect } from 'react';
import { useTimer } from 'reactjs-countdown-hook';
import { withStyles } from '@material-ui/styles';
import { axiosInstance } from '../../utils/axiosInstance';
import {
    columnTypes,
    endpoint,
    localStorageKey,
    snackbarDefaultValue,
    snackbarMsg,
    snackbarVariant,
    tabHeadToSymbols,
    tabHeadToSymbolsToTitles,
    tableTranslations,
    waterpipesAll,
} from '../../utils/constants';
import theme from '../../themes/theme';
import PaginationRows from '../Pagination/PaginationRows';
import showIcon from '../../assets/img/show-icon.svg';
import deleteIcon from '../../assets/img/delete-icon.svg';
import { dateDiff, getDate } from '../../utils/helpers';
import Snackbar from '../Snackbar/Snackbar';

const styles = {
    container: {
        position: 'relative',
        height: 'calc(100% - 50px - 1rem)',
    },
    table: {
        height: '100%',
        fontFamily: theme.font.family,
        position: 'relative',
    },
    tableHeader: {
        borderBottom: 'none',
        background: theme.colors.panelBackground,
        border: `1px solid ${theme.colors.border}`,
        display: 'flex',
        marginTop: 30,
    },
    tableName: {
        fontWeight: theme.font.weight.bold,
        marginLeft: 25,
        width: '100%',
    },
    errorMessage: {
        color: 'red',
        fontWeight: theme.font.weight.bold,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        userSelect: 'none',
    },
};

const TestListOrdered = ({ classes: { container, tableHeader, tableName, errorMessage } }) => {
    const REFRESH_TIME = 7;
    const [tableData, setTableData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const PAGINATION_SIZES = [10, 50, 100, 500, 1000];
    const [paginationSize, setPaginationSize] = useState(50);
    const [isFetching, setIsFetching] = useState(false);
    const [isErrorLoadingData, setIsErrorLoadingData] = useState(false);
    const [snackbarData, setSnackbarData] = useState(snackbarDefaultValue);
    const { counter, reset } = useTimer(REFRESH_TIME, () => fetchDataInterval());

    const columnsList = [
        {
            field: columnTypes.ORDINAL_NUMBER,
            width: 60,
        },
        {
            field: columnTypes.SHOW,
            width: 60,
        },
        {
            field: columnTypes.DATE_ORDERED,
            width: 200,
        },
        {
            field: columnTypes.DATE_FINISHED,
            width: 200,
        },
        {
            field: columnTypes.WATERPIPE,
            width: 160,
        },
        {
            field: columnTypes.CHANNEL,
            width: 100,
        },
        {
            field: columnTypes.DATE_FROM,
            width: 120,
        },
        {
            field: columnTypes.DATE_TO,
            width: 120,
        },
        {
            field: columnTypes.NUMBERS,
        },
        {
            field: columnTypes.DELETE,
            width: 60,
        },
    ];

    const filterParams = {
        comparator: (selectedValue, cellValue) => {
            const valueDate = cellValue.substring(0, 10);
            const selectedDate = getDate(selectedValue, true);

            if (selectedDate === valueDate) return 0;
            return selectedDate > valueDate ? -1 : 1;
        },
        browserDatePicker: true,
    };

    const setError = () => setIsErrorLoadingData(true);

    const endFetching = () => setIsFetching(false);

    const getData = (enableFetching, withInterval) => {
        enableFetching && setIsFetching(true);
        axiosInstance
            .get(endpoint.TEST_REPORT_V2_INDEX)
            .then(res => {
                if (res.data.data.length) {
                    setTableData(
                        res.data.data.map(data => ({
                            id: data.id,
                            date_ordered: data.created_at,
                            date_finished: data.date_finished,
                            waterpipe: data?.waterpipe?.name ?? waterpipesAll.name,
                            waterpipe_id: data?.waterpipe?.id ?? waterpipesAll.id,
                            waterpipe_sign: data?.waterpipe?.sign ?? waterpipesAll.sign,
                            date_from: data.date_from,
                            date_to: data.date_to,
                            numbers: data.numbers,
                            channel: data.channel,
                        }))
                    );
                } else {
                    setTableData([]);
                }
                withInterval && reset();
            })
            .catch(setError)
            .finally(enableFetching && endFetching);
    };

    const showErrorSnackbar = () =>
        setSnackbarData({
            isSnackbarOpen: true,
            message: snackbarMsg.TEST_LIST_DELETED_ERROR,
            errorInfo: '',
            variant: snackbarVariant.ERROR,
        });

    const deleteData = elementId => {
        axiosInstance
            .delete(`${endpoint.TEST_REPORT_V2_DELETE}/${elementId}`)
            .then(res => {
                if (res?.data?.success) {
                    setSnackbarData({
                        isSnackbarOpen: true,
                        message: snackbarMsg.TEST_LIST_DELETED_SUCCESS,
                        errorInfo: '',
                        variant: snackbarVariant.SUCCESS,
                    });
                    getData(false);
                } else {
                    showErrorSnackbar();
                }
            })
            .catch(showErrorSnackbar);
    };

    useEffect(() => {
        getData(true);
    }, []);

    const fetchDataInterval = () => {
        getData(true, true);
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
            if (isFetching) {
                gridApi.showLoadingOverlay();
            } else if (!tableData.length && !isFetching) {
                gridApi.showNoRowsOverlay();
            } else {
                gridApi.hideOverlay();
            }
        }
    }, [gridApi, tableData, isFetching]);

    const onSortChanged = params => {
        params.api.refreshCells();
    };

    const onFilterChanged = params => {
        params.api.refreshCells();
    };

    const onGridReady = params => setGridApi(params.api);

    const changePaginationSize = event => {
        setPaginationSize(+event.target.value);
    };

    const setCellStyle = params => {
        if (params.colDef.field === columnTypes.SHOW || params.colDef.field === columnTypes.DELETE) {
            return {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            };
        }
        return null;
    };

    const onShow = element => {
        if (typeof window !== 'undefined') {
            saveDataToLocalStorage(element).then(() =>
                window.open(`${window.location.origin}/#/test-generated-list-v2`)
            );
        }
    };

    const saveDataToLocalStorage = async element => {
        const {
            waterpipe_id: waterpipeId,
            waterpipe_sign: waterpipeSign,
            date_from: dateFrom,
            date_to: dateTo,
            numbers,
            channel,
            id,
        } = element;

        const getNumbers = () => {
            if (numbers === '') return null;
            return numbers !== '' ? numbers : null;
        };

        const generatedListData = {
            waterpipeId: Number(waterpipeId),
            waterpipeSign,
            dateFrom,
            dateTo,
            numbers: getNumbers() !== null ? getNumbers() : null,
            channel,
            id,
        };

        localStorage.setItem(localStorageKey.GENERATED_TEST_LIST_DATA_V2, JSON.stringify(generatedListData));

        return Promise.resolve(generatedListData);
    };

    const onDelete = elementId => deleteData(elementId);

    const cellRenderer = params => {
        const HOURS_DIFF = 120;
        const { field } = params.colDef;
        const element = params.data;
        const dateFinished = element?.date_finished;
        const dateOrdered = element?.date_ordered;

        if (field === columnTypes.SHOW || field === columnTypes.DELETE) {
            const actionButton = document.createElement('div');
            actionButton.style.display = 'flex';
            actionButton.style.alignItems = 'center';
            actionButton.style.justifyContent = 'center';

            const icon = document.createElement('img');
            icon.style = 'width: 20px; height: 20px; cursor: pointer;';
            if (field === columnTypes.SHOW) {
                icon.src = showIcon;
                icon.onclick = () => onShow(element);
                if (dateFinished) {
                    actionButton.appendChild(icon);
                }
            } else {
                icon.src = deleteIcon;
                icon.onclick = () => onDelete(element.id);

                if (
                    dateFinished ||
                    dateDiff(new Date().toISOString().replace('T', ' ').split('.')[0], dateOrdered) >
                        HOURS_DIFF - 1
                ) {
                    actionButton.appendChild(icon);
                }
            }

            return actionButton;
        }
        const text = document.createElement('p');
        text.style =
            'font-weight: bold; font-size: 30px; color: #303030; margin-top: 2px; height: 20px; letter-spacing: 9px; margin-left: 7px;';
        text.innerText = '.'.repeat(REFRESH_TIME - counter);
        return dateFinished ?? text;
    };

    const getFilter = field => {
        switch (field) {
            case columnTypes.DATE_ORDERED:
                return 'agDateColumnFilter';
            case columnTypes.WATERPIPE:
            case columnTypes.CHANNEL:
                return true;
            default:
                return false;
        }
    };

    const stringSorter = (valueA, valueB) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? -1 : 1;
    };

    const handleIsSnackbarOpen = () => {
        setSnackbarData(dataSnackbar => ({
            isSnackbarOpen: false,
            message: dataSnackbar.message,
            errorInfo: dataSnackbar.errorInfo,
            variant: dataSnackbar.variant,
        }));
    };

    return (
        <div className={container}>
            <div className={tableHeader}>
                <p className={tableName}>{'Zamówione zestawienia testowe'}</p>
            </div>
            <div className={'ag-theme-alpine'} style={styles.table}>
                <AgGridReact
                    onSortChanged={onSortChanged}
                    onFilterChanged={onFilterChanged}
                    localeText={tableTranslations}
                    rowData={tableData}
                    suppressCellSelection
                    onGridReady={onGridReady}
                    paginationPageSize={paginationSize}
                    pagination
                    tooltipShowDelay={0}
                    enableCellTextSelection
                    suppressDragLeaveHidesColumns>
                    {columnsList.map(column => (
                        <AgGridColumn
                            key={column.field}
                            headerName={tabHeadToSymbols[column.field]}
                            headerTooltip={
                                column.field === columnTypes.ORDINAL_NUMBER
                                    ? tabHeadToSymbolsToTitles[column.field]
                                    : tabHeadToSymbols[column.field]
                            }
                            field={column.field}
                            cellRenderer={
                                (column.field === columnTypes.SHOW ||
                                    column.field === columnTypes.DELETE ||
                                    column.field === columnTypes.DATE_FINISHED) &&
                                cellRenderer
                            }
                            cellStyle={setCellStyle}
                            suppressSizeToFit={!!column.width}
                            width={column.width}
                            tooltipValueGetter={params => params.value}
                            resizable
                            sortable={
                                column.field === columnTypes.DATE_ORDERED ||
                                column.field === columnTypes.WATERPIPE
                            }
                            filter={getFilter(column.field)}
                            filterParams={filterParams}
                            comparator={stringSorter}
                            valueGetter={params => {
                                if (column.field === columnTypes.ORDINAL_NUMBER) {
                                    return params.node.rowIndex + 1;
                                }

                                return params.data[column.field];
                            }}
                        />
                    ))}
                </AgGridReact>
                <PaginationRows
                    paginationSizes={PAGINATION_SIZES}
                    paginationSize={paginationSize}
                    changePaginationSize={changePaginationSize}
                />
                {isErrorLoadingData && <p className={errorMessage}>{tableTranslations.errorLoadingData}</p>}
                <Snackbar snackbarData={snackbarData} handleIsSnackbarOpen={handleIsSnackbarOpen} />
            </div>
        </div>
    );
};

export default withStyles(styles)(TestListOrdered);
