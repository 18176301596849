/* eslint-disable no-restricted-globals */
/* eslint-disable chai-friendly/no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import { communicatorFieldType } from '../../utils/constants';
import { removeSpaces } from '../../utils/helpers';

const styles = {
    container: {
        marginTop: '1.4rem',
    },
    field: {
        margin: '0 1rem',
        width: '35.4rem',
    },
};

const CommunicatorsProdList = ({
    classes: { container, field },
    isFocusOnCommunicatorsList,
    setFocusOnCommunicatorsFieldType,
    setIsCommunicatorsListEmpty,
    isCommunicatorsRangeEmpty,
    setIsAnyError,
    setNumbers,
}) => {
    const [communicatorsList, setCommunicatorsList] = useState('');
    const [isError, setIsError] = useState(false);
    const [helperMessage, setHelperMessage] = useState('');

    const handleInputValue = e => {
        const { value } = e.target;
        setCommunicatorsList(removeSpaces(value));
    };

    const handleInputFocus = value => {
        if (isCommunicatorsRangeEmpty) {
            setFocusOnCommunicatorsFieldType(communicatorFieldType.COMMUNICATORS_LIST, value);
        }
    };

    const isNumber = value => {
        const valueNoComma = value.replace(/,/g, '');

        if (isNaN(Number(valueNoComma))) {
            setError('Tylko cyfry');
            return true;
        }
        clearError();
        return false;
    };

    const setError = msg => {
        setIsError(true);
        setHelperMessage(msg);
    };

    const clearError = () => {
        setIsError(false);
        setHelperMessage('');
    };

    const handleError = isCommunicatorLessThan8Characters => {
        if (isNumber(communicatorsList)) return;
        isCommunicatorLessThan8Characters.length > 0 &&
            setError('Lista komunikatorów (8 cyfr) oddzielona przecinkami');
    };

    const handleIsCommunicatorsListEmpty = () => {
        const IsCommunicatorsListEmpty = communicatorsList.length === 0;
        setIsCommunicatorsListEmpty(IsCommunicatorsListEmpty);
    };

    const isSingleCommunicator = () => communicatorsList.length === 8 && !isNumber(communicatorsList);

    useEffect(() => {
        const communicatorListToArr = communicatorsList.split(',');
        const isCommunicatorLessThan8Characters = communicatorListToArr.filter(
            communicator => communicator.length !== 8
        );

        handleIsCommunicatorsListEmpty();
        handleError(isCommunicatorLessThan8Characters);

        setNumbers(communicatorsList);
        if (!communicatorsList) clearError();
        if (isSingleCommunicator()) {
            setIsAnyError(false);
        }
    }, [communicatorsList]);

    useEffect(() => {
        setIsAnyError(isError);
    }, [isError]);

    useEffect(() => {
        clearError();
    }, []);

    return (
        <section className={container}>
            <TextField
                label="Lista numerów oddzielona przecinkami"
                placeholder="np. 12345678,12345678,12345678,12345678"
                type="text"
                className={field}
                InputLabelProps={{
                    shrink: true,
                }}
                helperText={helperMessage}
                onChange={e => handleInputValue(e)}
                value={communicatorsList}
                error={isError}
                onFocus={() => handleInputFocus(false)}
                onMouseEnter={() => handleInputFocus(false)}
                disabled={isFocusOnCommunicatorsList}
            />
        </section>
    );
};

export default withStyles(styles)(CommunicatorsProdList);
