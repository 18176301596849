import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SwitchLabel from './SwitchLabel';
import { clearLocalStorage, isAdminRole } from '../../utils/helpers';
import GoTopButton from '../GoTopButton/GoTopButton';
import SimpleMenu from '../Submenu/Submenu';
import appContext from '../../context/AppContext';
import theme from '../../themes/theme';
import { axiosInstance } from '../../utils/axiosInstance';
import { endpoint } from '../../utils/constants';

const styles = {
    root: {
        flexGrow: 1,
        width: '100%',
    },
    appBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '1rem',
    },
    logoutLabel: {
        color: 'inherit',
        fontSize: '.7rem',
        textTransform: 'uppercase',
        fontWeight: theme.font.weight.normal,
    },
    logoutContainer: {
        width: '5.5rem',
        height: '2.5rem',
        paddingLeft: '.2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#E2325A',
        '&:hover': {
            cursor: 'pointer',
            color: '#ad2439',
        },
    },
    logoutIcon: {
        color: 'inherit',
    },
};

const NavBar = ({ classes: { root, appBar, logoutContainer, logoutIcon, logoutLabel }, history }) => {
    const AppContext = useContext(appContext);
    const [isSubmenu, setIsSubmenu] = useState(null);

    const setTabHighlight = () => {
        const location = window.location.hash;
        const checkIfLocationIsError = () => {
            const regex = /\berror\b/g;
            return regex.test(location);
        };

        if (checkIfLocationIsError()) {
            return '#/packages';
        }
        if (location.includes('list')) {
            return '#/lists';
        }
        if (location.includes('pipeline')) {
            return '#/pipelines';
        }

        return location;
    };

    const clearData = () => {
        clearLocalStorage();
        history.push('/');
    };

    const handleLogout = () => {
        axiosInstance
            .post(endpoint.USER_LOGOUT)
            .catch(error => console.error('Error while logging out', error))
            .finally(clearData);
    };

    return (
        <div className={root}>
            <AppBar position="static" color="default" className={appBar}>
                <Tabs
                    value={setTabHighlight()}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={() => {
                        AppContext.setSubmenuElement(null);
                    }}>
                    <Tab label="Start" component={RouterLink} to="/" value="#/" />
                    <Tab label="Pakiety" component={RouterLink} to="/packages" value="#/packages" />
                    <Tab
                        label="Lista wodociągów"
                        component={RouterLink}
                        to="/pipelines"
                        value="#/pipelines"
                    />
                    {isAdminRole() && (
                        <Tab label="Generator" component={RouterLink} to="/generator" value="#/generator" />
                    )}
                    {isAdminRole() && (
                        <Tab label="Bramki" component={RouterLink} to="/gateways" value="#/gateways" />
                    )}
                    {isAdminRole() && (
                        <Tab label="Użytkownicy" component={RouterLink} to="/users" value="#/users" />
                    )}
                    {isAdminRole() && (
                        <Tab label="Protokoły" component={RouterLink} to="/protocols" value="#/protocols" />
                    )}
                    <Tab label="Zestawienia" value={'#/lists'} onClick={e => setIsSubmenu(e.currentTarget)} />
                </Tabs>
                <SimpleMenu isSubmenu={isSubmenu} setIsSubmenu={setIsSubmenu} />
                <SwitchLabel history={history} />
                <div onClick={handleLogout} className={logoutContainer}>
                    <MeetingRoomIcon className={logoutIcon} />
                    <Typography className={logoutLabel}>wyloguj</Typography>
                </div>
            </AppBar>
            <GoTopButton />
        </div>
    );
};

export default withStyles(styles)(NavBar);
