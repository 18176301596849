import React, { Component } from 'react';
import { SingleSelect } from 'react-select-material-ui';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { reportTypes, selectStrings } from '../../utils/constants';
import theme from '../../themes/theme';
import { convertToEnglish, convertToPolish } from '../../utils/helpers';

const styles = {
    container: {
        width: '11rem',
        padding: '1rem',
        fontFamily: theme.font.family,
        fontSize: 16,
        zIndex: 3,
    },
    wider: {
        width: '14rem',
    },
    lowerZIndex: {
        zIndex: 2,
    },
};

class AutocompleteBar extends Component {
    state = {
        filteredValue: [], // need to be an array, to get the placeholder
    };

    componentDidMount() {
        const { reportType } = this.props;
        this.setState({ filteredValue: [reportType] });
    }

    componentDidUpdate(prevProps) {
        this.getValueToDisplayStatus();
        this.getValueToDisplayProtocol();
        this.getValueToDisplayDocumentType();
        this.getValueToDisplayWaterpipe();
        if (prevProps.checkType !== this.props.checkType) {
            this.setState({
                filteredValue: this.props.isTable
                    ? [convertToPolish(this.props.checkType)]
                    : [this.props.checkType],
            });
        }
    }

    doFilter = () => {
        this.props.handleFilter(
            this.props.isTable ? convertToEnglish(this.state.filteredValue) : this.state.filteredValue
        );
    };

    handleChange = value => {
        this.setState(
            {
                filteredValue: value,
            },
            this.doFilter
        );
    };

    getValueToDisplayStatus = () => {
        const { localStorageKey, isStatus, value } = this.props;
        const { filteredValue } = this.state;
        const storageValue = localStorage.getItem(localStorageKey);
        if (value === '' && filteredValue !== 'Wszystkie' && storageValue !== 'Nowe') {
            this.setState({ filteredValue: 'Wszystkie' });
        } else if (isStatus && value === 'new' && filteredValue !== 'Nowe') {
            this.setState({ filteredValue: 'Nowe' });
        } else if (isStatus && value === 'done' && filteredValue !== 'Zakończone') {
            this.setState({ filteredValue: 'Zakończone' });
        } else if (isStatus && value === 'done_off' && filteredValue !== 'Zakończone OFF') {
            this.setState({ filteredValue: 'Zakończone OFF' });
        } else if (isStatus && value === 'done_wo_off' && filteredValue !== 'Zakończone bez OFF') {
            this.setState({ filteredValue: 'Zakończone bez OFF' });
        } else if (isStatus && value === 'parser_error' && filteredValue !== 'Błąd parsowania') {
            this.setState({ filteredValue: 'Błąd parsowania' });
        } else if (isStatus && value === 'untrusted_gateway' && filteredValue !== 'Błąd bramki') {
            this.setState({ filteredValue: 'Błąd bramki' });
        } else if (isStatus && value === 'pending' && filteredValue !== 'Oczekujące') {
            this.setState({ filteredValue: 'Oczekujące' });
        } else if (isStatus && value === 'non_affiliated_waterpipe' && filteredValue !== 'Błąd wodociągu') {
            this.setState({ filteredValue: 'Błąd wodociągu' });
        } else if (isStatus && value === 'bad_protocol' && filteredValue !== 'Błąd protokołu') {
            this.setState({ filteredValue: 'Błąd protokołu' });
        } else if (isStatus && value === 'duplicate' && filteredValue !== 'Duplikaty') {
            this.setState({ filteredValue: 'Duplikaty' });
        } else if (isStatus && value === 'date_error' && filteredValue !== 'Błąd daty') {
            this.setState({ filteredValue: 'Błąd daty' });
        } else if (isStatus && value === 'not_parsed' && filteredValue !== 'Niesparsowane') {
            this.setState({ filteredValue: 'Niesparsowane' });
        } else if (isStatus && value === 'decrypt_error' && filteredValue !== 'Błąd dekodowania') {
            this.setState({ filteredValue: 'Błąd dekodowania' });
        }
    };

    getValueToDisplayProtocol = () => {
        const { isProtocol, value } = this.props;
        const { filteredValue } = this.state;
        if (isProtocol && value === 'V2' && filteredValue !== 'Protocol_V2') {
            this.setState({ filteredValue: 'Protocol_V2' });
        } else if (isProtocol && value === 'V7' && filteredValue !== 'Protocol_V7') {
            this.setState({ filteredValue: 'Protocol_V7' });
        } else if (isProtocol && value === 'V8' && filteredValue !== 'Protocol_V8') {
            this.setState({ filteredValue: 'Protocol_V8' });
        } else if (isProtocol && value === 'V9' && filteredValue !== 'Protocol_V9') {
            this.setState({ filteredValue: 'Protocol_V9' });
        } else if (isProtocol && value === 'V10' && filteredValue !== 'Protocol_V10') {
            this.setState({ filteredValue: 'Protocol_V10' });
        }
    };

    getValueToDisplayDocumentType = () => {
        const { isDocumentType, value } = this.props;
        const { filteredValue } = this.state;

        if (isDocumentType) {
            if (value === reportTypes.ALL && filteredValue !== reportTypes.ALL_PL) {
                this.setState({ filteredValue: reportTypes.ALL_PL });
            } else if (value === reportTypes.ALARM && filteredValue !== reportTypes.ALARM_PL) {
                this.setState({ filteredValue: reportTypes.ALARM_PL });
            } else if (value === reportTypes.REPORT && filteredValue !== reportTypes.REPORT_PL) {
                this.setState({ filteredValue: reportTypes.REPORT_PL });
            } else if (value === reportTypes.MINUTE && filteredValue !== reportTypes.MINUTE_REPORT_PL) {
                this.setState({ filteredValue: reportTypes.MINUTE_REPORT_PL });
            } else if (value === reportTypes.SUMMARY && filteredValue !== reportTypes.SUMMARY_PL) {
                this.setState({ filteredValue: reportTypes.SUMMARY_PL });
            } else if (value === reportTypes.CONFIG && filteredValue !== reportTypes.CONFIG_PL) {
                this.setState({ filteredValue: reportTypes.CONFIG_PL });
            } else if (value === reportTypes.CON_MAP && filteredValue !== reportTypes.CON_MAP_PL) {
                this.setState({ filteredValue: reportTypes.CON_MAP_PL });
            } else if (value === reportTypes.CON_CONFIG && filteredValue !== reportTypes.CON_CONFIG_PL) {
                this.setState({ filteredValue: reportTypes.CON_CONFIG_PL });
            }
        }
    };

    getValueToDisplayWaterpipe = () => {
        const { isWaterpipe, value } = this.props;
        const { filteredValue } = this.state;

        if (isWaterpipe && filteredValue !== value && value !== '') {
            this.setState({ filteredValue: value });
        }
    };

    render() {
        const { classes, placeholder, options, localStorageKey, label, wider, lowerZIndex } = this.props;
        const { filteredValue } = this.state;
        const storageValue = localStorage.getItem(localStorageKey);

        return (
            <div className="App">
                <SingleSelect
                    key={filteredValue?.length > 1 ? filteredValue : storageValue}
                    label={label}
                    value={filteredValue?.length > 1 ? filteredValue : storageValue}
                    placeholder={placeholder}
                    options={options}
                    onChange={this.handleChange}
                    onKeyUp={this.doFilter}
                    SelectProps={{
                        msgNoOptionsAvailable: selectStrings.noOptionsAvailable,
                        msgNoOptionsMatchFilter: selectStrings.noOptionsMatchFilter,
                        msgNoValidValue: selectStrings.noValidValue,
                    }}
                    className={[
                        classes.container,
                        wider && classes.wider,
                        lowerZIndex && classes.lowerZIndex,
                    ].join(' ')}
                />
            </div>
        );
    }
}

AutocompleteBar.propTypes = {
    classes: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
};

export default withStyles(styles)(AutocompleteBar);
