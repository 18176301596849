import React, { Component } from 'react';
import { axiosInstance, cancelToken } from '../../utils/axiosInstance';
import { errorHandling, isAdminRole } from '../../utils/helpers';
import TableWrapper from '../Tables/TableWrapper';
import { endpoint } from '../../utils/constants';
import TitleHeader from '../TitleHeader/TitleHeader';

class UsersList extends Component {
    state = {
        loading: false,
        content: {
            data: [],
            headers: {},
        },
    };

    componentDidMount() {
        this.cancelHttpReq = cancelToken.source();
        this.fetchEndpoint();
    }

    componentWillUnmount() {
        this.cancelHttpReq.cancel();
    }

    fetchEndpoint = () => {
        this.setState({ loading: true });

        axiosInstance
            .get(endpoint.USER_SHOW, {
                cancelToken: this.cancelHttpReq.token,
            })
            .then(content => {
                this.setState({
                    loading: false,
                    content: {
                        headers: content.data.headers,
                        data: content.data.data,
                    },
                });
            })
            .catch(err => {
                errorHandling(err, this);
            });
    };

    render() {
        const { content, loading, isServerError } = this.state;

        return (
            isAdminRole() && (
                <div>
                    <TitleHeader title={'Lista użytkowników'} />
                    <TableWrapper
                        hidePagination
                        isThrobberActive={loading}
                        content={content}
                        isServerError={isServerError}
                    />
                </div>
            )
        );
    }
}

export default UsersList;
