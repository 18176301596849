import React, { useState, useEffect } from 'react';
import { FormControlLabel, Switch, withStyles } from '@material-ui/core';
import { axiosInstance } from '../../utils/axiosInstance';
import Modal from '../Modal/Modal';
import { isAdminRole } from '../../utils/helpers';

const styles = {
    labelChecked: {
        textTransform: 'uppercase',
        fontSize: '.7rem',
        color: '#3F51B5',
    },
    labelUnchecked: {
        textTransform: 'uppercase',
        fontSize: '.7rem',
        color: '#999999',
    },
};

const switchLabel = ({ classes }) => {
    const [switchState, setSwitchState] = useState({
        isChecked: true,
    });

    const [modalState, setModalState] = useState({
        isModalOpen: false,
    });

    const [spinnerState, setspinnerState] = useState({
        isSpinnerActive: false,
    });

    const getSwitchStatus = () => axiosInstance.get(`api/auto-parse-status`).then(resp => resp);

    useEffect(() => {
        getSwitchStatus()
            .then(response => {
                if (switchState.isChecked !== response.data.status) {
                    setSwitchState({ isChecked: response.data.status });
                }
            })
            .catch(err => {
                console.error('err', err);
            });
    }, []);

    const handleSpinnerChange = isSpinnerOn => {
        setspinnerState({
            ...spinnerState,
            isSpinnerActive: isSpinnerOn,
        });
    };

    const handleSwitchChange = () => {
        getSwitchStatus().then(response => {
            handleSpinnerChange(true);
            const status = { status: !response.data.status };
            axiosInstance
                .post('api/auto-parse-change', status)
                .then(() => {
                    handleSpinnerChange(false);
                    setModalState({
                        isModalOpen: false,
                    });
                    setSwitchState({ isChecked: status.status });
                })
                .catch(error => {
                    console.error('post error', error);
                    handleSpinnerChange(false);
                    setModalState({
                        isModalOpen: false,
                    });
                });
        });
    };

    const modalChangeHandler = (isModalOpen = true) => {
        setModalState({
            isModalOpen,
        });
    };

    const getModalMessage = () =>
        switchState.isChecked
            ? 'Czy na pewno chcesz wyłączyć automatyczne parsowanie?'
            : 'Czy na pewno chcesz włączyć automatyczne parsowanie?';

    const handleAnswer = answer => (answer ? handleSwitchChange() : modalChangeHandler(false));

    return (
        <>
            {modalState.isModalOpen && (
                <Modal
                    isSpinnerActive={spinnerState.isSpinnerActive}
                    isModalOpen={modalState.isModalOpen}
                    handleAnswer={handleAnswer}
                    modalMessage={getModalMessage()}
                />
            )}
            {isAdminRole() && (
                <FormControlLabel
                    control={
                        <Switch
                            checked={switchState.isChecked}
                            onChange={() => modalChangeHandler()}
                            value="checkedA"
                            color="primary"
                        />
                    }
                    label={switchState.isChecked === true ? 'Parsowanie włączone' : 'Parsowanie wyłączone'}
                    classes={
                        switchState.isChecked === true
                            ? { label: classes.labelChecked }
                            : { label: classes.labelUnchecked }
                    }
                />
            )}
        </>
    );
};

export default withStyles(styles)(switchLabel);
