import { withStyles } from '@material-ui/styles';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { fetchStatuses } from '../../utils/constants';
import theme from '../../themes/theme';

const styles = {
    loaderWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    loaderText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'black',
        marginTop: 20,
    },
};

const LoadingSpinner = ({ classes: { loaderWrapper, loaderText } }) => (
    <div className={loaderWrapper}>
        <ClipLoader color={theme.colors.main} loading size={80} />
        <div className={loaderText}>{fetchStatuses.loading}</div>
    </div>
);

export default withStyles(styles)(LoadingSpinner);
