import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '5rem',
    },
    td: {
        border: '1px solid black',
        textAlign: 'center',
        padding: '.5rem',
        width: '14.5rem',
    },
    table: {
        borderCollapse: 'collapse',
    },
};

const StatisticsTable = ({ classes: { container, td, table }, data, headers  }) =>
    (
        <div className={container}>
            <table className={table}>
                <tbody>
                    {Object.keys(headers).map(header => (
                        <tr key={header}>
                            <td className={td}>{headers[header]}</td>
                            <td className={td}>{data[header]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );


export default withStyles(styles)(StatisticsTable);
