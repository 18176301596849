import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    displayError: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontStyle: 'italic',
        color: '#4E4B48',
    },
});

const displayError = ({ classes, isUnauthorized }) => (
    <div className={classes.displayError}>
        {isUnauthorized ? <h2>Brak uprawnień do przeglądania strony</h2> : <h2>Błąd serwera</h2>}
    </div>
);

export default withStyles(styles)(displayError);
