export const TABLE_TABS_PREFIX = 'tab_';
export const TEMPLATE_PREFIX = 'template_';
export const TEST_DATA_PATH = 'test-data';

export const listTypes = {
    PRODUCTION_LIST: 'productionList',
    TEST_LIST: 'testList',
    WATERPIPE_LIST: 'waterpipeList',
};

export const listTypesId = {
    PRODUCTION_LIST: 1,
    TEST_LIST: 2,
};

export const testListVersion = {
    V1: 1,
    V2: 2,
};

export const listTypesNames = {
    PRODUCTION_LIST: 'produkcyjne',
    TEST_LIST: 'testowe',
};

export const responseCode = {
    SUCCESS: 200,
};

export const tableTranslations = {
    filterOoo: 'Filtr...',
    contains: 'Zawiera',
    notContains: 'Nie zawiera',
    equals: 'Równa się',
    notEqual: 'Nie równe',
    greaterThan: 'Większe niż',
    lessThan: 'Mniejsze niż',
    inRange: 'W zakresie',
    startsWith: 'Zaczyna się od',
    endsWith: 'Kończy się na',
    andCondition: 'ORAZ',
    orCondition: 'LUB',
    noRowsToShow: 'Brak danych do wyświetlenia',
    loadingOoo: 'Trwa wczytywanie danych...',
    errorLoadingData: 'Wystąpił błąd podczas pobierania danych.',
    to: 'do',
    of: 'z',
    page: 'Strona',
};

export const submenuElementList = {
    FIRST_ELEMENT: 'firstElement',
    SECOND_ELEMENT: 'secondElement',
};

export const endpoint = {
    USER_LOGIN: '/api/user/login',
    USER_LOGOUT: '/api/user/logout',
    SCHEME_CREATE: `/api/scheme/create`,
    SCHEME_DELETE: '/api/scheme/delete',
    SCHEME_LIST: '/api/scheme/list',
    SCHEME_SHOW: '/api/scheme/show',
    USER_SHOW: '/api/user/show',
    PACKAGES: '/api/packages',
    REPORTS: '/api/reports',
    ALARMS: '/api/alarms',
    SUMMARIES: '/api/summaries',
    MINUTE_REPORTS: '/api/minute-reports',
    CONFIGS: '/api/configs',
    STATISTICS: '/api/statistics',
    ERROR: '/api/error/',
    GATEWAY_SHOW: '/api/gateway/show',
    WATERPIPE: '/api/waterpipe',
    WATERPIPES: '/api/waterpipes',
    WATERPIPE_ADD: '/api/waterpipe/add',
    WATERPIPE_EDIT: '/api/waterpipe/edit',
    WATERPIPE_DELETE: '/api/waterpipe/delete',
    LIVE_CHECK: '/api/live-check',
    PROTOCOLS_SHOW: '/api/protocols/show',
    PRODUCTION_REPORT: '/api/reports/production-report',
    TEST_REPORT: '/api/reports/test-report',
    TEST_REPORT_V2_INDEX: '/api/reports/test-report/v2/index',
    TEST_REPORT_V2_GET: '/api/reports/test-report/v2/get',
    TEST_REPORT_V2_CREATE: '/api/reports/test-report/v2/create',
    TEST_REPORT_V2_DELETE: '/api/reports/test-report/v2/delete',
    DECRYPT_PACKAGE: '/api/decrypt-package',
    ENCRYPT_PACKAGE: '/api/encrypt-package',
    TEST_PACKAGE: '/api/testPackage',
    LIST_TEMPLATE_INDEX: '/api/list-template/index',
    LIST_TEMPLATE_SHOW: '/api/list-template/show',
    LIST_TEMPLATE_CREATE: '/api/list-template/create',
    LIST_TEMPLATE_UPDATE: '/api/list-template/update',
    LIST_TEMPLATE_DELETE: '/api/list-template/delete',
};

export const inputField = {
    FROM: 'from',
    TO: 'to',
};

export const localStorageMode = {
    SET_ITEM: 'setItem',
    GET_ITEM: 'getItem',
    REMOVE_ITEM: 'removeItem',
};

export const localStorageKey = {
    TOKEN: 'token',
    USER_ROLE: 'userRole',
    HEADER: 'header',
    COMMUNICATOR_RANGE_FROM_VALUE: 'communicatorRangeFromValue',
    COMMUNICATOR_RANGE_TO_VALUE: 'communicatorRangeToValue',
    LISTS_SELECTED_WATERPIPE_NAME: 'listsSelectedWaterpipeName',
    GENERATED_TEST_LIST_DATA: 'generatedTestListData',
    GENERATED_TEST_LIST_DATA_V2: 'generatedTestListDataV2',
    GENERATED_PROD_LIST_DATA: 'generatedProdListData',
    COLUMNS_SETTINGS: 'columnsSettings',
    SELECTED_PACKAGE_COMMUNICATOR: 'selectedPackageCommunicator',
    SELECTED_PACKAGE_ID: 'selectedPackageId',
    WATERPIPES_LIST: 'waterpipesList',
    NUMBERS_LIST: 'numbersList',
    PAGINATION_SIZE_TEST_LIST: 'paginationSizeTestList',
    COLUMNS_DEFS_TEST_LIST: 'columnsDefsTestList',
    COLUMN_DEFS_TABLE_REPORTS: 'columnDefsTableReports',
    COLUMN_DEFS_TABLE_ALARMS: 'columnDefsTableAlarms',
    COLUMN_DEFS_TABLE_SUMMARY: 'columnDefsTableSummary',
    COLUMN_DEFS_TABLE_MINUTE_REPORTS: 'columnDefsTableMinuteReports',
    COLUMN_DEFS_TABLE_CONFIG_REPORTS: 'columnDefsTableConfigReports',
    COLUMN_DEFS_TABLE_CON_MAPS: 'columnDefsTableConMaps',
    COLUMN_DEFS_TABLE_CON_CONFIGS: 'columnDefsTableConConfigs',
    DOCS_REPORT_TYPE: 'docsReportType',
    FAVORITE_REPORT_EDITED: 'favoriteReportEdited',
    LIST_TEMPLATES_COLUMN_STATE: 'listTemplatesColumnState',
    PAGINATION_SIZE_TABLE_REPORTS: 'paginationSizeTableReports',
    DOCS_SEARCH_COMMUNICATOR: 'docsSearchCommunicator',
    DOCS_SEARCH_PACKAGE_ID: 'docsSearchPackageId',
    DOCS_PAGE: 'docsPage',
    CURRENT_URL: 'currentUrl',
    PIPELINE_LIST_SEARCH: 'pipelinesListSearch',
    PACKAGES_PAGE: 'packagesPage',
    PACKAGES_SEARCH_ID: 'packagesSearchId',
    PACKAGES_SEARCH_SMS: 'packagesSearchSms',
    PACKAGES_SEARCH_DOK: 'packagesSearchDok',
    PACKAGES_SEARCH_DOK_NAME: 'packagesSearchDokName',
    PACKAGES_SEARCH_SIM: 'packagesSearchSim',
    PACKAGES_SEARCH_COM: 'packagesSearchCom',
    PACKAGES_SEARCH_PHRASE: 'packagesSearchPhrase',
    PACKAGES_SELECTED_WATERPIPE: 'packagesSelectedWaterpipe',
    PACKAGES_SELECTED_WATERPIPE_NAME: 'packagesSelectedWaterpipeName',
    PACKAGES_SELECTED_STATUS: 'packagesSelectedStatus',
    PACKAGES_SELECTED_STATUS_NAME: 'packagesSelectedStatusName',
    PACKAGES_SELECTED_PROTOCOL: 'packagesSelectedProtocol',
    PACKAGES_SELECTED_PROTOCOL_NAME: 'packagesSelectedProtocolName',
    PARSER_ERROR_PACKAGE_DATA: 'parserErrorPackageData',
    PARSER_ERROR_PACKAGE_DATE_CREATED: 'parserErrorPackageDateCreated',
    IS_SHOW_ONLY_OFFLINE_CHECKED: 'isShowOnlyOfflineChecked',
    IS_PARSER_ERROR_LINK_CLICKED: 'isParserErrorLinkClicked',
    IS_DUPLICATE_CHECKED: 'isDuplicateChecked',
};

export const sessionStorageKey = {
    SELECTED_PACKAGE_COMMUNICATOR: 'selectedPackageCommunicator',
    SELECTED_PACKAGE_ID: 'selectedPackageId',
    IS_USER_ENTERED_FROM_PACKAGES_LINK: 'isUserEnteredFromPackagesLink',
};

export const docsSearchValue = {
    COMMUNICATOR: 'searchCommunicator',
    PACKAGE_ID: 'searchPackageId',
};

export const communicatorFieldType = {
    COMMUNICATORS_LIST: 'communicatorsList',
    COMMUNICATORS_RANGE: 'communicatorsRange',
};

export const communicatorsType = {
    COMMUNICATOR: 'communicator',
    COUPLED_COMMUNICATOR: 'coupledCommunicator',
    CONCENTRATOR: 'concentrator',
};

export const communicatorsTypePl = {
    COMMUNICATOR: 'Komunikator',
    COUPLED_COMMUNICATOR: 'Komunikator sprzężony',
    CONCENTRATOR: 'Koncentrator',
};

export const communicatorsChannel = {
    ONE: '1',
    TWO: '2',
};

export const tabHeadToSymbols = {
    ordinal_number: 'LP',
    tab_1: '\u2587\u2587\u2587',
    tab_2: '\u2587\u2587\u2587',
    tab_3: '\u2587\u2587\u2587',
    tab_4: '\u2587\u2587\u2587',
    tab_5: '\u2587\u2587\u2587',
    date_ordered: 'Data zamówienia',
    date_finished: 'Data zakończenia',
    date_from: 'Data od',
    date_to: 'Data do',
    numbers: 'Numery',
    channel: 'Kanał',
    show: 'Pokaż',
    delete: 'Usuń',
    waterpipe: 'Wodociąg',

    report_package_id: 'ZD1',
    report_communicator: 'ZD4',
    report_gsm_channel: 'ZG1',
    report_used: 'ZI8',
    report_firmware: 'ZI9',
    report_recipient: 'ZD9',
    report_gsm_level_first: 'ZG4',
    report_gsm_count_starts: 'ZG5',
    report_gsm_count_communications: 'ZG6',
    report_gsm_buffer: 'ZG2',
    report_gsm_buffer_all: 'ZG14',
    report_gsm_uptime: 'ZG7',
    report_radio_battery: 'ZR1',
    report_battery: 'ZI1',
    report_temperature: 'ZI3',
    report_quality: 'ZD7',
    report_learning: 'ZI4',
    report_inductor_1_quality: 'ZI5',
    report_inductor_2_quality: 'ZI6',
    report_inductor_3_quality: 'ZI7',
    report_alert_mechanic_count: 'ZA2',
    report_alert_mechanic_duration: 'ZA3',
    report_alert_mechanic_prealerts: 'ZA1',
    report_alert_magnetic_count: 'ZA5',
    report_alert_magnetic_duration: 'ZA6',
    report_alert_magnetic_prealert: 'ZA4',
    report_config_statuses: 'ZS2',
    report_debug_statuses: 'ZS3',
    report_debug_reset_time: 'ZS4',
    report_lte_bts_band: 'ZS6',

    alarm_id: 'LP',
    alarm_communicator: 'AD4/ZD4',
    alarm_received_at: 'AD2',
    alarm_date: 'AD3',
    alarm_gsm_level: 'AG3',
    alarm_used: 'AI9',
    alarm_firmware: 'AI10',
    alarm_recipient: 'AD7',
    alarm_voltage: 'AI2',
    report_received_at: 'ZD2',
    report_date: 'ZD3',
    report_channel: 'ZD8',
    report_voltage: 'ZI2',
    report_radio_signal_strength: 'ZR6',
    report_gsm_level: 'ZG3',
    report_readings: 'ZD5',
    report_sabotage: 'ZD6',
    report_plus: '+',
    report_minus: '-',
    report_m: 'm',
    report_M: 'M',
    report_g: 'g',
    report_G: 'G',
    report_d: 'd',
    report_D: 'D',
    parser_error: 'Błąd parsera',
};

export const tabHeadToSymbolsToTitles = {
    alarm_id: 'LP',
    alarm_communicator: 'NR. K',
    alarm_received_at: 'DATA DOSTARCZENIA NA SERWER',
    alarm_date: 'DATA WYGENEROWANIA',
    alarm_gsm_level: 'POZIOM SYGNAŁU ',
    alarm_used: 'TYP ANTENY/PRZEZNACZENIE',
    alarm_firmware: 'FIRMWARE',
    alarm_recipient: 'ODBIORCA',
    alarm_voltage: 'NAPĘCIE',
    report_received_at: 'DATA DOSTARCZENIA NA SERWER',
    report_date: 'DATA WYGENEROWANIA',
    report_channel: 'KANAŁ',
    report_voltage: 'NAPIĘCIE',
    report_radio_signal_strength: 'SYGNAŁ RADIOWY W PRZYPADKU IMPULSATORA',
    report_gsm_level: 'SYGNAŁ GSM',
    report_readings: 'ODCZYTY',
    report_sabotage: 'SABOTAŻ',
    report_plus: '+',
    report_minus: '-',
    report_m: 'm',
    report_M: 'M',
    report_g: 'g',
    report_G: 'G',
    report_d: 'd',
    report_D: 'D',
    parser_error: 'Błąd parsera',

    ordinal_number: 'Liczba porządkowa',
    tab_1: '',
    tab_2: '',
    tab_3: '',
    tab_4: '',
    tab_5: '',

    report_package_id: 'ID',
    report_communicator: 'Nr komunikatora',
    report_gsm_channel: 'Nr kanału transmisyjnego',
    report_used: 'Przeznaczenie komunikatora: typ anteny',
    report_firmware: 'Firmware ID komunikatora',
    report_recipient: 'Numer odbiorcy - klienta',
    report_gsm_level_first: 'Poziom GSM przy pierwszej próbie wysłania bieżącego raportu',
    report_gsm_count_starts: 'Licznik sesji GSM',
    report_gsm_count_communications: 'Ilość poprawnych komunikacji przez moduł GSM/LTE',
    report_gsm_buffer: 'Ilość raportów w buforze bez raportów minutowych',
    report_gsm_buffer_all: 'Ilość raportów minutowych w buforze',
    report_gsm_uptime: 'Czas włączenia modułu GSM/LTE w komunikatorze [min] / zużycie energii [mAh]',
    report_radio_battery: 'Zużycie energii [mAh]',
    report_battery: 'Stan baterii komunikatora [%]',
    report_temperature: 'Temperatura komunikatora',
    report_quality: 'Licznik przepływu wstecznego [impuls]',
    report_learning: 'Stan "uczenia się" 0 - 100 [%]',
    report_inductor_1_quality: 'Jakość sygnału cewki 1',
    report_inductor_2_quality: 'Jakość sygnału cewki 2',
    report_inductor_3_quality: 'Jakość sygnału cewki 3',
    report_alert_mechanic_count: 'Dobowy Licznik ilości sabotażu mechanicznego',
    report_alert_mechanic_duration: 'Łączny czas trwania sabotażu mech.o [s]. max 194 dni',
    report_alert_mechanic_prealerts: 'Ilość prealarmów sabotażu mechanicznego',
    report_alert_magnetic_count: 'Dobowy Licznik ilości sabotażu magnetycznego',
    report_alert_magnetic_duration: 'Łączny czas trwania sabotażu magnet. [s], max 194 dni',
    report_alert_magnetic_prealert: 'Ilość prealarmów sabotażu magnetycznego',
    report_config_statuses: 'Statusy debugowe',
    report_debug_statuses: 'Statusy debugowe',
    report_debug_reset_time: 'Opis Statusów debugowych - czas od ostatniego resetu [min]',
    report_lte_bts_band: 'Informacja o pasmie LTE i nr BTSa',

    template_name: 'Nazwa zestawienia',
    template_description: 'Opis zestawienia',
    template_createdAt: 'Data utworzenia',
    template_numbers: 'Numery komunikatorów',
    template_waterpipeName: 'Nazwa wodociągu',
    template_waterpipeSign: 'Znak rozdzielnicy',
    template_channel: 'Kanał',
    template_action: '',
};

export const columnTypes = {
    ORDINAL_NUMBER: 'ordinal_number',
    TAB_1: 'tab_1',
    TAB_2: 'tab_2',
    TAB_3: 'tab_3',
    TAB_4: 'tab_4',
    TAB_5: 'tab_5',
    SHOW: 'show',
    DELETE: 'delete',

    DATE_ORDERED: 'date_ordered',
    DATE_FINISHED: 'date_finished',
    WATERPIPE: 'waterpipe',
    DATE_FROM: 'date_from',
    DATE_TO: 'date_to',
    NUMBERS: 'numbers',

    REPORT_GSM_LEVEL: 'report_gsm_level',
    REPORT_GSM_LEVEL_FIRST: 'report_gsm_level_first',
    REPORT_GSM_CHANNEL: 'report_gsm_channel',
    REPORT_COMMUNICATOR: 'report_communicator',
    REPORT_READINGS: 'report_readings',
    REPORT_SABOTAGE: 'report_sabotage',
    REPORT_RECEIVED_AT: 'report_received_at',
    REPORT_PLUS: 'report_plus',
    REPORT_MINUS: 'report_minus',
    REPORT_M: 'report_m',
    REPORT_CAPITAL_M: 'report_M',
    REPORT_G: 'report_g',
    REPORT_CAPITAL_G: 'report_G',
    REPORT_D: 'report_d',
    REPORT_CAPITAL_D: 'report_D',
    PARSER_ERROR: 'parser_error',
    REPORT_DEBUG_RESET_TIME: 'report_debug_reset_time',
    REPORT_PACKAGE_ID: 'report_package_id',
    REPORT_DATE: 'report_date',
    REPORT_USED: 'report_used',
    REPORT_FIRMWARE: 'report_firmware',
    REPORT_RECIPIENT: 'report_recipient',
    REPORT_GSM_COUNT_STARTS: 'report_gsm_count_starts',
    REPORT_GSM_COUNT_COMMUNICATIONS: 'report_gsm_count_communications',
    REPORT_GSM_BUFFER: 'report_gsm_buffer',
    REPORT_GSM_BUFFER_ALL: 'report_gsm_buffer_all',
    REPORT_GSM_UPTIME: 'report_gsm_uptime',
    REPORT_RADIO_BATTERY: 'report_radio_battery',
    REPORT_RADIO_SIGNAL_STRENGTH: 'report_radio_signal_strength',
    REPORT_VOLTAGE: 'report_voltage',
    REPORT_BATTERY: 'report_battery',
    REPORT_TEMPERATURE: 'report_temperature',
    REPORT_CHANNEL: 'report_channel',
    REPORT_QUALITY: 'report_quality',
    REPORT_LEARNING: 'report_learning',
    REPORT_INDUCTOR_1_QUALITY: 'report_inductor_1_quality',
    REPORT_INDUCTOR_2_QUALITY: 'report_inductor_2_quality',
    REPORT_INDUCTOR_3_QUALITY: 'report_inductor_3_quality',
    REPORT_ALERT_MECHANIC_COUNT: 'report_alert_mechanic_count',
    REPORT_ALERT_MECHANIC_DURATION: 'report_alert_mechanic_duration',
    REPORT_ALERT_MECHANIC_PREALERTS: 'report_alert_mechanic_prealerts',
    REPORT_ALERT_MAGNETIC_COUNT: 'report_alert_magnetic_count',
    REPORT_ALERT_MAGNETIC_DURATION: 'report_alert_magnetic_duration',
    REPORT_ALERT_MAGNETIC_PREALERT: 'report_alert_magnetic_prealert',
    REPORT_CONFIG_STATUSES: 'report_config_statuses',
    REPORT_DEBUG_STATUSES: 'report_debug_statuses',
    REPORT_LTE_BTS_BAND: 'report_lte_bts_band',

    ALARM_ID: 'alarm_id',
    ALARM_COMMUNICATOR: 'alarm_communicator',
    ALARM_RECEIVED_AT: 'alarm_received_at',
    ALARM_DATE: 'alarm_date',
    ALARM_GSM_LEVEL: 'alarm_gsm_level',
    ALARM_USED: 'alarm_used',
    ALARM_RECIPIENT: 'alarm_recipient',
    ALARM_VOLTAGE: 'alarm_voltage',

    TEMPLATE_ID: 'id',
    TEMPLATE_NAME: 'name',
    TEMPLATE_DESCRIPTION: 'description',
    TEMPLATE_CREATED_AT: 'createdAt',
    TEMPLATE_NUMBERS: 'numbers',
    TEMPLATE_WATERPIPE_NAME: 'waterpipeName',
    TEMPLATE_WATERPIPE_SIGN: 'waterpipeSign',
    TEMPLATE_CHANNEL: 'channel',
    TEMPLATE_ACTION: 'action',
    READINGS: 'readings',
    DEBUG_RESET_TIME: 'debug_reset_time',
    FLOWS: 'flows',
    ALERT_MECHANIC_DURATION: 'alert_mechanic_duration',
    TRANSMITTERS: 'transmitters',
    CHANNEL: 'channel',
};

export const transmittersFields = {
    number: 'number',
    communication_status: 'communication_status',
};

export const tabHeadOrderTestList = [
    columnTypes.ORDINAL_NUMBER,
    columnTypes.REPORT_PACKAGE_ID,
    columnTypes.REPORT_RECEIVED_AT,
    columnTypes.REPORT_DATE,
    columnTypes.REPORT_COMMUNICATOR,
    columnTypes.REPORT_GSM_CHANNEL,
    columnTypes.REPORT_USED,
    columnTypes.REPORT_FIRMWARE,
    columnTypes.REPORT_RECIPIENT,
    columnTypes.REPORT_GSM_LEVEL,
    columnTypes.REPORT_GSM_LEVEL_FIRST,
    columnTypes.REPORT_GSM_COUNT_STARTS,
    columnTypes.REPORT_GSM_COUNT_COMMUNICATIONS,
    columnTypes.REPORT_GSM_BUFFER,
    columnTypes.REPORT_GSM_BUFFER_ALL,
    columnTypes.REPORT_GSM_UPTIME,
    columnTypes.REPORT_RADIO_BATTERY,
    columnTypes.REPORT_VOLTAGE,
    columnTypes.REPORT_BATTERY,
    columnTypes.REPORT_TEMPERATURE,
    columnTypes.REPORT_CHANNEL,
    columnTypes.REPORT_READINGS,
    columnTypes.REPORT_SABOTAGE,
    columnTypes.REPORT_QUALITY,
    columnTypes.REPORT_LEARNING,
    columnTypes.REPORT_INDUCTOR_1_QUALITY,
    columnTypes.REPORT_INDUCTOR_2_QUALITY,
    columnTypes.REPORT_INDUCTOR_3_QUALITY,
    columnTypes.REPORT_ALERT_MECHANIC_COUNT,
    columnTypes.REPORT_ALERT_MECHANIC_DURATION,
    columnTypes.REPORT_ALERT_MECHANIC_PREALERTS,
    columnTypes.REPORT_ALERT_MAGNETIC_COUNT,
    columnTypes.REPORT_ALERT_MAGNETIC_DURATION,
    columnTypes.REPORT_ALERT_MAGNETIC_PREALERT,
    columnTypes.REPORT_CONFIG_STATUSES,
    columnTypes.REPORT_DEBUG_STATUSES,
    columnTypes.REPORT_DEBUG_RESET_TIME,
    columnTypes.REPORT_LTE_BTS_BAND,
    columnTypes.TAB_1,
    columnTypes.TAB_2,
    columnTypes.TAB_3,
    columnTypes.TAB_4,
    columnTypes.TAB_5,
];

export const tabHeadOrderProdList = [
    columnTypes.ALARM_ID,
    columnTypes.ALARM_COMMUNICATOR,
    columnTypes.ALARM_RECEIVED_AT,
    columnTypes.ALARM_DATE,
    columnTypes.ALARM_GSM_LEVEL,
    columnTypes.ALARM_USED,
    columnTypes.REPORT_FIRMWARE,
    columnTypes.ALARM_RECIPIENT,
    columnTypes.ALARM_VOLTAGE,
    columnTypes.REPORT_RECEIVED_AT,
    columnTypes.REPORT_DATE,
    columnTypes.REPORT_CHANNEL,
    columnTypes.REPORT_VOLTAGE,
    columnTypes.REPORT_RADIO_SIGNAL_STRENGTH,
    columnTypes.REPORT_GSM_LEVEL,
    columnTypes.REPORT_READINGS,
    columnTypes.REPORT_SABOTAGE,
    columnTypes.REPORT_PLUS,
    columnTypes.REPORT_MINUS,
    columnTypes.REPORT_M,
    columnTypes.REPORT_CAPITAL_M,
    columnTypes.REPORT_G,
    columnTypes.REPORT_CAPITAL_G,
    columnTypes.REPORT_D,
    columnTypes.REPORT_CAPITAL_D,
    columnTypes.PARSER_ERROR,
];

export const filterStatusesNoDiacritic = {
    PARSER_ERROR: 'blad parsowania',
    DATE_ERROR: 'blad daty',
    GATEWAY_ERROR: 'blad bramki',
    PIPELINE_ERROR: 'blad wodociagu',
    PROTOCOL_ERROR: 'blad protokolu',
    FINISHED_OFF: 'zakonczone off',
    FINISHED_WITHOUT_OFF: 'zakonczone bez off',
    DECRYPTION_ERROR: 'blad dekodowania',
};

export const filterStatuses = {
    ALL: 'Wszystkie',
    NEW: 'Nowe',
    PENDING: 'Oczekujące',
    FINISHED: 'Zakończone',
    FINISHED_OFF: 'Zakończone OFF',
    FINISHED_WITHOUT_OFF: 'Zakończone bez OFF',
    PARSER_ERROR: 'Błąd parsowania',
    DATE_ERROR: 'Błąd daty',
    GATEWAY_ERROR: 'Błąd bramki',
    PIPELINE_ERROR: 'Błąd wodociągu',
    PROTOCOL_ERROR: 'Błąd protokołu',
    DUPLICATES: 'Duplikaty',
    NOT_PARSED: 'Nieparsowane',
    DECRYPTION_ERROR: 'Błąd dekodowania',
};

export const protocolTypes = {
    V8: 'V8',
    V9: 'V9',
    V10: 'V10',
    V11: 'V11',
    V12: 'V12',
};

export const labels = {
    PASTE_PACKAGE_DATA: 'Wklej treść pakietu',
    PASTE_ENCRYPTED_PACKAGE_DATA: 'Wklej treść zakodowanego pakietu',
};

export const snackbarMsg = {
    PACKAGE_HAS_BEEN_SENT: 'Pakiet został wysłany',
    PACKAGE_HAS_BEEN_DECRYPTED: 'Pakiet został zdekodowany',
    PACKAGE_HAS_BEEN_ENCRYPTED: 'Pakiet został zakodowany',
    TEMPLATE_HAS_BEEN_ADDED: 'Szablon został dodany',
    TEMPLATE_HAS_BEEN_DELETED: 'Szablon został usunięty',
    LIST_TEMPLATE_HAS_BEEN_ADDED: 'Pomyślnie dodano zestawienie do listy ulubionych',
    LIST_TEMPLATE_HAS_BEEN_EDITED: 'Zestawienie zostało pomyślnie edytowane',
    LIST_TEMPLATE_HAS_BEEN_DELETED: 'Zestawienie zostało pomyślnie usunięte',
    TEST_REPORT_GENERATE_SUCCESS: 'Rozpoczęto generowanie zestawienia testowego',
    TEST_REPORT_GENERATE_ERROR: 'Wystąpił błąd podczas generowania zestawienia testowego',
    TEST_LIST_DELETED_SUCCESS: 'Zestawienie testowe zostało pomyślnie usunięte',
    TEST_LIST_DELETED_ERROR: 'Wystąpił błąd podczas usuwania zestawienia testowego',
    ERROR_CONNECTION: 'Wystąpił błąd połączenia z serwerem',
    ERROR: 'Błąd:',
};

export const snackbarVariant = {
    ERROR: 'error',
    SUCCESS: 'success',
};

export const snackbarDefaultValue = {
    isSnackbarOpen: false,
    message: '',
    errorInfo: '',
    variant: '',
};

export const fileExporterTypes = {
    XLS: 'XLS',
    CSV: 'CSV',
    PDF: 'PDF',
};

export const reportTypes = {
    ALL: 'all',
    ALL_PL: 'Wszystkie',
    REPORT: 'report',
    REPORTS: 'reports',
    REPORT_PL: 'Raport',
    REPORTS_PL: 'Raporty',
    ALARM: 'alarm',
    ALARMS: 'alarms',
    ALARM_PL: 'Alarm',
    ALARMS_PL: 'Alarmy',
    SUMMARY: 'summary',
    SUMMARIES: 'summaries',
    SUMMARY_PL: 'Podsumowanie',
    SUMMARIES_PL: 'Podsumowania',
    MINUTE: 'minute',
    MINUTE_REPORTS: 'minute-reports',
    MINUTE_REPORT_PL: 'Raport minutowy',
    MINUTE_REPORTS_PL: 'Raporty minutowe',
    CONFIG: 'config',
    CONFIGS: 'configs',
    CONFIG_PL: 'Raport konfiguracyjny',
    CONFIGS_PL: 'Raporty konfiguracyjne',
    CON_MAP: 'con_map',
    CON_MAPS: 'concentrator-maps',
    CON_MAP_PL: 'Koncentrator mapa',
    CON_MAPS_PL: 'Koncentrator mapy',
    CON_CONFIG: 'con_config',
    CON_CONFIGS: 'concentrator-configs',
    CON_CONFIG_PL: 'Raport konfiguracyjny z koncentratora',
    CON_CONFIGS_PL: 'Raporty konfiguracyjne z koncentratora',
};

export const fetchStatuses = {
    loading: 'Trwa wczytywanie danych...',
    exporting: 'Trwa eksportowanie...',
    error: 'Wystąpił błąd połączenia z serwerem.',
};

export const selectStrings = {
    noOptionsAvailable: 'Brak opcji do wyświetlenia',
    noOptionsMatchFilter: 'Żadne opcje nie pasują do filtru',
    noValidValue: 'Nowa wartość jest nieprawidłowa',
    clearText: 'Wyczyść',
    openText: 'Otwórz',
    closeText: 'Zamknij',
};

export const historyActions = {
    PUSH: 'PUSH',
    POP: 'POP',
};

export const waterpipesAll = {
    id: 0,
    name: 'Wszystkie',
    sign: '',
};

export const waterpipeWater = {
    name: 'woda',
    sign: '0W',
};

export const columnEventTypes = {
    resize: 'columnResized',
    move: 'columnMoved',
};
