import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
    },
};

class HeaderV9 extends React.Component {
    state = {
        protocolType: '',
        transmissionChannel: '',
        customerNumber: '',
        communicatorNumber: '',
        measureChannel: '',
        date: '',
        battery: '',
        GSMOrGPRSLevel: '',
        GSMSOrGPRSwitchOnAmount: '',
        GSMSOrGPRProperCommunicationAmount: '',
        GSMSOrGPRSwitchOnTime: '',
        buffersReportAmount: '',
        counterPurpose: '',
        firmWareID: '',
        temperature: '',
        batteryVoltage: '',
    };

    componentDidUpdate(prevProps) {
        const { protocolType, schemeV9Header, selectedScheme, clearSelectedScheme } = this.props;
        if (prevProps.protocolType !== protocolType) {
            this.setHeaderProtocolType();
        }
        if (prevProps.schemeV9Header !== schemeV9Header) {
            this.setState({ ...schemeV9Header });
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
            this.setHeaderProtocolType();
        }
    }

    handleChange = (key, event) => {
        this.setState({ [key]: event.target.value }, () => this.props.getPackagesData(this.state, 'header'));
    };

    onSubmit = () => {
        this.props.saveHeaderFormState(this.state);
        return false;
    };

    setHeaderProtocolType = () => {
        const { protocolType } = this.props;
        this.setState({ protocolType });
        this.handleChange('protocolType', {
            target: { value: protocolType },
        });
    };

    clearState = () => {
        this.setState({
            protocolType: '',
            transmissionChannel: '',
            customerNumber: '',
            communicatorNumber: '',
            measureChannel: '',
            date: '',
            battery: '',
            GSMOrGPRSLevel: '',
            GSMSOrGPRSwitchOnAmount: '',
            GSMSOrGPRProperCommunicationAmount: '',
            GSMSOrGPRSwitchOnTime: '',
            buffersReportAmount: '',
            counterPurpose: '',
            firmWareID: '',
            temperature: '',
            batteryVoltage: '',
        });
    };

    render() {
        const { classes } = this.props;
        const {
            protocolType,
            transmissionChannel,
            customerNumber,
            communicatorNumber,
            measureChannel,
            date,
            battery,
            GSMOrGPRSLevel,
            GSMSOrGPRSwitchOnAmount,
            GSMSOrGPRProperCommunicationAmount,
            GSMSOrGPRSwitchOnTime,
            buffersReportAmount,
            counterPurpose,
            firmWareID,
            temperature,
            batteryVoltage,
        } = this.state;
        return (
            <div className={classes.root}>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <TextValidator
                        className={classes.margin}
                        value={protocolType}
                        label="Rodzaj Protokołu: 1 znak [0,6-9,A-Z,a-z] np. C"
                        validators={['matchRegexp:^([6-9]|[A-Za-z]|[0]?)$']}
                        variant="outlined"
                        errorMessages={['Niepoprawny format']}
                        required
                        disabled
                        data-cy="protocolType"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={transmissionChannel}
                        onChange={e => this.handleChange('transmissionChannel', e)}
                        label="Nr kanału transmisyjnego: 1 znak [1-5] np. 2"
                        validators={['matchRegexp:^([1-5]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="transmissionChannel"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={customerNumber}
                        onChange={e => this.handleChange('customerNumber', e)}
                        label="Numer odbiorcy: 1 znak [0-9,a-z,A-Z,inne znaki ASCII] np. X"
                        validators={['matchRegexp:^(.){1}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="customerNumber"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={communicatorNumber}
                        onChange={e => this.handleChange('communicatorNumber', e)}
                        label="Numer komunikatora: 8 znaków [00000000-9999999] np. 12345678"
                        validators={['matchRegexp:^([0-9]{8})$']}
                        errorMessages={['Niepoprawny format']}
                        data-cy="communicatorNumber"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={measureChannel}
                        onChange={e => this.handleChange('measureChannel', e)}
                        label="Numer kanału pomiarowego: 1 znak [1,2] np. 1"
                        validators={['matchRegexp:^([1-2])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="measureChannel"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={date}
                        onChange={e => this.handleChange('date', e)}
                        label="Rok miesiąc: 4 znaki [0-9] np. 1908"
                        validators={['matchRegexp:^([0-9]{2})(-?)(1[0-2]|0[1-9])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="date"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={battery}
                        onChange={e => this.handleChange('battery', e)}
                        label="Bateria: 1 znak [A,0-9] np. 1"
                        validators={['matchRegexp:^([0-9]|[A]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="battery"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMOrGPRSLevel}
                        onChange={e => this.handleChange('GSMOrGPRSLevel', e)}
                        label="Poziom GSM: 1 znak [0-9,A-V] np. V"
                        validators={['matchRegexp:^([0-9]|[A-V]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMOrGPRSLevel"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMSOrGPRSwitchOnAmount}
                        onChange={e => this.handleChange('GSMSOrGPRSwitchOnAmount', e)}
                        label="Ilość wszystkich włączeń modułu GSM/GPRS: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMSOrGPRSwitchOnAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMSOrGPRProperCommunicationAmount}
                        onChange={e => this.handleChange('GSMSOrGPRProperCommunicationAmount', e)}
                        label="Ilość poprawnych komunikacji przez moduł GSM/GPRS: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMSOrGPRProperCommunicationAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMSOrGPRSwitchOnTime}
                        onChange={e => this.handleChange('GSMSOrGPRSwitchOnTime', e)}
                        label="Czas włączenia modułu GSM/GPRS w nadajniku[min]: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMSOrGPRSwitchOnTime"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={buffersReportAmount}
                        onChange={e => this.handleChange('buffersReportAmount', e)}
                        label="Ilość raportów w buforze: 2 znaki [00-99] np. 12"
                        validators={['matchRegexp:^([00-99]){2}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="buffersReportAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={counterPurpose}
                        onChange={e => this.handleChange('counterPurpose', e)}
                        label="Przeznaczenie licznika: 1 znak [0-9,a-z,A-Z] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-Z]|[a-z]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="counterPurpose"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firmWareID}
                        onChange={e => this.handleChange('firmWareID', e)}
                        label="Firmware ID: 4 znaki [0-9,a-z,A-Z,inne znaki ASCII] np. 12aB"
                        validators={['matchRegexp:^(.){4}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firmWareID"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={temperature}
                        onChange={e => this.handleChange('temperature', e)}
                        label="Temperatura: 1 znak [0-9,a-z,A-Z,inne znaki ASCII] np. 1"
                        validators={['matchRegexp:^(.){1}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="temperature"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={batteryVoltage}
                        onChange={e => this.handleChange('batteryVoltage', e)}
                        label="Napięcie baterii: 1 znak [0-9,a-z,A-Z,inne znaki ASCII] np. 1"
                        validators={['matchRegexp:^(.){1}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="batteryVoltage"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

HeaderV9.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderV9);
