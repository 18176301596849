import React from 'react';
import { withStyles } from '@material-ui/core';
import theme from '../../themes/theme';

const styles = {
    link: {
        margin: theme.margins.textField.all,
        cursor: 'pointer',
        color: '#3f51b5',
        fontWeight: theme.font.weight.bold,
        backgroundImage: 'linear-gradient(#3f51b5, #3f51b5)',
        backgroundPosition: '0 100%',
        backgroundSize: '0% 2px',
        backgroundRepeat: 'no-repeat',
        paddingBottom: '4px',
        transition: 'background-size 0.3s, background-position 0s 0.3s, color 0.3s',
        '&:hover': {
            color: '#3f51b595',
            backgroundPosition: '100% 100%',
            backgroundSize: '100% 2px',
            backgroundImage: 'linear-gradient(#3f51b595, #3f51b595)',
        },
    },
};

const TextLink = ({ description, onClick, classes: { link }, style }) => (
    <p className={link} onClick={onClick} style={style}>
        {description}
    </p>
);

export default withStyles(styles)(TextLink);
