import React, { Component } from 'react';
import TableSearchBar from '../Tables/TableSearchBar';
import AutocompleteBar from '../Tables/AutocompleteBar';
import { localStorageKey, reportTypes } from '../../utils/constants';

class FilterPackages extends Component {
    state = {
        id: '',
        sms: '',
        dokSelection: [
            reportTypes.ALL_PL,
            reportTypes.ALARM_PL,
            reportTypes.REPORT_PL,
            reportTypes.MINUTE_REPORT_PL,
            reportTypes.SUMMARY_PL,
            reportTypes.CONFIG_PL,
            reportTypes.CON_CONFIG_PL,
            reportTypes.CON_MAP_PL,
        ],
        dok: '',
        sim: '',
        com: '',
    };

    componentDidMount() {
        this.setStateFromLocalStorage();
    }

    componentDidUpdate(prevProps, prevState) {
        const { clearSearchField } = this.props;
        const { id, sms, dok, sim, com } = this.state;
        const checkIfSearchFieldIsClear = () => {
            if (clearSearchField && prevProps.clearSearchField !== clearSearchField) {
                this.setState({
                    id: '',
                    sms: '',
                    sim: '',
                    com: '',
                });
            }
        };

        const checkIfStateChanged = () => {
            if (
                prevState.id !== id ||
                prevState.sms !== sms ||
                prevState.dok !== dok ||
                prevState.sim !== sim ||
                prevState.com !== com
            ) {
                this.setStateFromLocalStorage();
            }
        };

        checkIfSearchFieldIsClear();
        checkIfStateChanged();
    }

    handleSearch = (phrase, filterType, isEnterPressed) => {
        const { handleFilterPackages } = this.props;
        localStorage.removeItem(localStorageKey.PACKAGES_PAGE);
        const selectedDok = this.getTranslatedDokSelection(phrase);

        this.setState({ [filterType]: selectedDok }, () => {
            if (filterType === 'dok') {
                localStorage.setItem(localStorageKey.PACKAGES_SEARCH_DOK_NAME, phrase);
                isEnterPressed = true;
            }
            handleFilterPackages(selectedDok, filterType, isEnterPressed);
        });
    };

    getTranslatedDokSelection = selectedDok => {
        switch (selectedDok) {
            case reportTypes.ALL_PL:
                return reportTypes.ALL;
            case reportTypes.ALARM_PL:
                return reportTypes.ALARM;
            case reportTypes.REPORT_PL:
                return reportTypes.REPORT;
            case reportTypes.SUMMARY_PL:
                return reportTypes.SUMMARY;
            case reportTypes.MINUTE_REPORT_PL:
                return reportTypes.MINUTE;
            case reportTypes.CONFIG_PL:
                return reportTypes.CONFIG;
            case reportTypes.CON_MAP_PL:
                return reportTypes.CON_MAP;
            case reportTypes.CON_CONFIG_PL:
                return reportTypes.CON_CONFIG;
            default:
                return selectedDok;
        }
    };

    setStateFromLocalStorage = () => {
        const id = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_ID);
        const sms = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_SMS);
        const dok = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_DOK);
        const sim = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_SIM);
        const com = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_COM);

        this.setState(state => ({
            id: id || state.id,
            sms: sms || state.sms,
            dok: dok || state.dok,
            sim: sim || state.sim,
            com: com || state.com,
        }));
    };

    render() {
        const { clearSearchField, clearedSearchField } = this.props;
        const { id, sms, dok, sim, com, dokSelection } = this.state;

        return (
            <>
                <AutocompleteBar
                    label="Typ dokumentu"
                    placeholder="Wszystkie"
                    options={dokSelection}
                    handleFilter={selection => this.handleSearch(selection, 'dok')}
                    localStorageKey="packagesSearchDokName"
                    value={dok}
                    isDocumentType
                />
                <TableSearchBar
                    handleSearch={(phrase, isEnterPressed) => this.handleSearch(phrase, 'id', isEnterPressed)}
                    clearSearchField={clearSearchField}
                    clearedSearchField={clearedSearchField}
                    value={id}
                    placeholder="Szukaj ID"
                />
                <TableSearchBar
                    handleSearch={(phrase, isEnterPressed) =>
                        this.handleSearch(phrase, 'sms', isEnterPressed)
                    }
                    clearSearchField={clearSearchField}
                    clearedSearchField={clearedSearchField}
                    value={sms}
                    placeholder="Szukaj SMS"
                />
                <TableSearchBar
                    handleSearch={(phrase, isEnterPressed) =>
                        this.handleSearch(phrase, 'sim', isEnterPressed)
                    }
                    clearSearchField={clearSearchField}
                    clearedSearchField={clearedSearchField}
                    value={sim}
                    placeholder="Szukaj SIM"
                />
                <TableSearchBar
                    handleSearch={(phrase, isEnterPressed) =>
                        this.handleSearch(phrase, 'com', isEnterPressed)
                    }
                    clearSearchField={clearSearchField}
                    clearedSearchField={clearedSearchField}
                    value={com}
                    placeholder="Szukaj NAK"
                />
            </>
        );
    }
}

export default FilterPackages;
