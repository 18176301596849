/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import FavoriteReportName from '../FavoriteReportName/FavoriteReportName';
import FavoriteReportDescription from '../FavoriteReportDescription/FavoriteReportDescription';
import WaterpipeList from '../../WaterpipeList/WaterpipeList';
import CommunicatorsList from '../../CommunicatorsList/CommunicatorsList';
import { endpoint, listTypes, listTypesId, localStorageKey, waterpipesAll } from '../../../utils/constants';
import theme from '../../../themes/theme';
import TitleHeader from '../../TitleHeader/TitleHeader';
import { axiosInstance } from '../../../utils/axiosInstance';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import CommunicatorChannelList from '../../CommunicatorChannelList/CommunicatorChannelList';

const styles = {
    container: {
        margin: '0 auto',
        width: '80%',
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'start',
        alignItems: 'start',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '20px',
    },
    actionButton: {
        margin: theme.margins.button.all,
        width: 150,
    },
    selectsContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.margins.textField.bottom,
    },
    actionButtonsContainer: {
        width: '100%',
        marginTop: '2.2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    '@keyframes shake': {
        '10%, 90%': {
            transform: 'translate3d(-1px, 0, 0)',
        },
        '20%, 80%': {
            transform: 'translate3d(2px, 0, 0)',
        },
        '30%, 50%, 70%': {
            transform: 'translate3d(-2px, 0, 0)',
        },
        '40%, 60%': {
            transform: 'translate3d(2px, 0, 0)',
        },
    },
    infoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
};

const FavoriteReportEdit = ({
    classes: { actionButton, container, selectsContainer, actionButtonsContainer, loadingContainer },
}) => {
    const [waterpipeId, setWaterpipeId] = useState(null);
    const [waterpipeSign, setWaterpipeSign] = useState(null);
    const [numbers, setNumbers] = useState(null);
    const [channel, setChannel] = useState('');
    const [isAnyError, setIsAnyError] = useState(false);
    const [isFocusOnCommunicatorsList, setIsFocusOnCommunicatorsList] = useState(false);
    const [isCommunicatorsListEmpty, setIsCommunicatorsListEmpty] = useState(true);
    const [favoriteReportName, setFavoriteReportName] = useState('');
    const [favoriteReportDesc, setFavoriteReportDesc] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [id, setId] = useState(null);
    const [templateType, setTemplateType] = useState(listTypesId.TEST_LIST);
    const [waterpipesList, setWaterpipesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getWaterpipeIdToEdit();
        getWaterpipesList();
    }, []);

    useEffect(() => {
        if (waterpipesList?.length) {
            getFavoriteReport();
        }
    }, [waterpipesList]);

    const getWaterpipeIdToEdit = () => {
        if (typeof window !== 'undefined') {
            const { href } = window.location;
            return href.substring(href.lastIndexOf('/') + 1);
        }
        return null;
    };

    const getFavoriteReport = () => {
        axiosInstance
            .get(`${endpoint.LIST_TEMPLATE_SHOW}/${getWaterpipeIdToEdit()}`)
            .then(res => {
                if (res?.data) {
                    const {
                        id,
                        name,
                        description,
                        numbers,
                        template_type,
                        channel,
                        waterpipe_id,
                        created_at,
                    } = res.data;

                    setId(id);
                    setFavoriteReportName(name);
                    setFavoriteReportDesc(description);
                    setNumbers(numbers);
                    setTemplateType(template_type);
                    setChannel(channel);
                    setWaterpipeId(waterpipe_id);
                    setCreatedAt(created_at);
                    setWaterpipeSign(
                        waterpipesList?.filter(waterpipe => waterpipe.id === waterpipe_id)[0]?.sign
                    );
                    setIsLoading(false);
                } else {
                    redirectToFavoriteList();
                }
            })
            .catch(err => {
                console.error('Error while fetching data', err);
                passData({ error: true });
                redirectToFavoriteList();
            });
    };

    useEffect(() => {
        if (isCommunicatorsListEmpty) {
            setIsAnyError(true);
        }
    }, [isCommunicatorsListEmpty]);

    const getWaterpipesList = () => {
        const waterpipesList = JSON.parse(localStorage.getItem(localStorageKey.WATERPIPES_LIST));

        if (waterpipesList) {
            setWaterpipesList(waterpipesList);
        } else {
            axiosInstance
                .get(endpoint.PACKAGES, {
                    params: {
                        chunk: 1,
                        page: 1,
                    },
                })
                .then(content => {
                    const { data } = content.data.filters.waterpipes;
                    if (templateType === listTypes.TEST_LIST) {
                        data.push(waterpipesAll);
                    }

                    setWaterpipesList(data);
                })
                .catch(err => {
                    console.error('Error while fetching waterpipes', err);
                    passData({ error: true });
                    redirectToFavoriteList();
                });
        }
    };

    const saveData = () => {
        const data = {
            id,
            name: favoriteReportName,
            description: favoriteReportDesc,
            numbers,
            channel,
            waterpipeId,
            waterpipeName: waterpipesList?.filter(waterpipe => waterpipe.id === waterpipeId)[0]?.name,
            waterpipeSign,
            createdAt,
            templateType,
        };

        passData(data).then(redirectToFavoriteList);
    };

    const deleteData = () => {
        passData({ id }).then(redirectToFavoriteList);
    };

    const redirectToFavoriteList = () => {
        if (typeof window !== 'undefined') {
            window.open(`${window.location.origin}/#/list-templates/${templateType}`, '_self');
        }
    };

    const passData = async data => {
        localStorage.setItem(localStorageKey.FAVORITE_REPORT_EDITED, JSON.stringify(data));
        return Promise.resolve(data);
    };

    return isLoading ? (
        <div className={loadingContainer}>
            <LoadingSpinner />
        </div>
    ) : (
        <section className={container}>
            <TitleHeader title={'Edycja zestawienia'} />
            <div className={selectsContainer}>
                <div style={{ flex: 1 }}>
                    <FavoriteReportName
                        favoriteReportName={favoriteReportName}
                        setFavoriteReportName={setFavoriteReportName}
                    />
                </div>

                <WaterpipeList
                    listType={
                        templateType === listTypesId.TEST_LIST
                            ? listTypes.TEST_LIST
                            : listTypes.PRODUCTION_LIST
                    }
                    setWaterpipeId={setWaterpipeId}
                    setWaterpipeSign={setWaterpipeSign}
                    waterpipeId={waterpipeId}
                />

                <CommunicatorChannelList setChannel={setChannel} channel={channel} />
            </div>

            <CommunicatorsList
                isFocusOnCommunicatorsList={isFocusOnCommunicatorsList}
                setFocusOnCommunicatorsFieldType={setIsFocusOnCommunicatorsList}
                setIsCommunicatorsListEmpty={setIsCommunicatorsListEmpty}
                setIsAnyError={setIsAnyError}
                setNumbers={setNumbers}
                numbers={numbers}
                multiline
            />

            <FavoriteReportDescription
                favoriteReportDesc={favoriteReportDesc ?? ''}
                setFavoriteReportDesc={setFavoriteReportDesc}
            />

            <div className={actionButtonsContainer}>
                <Button variant="contained" className={actionButton} onClick={redirectToFavoriteList}>
                    ANULUJ
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={actionButton}
                    onClick={saveData}
                    disabled={isAnyError || numbers?.length < 8 || !favoriteReportName}>
                    ZAPISZ
                </Button>
                <Button variant="contained" color="secondary" className={actionButton} onClick={deleteData}>
                    USUŃ
                </Button>
            </div>
        </section>
    );
};

export default withStyles(styles)(FavoriteReportEdit);
