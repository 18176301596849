import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { communicatorFieldType, listTypes, localStorageKey } from '../../../utils/constants';
import DateRange from '../../DateRange/DateRange';
import CommunicatorsRange from '../../CommunicatorsRange/CommunicatorsRange';
import WaterpipeList from '../../WaterpipeList/WaterpipeList';
import CommunicatorTypeList from '../../CommunicatorTypeList/CommunicatorTypeList';
import TitleHeader from '../../TitleHeader/TitleHeader';
import CommunicatorsProdList from '../../CommunicatorsList/CommunicatorsProdList';

const styles = {
    container: {
        margin: '0 auto',
        width: '602px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'start',
        alignItems: 'start',
        flexDirection: 'column',
    },
    selectsContainer: {
        display: 'flex',
    },
    button: {
        margin: '2rem auto',
        width: '15vw',
    },
};

const ProductionListForm = ({ classes: { container, selectsContainer, button } }) => {
    const [isFocusOnCommunicatorsRange, setIsFocusOnCommunicatorsRange] = useState(false);
    const [isCommunicatorsRangeEmpty, setIsCommunicatorsRangeEmpty] = useState(true);
    const [isCommunicatorsListEmpty, setIsCommunicatorsListEmpty] = useState(true);
    const [isFocusOnCommunicatorsList, setIsFocusOnCommunicatorsList] = useState(false);
    const [isAnyError, setIsAnyError] = useState(false);

    const [waterpipeId, setWaterpipeId] = useState(null);
    const [waterpipeSign, setWaterpipeSign] = useState(null);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [numbersFrom, setNumbersFrom] = useState(null);
    const [numbersTo, setNumbersTo] = useState(null);
    const [numbers, setNumbers] = useState(null);
    const [type, setType] = useState('');

    const setFocusOnCommunicatorsFieldType = (field, value) => {
        if (field === communicatorFieldType.COMMUNICATORS_RANGE) {
            setIsFocusOnCommunicatorsRange(value);
            setIsFocusOnCommunicatorsList(!value);
        }
        if (field === communicatorFieldType.COMMUNICATORS_LIST) {
            setIsFocusOnCommunicatorsList(value);
            setIsFocusOnCommunicatorsRange(!value);
        }
    };

    const handleOpenNewTabGeneratedList = () => {
        if (typeof window !== 'undefined') {
            window.open(`${window.location.origin}/#/generated-list`);
        }
    };

    useEffect(() => {
        if (isCommunicatorsRangeEmpty && isCommunicatorsListEmpty) {
            setIsAnyError(true);
        }
    }, [isCommunicatorsListEmpty, isCommunicatorsRangeEmpty]);

    useEffect(() => {
        const getNumbers = () => {
            if (numbers === '') return null;
            return numbers !== '' ? numbers : null;
        };

        const generatedListData = {
            waterpipeId: Number(waterpipeId),
            waterpipeSign,
            dateFrom,
            dateTo,
            numbersFrom: numbersFrom || null,
            numbersTo: numbersTo || null,
            numbers: getNumbers() !== null ? getNumbers() : null,
            type,
        };

        if (type) {
            localStorage.setItem(localStorageKey.GENERATED_PROD_LIST_DATA, JSON.stringify(generatedListData));
        }
    }, [waterpipeId, dateFrom, dateTo, numbersFrom, numbersTo, numbers, type]);

    useEffect(() => {
        const dataFromLocalStorage = JSON.parse(
            localStorage.getItem(localStorageKey.GENERATED_PROD_LIST_DATA)
        );

        if (dataFromLocalStorage) {
            setWaterpipeId(dataFromLocalStorage.waterpipeId);
            setWaterpipeSign(dataFromLocalStorage.waterpipeSign);
        }
    }, []);

    return (
        <div>
            <TitleHeader title={'Zestawienie produkcyjne'} />
            <section className={container}>
                <div className={selectsContainer}>
                    <WaterpipeList
                        listType={listTypes.PRODUCTION_LIST}
                        setWaterpipeId={setWaterpipeId}
                        setWaterpipeSign={setWaterpipeSign}
                        waterpipeId={waterpipeId}
                    />
                    <DateRange
                        setIsAnyError={setIsAnyError}
                        setDateFrom={setDateFrom}
                        setDateTo={setDateTo}
                    />
                </div>

                <CommunicatorsRange
                    listType={listTypes.PRODUCTION_LIST}
                    isFocusOnCommunicatorsRange={isFocusOnCommunicatorsRange}
                    setFocusOnCommunicatorsFieldType={setFocusOnCommunicatorsFieldType}
                    isCommunicatorsListEmpty={isCommunicatorsListEmpty}
                    setIsCommunicatorsRangeEmpty={setIsCommunicatorsRangeEmpty}
                    setIsAnyError={setIsAnyError}
                    setNumbersFrom={setNumbersFrom}
                    setNumbersTo={setNumbersTo}
                />
                <CommunicatorsProdList
                    isFocusOnCommunicatorsList={isFocusOnCommunicatorsList}
                    setFocusOnCommunicatorsFieldType={setFocusOnCommunicatorsFieldType}
                    isCommunicatorsRangeEmpty={isCommunicatorsRangeEmpty}
                    setIsCommunicatorsListEmpty={setIsCommunicatorsListEmpty}
                    setIsAnyError={setIsAnyError}
                    setNumbers={setNumbers}
                />
                <CommunicatorTypeList setType={setType} />
                <Button
                    variant="contained"
                    color="primary"
                    className={button}
                    onClick={handleOpenNewTabGeneratedList}
                    disabled={isAnyError}>
                    GENERUJ ZESTAWIENIE
                </Button>
            </section>
        </div>
    );
};

export default withStyles(styles)(ProductionListForm);
