import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { SingleSelect } from 'react-select-material-ui';
import AlertBodyV8 from './ProtocolV8/AlertBodyV8';
import ReportBodyV8 from './ProtocolV8/ReportBodyV8';
import SummaryBodyV8 from './ProtocolV8/SummaryBodyV8';
import ReportBodyV9 from './ProtocolV9/ReportBodyV9';
import AlertBodyV9 from './ProtocolV9/AlertBodyV9';
import SummaryBodyV9 from './ProtocolV9/SummaryBodyV9';
import GeneratorInputs from './GeneratorInputs';
import AlertBodyV10, { alertBodyV10fieldsToLittleEndian } from './ProtocolV10/AlertBodyV10';
import ReportBodyV10, { reportBodyV10fieldsToLittleEndian } from './ProtocolV10/ReportBodyV10';
import HeaderV10, { headerV10fieldsToLittleEndian } from './ProtocolV10/HeaderV10';

import SummaryBodyV10, { summaryBodyV10fieldsToLittleEndian } from './ProtocolV10/SummaryBodyV10';
import HeaderV8 from './ProtocolV8/HeaderV8';
import HeaderV9 from './ProtocolV9/HeaderV9';
import {
    cloneObjByJSON,
    getArrayFromObject,
    getSchemeId,
    isAdminRole,
    toLittleEndian,
} from '../../utils/helpers';
import { axiosInstance } from '../../utils/axiosInstance';
import Snackbar from '../Snackbar/Snackbar';
import {
    endpoint,
    localStorageKey,
    protocolTypes,
    snackbarMsg,
    snackbarVariant,
} from '../../utils/constants';
import TitleHeader from '../TitleHeader/TitleHeader';

const styles = {
    mainHeader: {
        margin: '2rem 0',
        textAlign: 'center',
        fontSize: '1.6rem',
        color: '#595652',
    },
    dropdownContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    dropdownItem: {
        padding: '1rem',
        maxWidth: '9.375rem',
    },
    generatorHeader: {
        display: 'flex',
        justifyContent: 'center',
        margin: '1rem',
    },
    livePreviewButton: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
    },

    selectDropdown: {
        width: '47rem',
        margin: '0 auto',
        marginBottom: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    addNewPackageButton: {
        minWidth: '3rem',
        margin: '0 auto',
        marginBottom: '1rem',
    },
    deleteButton: {
        width: '45rem',
        height: '2.5rem',
        marginLeft: '2rem',
    },
};

class Generator extends Component {
    state = {
        headerForm: {},
        selectedForms: [],
        livePreviewVal: {
            header: {},
        },
        numOfPackages: 1,
        selectedReportType: 'alarm',
        selectedProtocolType: 'V8',
        selectedScheme: [],
        schemeList: [],
        schemeV8Header: {},
        schemeV8AlertBody: {},
        schemeV8ReportBody: {},
        schemeV8SummaryBody: {},
        schemeV9Header: {},
        schemeV9AlertBody: {},
        schemeV9ReportBody: {},
        schemeV9SummaryBody: {},
        schemeV10Header: {},
        schemeV10AlertBody: {},
        schemeV10ReportBody: {},
        schemeV10SummaryBody: {},
        isSchemeSelected: false,
        protocolType: '',
        reportTypesV8: {
            alarm: AlertBodyV8,
            raport: ReportBodyV8,
            podsumowanie: SummaryBodyV8,
        },
        reportTypesV9: {
            alarm: AlertBodyV9,
            raport: ReportBodyV9,
            podsumowanie: SummaryBodyV9,
        },
        reportTypesV10: {
            alarm: AlertBodyV10,
            raport: ReportBodyV10,
            podsumowanie: SummaryBodyV10,
        },
        snackbarData: {
            isSnackbarOpen: false,
            message: '',
            errorInfo: '',
            variant: '',
        },
        isCleared: false,
        reportTypeOptions: ['alarm', 'raport', 'podsumowanie'],
        protocolTypeOptions: ['V8', 'V9', 'V10', 'V11'],
        isV11Selected: false,
    };

    componentDidMount() {
        this.getSchemeList();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedReportType, selectedProtocolType, selectedScheme, isV11Selected } = this.state;
        if (
            prevState.selectedReportType !== selectedReportType ||
            prevState.selectedProtocolType !== selectedProtocolType
        ) {
            if (selectedProtocolType === protocolTypes.V11) {
                this.handleIsV11Selected();
                return;
            }
            if (isV11Selected) {
                this.handleIsV11Selected();
            }
            this.getSchemeList();
        }
        if (prevState.selectedScheme !== selectedScheme && selectedScheme.length > 0) {
            this.getSchemeData();
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearLivePreviewOnReset();
        }
    }

    updateLivePreview = () => {
        const { headerForm, selectedForms } = this.state;

        const headerPart = Object.values(headerForm).reduce((a, b) => a.concat(b), '');
        const contentPart = selectedForms.reduce(
            (accumulator, currentValue) =>
                accumulator.concat(Object.values(currentValue).reduce((a, b) => a.concat(b), '')),
            ''
        );
        const livePreviewVal = headerPart.concat(contentPart);
        this.setState({ livePreviewVal });
    };

    saveHeaderFormState = headerState => {
        const { headerForm } = this.state;
        this.setState({ headerForm: { ...headerState } });
        this.updateLivePreview();
        localStorage.setItem(localStorageKey.HEADER, JSON.stringify(headerForm));
    };

    addNewPackage = () => {
        this.setState(prevState => ({
            numOfPackages: prevState.numOfPackages + 1,
        }));
    };

    handleSelectChange = selectedReportType => {
        this.setState({
            selectedReportType,
            selectedScheme: [],
            isSchemeSelected: false,
            schemeV8AlertBody: {},
        });
    };

    handleProtocolChange = selectedProtocolType => {
        this.setState({
            selectedProtocolType,
            selectedScheme: [],
            isSchemeSelected: false,
            schemeV8AlertBody: {},
            numOfPackages: 1,
        });
        this.clearLivePreview(true);
    };

    handleSchemeSelect = selectedScheme => {
        this.setState(state => ({
            selectedScheme,
            isSchemeSelected: true,
            isCleared: !state.isCleared,
        }));
    };

    getErrorMessage = err => {
        const errStatus = err.response.data.status;
        const errMessageOnDuplicate = 'Pakiet nie może zostać wysłany ponieważ już istnieje w bazie.';
        return errStatus === 'duplicate' ? errMessageOnDuplicate : err.message;
    };

    sendData = () => {
        const { livePreviewVal } = this.state;
        axiosInstance
            .post(endpoint.TEST_PACKAGE, {
                sim: '500000000',
                data: this.toStringHeaderAndPackagesData(Object.keys(livePreviewVal), livePreviewVal),
            })
            .then(() => {
                this.setState({
                    snackbarData: {
                        isSnackbarOpen: true,
                        message: snackbarMsg.PACKAGE_HAS_BEEN_SENT,
                        errorInfo: '',
                        variant: snackbarVariant.SUCCESS,
                    },
                });
            })
            .catch(err => {
                this.setState({
                    snackbarData: {
                        isSnackbarOpen: true,
                        message: snackbarMsg.ERROR,
                        errorInfo: this.getErrorMessage(err),
                        variant: snackbarVariant.ERROR,
                    },
                });
            });
    };

    getPackagesData = (state, contentType) => {
        const { livePreviewVal } = this.state;
        const copylivePreviewValState = { ...livePreviewVal };
        copylivePreviewValState[contentType] = { ...state };
        this.setState({ livePreviewVal: copylivePreviewValState });
    };

    setProtocolType = protocolType => {
        this.setState({ protocolType });
    };

    clearLivePreview = (isProtocolChanged = false) => {
        this.setState(prevState => ({
            livePreviewVal: isProtocolChanged ? {} : { header: prevState.livePreviewVal.header },
        }));
    };

    clearLivePreviewOnReset = () => {
        this.setState(prevState => ({
            livePreviewVal: { header: prevState.livePreviewVal.header },
        }));
    };

    toStringHeaderAndPackagesData = (headerAndPackages, livePreviewVal) =>
        headerAndPackages.reduce((accumulator, current) => {
            if (current === 'header') {
                return `${accumulator}${getArrayFromObject(livePreviewVal[current]).join('')}`;
            }
            return `${accumulator}${getArrayFromObject(livePreviewVal[current]).join('')}`;
        }, '');

    getReportName = () => {
        const { selectedReportType, selectedProtocolType, reportTypesV8, reportTypesV9, reportTypesV10 } =
            this.state;

        if (selectedProtocolType === 'V8') {
            return reportTypesV8[selectedReportType];
        }
        if (selectedProtocolType === 'V9') {
            return reportTypesV9[selectedReportType];
        }
        if (selectedProtocolType === 'V10') {
            return reportTypesV10[selectedReportType];
        }
        return '';
    };

    getHeader = () => {
        const {
            protocolType,
            selectedProtocolType,
            schemeV8Header,
            schemeV9Header,
            schemeV10Header,
            selectedScheme,
            isCleared,
        } = this.state;
        if (selectedProtocolType === 'V8') {
            return (
                <HeaderV8
                    saveHeaderFormState={this.saveHeaderFormState}
                    getPackagesData={this.getPackagesData}
                    protocolType={protocolType}
                    schemeV8Header={schemeV8Header}
                    selectedScheme={selectedScheme}
                    clearSelectedScheme={this.clearSelectedScheme}
                />
            );
        }
        if (selectedProtocolType === 'V9') {
            return (
                <HeaderV9
                    saveHeaderFormState={this.saveHeaderFormState}
                    getPackagesData={this.getPackagesData}
                    protocolType={protocolType}
                    schemeV9Header={schemeV9Header}
                    selectedScheme={selectedScheme}
                    clearSelectedScheme={this.clearSelectedScheme}
                />
            );
        }
        if (selectedProtocolType === 'V10') {
            return (
                <HeaderV10
                    key={isCleared}
                    isCleared={isCleared}
                    saveHeaderFormState={this.saveHeaderFormState}
                    getPackagesData={this.getPackagesData}
                    protocolType={protocolType}
                    schemeV10Header={schemeV10Header}
                    selectedScheme={selectedScheme}
                    clearSelectedScheme={this.clearSelectedScheme}
                />
            );
        }
        return '';
    };

    getPackageBody = () => {
        const { numOfPackages, selectedScheme, isCleared } = this.state;

        const packageBody = [];
        const schemeData =
            this.state[this.getSchemeProtocolAndReportType()][this.getSchemeProtocolAndReportType()];
        const ReportName = this.getReportName();
        for (let i = 0; i < numOfPackages; i += 1) {
            packageBody.push(
                <ReportName
                    isCleared={isCleared}
                    key={i}
                    number={i}
                    getPackagesData={this.getPackagesData}
                    onSetProtocolType={this.setProtocolType}
                    {...{
                        [this.getSchemeProtocolAndReportType()]: schemeData
                            ? schemeData[`packageBody${i}`]
                            : undefined,
                    }} // dynamically created props e.g. schemeV8AlertBody
                    selectedScheme={selectedScheme}
                    clearSelectedScheme={this.clearSelectedScheme}
                />
            );
        }
        return packageBody;
    };

    getSchemeProtocolAndReportType = () => {
        const { selectedProtocolType } = this.state;
        return `scheme${selectedProtocolType}${this.getSelectedProtocolTypeName()}`;
    };

    getSelectedProtocolTypeName = () => {
        const { selectedReportType } = this.state;
        if (selectedReportType === 'alarm') {
            return 'AlertBody';
        }
        if (selectedReportType === 'raport') {
            return 'ReportBody';
        }
        if (selectedReportType === 'podsumowanie') {
            return 'SummaryBody';
        }
        return '';
    };

    deleteButtonHandler = () => {
        const { schemeList, selectedScheme } = this.state;

        axiosInstance
            .post(endpoint.SCHEME_DELETE, {
                scheme_id: getSchemeId(schemeList, selectedScheme),
            })
            .then(() => {
                this.setState(
                    {
                        selectedScheme: [],
                        isSchemeSelected: false,
                        snackbarData: {
                            isSnackbarOpen: true,
                            message: snackbarMsg.TEMPLATE_HAS_BEEN_DELETED,
                            errorInfo: '',
                            variant: snackbarVariant.SUCCESS,
                        },
                    },
                    this.getSchemeList
                );
            })
            .catch(err => {
                if (err.message === undefined) {
                    return;
                }
                if (err.response.status === 401) {
                    this.props.history.push(`/login`);
                }
                if (err.message) {
                    this.setState({
                        snackbarData: {
                            isSnackbarOpen: true,
                            message: snackbarMsg.ERROR,
                            errorInfo: err.message,
                            variant: snackbarVariant.ERROR,
                        },
                    });
                }
            });
    };

    getSchemeList = () => {
        const { selectedReportType, selectedProtocolType } = this.state;

        const getSchemeNames = schemeList => schemeList.reduce((sum, current) => sum.concat(current), []);

        axiosInstance
            .get(endpoint.SCHEME_LIST, {
                params: {
                    type: selectedReportType,
                    protocol: selectedProtocolType.match(/(\d+)/)[0],
                },
            })
            .then(content => {
                this.setState(() => ({
                    schemeList: [{ id: 999, name: '- PUSTY -' }].concat(getSchemeNames(content.data)),
                }));
            })
            .catch(err => {
                console.error('err', err);
                if (err.message === undefined) {
                    return;
                }
                if (err.response.status === 401) {
                    this.props.history.push(`/login`);
                }
                if (err.message) {
                    console.error('errorGenerator', err.message);
                }
            });
    };

    getSchemeData = () => {
        const { schemeList, selectedScheme, selectedProtocolType } = this.state;
        if (selectedScheme === '- PUSTY -') {
            return;
        }
        axiosInstance
            .get(endpoint.SCHEME_SHOW, {
                params: {
                    scheme_id: getSchemeId(schemeList, selectedScheme),
                },
            })
            .then(content => {
                const schemeContent = JSON.parse(content.data[0].scheme);
                this.setState({
                    [`scheme${selectedProtocolType}Header`]: this.setSchemeBody(
                        this.getSchemeProtocolAndReportType(),
                        this.setFormAndLivePrevBasedOnSchemeContent({
                            ...schemeContent,
                        })
                    )[this.getSchemeProtocolAndReportType()].header,

                    [this.getSchemeProtocolAndReportType()]: this.setSchemeBody(
                        this.getSchemeProtocolAndReportType(),
                        this.setFormAndLivePrevBasedOnSchemeContent({
                            ...schemeContent,
                        })
                    ),
                    livePreviewVal: this.setLivePreviewValObj(schemeContent),
                    numOfPackages: this.countPackageBodyAmount(schemeContent),
                });
            })
            .catch(err => {
                if (err.message === undefined) {
                    return;
                }
                if (err.response.status === 401) {
                    this.props.history.push(`/login`);
                }
                if (err.message) {
                    console.error('errorGenerator', err.message);
                }
            });
    };

    setLivePreviewValObj = schemeContent => {
        const livePreviewVal = {
            header: schemeContent.templateContent.header,
        };
        for (let i = 0; i < this.countPackageBodyAmount(schemeContent); i++) {
            livePreviewVal[`packageBody${i}`] = schemeContent.templateContent[`packageBody${i}`];
        }

        return livePreviewVal;
    };

    countPackageBodyAmount = schemeContent =>
        this.getPackageBodyOnly(Object.keys(schemeContent.templateContent)).length;

    setSchemeBody = (schemeProtocolAndReportType, schemeContent) => ({
        [schemeProtocolAndReportType]: { ...schemeContent.templateContent },
    });

    getPackageBodyOnly = packageBodyData => packageBodyData.filter(i => /packageBody/.test(i));

    clearSelectedScheme = () => {
        this.setState({
            selectedScheme: [],
            isSchemeSelected: false,
            numOfPackages: 1,
        });
    };

    handleIsSnackbarOpen = () => {
        this.setState(state => ({
            snackbarData: {
                isSnackbarOpen: false,
                message: state.snackbarData.message,
                errorInfo: state.snackbarData.errorInfo,
                variant: state.snackbarData.variant,
            },
        }));
    };

    setFormAndLivePrevBasedOnSchemeContent = schemeData => {
        const copySchemeData = cloneObjByJSON(schemeData);

        if (
            this.getSchemeProtocolAndReportType() === 'schemeV10ReportBody' ||
            this.getSchemeProtocolAndReportType() === 'schemeV10AlertBody' ||
            this.getSchemeProtocolAndReportType() === 'schemeV10SummaryBody'
        ) {
            for (let i = 0; i < this.countPackageBodyAmount(copySchemeData); i++) {
                this.getBodyFieldsToLittleEndian().forEach(e => {
                    copySchemeData.templateContent[`packageBody${i}`][e] = toLittleEndian(
                        copySchemeData.templateContent[`packageBody${i}`][e]
                    );
                });
            }
            headerV10fieldsToLittleEndian.forEach(e => {
                copySchemeData.templateContent.header[e] = toLittleEndian(
                    copySchemeData.templateContent.header[e]
                );
            });
            return copySchemeData;
        }

        return copySchemeData;
    };

    getBodyFieldsToLittleEndian = () => {
        const { selectedReportType } = this.state;
        if (selectedReportType === 'raport') {
            return reportBodyV10fieldsToLittleEndian;
        }
        if (selectedReportType === 'alarm') {
            return alertBodyV10fieldsToLittleEndian;
        }
        if (selectedReportType === 'podsumowanie') {
            return summaryBodyV10fieldsToLittleEndian;
        }
        return '';
    };

    // TODO: correct this temporary solution if generator for V11 is ready
    handleIsV11Selected = () => {
        this.setState(state => ({
            isV11Selected: !state.isV11Selected,
        }));
    };

    isV11Selected = () => {
        const { selectedProtocolType } = this.state;

        return selectedProtocolType === protocolTypes.V11;
    };

    render() {
        const { classes } = this.props;
        const {
            selectedReportType,
            selectedProtocolType,
            selectedForms,
            selectedScheme,
            headerForm,
            livePreviewVal,
            schemeList,
            isSchemeSelected,
            snackbarData,
            reportTypeOptions,
            protocolTypeOptions,
            isV11Selected,
        } = this.state;
        // TODO: correct this temporary solution if generator for V11 is ready
        return (
            isAdminRole() && (
                <>
                    <TitleHeader title="Generator Pakietów" />
                    <article className={classes.selectDropdown}>
                        {!this.isV11Selected() && (
                            <SingleSelect
                                label="Typ raportu"
                                value={selectedReportType}
                                options={reportTypeOptions}
                                onChange={this.handleSelectChange}
                                className={classes.dropdownItem}
                            />
                        )}

                        <SingleSelect
                            label="Typ protokołu"
                            value={selectedProtocolType}
                            options={protocolTypeOptions}
                            onChange={this.handleProtocolChange}
                            className={classes.dropdownItem}
                        />

                        {!this.isV11Selected() && (
                            <SingleSelect
                                label="Szablony"
                                key={`${selectedProtocolType}${selectedReportType}${selectedScheme}`}
                                value={selectedScheme}
                                options={schemeList.map(i => i.name)}
                                onChange={this.handleSchemeSelect}
                                className={classes.dropdownItem}
                                data-cy="schemeList"
                            />
                        )}
                        {isAdminRole() && !this.isV11Selected() && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.deleteButtonHandler}
                                className={classes.deleteButton}
                                disabled={!isSchemeSelected}
                                data-cy="deleteScheme">
                                Usuń szablon
                            </Button>
                        )}
                    </article>
                    {!this.isV11Selected() && this.getHeader()}
                    {!this.isV11Selected() && (
                        <article className={classes.dropdownContainer}>
                            {this.getPackageBody()}
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.addNewPackage}
                                className={classes.addNewPackageButton}>
                                Dodaj kolejny pakiet
                            </Button>
                        </article>
                    )}
                    <GeneratorInputs
                        content={livePreviewVal}
                        header={headerForm}
                        body={selectedForms}
                        sendData={this.sendData}
                        selectedReportType={selectedReportType}
                        selectedProtocolType={selectedProtocolType}
                        clearLivePreview={this.clearLivePreview}
                        isSchemeSaved={this.getSchemeList}
                        selectedScheme={selectedScheme}
                        schemeList={schemeList}
                        isV11Selected={isV11Selected}
                    />
                    <Snackbar snackbarData={snackbarData} handleIsSnackbarOpen={this.handleIsSnackbarOpen} />
                </>
            )
        );
    }
}

Generator.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Generator);
