import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = {
    paginationSettings: {
        position: 'absolute',
        bottom: 4,
        left: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paginationRows: {
        marginRight: 5,
        userSelect: 'none',
    },
    select: {
        height: 30,
    },
};

const PaginationRows = ({
    classes: { paginationSettings, paginationRows, select },
    changePaginationSize,
    paginationSize,
    paginationSizes,
    isTableReports,
}) => {
    const tableReportsChangePaginationSize = e => {
        changePaginationSize(e);
    };

    return (
        <div className={paginationSettings} style={isTableReports && { bottom: 'auto', position: 'static' }}>
            <p className={paginationRows}>Wiersze</p>
            <select
                className={select}
                onChange={isTableReports ? tableReportsChangePaginationSize : changePaginationSize}
                value={paginationSize}>
                {paginationSizes.map(e => (
                    <option key={e} value={e}>
                        {e}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default withStyles(styles)(PaginationRows);
