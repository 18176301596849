import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { columnTypes } from '../../../../utils/constants';
import { isColumnHighlighted } from '../../../../utils/helpers';

const useStyles = makeStyles({
    tableCell: {
        padding: 0,
        borderBottom: 'none',
        cursor: 'pointer',
    },
    paragraph: {
        margin: 0,
    },
});

const ExpandableComponent = ({
    value,
    getValue,
    generatedListData,
    indexRow,
    columnType,
    setExpandedRows,
    expandedRows,
    lastReadingsId,
}) => {
    const { tableCell, paragraph } = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);

    const getExpandedReadingValue = () => {
        const isSabotageColumn = columnType === columnTypes.REPORT_SABOTAGE;

        const dataReports = generatedListData.dataReportWithPrefix;
        const exactRowReportId = isSabotageColumn ? lastReadingsId : value.reportId;

        const reportsDataFilteredByReportId = dataReports.filter(item => {
            const dataReportId = item.report_readings && item.report_readings[0].report_id;
            return dataReportId === exactRowReportId;
        });

        const reportDataReadings = reportsDataFilteredByReportId[0]?.report_readings;

        return (
            reportDataReadings &&
            reportDataReadings.map((item, index) => (
                <p className={paragraph} key={index}>
                    {isSabotageColumn ? item.sabotage : item.data}
                </p>
            ))
        );
    };

    const isRowExpanded = () => expandedRows.includes(indexRow);

    const handleRowExpandable = () => {
        if (isExpanded) {
            setExpandedRows([...expandedRows, indexRow]);
        } else {
            setExpandedRows(expandedRows.filter(i => i !== indexRow));
        }
    };

    const handleReadingsAndSabotageExpand = () => {
        if (expandedRows.includes(indexRow) && !isExpanded) {
            setIsExpanded(true);
        }

        if (!expandedRows.includes(indexRow)) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        handleRowExpandable();
    }, [isExpanded]);

    useEffect(() => {
        handleReadingsAndSabotageExpand();
    }, [expandedRows]);

    return (
        <TableCell
            key={indexRow}
            align="center"
            size="small"
            className={tableCell}
            onClick={() => setIsExpanded(!isExpanded)}
            style={isColumnHighlighted(indexRow)}>
            {isRowExpanded() ? getExpandedReadingValue() : getValue(value)}
        </TableCell>
    );
};

export default ExpandableComponent;
