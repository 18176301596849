import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DocsContext from '../../context/DocsContext';
import PackagesContext from '../../context/PackagesContext';
import { createRangeArr } from '../../utils/helpers';

const styles = {
    paginationContainer: {
        width: '35rem',
        height: '3rem',
        margin: '2rem auto',
        marginBottom: '5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    chevron: {
        margin: '0 1rem',
    },
};
const pagination = ({ classes, click, selectedPage }) => {
    const [page, setPage] = useState(1);
    const [pagesToSelect, setPagesToSelect] = useState(1);
    const getDocsContext = useContext(DocsContext);
    const getPackagesContext = useContext(PackagesContext);
    const checkIsDecramentingPages = () => page > pagesToSelect + 4;

    const incrementPagesToSelect = () => {
        if (page > pagesToSelect + 9) {
            setPagesToSelect(pagesToSelect + 5);
        } else if (page > pagesToSelect + 8) {
            setPagesToSelect(pagesToSelect + 4);
        } else if (page > pagesToSelect + 7) {
            setPagesToSelect(pagesToSelect + 3);
        } else if (page > pagesToSelect + 6) {
            setPagesToSelect(pagesToSelect + 2);
        } else if (page > pagesToSelect + 5) {
            setPagesToSelect(pagesToSelect + 1);
        }
    };

    const decrementPagesToSelect = () => {
        const isPagesToSelectEqZero = subtraction => subtraction === 0;
        if (pagesToSelect === 1) {
            return;
        }
        if (page < pagesToSelect + 4) {
            if (isPagesToSelectEqZero(pagesToSelect - 1)) {
                return;
            }
            setPagesToSelect(pagesToSelect - 1);
        }
        if (page < pagesToSelect + 3) {
            if (isPagesToSelectEqZero(pagesToSelect - 2)) {
                setPagesToSelect(pagesToSelect - 1);
                return;
            }
            setPagesToSelect(pagesToSelect - 2);
        }
        if (page < pagesToSelect + 2) {
            if (isPagesToSelectEqZero(pagesToSelect - 3)) {
                setPagesToSelect(pagesToSelect - 2);
                return;
            }
            setPagesToSelect(pagesToSelect - 3);
        }
        if (page < pagesToSelect + 1) {
            if (isPagesToSelectEqZero(pagesToSelect - 4)) {
                setPagesToSelect(pagesToSelect - 3);
                return;
            }
            setPagesToSelect(pagesToSelect - 4);
        }
    };

    useEffect(() => {
        if (checkIsDecramentingPages()) {
            incrementPagesToSelect();
        } else {
            decrementPagesToSelect();
        }
    }, [page]);

    const getPagesArr = pageNr => {
        const pageCounter = pageNr + 10;
        return createRangeArr(pageNr, pageCounter);
    };

    const pushPageButtonHandler = button => {
        if (button <= 0) {
            return;
        }
        click(button);
        setPage(button);
    };

    useEffect(() => {
        if (getDocsContext !== undefined) {
            setPage(1);
        }
    }, [getDocsContext]);

    useEffect(() => {
        setPage(1);
    }, [
        getPackagesContext.packagesData.selectedWaterpipe,
        getPackagesContext.packagesData.selectedStatus,
        getPackagesContext.packagesData.id,
        getPackagesContext.packagesData.sms,
        getPackagesContext.packagesData.dok,
    ]);

    useEffect(() => {
        setPage(selectedPage);
    });

    const setPaginationButtons = () =>
        getPagesArr(pagesToSelect).map(button => (
            <Fab
                key={button}
                color={button === page ? 'secondary' : 'primary'}
                size="small"
                onClick={() => pushPageButtonHandler(button)}>
                {button}
            </Fab>
        ));

    return (
        <div className={classes.paginationContainer}>
            <Fab
                color="primary"
                size="medium"
                className={classes.chevron}
                onClick={() => pushPageButtonHandler(page - 1)}>
                <ChevronLeft />
            </Fab>
            {setPaginationButtons()}
            <Fab
                color="primary"
                size="medium"
                className={classes.chevron}
                onClick={() => pushPageButtonHandler(page + 1)}>
                <ChevronRight />
            </Fab>
        </div>
    );
};

export default withStyles(styles)(pagination);
