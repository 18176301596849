import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { TextValidator } from 'react-material-ui-form-validator';

const styles = {
    textValidator: {
        width: '20vw',
        background: '#e7e7e7',
    },
};

class AsciiV10 extends PureComponent {
    state = {
        value: '',
        isFocus: false,
        noAsciiValue: 'brak wartości ASCII',
    };

    componentDidMount() {
        this.setState({ value: this.props.hexValue });
    }

    componentDidUpdate(prevProps) {
        const { hexValue } = this.props;
        if (prevProps.hexValue !== hexValue && !this.state.isFocus) {
            if (hexValue === '') {
                this.setState({ value: hexValue });
            } else {
                this.setState({ value: this.convertHexToAscii(hexValue) });
            }
        }
    }

    onChangeHandler = e => {
        const { handleChange } = this.props;
        const changedValue = e.target.value;
        this.setState(
            {
                value: changedValue,
            },
            () => {
                handleChange(this.convertAsciiToHex(this.state.value));
            }
        );
    };

    convertHexToAscii = hexValue => {
        const { isBCD } = this.props;
        const convertToDec = isBCD() ? hexValue : parseInt(hexValue, 16);
        if (convertToDec > 31 && convertToDec < 127) {
            return String.fromCharCode(convertToDec);
        }
        return this.state.noAsciiValue;
    };

    convertAsciiToHex = asciiValue => {
        const { isBCD } = this.props;
        if (asciiValue === '') {
            return asciiValue;
        }
        return isBCD() ? asciiValue.charCodeAt(0) : asciiValue.charCodeAt(0).toString(16);
    };

    onFocusHandler = () => {
        const { handleChange } = this.props;
        if (this.state.value === this.state.noAsciiValue) {
            this.setState(
                state => ({ value: '', isFocus: !state.isFocus }),
                () => {
                    handleChange(this.convertAsciiToHex(this.state.value));
                }
            );
        } else {
            this.setState(state => ({ isFocus: !state.isFocus }));
        }
    };

    render() {
        const { value } = this.state;
        const { classes, label } = this.props;
        return (
            <TextValidator
                className={classes.textValidator}
                variant="outlined"
                value={value}
                onChange={this.onChangeHandler}
                label={`${label}[ASCII]`}
                onFocus={this.onFocusHandler}
                onBlur={this.onFocusHandler}
            />
        );
    }
}

export default withStyles(styles)(AsciiV10);
