import React, { Component } from 'react';
import { axiosInstance, cancelToken } from '../../utils/axiosInstance';
import { getVisualDividedNumber } from '../../utils/helpers';
import CountButtonResult from '../CountButtonResult/CountButtonResult';
import docsContext from '../../context/DocsContext';

class CountPipelineDocs extends Component {
    static contextType = docsContext;

    state = {
        countedDocs: 'Pakiety są zliczane...',
        isCounting: false,
    };

    componentDidMount() {
        this.cancelHttpReq = cancelToken.source();
    }

    componentDidUpdate(prevProps) {
        const { reportType, selectedCommunicator, searchPhrase } = this.props;

        if (
            prevProps.reportType !== reportType ||
            prevProps.selectedCommunicator !== selectedCommunicator ||
            prevProps.searchPhrase !== searchPhrase
        ) {
            this.setState({ isCounting: false });
        }
    }

    componentWillUnmount() {
        if (this.cancelHttpReq !== undefined) {
            this.cancelHttpReq.cancel();
        }
    }

    getWaterPipeId = () => {
        const { match } = this.props;
        let waterPipeId = null;
        if (match !== undefined && match.params.id !== undefined) {
            waterPipeId = match.params.id;
        }
        return waterPipeId;
    };

    getCountedAmountDocs = () => {
        const { reportType, searchPhrase, history } = this.props;
        const { searchCommunicator, searchPackageId } = this.context;

        const endpoint = `api/${reportType}`;
        this.setState({
            countedDocs: 'Pakiety są zliczane...',
            isCounting: true,
        });

        axiosInstance
            .get(endpoint, {
                params: {
                    ...(searchPackageId && {
                        packageId: searchPackageId,
                    }),
                    ...(searchCommunicator && {
                        communicator: searchCommunicator,
                    }),
                    waterpipeId: this.getWaterPipeId(),
                    filter: searchPhrase,
                    count: true,
                },

                cancelToken: this.cancelHttpReq.token,
            })
            .then(content => {
                this.setState({
                    countedDocs: <strong>{getVisualDividedNumber(content.data.count)}</strong>,
                });
            })
            .catch(err => {
                this.setState({
                    countedDocs: `Błąd serwera`,
                });
                if (err.message === undefined) {
                    return;
                }
                if (err.response.status === 401 && history !== undefined) {
                    history.push(`/login`);
                }
            });
    };

    render() {
        const { isCounting, countedDocs } = this.state;
        return (
            <CountButtonResult
                getCountedAmountPackages={this.getCountedAmountDocs}
                isCounting={isCounting}
                countedList={countedDocs}
            />
        );
    }
}

export default CountPipelineDocs;
