import { withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import DropdownTreeSelect from '@ernestbies/react-dropdown-tree-select';
import '@ernestbies/react-dropdown-tree-select/dist/styles.css';

const styles = {
    dropdown: {
        maxWidth: 257,
        padding: 10,
        height: 'auto',
        '& a': {
            borderRadius: 8,
        },
        '& div': {
            width: '100%',
            background: '#f8f8f8 !important',
        },
        '& input': {
            background: 'transparent',
        },
    },
};

const DropdownSelect = ({ classes: { dropdown }, data, onChange }) =>
    useMemo(
        () => (
            <DropdownTreeSelect
                data={data}
                onChange={onChange}
                texts={{
                    placeholder: 'Szukaj...',
                    noMatches: 'Nie znaleziono wyników',
                }}
                className={dropdown}
                showTags={false}
            />
        ),
        [data]
    );

export default withStyles(styles)(DropdownSelect);
