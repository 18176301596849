/* eslint-disable no-shadow */
/* eslint-disable new-cap */
/* eslint-disable no-nested-ternary */
import { withStyles } from '@material-ui/styles';
import React from 'react';
import writeXlsxFile from 'write-excel-file';
import xlsIcon from '../../../../assets/img/file-xls.svg';
import csvIcon from '../../../../assets/img/file-csv.svg';
import { columnTypes, fileExporterTypes, TABLE_TABS_PREFIX } from '../../../../utils/constants';
import { getDate } from '../../../../utils/helpers';
import theme from '../../../../themes/theme';

const styles = {
    optionWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 25px 0 25px',
        cursor: 'pointer',
    },
    icon: {
        width: 30,
        height: 30,
        marginRight: 5,
        pointerEvents: 'none',
        userSelect: 'none',
    },
    typeText: {
        userSelect: 'none',
    },
    typeName: {
        fontWeight: theme.font.weight.bold,
    },
};

const FileExporter = ({
    gridApi,
    type,
    listName,
    classes: { optionWrapper, icon, typeText, typeName },
    setIsDataProcessing,
}) => {
    const FILE_EXPORTER_OPTIONS = {
        XLS: {
            name: fileExporterTypes.XLS,
            icon: xlsIcon,
            onClick: () => exportToXls(),
        },
        CSV: {
            name: fileExporterTypes.CSV,
            icon: csvIcon,
            onClick: () => exportToCsv(),
        },
    };

    const FILE_NAME = `${listName.replace(' ', '_')}-${getDate(new Date())}`;

    const getColumns = isOrdinalNumberVisible =>
        gridApi.columnModel
            .getColumnState()
            .map(column => ({ field: column.colId, hide: column.hide }))
            .map(column => ({
                ...column,
                headerName: gridApi.columnModel.getColumnDefs().filter(item => column.field === item.field)[0]
                    .headerName,
            }))
            .filter(item => !(item.field.includes(TABLE_TABS_PREFIX) || item.hide))
            .filter(item => (isOrdinalNumberVisible ? item : item.field !== columnTypes.ORDINAL_NUMBER));

    const gridToTable = isOrdinalNumberVisible => {
        const columns = getColumns(isOrdinalNumberVisible);
        const tableData = [];
        gridApi.forEachNodeAfterFilterAndSort(rowNode => {
            if (rowNode.displayed) {
                tableData.push(
                    columns.map(e => {
                        let newValue = rowNode.data[e.field] ?? '';
                        if (typeof newValue === 'object') {
                            newValue = rowNode.data[e.field].lastReading || rowNode.data[e.field][0]?.data;
                        }
                        if (typeof newValue === 'string' && newValue.indexOf('</p>') !== -1) {
                            return newValue
                                .split('</p>')
                                .map(item => item.substring(item.lastIndexOf('>') + 1))[0];
                        }

                        return newValue;
                    })
                );
            }
        });

        return {
            columnsField: columns.map(e => e.field),
            columnsName: columns.map(e => e.headerName),
            data: tableData,
        };
    };

    const exportToXls = async () => {
        setIsDataProcessing(true);
        const table = gridToTable(false);
        const columns = table.columnsName.map(col => ({
            value: col,
        }));

        const data = table.data.map(row =>
            row.map((cell, index) => {
                const VALUE_FORMATS = {
                    REPORT_READINGS: { format: '0.000' },
                    REPORT_COMMUNICATOR: { format: '00000000' },
                    ALIGN_RIGHT: { align: 'right' },
                };

                const isReadingsCell =
                    table.columnsField[index] === columnTypes.REPORT_READINGS && cell && cell !== ' ';

                const isCommunicatorCell = table.columnsField[index] === columnTypes.REPORT_COMMUNICATOR;

                const isDebugResetTimeCell =
                    table.columnsField[index] === columnTypes.REPORT_DEBUG_RESET_TIME;

                const isAlertMechanicDurationCell =
                    table.columnsField[index] === columnTypes.REPORT_ALERT_MECHANIC_DURATION;

                const getValueType = () => {
                    if (isReadingsCell || isCommunicatorCell) {
                        return Number;
                    }

                    if (typeof cell === 'string') {
                        return String;
                    }

                    return Number;
                };

                const valueObject = {
                    value: isReadingsCell || isCommunicatorCell ? +cell : cell,
                    type: getValueType(),
                };

                if (isReadingsCell) {
                    Object.assign(valueObject, VALUE_FORMATS.REPORT_READINGS);
                } else if (isCommunicatorCell) {
                    Object.assign(valueObject, VALUE_FORMATS.REPORT_COMMUNICATOR);
                } else if (isDebugResetTimeCell || isAlertMechanicDurationCell) {
                    Object.assign(valueObject, VALUE_FORMATS.ALIGN_RIGHT);
                }

                return valueObject;
            })
        );

        const tableData = [[...columns], ...data];

        return writeXlsxFile(tableData, { fileName: FILE_NAME }).then(() => setIsDataProcessing(false));
    };

    const exportToCsv = async () => {
        gridApi.exportDataAsCsv({
            fileName: FILE_NAME,
            columnKeys: gridToTable(false).columnsField,
            columnSeparator: ';',
            processCellCallback: params => {
                const { value, column } = params;
                if (column.colId === columnTypes.READINGS && value?.length) {
                    const firstReading = value?.[0];
                    return `${firstReading?.data} ${firstReading?.sabotage}`;
                }
                return value;
            },
        });
    };

    return (
        <div className={optionWrapper}>
            <img src={FILE_EXPORTER_OPTIONS[type].icon} className={icon} />
            <p className={typeText} onClick={FILE_EXPORTER_OPTIONS[type].onClick}>
                {`Eksport do `}
                <span className={typeName}>{FILE_EXPORTER_OPTIONS[type].name}</span>
            </p>
        </div>
    );
};

export default withStyles(styles)(FileExporter);
