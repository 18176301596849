import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { axiosInstance } from '../../utils/axiosInstance';
import FavoriteReportsTable from '../FavoriteReports/FavoriteReportsTable/FavoriteReportsTable';
import { endpoint, localStorageKey, waterpipesAll } from '../../utils/constants';
import { getListType } from '../../utils/helpers';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const styles = {
    root: {
        marginTop: '2rem',
        marginBottom: '2rem',
        height: '100%',
    },
    loadingWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
};

const ListTemplates = ({ classes: { root, loadingWrapper } }) => {
    const [favoriteReports, setFavoriteReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [waterpipes, setWaterpipes] = useState([]);

    useEffect(() => {
        getFavoriteReports();
    }, []);

    const getFavoriteReports = () => {
        setIsLoading(true);
        axiosInstance
            .get(`${endpoint.LIST_TEMPLATE_INDEX}?templateType=${getListType()}`)
            .then(res => {
                const waterpipesList = JSON.parse(localStorage.getItem(localStorageKey.WATERPIPES_LIST));

                const isAllWaterpipesInList = !!waterpipesList.filter(e => e.name === waterpipesAll.name)[0];

                if (!isAllWaterpipesInList) {
                    waterpipesList.push(waterpipesAll);
                }

                setWaterpipes(waterpipesList);

                const getWaterpipeData = (property, id) =>
                    waterpipesList?.filter(waterpipe => waterpipe.id === id)[0][property];

                setFavoriteReports(
                    res.data.map(result => ({
                        id: result.id,
                        waterpipeId: result.waterpipe_id,
                        waterpipeName: getWaterpipeData('name', result.waterpipe_id),
                        waterpipeSign: getWaterpipeData('sign', result.waterpipe_id),
                        numbers: result.numbers,
                        name: result.name,
                        description: result.description,
                        createdAt: result.created_at,
                        channel: result.channel,
                        templateType: result.template_type,
                    }))
                );
                setIsLoading(false);
            })
            .catch(err => {
                console.error('Error while fetching data', err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={root}>
            {isLoading ? (
                <div className={loadingWrapper}>
                    <LoadingSpinner />
                </div>
            ) : (
                <FavoriteReportsTable
                    data={favoriteReports}
                    waterpipes={waterpipes}
                    templateType={getListType()}
                />
            )}
        </div>
    );
};

export default withStyles(styles)(ListTemplates);
