export default {
    V7report: { header: 40, body: 40 },
    V7alarm: { header: 40, body: 120 },
    V7summary: { header: 40, body: 120 },
    V8report: { header: 71, body: 48 },
    V8alarm: { header: 71, body: 89 },
    V8summary: { header: 71, body: 89 },
    V9report: { header: 39, body: 107 },
    V9alarm: { header: 39, body: 121 },
    V9summary: { header: 39, body: 121 },
    V10report: { header: 86, body: 194 },
    V10alarm: { header: 86, body: 194 },
    V10summary: { header: 86, body: 194 },
};
