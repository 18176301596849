/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import { columnTypes, endpoint, localStorageKey, reportTypes, testListVersion } from '../../utils/constants';
import TestList from './List/TestList';

export default function ListGenerator() {
    const ORDER = {
        direction: 'asc',
        by: 'calories',
    };
    const [generatedListData, setGeneratedListData] = useState({});
    const [listDataReadingLastVal, setListDataReadingLastVal] = useState({});
    const [reportData, setReportData] = useState([]);
    const [reportTableHeader, setReportTableHeader] = useState([]);
    const [reportHeadCells, setReportHeadCells] = useState([]);
    const [isErrorLoadingData, setIsErrorLoadingData] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const isGeneratedListData = () => Object.keys(generatedListData).length > 0;

    const isRemoveUnusedValues = val => {
        const unusedKeys = ['alarm_package_id', 'alarm_channel', 'alarm_alert_type', 'report_id'];

        return unusedKeys.includes(val);
    };

    const getSorTableData = () => {
        function createData() {
            const mergeCellsId = getHeadCells();
            let itemObj = {};
            function mergeReportsTypeData() {
                return reportData;
            }

            return mergeReportsTypeData().reduce((result, item) => {
                for (const itemKey in item) {
                    if (item[itemKey] === null) {
                        item[itemKey] = ' ';
                    }
                }

                mergeCellsId.forEach(cellHeadName => {
                    itemObj = {
                        ...itemObj,
                        [cellHeadName.id]: item[cellHeadName.id],
                    };
                });

                return [...result, itemObj];
            }, []);
        }
        return createData();
    };

    function changeGsmLevelToNumber(data) {
        const copyData = JSON.stringify(data);
        const copiedData = JSON.parse(copyData);

        copiedData.forEach(item => {
            const {
                report,
                report: { gsm_level },
            } = item;
            if (report && gsm_level) {
                if (gsm_level !== '--') {
                    item.report.gsm_level = +gsm_level;
                }
            }
        });

        return copiedData;
    }

    const getHeadCells = () => [
        {
            id: columnTypes.ORDINAL_NUMBER,
            label: columnTypes.ORDINAL_NUMBER,
        },
        { id: columnTypes.TAB_1, label: columnTypes.TAB_1 },
        { id: columnTypes.TAB_2, label: columnTypes.TAB_2 },
        { id: columnTypes.TAB_3, label: columnTypes.TAB_3 },
        { id: columnTypes.TAB_4, label: columnTypes.TAB_4 },
        { id: columnTypes.TAB_5, label: columnTypes.TAB_5 },
        ...reportHeadCells,
    ];

    const getGeneratedListConfig = () =>
        JSON.parse(localStorage.getItem(localStorageKey.GENERATED_TEST_LIST_DATA));

    const setData = data => {
        const dataCopy = changeGsmLevelToNumber(data.data.data);
        setGeneratedListData(setPrefixToDataKeys(dataCopy));
        setListDataReadingLastVal(setPrefixToDataKeys(setDataForSorting(dataCopy), dataCopy));
    };

    const setError = error => {
        console.error('Error list', error);
        setIsErrorLoadingData(true);
    };

    const getGeneratedList = () => {
        setIsFetching(true);
        axiosInstance
            .post(endpoint.TEST_REPORT, {
                waterpipeId: Number(getGeneratedListConfig().waterpipeId),
                dateFrom: getGeneratedListConfig().dateFrom,
                dateTo: getGeneratedListConfig().dateTo,
                numbers: getGeneratedListConfig().numbers,
                channel: +getGeneratedListConfig().channel,
            })
            .then(data => setData(data))
            .catch(err => setError(err))
            .finally(() => setIsFetching(false));
    };

    const setDataForSorting = listData => {
        const dataToJSON = JSON.stringify(listData);
        const dataObjCopy = JSON.parse(dataToJSON);

        let iteration = 0;
        const dataModyfied = dataObjCopy;

        while (iteration < dataModyfied.length) {
            const dataItem = dataModyfied[iteration];

            if (dataItem.report.readings != null && dataItem.report.readings.length > 0) {
                dataItem.report.readings = {
                    reportId: dataItem.report.readings[0].report_id,
                    lastReading: dataItem.report.readings[0].data,
                };
            } else {
                dataItem.report.readings = null;
            }

            iteration++;
        }
        return dataModyfied;
    };

    const setPrefixToDataKeys = (dataArray, rawDataWithFullReadingsObj) => {
        let dataReport = [];
        const dataAlarmWithPrefix = [];
        let dataReportWithPrefix = [];
        dataArray.forEach(item => {
            function renameKeys(obj, newKeys) {
                const keyValues = Object.keys(obj).map((key, index) => {
                    const newKey = newKeys[index] || key;
                    if (isRemoveUnusedValues(newKey)) return;
                    return { [newKey]: obj[key] };
                });

                return Object.assign({}, ...keyValues);
            }

            function addPrefixToKeys(keys, reportType) {
                return keys.map(key => `${reportType}_${key}`);
            }

            function setSabotageValue(index) {
                let lastReportSabotageVal;
                if (rawDataWithFullReadingsObj) {
                    if (
                        rawDataWithFullReadingsObj[index].report.readings != null &&
                        rawDataWithFullReadingsObj[index].report.readings.length > 0
                    ) {
                        lastReportSabotageVal = rawDataWithFullReadingsObj[index].report.readings[0].sabotage;
                    } else {
                        lastReportSabotageVal = '';
                    }
                }
                return lastReportSabotageVal;
            }

            dataReport = [...dataReport, item.report];

            dataReportWithPrefix = dataReport.map((item, index) => {
                function putObjKeysOnExactPlace() {
                    const arrFromObj = Object.entries(item);
                    const copyArrFromObj = [...arrFromObj];

                    function setSabotagePosition() {
                        copyArrFromObj.splice(34, 0, ['sabotage', setSabotageValue(index)]);
                    }

                    function setPackageIdPosition() {
                        const voltage = arrFromObj[1];
                        const key = voltage[0];
                        const val = voltage[1];
                        copyArrFromObj.splice(1, 1);
                        copyArrFromObj.splice(0, 0, [key, val]);
                    }

                    setSabotagePosition();
                    setPackageIdPosition();

                    return Object.fromEntries(copyArrFromObj);
                }

                return renameKeys(
                    {
                        ...putObjKeysOnExactPlace(),
                    },
                    addPrefixToKeys(Object.keys(putObjKeysOnExactPlace()), reportTypes.REPORT)
                );
            });
        });
        return { dataAlarmWithPrefix, dataReportWithPrefix };
    };

    const getReportData = () => {
        const reportData = listDataReadingLastVal.dataReportWithPrefix;
        setReportData(reportData);
    };

    const setPartsOfTableHeader = () => {
        if (reportData.length > 0) {
            const headerNames = Object.keys(reportData[0]);
            setReportTableHeader(headerNames);
        }
    };

    const setTableHeader = () => {
        if (reportTableHeader.length > 0) {
            let cells = [];
            reportTableHeader.forEach(cellName => {
                const cell = {
                    id: cellName,

                    label: cellName,
                };
                cells = [...cells, cell];
            });

            setReportHeadCells(cells);
        }
    };

    useEffect(() => {
        getGeneratedList();
    }, []);

    useEffect(() => {
        if (
            isGeneratedListData() &&
            (Object.keys(listDataReadingLastVal.dataAlarmWithPrefix).length > 0 ||
                Object.keys(listDataReadingLastVal.dataReportWithPrefix).length > 0)
        ) {
            getReportData();
        }
    }, [listDataReadingLastVal.dataReportWithPrefix]);

    useEffect(() => {
        setPartsOfTableHeader();
    }, [reportData]);

    useEffect(() => {
        setTableHeader();
    }, [reportTableHeader]);

    return (
        <TestList
            version={testListVersion.V1}
            order={ORDER.direction}
            orderBy={ORDER.by}
            getSorTableData={getSorTableData}
            generatedListData={generatedListData}
            headCells={getHeadCells}
            isErrorLoadingData={isErrorLoadingData}
            isFetching={isFetching}
        />
    );
}
