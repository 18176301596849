import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';

const modal = ({ isModalOpen, isSpinnerActive, handleAnswer, modalMessage }) => {
    const handleAnswerNo = () => {
        handleAnswer(false);
    };

    const handleAnswerYes = () => {
        handleAnswer(true);
    };

    return (
        <div>
            <Dialog open={isModalOpen} onClose={handleAnswerNo}>
                <LoadingOverlay
                    active={isSpinnerActive}
                    spinner
                    text="Proszę czekać..."
                    styles={{
                        content: base => ({
                            ...base,
                            marginLeft: '50%',
                            margin: '4%',
                            transform: 'translateX(-50%)',
                        }),
                    }}>
                    <DialogTitle id="alert-dialog-title">{modalMessage}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleAnswerNo} color="primary">
                            Nie
                        </Button>
                        <Button onClick={handleAnswerYes} color="primary" autoFocus>
                            Tak
                        </Button>
                    </DialogActions>
                </LoadingOverlay>
            </Dialog>
        </div>
    );
};

export default modal;
