import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

class ReportBodyV9 extends Component {
    state = {
        day: '',
        counterState: '',
        sabotageState: '',
        multiplierIncrement: '',
        firstIncrement: '',
        sabotageStateForFirstIncrement: '',
        secondIncrement: '',
        sabotageStateForSecondIncrement: '',
        thirdIncrement: '',
        sabotageStateForThirdIncrement: '',
        fourthIncrement: '',
        sabotageStateForFourthIncrement: '',
        fifthIncrement: '',
        sabotageStateForFifthIncrement: '',
        sixthIncrement: '',
        sabotageStateForSixthIncrement: '',
        seventhIncrement: '',
        sabotageStateForSeventhIncrement: '',
        eighthIncrement: '',
        sabotageStateForEightIncrement: '',
        ninthIncrement: '',
        sabotageStateForNinthIncrement: '',
        tenthIncrement: '',
        sabotageStateForTenthIncrement: '',
        eleventhIncrement: '',
        sabotageStateForEleventhIncrement: '',
        twelfthIncrement: '',
        sabotageStateForTwelfthIncrement: '',
        thirteenthIncrement: '',
        sabotageStateForThirteenthIncrement: '',
        fourteenthIncrement: '',
        sabotageStateForFourteenthIncrement: '',
        fifteenthIncrement: '',
        sabotageStateForFifteenthIncrement: '',
        sixteenthIncrement: '',
        sabotageStateForSixteenthIncrement: '',
        seventeenthIncrement: '',
        sabotageStateForSeventeenthIncrement: '',
        eighteenthIncrement: '',
        sabotageStateForEighteenthIncrement: '',
        nineteenthIncrement: '',
        sabotageStateForNineteenthIncrement: '',
        twentiethIncrement: '',
        sabotageStateForTwentiethIncrement: '',
        twentiethFirstIncrement: '',
        sabotageStateForTwentiethFirstIncrement: '',
        twentiethSecondIncrement: '',
        sabotageStateForTwentiethSecondIncrement: '',
        twentiethThirdIncrement: '',
        sabotageStateForTwentiethThirdIncrement: '',
        networkQualityIndicator: '',
        GSMLevelFirstReportDispatch: '',
    };

    componentDidMount() {
        const { schemeV9ReportBody, onSetProtocolType } = this.props;
        onSetProtocolType('c');
        this.setState({ ...schemeV9ReportBody });
    }

    componentDidUpdate(prevProps) {
        const { schemeV9ReportBody, selectedScheme, clearSelectedScheme } = this.props;
        if (
            schemeV9ReportBody &&
            Object.keys(schemeV9ReportBody).length > 0 &&
            prevProps.schemeV9ReportBody !== schemeV9ReportBody
        ) {
            this.setState(schemeV9ReportBody);
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
        }
    }

    handleChange = (key, event) => {
        this.setState({ [key]: event.target.value }, () =>
            this.props.getPackagesData(this.state, `packageBody${this.props.number}`)
        );
    };

    onSubmit = () => {
        this.props.saveSelectedFormState(this.props.number, this.state);
        return false;
    };

    clearState = () => {
        this.setState({
            day: '',
            counterState: '',
            sabotageState: '',
            multiplierIncrement: '',
            firstIncrement: '',
            sabotageStateForFirstIncrement: '',
            secondIncrement: '',
            sabotageStateForSecondIncrement: '',
            thirdIncrement: '',
            sabotageStateForThirdIncrement: '',
            fourthIncrement: '',
            sabotageStateForFourthIncrement: '',
            fifthIncrement: '',
            sabotageStateForFifthIncrement: '',
            sixthIncrement: '',
            sabotageStateForSixthIncrement: '',
            seventhIncrement: '',
            sabotageStateForSeventhIncrement: '',
            eighthIncrement: '',
            sabotageStateForEightIncrement: '',
            ninthIncrement: '',
            sabotageStateForNinthIncrement: '',
            tenthIncrement: '',
            sabotageStateForTenthIncrement: '',
            eleventhIncrement: '',
            sabotageStateForEleventhIncrement: '',
            twelfthIncrement: '',
            sabotageStateForTwelfthIncrement: '',
            thirteenthIncrement: '',
            sabotageStateForThirteenthIncrement: '',
            fourteenthIncrement: '',
            sabotageStateForFourteenthIncrement: '',
            fifteenthIncrement: '',
            sabotageStateForFifteenthIncrement: '',
            sixteenthIncrement: '',
            sabotageStateForSixteenthIncrement: '',
            seventeenthIncrement: '',
            sabotageStateForSeventeenthIncrement: '',
            eighteenthIncrement: '',
            sabotageStateForEighteenthIncrement: '',
            nineteenthIncrement: '',
            sabotageStateForNineteenthIncrement: '',
            twentiethIncrement: '',
            sabotageStateForTwentiethIncrement: '',
            twentiethFirstIncrement: '',
            sabotageStateForTwentiethFirstIncrement: '',
            twentiethSecondIncrement: '',
            sabotageStateForTwentiethSecondIncrement: '',
            twentiethThirdIncrement: '',
            sabotageStateForTwentiethThirdIncrement: '',
            networkQualityIndicator: '',
            GSMLevelFirstReportDispatch: '',
        });
    };

    render() {
        const { classes } = this.props;
        const {
            day,
            counterState,
            sabotageState,
            multiplierIncrement,
            firstIncrement,
            sabotageStateForFirstIncrement,
            secondIncrement,
            sabotageStateForSecondIncrement,
            thirdIncrement,
            sabotageStateForThirdIncrement,
            fourthIncrement,
            sabotageStateForFourthIncrement,
            fifthIncrement,
            sabotageStateForFifthIncrement,
            sixthIncrement,
            sabotageStateForSixthIncrement,
            seventhIncrement,
            sabotageStateForSeventhIncrement,
            eighthIncrement,
            sabotageStateForEightIncrement,
            ninthIncrement,
            sabotageStateForNinthIncrement,
            tenthIncrement,
            sabotageStateForTenthIncrement,
            eleventhIncrement,
            sabotageStateForEleventhIncrement,
            twelfthIncrement,
            sabotageStateForTwelfthIncrement,
            thirteenthIncrement,
            sabotageStateForThirteenthIncrement,
            fourteenthIncrement,
            sabotageStateForFourteenthIncrement,
            fifteenthIncrement,
            sabotageStateForFifteenthIncrement,
            sixteenthIncrement,
            sabotageStateForSixteenthIncrement,
            seventeenthIncrement,
            sabotageStateForSeventeenthIncrement,
            eighteenthIncrement,
            sabotageStateForEighteenthIncrement,
            nineteenthIncrement,
            sabotageStateForNineteenthIncrement,
            twentiethIncrement,
            sabotageStateForTwentiethIncrement,
            twentiethFirstIncrement,
            sabotageStateForTwentiethFirstIncrement,
            twentiethSecondIncrement,
            sabotageStateForTwentiethSecondIncrement,
            twentiethThirdIncrement,
            sabotageStateForTwentiethThirdIncrement,
            networkQualityIndicator,
            GSMLevelFirstReportDispatch,
        } = this.state;
        return (
            <div className={classes.root}>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        label="Dzień: 2 znaki [01-31] np. 12"
                        value={day}
                        onChange={e => this.handleChange('day', e)}
                        validators={['matchRegexp:^((0[1-9])|(1[1-9])|(2[1-9])|(3[0-1])|(1[0-3]))$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="day"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={counterState}
                        onChange={e => this.handleChange('counterState', e)}
                        label="Stan licznika na godz. 1:00 [0,001m3]: 9 znaków [000000000-999999999] np. 123456789"
                        validators={['matchRegexp:^([0-9]{9})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="counterState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageState}
                        onChange={e => this.handleChange('sabotageState', e)}
                        label="Przepływ / stan sabotaży: 1 znak [+,-,m,M,g,G,d,D] np. m"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={multiplierIncrement}
                        onChange={e => this.handleChange('multiplierIncrement', e)}
                        label="Mnożnik przyrostów: 1 znak [0-5] np. 1"
                        validators={['matchRegexp:^([0-5])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="multiplierIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firstIncrement}
                        onChange={e => this.handleChange('firstIncrement', e)}
                        label="Przyrost 1 z godz. 2:00 (pomiędzy godz. 1:00 a 2:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firstIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForFirstIncrement}
                        onChange={e => this.handleChange('sabotageStateForFirstIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 1: 1 znak [+,-,m,M,g,G,d,D] np. M"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForFirstIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={secondIncrement}
                        onChange={e => this.handleChange('secondIncrement', e)}
                        label="Przyrost 2 z godz. 3:00 (pomiędzy godz. 2:00 a 3:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="secondIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForSecondIncrement}
                        onChange={e => this.handleChange('sabotageStateForSecondIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 2: 1 znak [+,-,m,M,g,G,d,D] np. g"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForSecondIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={thirdIncrement}
                        onChange={e => this.handleChange('thirdIncrement', e)}
                        label="Przyrost 3 z godz. 4:00 (pomiędzy godz. 3:00 a 4:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="thirdIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForThirdIncrement}
                        onChange={e => this.handleChange('sabotageStateForThirdIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 3: 1 znak [+,-,m,M,g,G,d,D] np. G"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForThirdIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={fourthIncrement}
                        onChange={e => this.handleChange('fourthIncrement', e)}
                        label="Przyrost 4 z godz. 5:00 (pomiędzy godz. 4:00 a 5:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="fourthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForFourthIncrement}
                        onChange={e => this.handleChange('sabotageStateForFourthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 4: 1 znak [+,-,m,M,g,G,d,D] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForFourthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={fifthIncrement}
                        onChange={e => this.handleChange('fifthIncrement', e)}
                        label="Przyrost 5 z godz. 6:00 (pomiędzy godz. 5:00 a 6:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="fifthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForFifthIncrement}
                        onChange={e => this.handleChange('sabotageStateForFifthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 5: 1 znak [+,-,m,M,g,G,d,D] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForFifthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sixthIncrement}
                        onChange={e => this.handleChange('sixthIncrement', e)}
                        label="Przyrost 6 z godz. 7:00 (pomiędzy godz. 6:00 a 7:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sixthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForSixthIncrement}
                        onChange={e => this.handleChange('sabotageStateForSixthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 6: 1 znak [+,-,m,M,g,G,d,D] np. D"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForSixthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={seventhIncrement}
                        onChange={e => this.handleChange('seventhIncrement', e)}
                        label="Przyrost 7 z godz. 8:00 (pomiędzy godz. 7:00 a 8:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="seventhIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForSeventhIncrement}
                        onChange={e => this.handleChange('sabotageStateForSeventhIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 7: 1 znak [+,-,m,M,g,G,d,D] np. m"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForSeventhIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={eighthIncrement}
                        onChange={e => this.handleChange('eighthIncrement', e)}
                        label="Przyrost 8 z godz. 9:00 (pomiędzy godz. 8:00 a 9:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="eighthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForEightIncrement}
                        onChange={e => this.handleChange('sabotageStateForEightIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 8: 1 znak [+,-,m,M,g,G,d,D] np. M"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForEightIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={ninthIncrement}
                        onChange={e => this.handleChange('ninthIncrement', e)}
                        label="Przyrost 9 z godz. 10:00 (pomiędzy godz. 9:00 a 10:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="ninthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForNinthIncrement}
                        onChange={e => this.handleChange('sabotageStateForNinthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 9: 1 znak [+,-,m,M,g,G,d,D] np. g"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForNinthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={tenthIncrement}
                        onChange={e => this.handleChange('tenthIncrement', e)}
                        label="Przyrost 10 z godz. 11:00 (pomiędzy godz. 10:00 a 11:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="tenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForTenthIncrement}
                        onChange={e => this.handleChange('sabotageStateForTenthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 10: 1 znak [+,-,m,M,g,G,d,D] np. G"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForTenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={eleventhIncrement}
                        onChange={e => this.handleChange('eleventhIncrement', e)}
                        label="Przyrost 11 z godz. 12:00 (pomiędzy godz. 11:00 a 12:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="eleventhIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForEleventhIncrement}
                        onChange={e => this.handleChange('sabotageStateForEleventhIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 11: 1 znak [+,-,m,M,g,G,d,D] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForEleventhIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={twelfthIncrement}
                        onChange={e => this.handleChange('twelfthIncrement', e)}
                        label="Przyrost 12 z godz. 13:00 (pomiędzy godz. 12:00 a 13:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="twelfthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForTwelfthIncrement}
                        onChange={e => this.handleChange('sabotageStateForTwelfthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 12: 1 znak [+,-,m,M,g,G,d,D] np. D"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForTwelfthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={thirteenthIncrement}
                        onChange={e => this.handleChange('thirteenthIncrement', e)}
                        label="Przyrost 13 z godz. 14:00 (pomiędzy godz. 13:00 a 14:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="thirteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForThirteenthIncrement}
                        onChange={e => this.handleChange('sabotageStateForThirteenthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 13: 1 znak [+,-,m,M,g,G,d,D] np. m"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForThirteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={fourteenthIncrement}
                        onChange={e => this.handleChange('fourteenthIncrement', e)}
                        label="Przyrost 14 z godz. 15:00 (pomiędzy godz. 14:00 a 15:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="fourteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForFourteenthIncrement}
                        onChange={e => this.handleChange('sabotageStateForFourteenthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 14: 1 znak [+,-,m,M,g,G,d,D] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForFourteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={fifteenthIncrement}
                        onChange={e => this.handleChange('fifteenthIncrement', e)}
                        label="Przyrost 15 z godz. 16:00 (pomiędzy godz. 15:00 a 16:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="fifteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForFifteenthIncrement}
                        onChange={e => this.handleChange('sabotageStateForFifteenthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 15: 1 znak [+,-,m,M,g,G,d,D] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForFifteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sixteenthIncrement}
                        onChange={e => this.handleChange('sixteenthIncrement', e)}
                        label="Przyrost 16 z godz. 17:00 (pomiędzy godz. 16:00 a 17:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sixteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForSixteenthIncrement}
                        onChange={e => this.handleChange('sabotageStateForSixteenthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 16: 1 znak [+,-,m,M,g,G,d,D] np. M"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForSixteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={seventeenthIncrement}
                        onChange={e => this.handleChange('seventeenthIncrement', e)}
                        label="Przyrost 17 z godz. 18:00 (pomiędzy godz. 17:00 a 18:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="seventeenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForSeventeenthIncrement}
                        onChange={e => this.handleChange('sabotageStateForSeventeenthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 17: 1 znak [+,-,m,M,g,G,d,D] np. D"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForSeventeenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={eighteenthIncrement}
                        onChange={e => this.handleChange('eighteenthIncrement', e)}
                        label="Przyrost 18 z godz. 19:00 (pomiędzy godz. 18:00 a 19:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="eighteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForEighteenthIncrement}
                        onChange={e => this.handleChange('sabotageStateForEighteenthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 18: 1 znak [+,-,m,M,g,G,d,D] np. m"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForEighteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={nineteenthIncrement}
                        onChange={e => this.handleChange('nineteenthIncrement', e)}
                        label="Przyrost 19 z godz. 20:00 (pomiędzy godz. 19:00 a 20:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="nineteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForNineteenthIncrement}
                        onChange={e => this.handleChange('sabotageStateForNineteenthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 19: 1 znak [+,-,m,M,g,G,d,D] np. D"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForNineteenthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={twentiethIncrement}
                        onChange={e => this.handleChange('twentiethIncrement', e)}
                        label="Przyrost 20 z godz. 21:00 (pomiędzy godz. 20:00 a 21:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="twentiethIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForTwentiethIncrement}
                        onChange={e => this.handleChange('sabotageStateForTwentiethIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 20: 1 znak [+,-,m,M,g,G,d,D] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForTwentiethIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={twentiethFirstIncrement}
                        onChange={e => this.handleChange('twentiethFirstIncrement', e)}
                        label="Przyrost 21 z godz. 22:00 (pomiędzy godz. 21:00 a 22:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="twentiethFirstIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForTwentiethFirstIncrement}
                        onChange={e => this.handleChange('sabotageStateForTwentiethFirstIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 21: 1 znak [+,-,m,M,g,G,d,D] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForTwentiethFirstIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={twentiethSecondIncrement}
                        onChange={e => this.handleChange('twentiethSecondIncrement', e)}
                        label="Przyrost 22 z godz. 23:00 (pomiędzy godz. 22:00 a 23:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="twentiethSecondIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForTwentiethSecondIncrement}
                        onChange={e => this.handleChange('sabotageStateForTwentiethSecondIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 22: 1 znak [+,-,m,M,g,G,d,D] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForTwentiethSecondIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={twentiethThirdIncrement}
                        onChange={e => this.handleChange('twentiethThirdIncrement', e)}
                        label="Przyrost 23 z godz. 24:00 (pomiędzy godz. 23:00 a 24:00): 3 znaki [000-999,XXX] np. 12X"
                        validators={['matchRegexp:^(([0-9X]){3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="twentiethThirdIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForTwentiethThirdIncrement}
                        onChange={e => this.handleChange('sabotageStateForTwentiethThirdIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 23: 1 znak [+,-,m,M,g,G,d,D] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForTwentiethThirdIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={networkQualityIndicator}
                        onChange={e => this.handleChange('networkQualityIndicator', e)}
                        label="Wskaźnik jakości sieci: 1 znak [0-9,a-z,A-Z] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-Z]|[a-z]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="networkQualityIndicator"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMLevelFirstReportDispatch}
                        onChange={e => this.handleChange('GSMLevelFirstReportDispatch', e)}
                        label="Poziom GSM przy pierwszej probie wysłania: 1 znak [0-9,A-W] np. 1 "
                        validators={['matchRegexp:^([0-9]|[A-W]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMLevelFirstReportDispatch"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

ReportBodyV9.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportBodyV9);
