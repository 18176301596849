/* eslint-disable default-param-last */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LoadingOverlay from 'react-loading-overlay';
import Typography from '@material-ui/core/Typography';
import { axiosInstance, cancelToken } from '../../utils/axiosInstance';
import { cloneObjByJSON, getToken, isEncryptedPackage } from '../../utils/helpers';
import DisplayError from '../DisplayError/DisplayError';
import GoBackButton from '../GoBackButton/GoBackButton';
import protocolsCharAmountData from '../../utils/protocolsCharactersAmountData';
import { endpoint, localStorageKey } from '../../utils/constants';
import TitleHeader from '../TitleHeader/TitleHeader';
import theme from '../../themes/theme';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '2rem',
        fontFamily: '"Roboto"',
    },

    tdData: {
        border: '1px solid black',
        padding: '.5rem',
        width: '90vw',
        maxWidth: '90vw',
        wordWrap: 'break-word',
    },
    tdTitle: {
        border: '1px solid black',
        padding: '.5rem',
        width: '7vw',
        verticalAlign: 'top',
        fontWeight: theme.font.weight.bold,
    },
    table: {
        borderCollapse: 'collapse',
    },
    typography: {
        margin: '2rem 0',
        textAlign: 'center',
        fontSize: '1.6rem',
        color: '#595652',
    },
    textArea: {
        width: '99%',
        margin: '0 auto',
        minHeight: '10rem',
        padding: '.3rem',
        fontFamily: '"Roboto"',
        fontSize: 'inherit',
    },
    titleButtonContainer: {
        width: '100%',
        position: 'relative',
    },
    errorHighlight: {
        background: 'rgba(226,50,90, .4)',
    },
    tdSubTitle: {
        fontSize: '.9rem',
    },
    errorWarning: {
        color: 'rgb(226,50,90)',
        fontWeight: 800,
    },
    errorDecryptedContainer: {
        background: 'whitesmoke',
        paddingBottom: '.4rem',
        marginTop: '1rem',
    },
    errorDecrypted: {
        fontFamily: 'Roboto',
        fontSize: '1rem',
        margin: 0,
    },
};

class Error extends Component {
    state = {
        error: {},
        errorDescription: '',
        packageId: '',
        parserErrorPackageData: '',
        isServerError: false,
        isThrobberActive: false,
        document_index: 0,
        protocolHeadBodyAmount: {},
        isV7Report: false,
        isV8Report: false,
        decryptedV11Data: '',
    };

    componentDidMount() {
        const { id } = this.props.match.params;
        this.cancelHttpReq = cancelToken.source();

        this.setState(
            {
                packageId: id,
                isThrobberActive: true,
            },
            this.getErrorData
        );
    }

    componentDidUpdate() {
        const { protocolHeadBodyAmount, errorDescription } = this.state;
        if (protocolHeadBodyAmount && this.isCharAmountToLow() && errorDescription === '') {
            this.setState(state => ({
                errorDescription: `${state.errorDescription}
                \nZa mała liczba znaków.`,
            }));
        }
    }

    componentWillUnmount() {
        this.cancelHttpReq.cancel();
        localStorage.removeItem(localStorageKey.PARSER_ERROR_PACKAGE_DATA);
    }

    getErrorData = () => {
        const { packageId } = this.state;

        axiosInstance(endpoint.ERROR, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
            params: {
                packageId,
            },
            cancelToken: this.cancelHttpReq.token,
        })
            .then(content => {
                const { data } = content;
                const packageData = data.data;
                if (isEncryptedPackage(packageData)) {
                    this.handlePackageDecryption(packageData, content);
                } else {
                    this.setErrorDescription(content);
                }
            })
            .catch(err => {
                if (err.response === undefined) {
                    return;
                }
                const errMsg = err.response.data.message;
                const regexToFindNoLogs = /No query results/;

                if (err.response.status === 401) {
                    this.props.history.push(`/login`);
                }

                if (regexToFindNoLogs.test(errMsg)) {
                    this.setState({
                        isThrobberActive: false,
                        error: {
                            details: 'Błąd nie został odnaleziony w logach',
                        },
                    });
                } else if (errMsg) {
                    this.setState({
                        isThrobberActive: false,
                        error: {
                            details: errMsg,
                        },
                    });
                }
            });
    };

    getHighlightedError = (error, errorIndex, errorFieldLength) => {
        const { classes } = this.props;
        const stringBeforeError = error.slice(0, errorIndex);
        const stringError = error.substr(errorIndex, errorFieldLength);
        const stringAfterError = error.slice(errorIndex + errorFieldLength);
        return (
            <p>
                {stringBeforeError}
                <strong className={classes.errorHighlight}>{stringError}</strong>
                {stringAfterError}
            </p>
        );
    };

    getErrorDescription = (error, documentIndex, protocolHeadBodyAmount) =>
        `Nazwa pola: "${error.description}"\nWartość pola po parsowaniu: "${
            error.value
        }"\nSymbol kolumny: "${error.name}"\nPoprawny przedział wartości: "${
            error.rule
        }"\nLiczba znaków: "${error.length}"\nOffset: "${this.getErrorOffset(
            error.offset,
            documentIndex,
            protocolHeadBodyAmount,
            false,
            error.data.length
        )}"`;

    getErrorOffset = (
        errorOffset,
        documentIndex,
        protocolHeadBodyAmount,
        isErrorHighlight = false,
        errorLength
    ) => {
        const getOffsetForMultiBody = () => {
            const bodyCharAmountAboveHeader = errorOffset - protocolHeadBodyAmount.header;

            return errorLength - protocolHeadBodyAmount.body + bodyCharAmountAboveHeader;
        };

        if (isErrorHighlight && documentIndex > 0) {
            return getOffsetForMultiBody();
        }
        if (documentIndex > 0 && errorLength > 0) {
            return `według dokumentacji: ${errorOffset}; według krotności body: ${getOffsetForMultiBody()}`;
        }

        return errorOffset;
    };

    onEditErrorDetails = e => {
        const changedErrorDescription = e.target.value;
        const { error } = this.state;
        this.setState({ errorDescription: changedErrorDescription });
        if (error.details) {
            const copyState = cloneObjByJSON(this.state);
            copyState.error.details = changedErrorDescription;
            this.setState(copyState);
        }
    };

    isErrorWarn = () => this.getProtocolDocumentedCharAmount() !== this.getPackageLength();

    getTableContent = () => {
        const { classes } = this.props;
        const { error, errorDescription, packageId } = this.state;

        return [
            {
                rowHead: <td className={classes.tdTitle}>Błąd pakietu:</td>,
                rowContent: <td className={classes.tdData}>{packageId}</td>,
            },
            {
                rowHead: <td className={classes.tdTitle}>Treść pakietu:</td>,
                rowContent: <td className={classes.tdData}>{this.setPackageDate()}</td>,
            },

            {
                rowHead: <td className={classes.tdTitle}>Protokół:</td>,
                rowContent: <td className={classes.tdData}>{error.protocol}</td>,
            },
            {
                rowHead: <td className={classes.tdTitle}>Typ protokołu:</td>,
                rowContent: <td className={classes.tdData}>{error.document_type}</td>,
            },
            {
                rowHead: <td className={classes.tdTitle}>Data:</td>,
                rowContent: <td className={classes.tdData}>{error.created}</td>,
            },

            {
                rowHead: (
                    <td className={classes.tdTitle}>
                        Liczba znaków w pakiecie:
                        {this.getPackageLength() > 0 && (
                            <p className={classes.tdSubTitle}>{`${
                                this.getProperCharAmount().description
                            }`}</p>
                        )}
                    </td>
                ),
                rowContent: (
                    <td className={[classes.tdData, this.isErrorWarn() && classes.errorWarning].join(' ')}>
                        {this.getPackageLength() > 0 && `${this.getPackageLength()}`}
                    </td>
                ),
            },
            {
                rowHead: <td className={classes.tdTitle}>Szczegóły:</td>,
                rowContent: (
                    <td className={classes.tdData}>
                        <form>
                            <textarea
                                className={classes.textArea}
                                value={error.details ? error.details : errorDescription}
                                onChange={this.onEditErrorDetails}
                            />
                        </form>
                    </td>
                ),
            },
        ];
    };

    createTableContent = () => {
        const { classes } = this.props;
        return (
            <table className={classes.table}>
                <tbody>
                    {this.getTableContent().map(i => (
                        <tr key={i.rowHead.props.children}>
                            {i.rowHead}
                            {i.rowContent}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    getPackageLength = () => {
        const { error } = this.state;

        if (error.data === undefined) {
            return 0;
        }
        return error.data.length;
    };

    isAnotherBodyIteration = () => {
        const { protocolHeadBodyAmount } = this.state;

        const isBodyExist = () => this.getPackageLength() - protocolHeadBodyAmount.header > 0;

        const isAnotherBody =
            (this.getPackageLength() - protocolHeadBodyAmount.header) % protocolHeadBodyAmount.body === 0;

        return isBodyExist() && isAnotherBody
            ? (this.getPackageLength() - protocolHeadBodyAmount.header) / protocolHeadBodyAmount.body
            : 1;
    };

    isV7ReportIteration = () => {
        if (this.getPackageLength() <= 80) {
            return 80;
        }
        if (this.getPackageLength() > 80 && this.getPackageLength() <= 120) {
            return 120;
        }
        if (this.getPackageLength() > 120) {
            return 160;
        }
        return null;
    };

    isV8ReportException = () => (this.getPackageLength() <= 112 ? 112 : 119);

    getProperCharAmount = () => {
        const { protocolHeadBodyAmount } = this.state;

        return protocolHeadBodyAmount === undefined
            ? this.setProtocolHeadBodyAmountOnUndefined()
            : this.setProtocolHeadBodyAmount();
    };

    setProtocolHeadBodyAmountOnUndefined = () => ({
        description: ``,
        amount: 0,
    });

    setProtocolHeadBodyAmount = () => {
        const { protocolHeadBodyAmount } = this.state;
        const docCharAmount = protocolHeadBodyAmount.header + protocolHeadBodyAmount.body;

        return protocolHeadBodyAmount.header && protocolHeadBodyAmount.body
            ? {
                  description: `(poprawna: ${this.getProtocolDocumentedCharAmount()})`,
                  amount: +docCharAmount,
              }
            : '';
    };

    getProtocolDocumentedCharAmount = () => {
        const { protocolHeadBodyAmount, isV7Report, isV8Report } = this.state;

        if (isV7Report) {
            return this.isV7ReportIteration();
        }

        if (isV8Report) {
            return this.isV8ReportException();
        }

        if (protocolHeadBodyAmount !== undefined) {
            return (
                protocolHeadBodyAmount.header + protocolHeadBodyAmount.body * this.isAnotherBodyIteration()
            );
        }

        return '';
    };

    isCharAmountToLow = () => this.getPackageLength() < this.getProperCharAmount().amount;

    setErrorDescription = content => {
        const {
            data,
            data: { protocol, document_type, document_index },
        } = content;

        const protocolHeadBodyAmount = protocolsCharAmountData[`${protocol}${document_type}`];
        this.setState({
            error: data,
            errorDescription: this.getErrorDescription(data, document_index, protocolHeadBodyAmount),
            isThrobberActive: false,
            document_index,
            protocolHeadBodyAmount,
            isV7Report: protocol + document_type === 'V7report',
            isV8Report: protocol + document_type === 'V8report',
        });
    };

    setV11ErrorDetails = (content, decryptedData) => {
        const {
            data,
            data: { protocol, document_type, document_index },
        } = content;

        const protocolHeadBodyAmount = protocolsCharAmountData[`${protocol}${document_type}`];

        this.setState({
            error: data,

            errorDescription: this.getErrorDescription(data, document_index, protocolHeadBodyAmount),
            isThrobberActive: false,
            document_index,
            protocolHeadBodyAmount,
            isV7Report: protocol + document_type === 'V7report',
            isV8Report: protocol + document_type === 'V8report',
            decryptedV11Data: decryptedData,
        });
    };

    handlePackageDecryption = (packageData, content) => {
        this.cancelHttpReq = cancelToken.source();

        axiosInstance
            .post(
                endpoint.DECRYPT_PACKAGE,
                {
                    data: packageData,
                },
                {
                    cancelToken: this.cancelHttpReq.token,
                }
            )
            .then(({ data: { data: data } }) => {
                const decryptedData = data;
                this.setV11ErrorDetails(content, decryptedData);
            })
            .catch(error => {
                console.error('errorDecodeV11', error);
            });
    };

    setPackageDate = () => {
        const { classes } = this.props;
        const { error, document_index, protocolHeadBodyAmount, decryptedV11Data } = this.state;

        if (decryptedV11Data) {
            return (
                <div>
                    {error.data}
                    {decryptedV11Data && (
                        <div className={classes.errorDecryptedContainer}>
                            <Typography variant="overline">Pakiet zdekodowany:</Typography>
                            <p className={classes.errorDecrypted}>
                                {error.offset
                                    ? this.getHighlightedError(
                                          decryptedV11Data,
                                          this.getErrorOffset(
                                              error.offset,
                                              document_index,
                                              protocolHeadBodyAmount,
                                              true,
                                              decryptedV11Data.length
                                          ),
                                          error.length
                                      )
                                    : decryptedV11Data}
                            </p>
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div>
                {error.offset
                    ? this.getHighlightedError(
                          error.data,
                          this.getErrorOffset(
                              error.offset,
                              document_index,
                              protocolHeadBodyAmount,
                              true,
                              error.data.length
                          ),
                          error.length
                      )
                    : error.data}
            </div>
        );
    };

    render() {
        const { classes, history } = this.props;
        const { isServerError, packageId, isThrobberActive } = this.state;
        return (
            <div className={classes.container}>
                <div className={classes.titleButtonContainer}>
                    <GoBackButton hashRouterHistory={history} />
                    <TitleHeader title={`Błąd pakietu (${packageId})`} />
                </div>

                {!isServerError ? (
                    <LoadingOverlay
                        active={isThrobberActive}
                        spinner
                        text="Proszę czekać..."
                        styles={{
                            content: base => ({
                                ...base,
                                marginLeft: '50%',
                                margin: '4%',
                                transform: 'translateX(-50%)',
                            }),
                        }}>
                        {this.createTableContent()}
                    </LoadingOverlay>
                ) : (
                    <DisplayError />
                )}
            </div>
        );
    }
}

export default withStyles(styles)(Error);
