import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import TableSearchBar from '../Tables/TableSearchBar';
import TableWrapper from '../Tables/TableWrapper';
import { errorHandling, isAdminRole, removeLocalStorageItems } from '../../utils/helpers';
import { axiosInstance, cancelToken } from '../../utils/axiosInstance';
import { endpoint, localStorageKey } from '../../utils/constants';
import TitleHeader from '../TitleHeader/TitleHeader';

const styles = {
    root: {
        width: '100%',
        marginBottom: '5rem',
    },
    inputContainer: {
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '50%',
        height: '5.5rem',
        marginBottom: '5rem',
    },
    button: {
        height: '2.5rem',
        width: '7.5rem',
        fontSize: '.7rem',
        padding: 0,
        marginBottom: '-1rem',
        marginRight: '1.8rem',
    },
    resetFieldsButton: {
        position: 'relative',
        top: '.9rem',
        marginLeft: '1.8rem',
        marginBottom: '.9rem',
    },
};

class PipelinesList extends Component {
    state = {
        pipelines: { data: [], headers: {} },
        switchgearLetter: [],
        searchPhrase: '',
        isServerError: false,
        clearSearchField: false,
    };

    componentDidMount() {
        this.cancelHttpReq = cancelToken.source();
        this.setStateFromLocalStorage();
    }

    componentWillUnmount() {
        if (this.cancelHttpReq !== undefined) {
            this.cancelHttpReq.cancel();
        }
    }

    getSortedWaterpipesList = waterpipiesList =>
        waterpipiesList.reduce(
            (total, item) => total.concat(item).sort((a, b) => a.name.localeCompare(b.name)),
            []
        );

    getPipelinesListData = () => {
        const { searchPhrase } = this.state;

        axiosInstance
            .get(endpoint.WATERPIPES, {
                params: {
                    filter: searchPhrase,
                },
                cancelToken: this.cancelHttpReq.token,
            })
            .then(content => {
                this.setState({
                    pipelines: {
                        data: this.getSortedWaterpipesList(content.data.data),
                        headers: content.data.headers,
                    },
                });
            })
            .catch(err => {
                errorHandling(err, this);
            });
    };

    handleSearch = (phrase, isEnterPressed) => {
        localStorage.getItem(localStorageKey.PIPELINE_LIST_SEARCH, phrase);
        this.setState({ searchPhrase: phrase }, () => {
            if (isEnterPressed) {
                this.getPipelinesListData();
            }
        });
    };

    addNewPipelineHandler = () => {
        const { match, history } = this.props;
        history.push(`${match.url}/add`);
    };

    setStateFromLocalStorage = () => {
        const pipelinesListSearch = localStorage.getItem(localStorageKey.PIPELINE_LIST_SEARCH);
        this.setState(
            state => ({
                searchPhrase: pipelinesListSearch || state.searchPhrase,
            }),
            this.getPipelinesListData
        );
    };

    handleResetFieldsButton = () => {
        removeLocalStorageItems(['pipelinesListSearch']);
        this.setState(
            {
                searchPhrase: '',
            },
            this.getPipelinesListData
        );
    };

    handleClearedSearchField = () => {
        this.setState({ clearSearchField: false });
    };

    render() {
        const { classes, clearSearchField } = this.props;
        const { pipelines, isServerError, searchPhrase } = this.state;
        return (
            <div className={classes.root}>
                <TitleHeader title={'Lista wodociągów'} />
                <article className={classes.inputContainer}>
                    {isAdminRole() && (
                        <Button
                            data-test="component-app"
                            variant="contained"
                            color="primary"
                            onClick={this.addNewPipelineHandler}
                            className={classes.button}>
                            Dodaj wodociąg
                        </Button>
                    )}
                    <TableSearchBar
                        handleSearch={(phrase, isEnterPressed) => this.handleSearch(phrase, isEnterPressed)}
                        clearSearchField={clearSearchField}
                        clearedSearchField={this.handleClearedSearchField}
                        value={searchPhrase}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.handleResetFieldsButton}
                        className={classes.resetFieldsButton}>
                        reset
                    </Button>
                </article>
                <TableWrapper
                    hidePagination
                    showWaterpipeLink
                    content={pipelines}
                    endpoint={endpoint.WATERPIPES}
                    isServerError={isServerError}
                />
            </div>
        );
    }
}

PipelinesList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PipelinesList);
