import React from 'react';
import { withStyles, TextField } from '@material-ui/core';
import theme from '../../../themes/theme';

const styles = {
    field: {
        margin: `0 ${theme.margins.textField.all} 0 ${theme.margins.textField.all}`,
        width: `calc(100% - 2 * ${theme.margins.textField.all})`,
    },
};

const FavoriteReportDescription = ({
    classes: { field },
    favoriteReportDesc,
    setFavoriteReportDesc,
    rows,
    style,
}) => {
    const DEFAULT_ROWS_NUMBER = 15;

    const changeDescription = e => {
        setFavoriteReportDesc(e.target.value);
    };

    return (
        <TextField
            label="Opis zestawienia"
            placeholder="Wprowadź opis zestawienia..."
            type="text"
            className={field}
            InputLabelProps={{
                shrink: true,
            }}
            onChange={changeDescription}
            value={favoriteReportDesc}
            multiline
            variant={'outlined'}
            minRows={rows ?? DEFAULT_ROWS_NUMBER}
            style={style}
        />
    );
};

export default withStyles(styles)(FavoriteReportDescription);
