/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { axiosInstance } from '../../utils/axiosInstance';
import { endpoint, localStorageKey, reportTypes } from '../../utils/constants';
import List from './List/List';

export default function ListGenerator() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [generatedListData, setGeneratedListData] = useState({});
    const [listDataReadingLastVal, setListDataReadingLastVal] = useState({});
    const [alarmData, setAlarmData] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [alarmTableHeader, setAlarmTableHeader] = useState([]);
    const [reportTableHeader, setReportTableHeader] = useState([]);
    const [alarmHeadCells, setAlarmHeadCells] = useState([]);
    const [reportHeadCells, setReportHeadCells] = useState([]);
    const [isThrobberActive, setIsThrobberActive] = useState(false);

    const isGeneratedListData = () => Object.keys(generatedListData).length > 0;

    const isRemoveUnusedValues = val => {
        const unusedKeys = [
            'alarm_package_id',
            'alarm_channel',
            'alarm_alert_type',
            'report_id',
            'report_package_id',
            'report_communicator',
        ];

        return unusedKeys.includes(val);
    };
    const getSorTableData = () => {
        function createData() {
            const mergeCellsId = getHeadCells();
            let itemObj = {};
            function mergeReportsTypeData() {
                return alarmData.map((item, index) => ({
                    ...item,
                    ...reportData[index],
                }));
            }

            return mergeReportsTypeData().reduce((result, item) => {
                for (const itemKey in item) {
                    if (item[itemKey] === null) {
                        item[itemKey] = ' ';
                    }
                }

                mergeCellsId.forEach(cellHeadName => {
                    itemObj = {
                        ...itemObj,
                        [cellHeadName.id]: item[cellHeadName.id],
                    };
                });

                return [...result, itemObj];
            }, []);
        }
        return createData();
    };

    function changeGsmLevelToNumber(data) {
        const copyData = JSON.stringify(data);
        const copiedData = JSON.parse(copyData);

        copiedData.forEach(item => {
            const {
                report,
                report: { gsm_level },
            } = item;
            if (report && gsm_level) {
                if (gsm_level !== '--') {
                    item.report.gsm_level = +gsm_level;
                }
            }
        });

        return copiedData;
    }

    const getHeadCells = () => [...alarmHeadCells, ...reportHeadCells];

    const getGeneratedListConfig = () =>
        JSON.parse(localStorage.getItem(localStorageKey.GENERATED_PROD_LIST_DATA));

    const getGeneratedList = () => {
        function getNumbersArray() {
            if (getGeneratedListConfig().numbers === '') return null;
            return getGeneratedListConfig().numbers ? getGeneratedListConfig().numbers.split(',') : null;
        }

        setIsThrobberActive(true);
        axiosInstance
            .post(endpoint.PRODUCTION_REPORT, {
                waterpipeId: Number(getGeneratedListConfig().waterpipeId),
                dateFrom: getGeneratedListConfig().dateFrom,
                dateTo: getGeneratedListConfig().dateTo,
                numbersFrom: getGeneratedListConfig().numbersFrom
                    ? getGeneratedListConfig().numbersFrom
                    : null,
                numbersTo: getGeneratedListConfig().numbersTo ? getGeneratedListConfig().numbersTo : null,
                numbers: getNumbersArray() !== null ? getNumbersArray() : null,
                type: getGeneratedListConfig().type,
            })
            .then(data => {
                const dataCopy = changeGsmLevelToNumber(data.data.data);
                setGeneratedListData(setPrefixToDataKeys(dataCopy));
                setListDataReadingLastVal(setPrefixToDataKeys(setDataForSorting(dataCopy), dataCopy));
                setIsThrobberActive(false);
            })
            .catch(err => {
                console.error('Error list', err);
                setIsThrobberActive(false);
            });
    };

    const setDataForSorting = listData => {
        const dataToJSON = JSON.stringify(listData);
        const dataObjCopy = JSON.parse(dataToJSON);

        let iteration = 0;
        const dataModyfied = dataObjCopy;

        while (iteration < dataModyfied.length) {
            const dataItem = dataModyfied[iteration];

            if (dataItem.report.readings !== null && dataItem.report.readings.length > 0) {
                dataItem.report.readings = {
                    reportId: dataItem.report.readings[0].report_id,
                    lastReading: dataItem.report.readings[0].data,
                };
            } else {
                dataItem.report.readings = null;
            }

            iteration++;
        }
        return dataModyfied;
    };

    const setPrefixToDataKeys = (dataArray, rawDataWithFullReadingsObj) => {
        let dataAlarm = [];
        let dataReport = [];
        let dataAlarmWithPrefix = [];
        let dataReportWithPrefix = [];
        dataArray.forEach(item => {
            function renameKeys(obj, newKeys) {
                const keyValues = Object.keys(obj).map((key, index) => {
                    const newKey = newKeys[index] || key;
                    if (isRemoveUnusedValues(newKey)) return;
                    return { [newKey]: obj[key] };
                });

                return Object.assign({}, ...keyValues);
            }

            function addPrefixToKeys(keys, reportType) {
                return keys.map(key => `${reportType}_${key}`);
            }

            function setSabotageValue(index) {
                let lastReportSabotageVal;
                if (rawDataWithFullReadingsObj) {
                    if (
                        rawDataWithFullReadingsObj[index].report.readings !== null &&
                        rawDataWithFullReadingsObj[index].report.readings.length > 0
                    ) {
                        lastReportSabotageVal = rawDataWithFullReadingsObj[index].report.readings[0].sabotage;
                    } else {
                        lastReportSabotageVal = '';
                    }
                }
                return lastReportSabotageVal;
            }

            dataAlarm = [...dataAlarm, item.alarm];
            dataReport = [...dataReport, item.report];
            dataAlarmWithPrefix = dataAlarm.map((item, index) => {
                const lp = index + 1;

                function putObjKeysOnExactPlace() {
                    const arrFromObj = Object.entries(item);
                    const copyArrFromObj = [...arrFromObj];
                    function setCommunicatorNumberPosition() {
                        const communicatorNumber = arrFromObj[4];
                        const key = communicatorNumber[0];
                        const val = communicatorNumber[1];
                        copyArrFromObj.splice(4, 1);
                        copyArrFromObj.splice(1, 0, [key, val]);
                    }

                    setCommunicatorNumberPosition();

                    return Object.fromEntries(copyArrFromObj);
                }

                return renameKeys(
                    { ...putObjKeysOnExactPlace(), id: lp },
                    addPrefixToKeys(Object.keys(putObjKeysOnExactPlace()), reportTypes.ALARM)
                );
            });

            dataReportWithPrefix = dataReport.map((item, index) => {
                const parserErrorVal = dataArray[index].parser_error;

                function putObjKeysOnExactPlace() {
                    const arrFromObj = Object.entries(item);
                    const copyArrFromObj = [...arrFromObj];

                    function setSabotagePosition() {
                        copyArrFromObj.splice(10, 0, ['sabotage', setSabotageValue(index)]);
                    }

                    function setVoltagePosition() {
                        const voltage = arrFromObj[7];
                        const key = voltage[0];
                        const val = voltage[1];
                        copyArrFromObj.splice(7, 1);
                        copyArrFromObj.splice(6, 0, [key, val]);
                    }

                    function setRadioSignalPosition() {
                        const radioSignal = arrFromObj[8];
                        const key = radioSignal[0];
                        const val = radioSignal[1];
                        copyArrFromObj.splice(8, 1);
                        copyArrFromObj.splice(7, 0, [key, val]);
                    }

                    setSabotagePosition();
                    setVoltagePosition();
                    setRadioSignalPosition();

                    return Object.fromEntries(copyArrFromObj);
                }

                return renameKeys(
                    {
                        ...putObjKeysOnExactPlace(),
                        parser_error: parserErrorVal,
                    },
                    addPrefixToKeys(Object.keys(putObjKeysOnExactPlace()), reportTypes.REPORT)
                );
            });
        });
        return { dataAlarmWithPrefix, dataReportWithPrefix };
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getAlarmData = () => {
        const alarmData = listDataReadingLastVal.dataAlarmWithPrefix;
        setAlarmData(alarmData);
    };

    const getReportData = () => {
        const reportData = listDataReadingLastVal.dataReportWithPrefix;
        setReportData(reportData);
    };

    const setPartsOfTableHeader = () => {
        if (alarmData.length > 0) {
            const headerNames = Object.keys(alarmData[0]);
            setAlarmTableHeader(headerNames);
        }
        if (reportData.length > 0) {
            const headerNames = Object.keys(reportData[0]);
            setReportTableHeader(headerNames);
        }
    };
    const setTableHeader = () => {
        if (alarmTableHeader.length > 0) {
            let cells = [];
            alarmTableHeader.forEach(cellName => {
                const cell = {
                    id: cellName,

                    label: cellName,
                };
                cells = [...cells, cell];
            });

            setAlarmHeadCells(cells);
        }
        if (reportTableHeader.length > 0) {
            let cells = [];
            reportTableHeader.forEach(cellName => {
                const cell = {
                    id: cellName,

                    label: cellName,
                };
                cells = [...cells, cell];
            });

            setReportHeadCells(cells);
        }
    };

    useEffect(() => {
        getGeneratedList();
    }, []);
    useEffect(() => {
        if (
            isGeneratedListData() &&
            (Object.keys(listDataReadingLastVal.dataAlarmWithPrefix).length > 0 ||
                Object.keys(listDataReadingLastVal.dataReportWithPrefix).length > 0)
        ) {
            getAlarmData();
            getReportData();
        }
    }, [listDataReadingLastVal.dataAlarmWithPrefix, listDataReadingLastVal.dataReportWithPrefix]);
    useEffect(() => {
        setPartsOfTableHeader();
    }, [alarmData, reportData]);
    useEffect(() => {
        setTableHeader();
    }, [alarmTableHeader, reportTableHeader]);

    return (
        <>
            <LoadingOverlay
                active={isThrobberActive}
                spinner
                text="Zestawienie jest generowane..."
                styles={{
                    overlay: base => ({
                        ...base,
                        top: '100%',
                    }),
                    content: base => ({
                        ...base,
                        marginLeft: '50%',
                        margin: '4%',
                        transform: 'translateX(-50%)',
                    }),
                }}>
                <List
                    order={order}
                    orderBy={orderBy}
                    handleRequestSort={handleRequestSort}
                    getSorTableData={getSorTableData}
                    listDataReadingLastVal={listDataReadingLastVal}
                    generatedListData={generatedListData}
                    headCells={getHeadCells}
                    reportType={reportTypes.REPORT}
                />
            </LoadingOverlay>
        </>
    );
}
