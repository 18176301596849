import React from 'react';

const Flow = ({ row }) => {
    const flowKey = 0;
    const flowValue = 1;
    return (
        <div>
            <span>{row[flowKey]}: </span>
            <span>{+row[flowValue]}</span>
        </div>
    );
};

export default Flow;
