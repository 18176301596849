/* eslint-disable no-useless-escape */
/* eslint-disable chai-friendly/no-unused-expressions */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import { communicatorFieldType } from '../../utils/constants';
import { removeSpaces } from '../../utils/helpers';
import theme from '../../themes/theme';

const styles = {
    field: {
        width: `calc(100% - 2 * ${theme.margins.textField.all})`,
        alignSelf: 'center',
        '& textarea': {
            wordBreak: 'break-all',
        },
    },
};

const CommunicatorsList = ({
    classes: { field },
    isFocusOnCommunicatorsList,
    setFocusOnCommunicatorsFieldType,
    setIsCommunicatorsListEmpty,
    isCommunicatorsRangeEmpty,
    setIsAnyError,
    setNumbers,
    numbers,
    multiline,
}) => {
    const [communicatorsList, setCommunicatorsList] = useState('');
    const [isError, setIsError] = useState(false);
    const [helperMessage, setHelperMessage] = useState('');

    const REGEX = /(^\@[0-9]{0,7})|([\,\-]([0-9]{8}))+/;
    const ERROR_REGEX = /(\-[0-9]{8}\-)+/;

    const handleInputValue = e => {
        setCommunicatorsList(removeSpaces(e.target.value));
    };

    const isErrorRegex = str => {
        if (str[0] === '-') {
            return true;
        }

        if (str[0] !== '@') str = `-${str}`;

        if (
            str.match(REGEX) === null ||
            str.match(REGEX)[0].length !== str.length ||
            str.substring(1).match(ERROR_REGEX) !== null
        ) {
            return true;
        }
        return false;
    };

    const handleInputFocus = value => {
        if (isCommunicatorsRangeEmpty) {
            setFocusOnCommunicatorsFieldType(communicatorFieldType.COMMUNICATORS_LIST, value);
        }
    };

    const isNumber = value => {
        const valueNoComma = value.replace(/,/g, '').replace(/-/g, '');

        if (isNaN(Number(valueNoComma))) {
            return true;
        }
        clearError();
        return false;
    };

    const setError = msg => {
        setIsError(true);
        setHelperMessage(msg);
    };

    const clearError = () => {
        setIsError(false);
        setHelperMessage('');
    };

    const handleError = isCommunicatorLessThan8Characters => {
        if (isNumber(communicatorsList)) return;
        isCommunicatorLessThan8Characters.length > 0 &&
            setError(
                'Lista numerów (8 cyfr) oddzielona przecinkami lub/i przedziałów komunikatorów z myślnikami'
            );
    };

    const handleIsCommunicatorsListEmpty = () => {
        setIsCommunicatorsListEmpty(!communicatorsList.length);
    };

    const handleIsCommunicatorsListCorrect = () => {
        if (isErrorRegex(communicatorsList)) {
            setError('Nieprawidłowa wartość przedziału');
        } else {
            clearError();
        }
    };

    useEffect(() => {
        if (numbers === ' ') {
            setCommunicatorsList('');
        } else if (numbers) {
            setCommunicatorsList(numbers);
        }
    }, [numbers]);

    useEffect(() => {
        const communicatorListToArr = communicatorsList.replaceAll(/-/g, ',').split(',');

        const isCommunicatorLessThan8Characters = communicatorListToArr.filter(
            communicator => communicator.length !== 8
        );

        handleIsCommunicatorsListEmpty();
        handleError(isCommunicatorLessThan8Characters);
        handleIsCommunicatorsListCorrect();

        setNumbers(communicatorsList);
        if (!communicatorsList) clearError();
        setIsAnyError(isError);
    }, [communicatorsList]);

    useEffect(() => {
        setIsAnyError(isError);
    }, [isError]);

    useEffect(() => {
        clearError();
    }, []);

    return (
        <TextField
            label="Lista numerów"
            placeholder="np. 12345678,12345680-12345689,12345690"
            type="text"
            className={field}
            InputLabelProps={{
                shrink: true,
            }}
            helperText={helperMessage}
            onChange={e => handleInputValue(e)}
            value={communicatorsList}
            error={isError}
            onFocus={() => handleInputFocus(false)}
            onMouseEnter={() => handleInputFocus(false)}
            disabled={isFocusOnCommunicatorsList}
            variant={multiline ? 'outlined' : 'standard'}
            multiline={multiline}
            style={multiline ? { height: 350 } : {}}
            minRows={15}
            maxRows={15}
        />
    );
};

export default withStyles(styles)(CommunicatorsList);
