import React, { Component } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import packagesContext from '../../context/PackagesContext';
import { isAdminRole } from '../../utils/helpers';

export default class ParseButton extends Component {
    static contextType = packagesContext;

    state = { visible: false };

    componentDidMount() {
        this.setState({ visible: this.props.isVisible });
    }

    componentDidUpdate(prevProps) {
        const { isVisible } = this.props;
        if (prevProps.isVisible !== isVisible) {
            this.setState({ visible: false });
        }
    }

    handleClick = () => {
        const endpoint = `api/parse/${this.props.id}`;

        axiosInstance
            .get(endpoint)
            .then(() => {
                this.setState({ visible: false }, this.context.handleRefreshButton);
            })
            .catch(err => {
                if (err.response.data === 'Bad protocol.' || err.response.data.message === 'not_logged') {
                    this.setState({ visible: false }, this.context.handleRefreshButton);
                } else {
                    console.error('err', err.response);
                }
            });
    };

    render() {
        return (
            this.state.visible &&
            isAdminRole() && (
                <div onClick={this.handleClick} style={{ cursor: 'pointer' }}>
                    parsuj
                </div>
            )
        );
    }
}
