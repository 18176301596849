import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TablePagination from './TablePagination';
import Table from './Table';

const styles = () => ({
    root: {
        width: '100%',
        margin: '0 auto',
    },
});
class TableWrapper extends React.Component {
    handleChangePage = page => {
        const { changePage } = this.props;
        changePage(page);
    };

    render() {
        const { content, isServerError, hidePagination, classes, isComponentMounted, selectedPage } =
            this.props;

        return (
            <div className={classes.root}>
                <Table
                    {...this.props}
                    headers={content.headers || []}
                    content={content.data}
                    isServerError={isServerError}
                />
                {isComponentMounted && !hidePagination && (
                    <TablePagination changePage={this.handleChangePage} selectedPage={selectedPage} />
                )}
            </div>
        );
    }
}

TableWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableWrapper);
