/* eslint-disable chai-friendly/no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import {
    getComparator,
    isColumnHighlighted,
    isObject,
    isValueBelow10,
    stableSort,
} from '../../../utils/helpers';
import ListConfigHeader from '../ListConfigHeader/ListConfigHeader';
import {
    columnTypes,
    tabHeadToSymbols,
    tabHeadToSymbolsToTitles,
    tabHeadOrderTestList,
    localStorageKey,
    tableTranslations,
    listTypes,
    TABLE_TABS_PREFIX,
    testListVersion,
    waterpipeWater,
    columnEventTypes,
} from '../../../utils/constants';
import SettingsPanel from '../SettingsPanel/SettingsPanel';
import CopyModal from '../../CopyModal/CopyModal';
import PaginationRows from '../../Pagination/PaginationRows';
import theme from '../../../themes/theme';
import WaterpipeFilter from '../../WaterpipeFilter/WaterpipeFilter';

const styles = {
    root: {
        margin: '1rem 0',
    },
    table: {
        fontFamily: theme.font.family,
        position: 'relative',
        height: '100%',
    },
    errorMessage: {
        color: 'red',
        fontWeight: theme.font.weight.bold,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        userSelect: 'none',
    },
};

export default function TestList({
    version,
    order,
    orderBy,
    getSorTableData,
    generatedListData,
    headCells,
    isErrorLoadingData,
    isFetching,
    waterpipes,
}) {
    const VERSION_V2 = version === testListVersion.V2;
    const PAGINATION_SIZES = [10, 50, 100, 500, 1000];
    const cellNumbersRef = useRef(null);
    const settingsPanelRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    const [columnsDefs, setColumnsDefs] = useState([]);
    const [paginationSize, setPaginationSize] = useState(
        localStorage.getItem(localStorageKey.PAGINATION_SIZE_TEST_LIST) ?? 100
    );
    const [tableHeight, setTableHeight] = useState();
    const [settingsPanelPosition, setSettingsPanelPosition] = useState(50);
    const [waterpipesList] = useState(
        JSON.parse(localStorage.getItem(localStorageKey.WATERPIPES_LIST)) ?? []
    );
    const isFieldTypeZeroToEmptySpace = fieldType => [columnTypes.REPORT_PLUS].includes(fieldType);

    const changeZeroToEmptySpace = columnType => isFieldTypeZeroToEmptySpace(columnType);

    const getValue = (value, columnType) => {
        function isValueTheLastReading(val) {
            return isObject(val);
        }

        if (changeZeroToEmptySpace(columnType) && +value === 0) return ' ';
        if (isValueTheLastReading(value)) {
            value = value?.lastReading;
        }
        return value;
    };

    const getTranslatedTitleHead = title => ({
        symbol: tabHeadToSymbols[title],
        title: tabHeadToSymbolsToTitles[title],
    });

    const onGridReady = params => setGridApi(params.api);

    const setCellStyle = params => {
        const COLUMN_OPACITY = theme.colors.tableTabs.opacity;
        const columnType = params.colDef.field;

        const defaultStyle = {
            textAlign: 'left',
        };

        const rightAlignStyle = {
            display: 'flex',
            justifyContent: 'end',
        };

        switch (columnType) {
            case columnTypes.REPORT_DEBUG_RESET_TIME:
                return {
                    ...defaultStyle,
                    ...rightAlignStyle,
                };
            case columnTypes.REPORT_ALERT_MECHANIC_DURATION:
                return {
                    ...defaultStyle,
                    ...rightAlignStyle,
                };
            case columnTypes.REPORT_READINGS:
                return {
                    ...defaultStyle,
                    ...rightAlignStyle,
                };
            case columnTypes.REPORT_GSM_LEVEL:
                if (isValueBelow10(params.value) && typeof params.value === 'number') {
                    return { ...defaultStyle, color: 'red' };
                }
                return defaultStyle;
            case columnTypes.TAB_1:
                return {
                    ...defaultStyle,
                    backgroundColor: theme.colors.tableTabs.tab_1 + COLUMN_OPACITY,
                };
            case columnTypes.TAB_2:
                return {
                    ...defaultStyle,
                    backgroundColor: theme.colors.tableTabs.tab_2 + COLUMN_OPACITY,
                };
            case columnTypes.TAB_3:
                return {
                    ...defaultStyle,
                    backgroundColor: theme.colors.tableTabs.tab_3 + COLUMN_OPACITY,
                };
            case columnTypes.TAB_4:
                return {
                    ...defaultStyle,
                    backgroundColor: theme.colors.tableTabs.tab_4 + COLUMN_OPACITY,
                };
            case columnTypes.TAB_5:
                return {
                    ...defaultStyle,
                    backgroundColor: theme.colors.tableTabs.tab_5 + COLUMN_OPACITY,
                };
            default:
                return defaultStyle;
        }
    };

    const getExpandedReadingValue = (exactRowReportId, columnType) => {
        const isSabotageColumn = columnType === columnTypes.REPORT_SABOTAGE;
        const dataReports = generatedListData.dataReportWithPrefix;

        const reportsDataFilteredByReportId = dataReports.filter(item => {
            const dataReportId = item.report_readings && item.report_readings[0].report_id;
            return dataReportId === exactRowReportId;
        });

        const reportDataReadings = reportsDataFilteredByReportId[0]?.report_readings;

        let cellPanel = '';

        if (reportDataReadings) {
            cellPanel = document.createElement('div');
            cellPanel.style.cursor = 'pointer';
            reportDataReadings.forEach(item => {
                const pTag = document.createElement('p');
                pTag.style.margin = 0;

                isSabotageColumn ? (pTag.textContent = item.sabotage) : (pTag.textContent = item.data);

                cellPanel.appendChild(pTag);
            });
        }
        return cellPanel instanceof HTMLElement ? cellPanel.outerHTML : cellPanel;
    };

    const onCellClicked = params => {
        const isGeneratedListData = Object.keys(generatedListData).length > 0;
        const columnType = params.colDef.field;

        if (
            (columnType === columnTypes.REPORT_READINGS || columnType === columnTypes.REPORT_SABOTAGE) &&
            isGeneratedListData
        ) {
            const { reportId } = params.data.report_readings;
            const rowNode = params.node;
            const reportReadingsValue = params.data.report_readings;
            const reportReadingsValueLast = params.data.report_readings_last
                ? params.data.report_readings_last
                : getExpandedReadingValue(reportId, columnTypes.REPORT_READINGS);

            const reportSabotageValue = params.data.report_sabotage;
            const reportSabotageValueLast = params.data.report_sabotage_last
                ? params.data.report_sabotage_last
                : getExpandedReadingValue(reportId, columnTypes.REPORT_SABOTAGE);

            rowNode.setDataValue(columnTypes.REPORT_READINGS, reportReadingsValueLast);
            rowNode.setDataValue(columnTypes.REPORT_SABOTAGE, reportSabotageValueLast);

            params.data.report_readings_last = reportReadingsValue;
            params.data.report_sabotage_last = reportSabotageValue;

            if (!(typeof reportReadingsValueLast === 'string' && reportReadingsValueLast.includes('</p>'))) {
                setShowModal(true);

                if (
                    typeof reportReadingsValueLast === 'string' ? reportReadingsValueLast.trim().length : true
                ) {
                    const parsedReadingsInToHtml = new DOMParser().parseFromString(
                        reportReadingsValue,
                        'text/xml'
                    ).firstChild.childNodes;
                    const parsedSabotageInToHtml = new DOMParser().parseFromString(
                        reportSabotageValue,
                        'text/xml'
                    ).firstChild.childNodes;

                    setModalData(
                        [...parsedReadingsInToHtml]
                            .map(
                                (item, index) =>
                                    `${item.textContent}${[...parsedSabotageInToHtml][index].textContent}`
                            )
                            .join(', ')
                    );
                }
            }
        } else {
            setModalData(params.value === ' ' ? null : params.value);
            setShowModal(true);
        }
    };

    const handleSelectCommunicator = (packageId, packageCommunicator, columnType) => {
        localStorage.setItem(localStorageKey.SELECTED_PACKAGE_ID, packageId);
        localStorage.setItem(
            localStorageKey.DOCS_SEARCH_PACKAGE_ID,
            columnType === columnTypes.REPORT_PACKAGE_ID ? packageId : ''
        );
        localStorage.setItem(
            localStorageKey.DOCS_SEARCH_COMMUNICATOR,
            columnType === columnTypes.REPORT_COMMUNICATOR ? packageCommunicator : ''
        );
    };

    const cellRenderer = params => {
        const isGeneratedListData = Object.keys(generatedListData).length > 0;
        const columnType = params.colDef.field;

        if (
            (columnType === columnTypes.REPORT_READINGS || columnType === columnTypes.REPORT_SABOTAGE) &&
            isGeneratedListData &&
            params?.data?.report_readings?.reportId
        ) {
            const cellPanel = document.createElement('p');
            cellPanel.style.cursor = 'pointer';
            cellPanel.style.color = theme.colors.link;
            cellPanel.style.margin = 0;
            cellPanel.appendChild(document.createTextNode(getValue(params.value, columnType)));

            return cellPanel;
        }

        if (
            (columnType === columnTypes.REPORT_COMMUNICATOR &&
                generatedListData.dataReportWithPrefix.filter(
                    data => data.report_communicator === params.value
                )[0]?.report_package_id) ||
            (columnType === columnTypes.REPORT_PACKAGE_ID &&
                generatedListData.dataReportWithPrefix.filter(
                    data => data.report_package_id === params.value
                )[0]?.report_package_id &&
                VERSION_V2)
        ) {
            const cellPanel = document.createElement('a');
            const reportRecipient =
                params.data.report_recipient.length === 1
                    ? `0${params.data.report_recipient}`
                    : params.data.report_recipient;

            let waterpipeId = waterpipesList.filter(waterpipe => waterpipe.sign === reportRecipient)[0]?.id;

            if (!waterpipeId) {
                waterpipeId = waterpipesList.filter(waterpipe => waterpipe.sign === waterpipeWater.sign)[0]
                    ?.id;
            }

            cellPanel.href = `#/pipeline/${waterpipeId}/reports`;
            cellPanel.style.color = theme.colors.link;
            cellPanel.style.textDecoration = 'none';
            cellPanel.onclick = () =>
                handleSelectCommunicator(
                    params.data.report_package_id,
                    params.data.report_communicator,
                    columnType
                );
            cellPanel.target = '_blank';
            cellPanel.appendChild(document.createTextNode(getValue(params.value, columnType)));

            return cellPanel;
        }

        return getValue(params.value, columnType);
    };

    useEffect(() => {
        setTableHeight(
            `calc(100% - ${
                cellNumbersRef.current.clientHeight + settingsPanelRef.current.clientHeight
            }px + 1rem`
        );

        const updateWindowDimensions = () => {
            const settingsPanelHeight = settingsPanelRef.current.clientHeight;
            const newHeight = `calc(100% - ${
                cellNumbersRef.current.clientHeight + settingsPanelHeight
            }px + 1rem`;
            setTableHeight(newHeight);
            setSettingsPanelPosition(settingsPanelHeight);
        };

        window.addEventListener('resize', updateWindowDimensions);

        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    const getOrderedHeadCells = () =>
        headCells().sort(
            (a, b) => tabHeadOrderTestList.indexOf(a.label) - tabHeadOrderTestList.indexOf(b.label)
        );

    const compareNumbers = (a, b) => a - b;

    const getFilter = field => {
        if (field === columnTypes.REPORT_RECIPIENT) {
            const data = JSON.parse(localStorage.getItem(getStorageKey()));
            if (!data.waterpipeSign) {
                return 'WaterpipeFilter';
            }
            return true;
        }

        if (!field.includes(TABLE_TABS_PREFIX) && field !== columnTypes.ORDINAL_NUMBER) {
            return true;
        }
        return false;
    };

    const isNumericColumn = field =>
        field === columnTypes.REPORT_READINGS ||
        field === columnTypes.REPORT_CHANNEL ||
        field === columnTypes.REPORT_GSM_CHANNEL ||
        field === columnTypes.REPORT_GSM_LEVEL ||
        field === columnTypes.REPORT_GSM_LEVEL_FIRST ||
        field === columnTypes.REPORT_QUALITY ||
        field === columnTypes.REPORT_TEMPERATURE;

    const renderColumns = () =>
        columnsDefs?.map((column, index) => (
            <AgGridColumn
                key={index}
                headerName={column.headerName}
                headerClass={column.headerClass}
                width={column.width}
                cellRenderer={
                    (column.field === columnTypes.REPORT_READINGS ||
                        column.field === columnTypes.REPORT_SABOTAGE ||
                        column.field === columnTypes.REPORT_COMMUNICATOR ||
                        column.field === columnTypes.REPORT_PACKAGE_ID) &&
                    cellRenderer
                }
                headerTooltip={column.headerTooltip}
                tooltipValueGetter={params => params.colDef.headerTooltip}
                field={column.field}
                cellStyle={setCellStyle}
                hide={column.hide}
                filter={getFilter(column.field)}
                filterParams={column.field === columnTypes.REPORT_RECIPIENT ? { waterpipes } : {}}
                sortable={
                    !column.field.includes(TABLE_TABS_PREFIX) && column.field !== columnTypes.ORDINAL_NUMBER
                }
                resizable
                comparator={isNumericColumn(column.field) && compareNumbers}
                autoHeight={column.field === columnTypes.REPORT_READINGS}
                valueGetter={params => {
                    if (column.field === columnTypes.ORDINAL_NUMBER) {
                        return params.node.rowIndex + 1;
                    }
                    if (
                        column.field === columnTypes.REPORT_READINGS &&
                        params.data[column.field]?.lastReading
                    ) {
                        return params.data[column.field].lastReading;
                    }

                    return params.data[column.field];
                }}
            />
        ));

    const changePaginationSize = event => {
        setPaginationSize(event.target.value);
    };

    useEffect(() => {
        localStorage.setItem(localStorageKey.PAGINATION_SIZE_TEST_LIST, paginationSize);
    }, [paginationSize]);

    const changeRowStyle = params => {
        params.api.refreshCells();
        gridApi.redrawRows();
    };

    const loadDefaultColumnSettings = () => {
        const columnsWidth = {
            [columnTypes.REPORT_RECEIVED_AT]: 145,
            [columnTypes.ORDINAL_NUMBER]: 60,
            [columnTypes.TAB_1]: 60,
            [columnTypes.TAB_2]: 60,
            [columnTypes.TAB_3]: 60,
            [columnTypes.TAB_4]: 60,
            [columnTypes.TAB_5]: 60,
            [columnTypes.REPORT_READINGS]: 100,
        };

        let colDefs = JSON.parse(localStorage.getItem(localStorageKey.COLUMNS_DEFS_TEST_LIST));

        if (!colDefs?.length) {
            colDefs = getOrderedHeadCells().map(column => ({
                field: column.label,
                headerName: getTranslatedTitleHead(column.label).symbol,
                headerTooltip: getTranslatedTitleHead(column.label).title,
                hide: false,
                width: columnsWidth[column.label] ?? 90,
                headerClass:
                    column.label.includes(TABLE_TABS_PREFIX) &&
                    `cell-header-tabs cell-header-${column.label}`,
            }));
        }

        setColumnsDefs(colDefs);
    };

    const saveColumnSettings = params => {
        localStorage.setItem(localStorageKey.COLUMNS_DEFS_TEST_LIST, JSON.stringify(params));
        setColumnsDefs(params);
    };

    useEffect(() => {
        if (headCells().length) {
            loadDefaultColumnSettings();
        }
    }, [headCells]);

    useEffect(() => {
        if (gridApi) {
            if (isFetching) {
                gridApi.showLoadingOverlay();
            } else if (
                !isFetching &&
                (!Object.keys(generatedListData).length ||
                    (!generatedListData?.dataAlarmWithPrefix?.length &&
                        !generatedListData?.dataReportWithPrefix?.length))
            ) {
                gridApi.showNoRowsOverlay();
            } else {
                gridApi.hideOverlay();
            }
        }
    }, [gridApi, generatedListData, isFetching]);

    const onColumnChange = event => {
        if (
            gridApi &&
            ((event?.type === columnEventTypes.resize && event?.finished) ||
                event?.type === columnEventTypes.move)
        ) {
            saveColumnSettings(gridApi.columnModel.getColumnDefs());
        }
    };

    const setColumnHide = params => {
        if (gridApi) {
            params.forEach(column =>
                gridApi.columnModel.columnApi.setColumnVisible(column.field, !column.hide)
            );
            saveColumnSettings(params);
        }
    };

    const defaultExportParams = {
        processCellCallback: params => {
            const newValue = getValue(params.value);
            if (typeof newValue === 'string' && newValue.indexOf('</p>') !== -1) {
                return newValue.split('</p>').map(item => item.substring(item.lastIndexOf('>') + 1))[0];
            }
            return newValue;
        },
    };

    const handleReturn = () => {
        setShowModal(false);
    };

    const getStorageKey = () =>
        version === testListVersion.V2
            ? localStorageKey.GENERATED_TEST_LIST_DATA_V2
            : localStorageKey.GENERATED_TEST_LIST_DATA;

    const getRowData = () =>
        VERSION_V2
            ? generatedListData?.dataReportWithPrefix
            : stableSort(getSorTableData(), getComparator(order, orderBy));

    return (
        <div style={{ ...styles.root, height: tableHeight }}>
            <SettingsPanel
                ref={settingsPanelRef}
                columnsDefs={columnsDefs}
                setColumnHide={setColumnHide}
                gridApi={gridApi}
                listType={listTypes.TEST_LIST}
                settingsPanelPosition={settingsPanelPosition}
                listVersion={version}
                hideFilters
                isFetching={isFetching}
            />
            <ListConfigHeader ref={cellNumbersRef} storageKey={getStorageKey()} />
            <div className={'ag-theme-alpine'} style={styles.table}>
                {!VERSION_V2 && showModal && <CopyModal modalData={modalData} handleReturn={handleReturn} />}
                <AgGridReact
                    localeText={tableTranslations}
                    onSortChanged={changeRowStyle}
                    onFilterChanged={changeRowStyle}
                    getRowStyle={params => isColumnHighlighted(params.node.rowIndex)}
                    rowData={getRowData()}
                    onCellClicked={!VERSION_V2 && onCellClicked}
                    onGridReady={onGridReady}
                    suppressCellSelection
                    pagination
                    paginationPageSize={paginationSize}
                    onColumnResized={onColumnChange}
                    onColumnMoved={onColumnChange}
                    defaultCsvExportParams={defaultExportParams}
                    tooltipShowDelay={0}
                    enableCellTextSelection
                    suppressDragLeaveHidesColumns
                    suppressColumnMoveAnimation
                    accentedSort
                    suppressNoRowsOverlay={!isFetching}
                    frameworkComponents={{ WaterpipeFilter }}>
                    {renderColumns()}
                </AgGridReact>
                <PaginationRows
                    paginationSizes={PAGINATION_SIZES}
                    paginationSize={paginationSize}
                    changePaginationSize={changePaginationSize}
                />
                {isErrorLoadingData && (
                    <p style={styles.errorMessage}>{tableTranslations.errorLoadingData}</p>
                )}
            </div>
        </div>
    );
}
