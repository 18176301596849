import axios from 'axios';
import { getToken } from './helpers';

export const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.request.use(
    config => {
        config.headers.Authorization = `Bearer ${getToken()}`;
        return config;
    },
    error => {
        console.error('errorInterceptors', error);
    }
);

export const cancelToken = axios.CancelToken;
export const cancelHttpReq = cancelToken.source();
