import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import theme from '../../../themes/theme';

const styles = {
    paginationButton: {
        background: 'none',
        borderStyle: 'solid',
        borderColor: `${theme.colors.border}`,
        borderWidth: '1px',
        borderRadius: '4px',
        padding: 0,
        height: '30px',
        width: '30px',
        marginRight: '0,4rem',

        '&:hover': {
            backgroundColor: `${theme.colors.panelBackground}`,
            cursor: 'pointer',
        },
    },
};

const PaginationButton = ({ classes, children, handlePageChange, color }) => {
    const [paginationColor, setPaginationColor] = useState({
        background: 'none',
    });

    useEffect(() => {
        if (color === 'selected') {
            setPaginationColor({
                background: `${theme.colors.panelBackground}`,
            });
        } else {
            setPaginationColor({ background: 'none' });
        }
    }, [color]);

    return (
        <button onClick={handlePageChange} className={classes.paginationButton} style={paginationColor}>
            {children}
        </button>
    );
};

export default withStyles(styles)(PaginationButton);
