/* eslint-disable chai-friendly/no-unused-expressions */
import React from 'react';
import { withStyles } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { selectStrings } from '../../../utils/constants';
import theme from '../../../themes/theme';

const styles = {
    autocompleteField: {
        margin: `${theme.margins.textField.top} ${theme.margins.textField.all}`,
        flexGrow: 1,
        minWidth: 350,
        '& div.MuiInputBase-root': {
            paddingBottom: 2,
        },
        '& div.MuiInputBase-root .MuiInputBase-input': {
            marginTop: -4,
            paddingBottom: 8,
        },
        '& label.MuiInputLabel-root': {
            marginTop: -6,
        },
    },
    boxItem: {
        width: 550,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
};

const FavoriteReportsList = ({
    classes: { autocompleteField, boxItem },
    favoriteReports,
    loadFavoriteReport,
    selectedFavoriteReport,
    setSelectedFavoriteReport,
}) => {
    const DROPDOWN_LIST_HEIGHT = 400;

    const onChange = (event, newValue) => {
        setSelectedFavoriteReport(newValue);
        newValue && loadFavoriteReport(newValue);
    };

    return (
        <Autocomplete
            className={autocompleteField}
            options={favoriteReports}
            getOptionLabel={option => option.name}
            value={selectedFavoriteReport || null}
            onChange={onChange}
            renderInput={params => (
                <TextField
                    {...params}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Ulubione zestawienia"
                    placeholder="Wybierz ulubione zestawienie z listy..."
                    variant="standard"
                />
            )}
            noOptionsText={selectStrings.noOptionsAvailable}
            clearText={selectStrings.clearText}
            closeText={selectStrings.closeText}
            openText={selectStrings.openText}
            ListboxProps={{
                style: {
                    maxHeight: DROPDOWN_LIST_HEIGHT,
                },
            }}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.name, inputValue, {
                    requireMatchAll: true,
                });
                const parts = parse(option.name, matches);

                return (
                    <li {...props} key={option.id}>
                        <div className={boxItem}>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight
                                            ? theme.font.weight.bold
                                            : theme.font.weight.normal,
                                    }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    );
};

export default withStyles(styles)(FavoriteReportsList);
