import React, { useEffect, useState } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import theme from '../../../themes/theme';

const styles = {
    field: {
        margin: `${theme.margins.textField.top} ${theme.margins.textField.all} 0 ${theme.margins.textField.all}`,
        width: `calc(100% - 2 * ${theme.margins.textField.all})`,
        minWidth: 200,
        '& div.MuiInputBase-root': {
            paddingBottom: 2,
        },
        '& div.MuiInputBase-root .MuiInputBase-input': {
            marginTop: -4,
            paddingBottom: 8,
        },
        '& label.MuiInputLabel-root': {
            marginTop: -6,
        },
    },
};

const FavoriteReportName = ({ favoriteReportName, setFavoriteReportName, classes: { field } }) => {
    const MAX_NAME_LENGTH = 200;
    const [helperMessage, setHelperMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const changeName = e => {
        setFavoriteReportName(e.target.value);
    };

    const handleError = error => {
        setIsError(error);
        if (error) {
            setHelperMessage('Nazwa zestawienia powinna zawierać maksymalnie 200 znaków');
        } else {
            setHelperMessage('');
        }
    };

    useEffect(() => {
        if (favoriteReportName.length >= MAX_NAME_LENGTH) {
            handleError(true);
        } else {
            handleError(false);
        }
    }, [favoriteReportName]);

    return (
        <TextField
            label="Nazwa zestawienia"
            placeholder="Wprowadź nazwę zestawienia..."
            type="text"
            className={field}
            InputLabelProps={{
                shrink: true,
            }}
            helperText={helperMessage}
            onChange={changeName}
            value={favoriteReportName}
            error={isError}
        />
    );
};

export default withStyles(styles)(FavoriteReportName);
