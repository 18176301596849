import React from 'react';
import { withStyles } from '@material-ui/core';
import { SingleSelect } from 'react-select-material-ui';
import theme from '../../themes/theme';
import { communicatorsChannel } from '../../utils/constants';

const styles = {
    container: {
        width: '5rem',
        padding: '1rem',
        fontFamily: theme.font.family,
        fontSize: 16,
        zIndex: 3,
    },
};

const CommunicatorChannelList = ({ classes: { container }, channel, setChannel }) => {
    const communicatorChannelList = [communicatorsChannel.ONE, communicatorsChannel.TWO];

    return (
        <div className="App">
            <SingleSelect
                key="channel"
                label="Kanał"
                value={String(channel)}
                options={communicatorChannelList}
                onChange={setChannel}
                className={container}
            />
        </div>
    );
};

export default withStyles(styles)(CommunicatorChannelList);
