/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import NavBar from '../Nav/NavBar';
import { AuthComponentProvider } from '../../context/AuthComponentContext';
import { getPresentYear } from '../../utils/helpers';
import { reportTypes } from '../../utils/constants';

const styles = {
    container: {
        width: '100vw',
        height: '100vh',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    nav: {
        height: '2.6rem',
        width: '100vw',
        zIndex: 999,
    },
    sectionContainer: {
        width: '99%',
        flex: 2,
        marginBottom: '3rem',
    },
    footer: {
        position: 'fixed',
        bottom: '0',
        marginTop: '2rem',
        background: '#f5f5f5',
        height: '2.6rem',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.52), 0px 4px 5px 0px rgba(0,0,0,0.54), 0px 1px 10px 0px rgba(0,0,0,0.46)',
        zIndex: '100000',
    },
};

const AuthComponent = ({ classes, history, token, component: Component, ...rest }) => {
    const [isSubmenuOpen, setIsSubmenuOpen] = useState([false]);
    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [isFooterVisible, setIsFooterVisible] = useState(true);
    const location = useLocation();

    const PATH_NAMES = [
        'list-templates',
        'test-ordered-list',
        reportTypes.REPORTS,
        reportTypes.ALARMS,
        reportTypes.MINUTE_REPORTS,
        reportTypes.CONFIGS,
        reportTypes.SUMMARIES,
        reportTypes.CON_MAPS,
        reportTypes.CON_CONFIGS,
    ];

    useEffect(() => {
        if (location.pathname === '/test-generated-list' || location.pathname === '/test-generated-list-v2') {
            setIsNavbarVisible(false);
            setIsFooterVisible(false);
        } else if (PATH_NAMES.some(path => location.pathname.includes(path))) {
            setIsNavbarVisible(true);
            setIsFooterVisible(false);
        } else {
            setIsNavbarVisible(true);
            setIsFooterVisible(true);
        }
    }, [location]);

    return (
        <AuthComponentProvider value={{ setIsSubmenuOpen }}>
            <div className={classes.container}>
                {isNavbarVisible && (
                    <nav className={classes.nav}>
                        <NavBar history={history} />
                    </nav>
                )}

                <section className={classes.sectionContainer}>
                    <Component {...rest} history={history} />
                </section>

                {isFooterVisible && (
                    <footer className={classes.footer}>
                        <Typography variant="overline">{`© ${getPresentYear()} PronAqua`}</Typography>
                    </footer>
                )}
            </div>
        </AuthComponentProvider>
    );
};

export default withStyles(styles)(AuthComponent);
