import React, { Component } from 'react';
import TitleHeader from '../TitleHeader/TitleHeader';
import Statistics from '../Statistics/Statistics';

class Home extends Component {
    render() {
        return (
            <div>
                <TitleHeader title={'Statystyka'} />
                <Statistics />
            </div>
        );
    }
}

export default Home;
