import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

const styles = {
    buttonCount: {
        width: '10.5rem',
    },
    displayCounted: {
        whiteSpace: 'nowrap',
    },
    counterContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        marginLeft: '1rem',
    },
    countedPackagesParagraph: {
        fontSize: '1rem',
    },
};

const CountButtonResult = ({ getCountedAmountPackages, isCounting, countedList, classes }) => (
    <div className={classes.counterContainer}>
        <Button
            variant="contained"
            color="primary"
            onClick={getCountedAmountPackages}
            className={classes.buttonCount}>
            liczba pakietów
        </Button>
        <div className={classes.displayCounted}>
            {isCounting && <p className={classes.countedPackagesParagraph}>{countedList}</p>}
        </div>
    </div>
);

export default withStyles(styles)(CountButtonResult);
