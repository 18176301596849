/* eslint-disable import/prefer-default-export */
export const messages = {
    package_id: 'ID przyjęcia',
    received_at: 'Data i godzina przyjęcia',
    date: 'Data',
    communicator: 'Nr nakładki',
    flows: 'Przepływy',
    recipient: 'Znak rozdzielnicy',
    reading: 'Stan licznika na godz powstania alarmu [impulsy]',
    gsm_channel: 'Nr kanału transmisyjnego',
    gsm_buffer: 'Ilość wszystkich raportów w buforze',
    gsm_buffer_all: 'Ilość raportów minutowych w buforze',
    gsm_level: 'Poziom sygnału GSM',
    gsm_level_first: 'Poziom GSM przy pierwszej próbie wysłania bieżącego raportu',
    gsm_count_starts: 'Licznik sesji GSM',
    gsm_count_communications: 'Ilość poprawnych komunikacji przez moduł GSM/LTE',
    gsm_uptime: 'Czas włączenia modułu GSM/LTE w komunikatorze [min]',
    gsm_band: 'Pasmo częstotliwościowe komunikacji z siecią GSM',
    gsm_firmware: 'Wersja firmware',
    gsm_imei: 'IMEI',
    gsm_imsi: 'IMSI',
    gsm_iccid: 'ICCID',
    readings: 'Przyrost i kierunek przeplywu',
    channel: 'Nr kanalu pomiarowego',
    quality: 'Licznik dobowego przepływu wstecznego',
    alert_mechanic_prealerts: 'Ilość prealarmów sabotażu mechanicznego',
    alert_mechanic_count: 'Dobowy Licznik ilości sabotażu mechanicznego',
    alert_mechanic_duration: 'Łączny czas trwania sabotażu mechanicznego [s]',
    alert_magnetic_prealert: 'Ilość prealarmów sabotażu magnetycznego',
    alert_magnetic_count: 'Dobowy Licznik ilości sabotażu magnetycznego',
    alert_magnetic_duration: 'Łączny czas trwania sabotażu magnetycznego [s] / Prąd pomierzony przez płytkę pomiarową [mAh]',
    registration_time_current: 'Czas rejestracji bieżący',
    registration_time_prev_1: 'Czas rejestracji poprzedni 1',
    registration_time_prev_2: 'Czas rejestracji poprzedni 2',
    registration_time_prev_3: 'Czas rejestracji poprzedni 3',
    registration_time_prev_4: 'Czas rejestracji poprzedni 4',
    battery: 'Stan baterii koncentratora 0 - 100 [%]',
    voltage: 'Napięcie baterii koncentratora',
    temperature: 'Temperatura koncentratora',
    learning: 'Stan "uczenia się"',
    inductor_1_quality: 'Jakość sygnału cewki 1',
    inductor_2_quality: 'Jakość sygnału cewki 2',
    inductor_3_quality: 'Jakość sygnału cewki 3',
    used: 'Przeznaczenie koncentratora: antena świnka, PCB, konektor, zalany, itp.',
    firmware: 'Firmware ID koncetratora',
    radio_battery: 'Zużycie baterii nadajnika radiowego [mAh]',
    radio_voltage: 'Napięcie baterii nadajnika radiowego: U = x * 0,05 V',
    radio_voltage_stabilized: 'Napięcie stabilizowane nadajnika radiowego: U = x * 0,05 V',
    radio_temperature: 'Napięcie stabilizowane nadajnika radiowego: U = x * 0,05 V',
    radio_uptime: 'Czas włączenia modułu radiowego w koncentratorze [min]',
    radio_signal_strength: 'Siła sygnału  nadajnika radiowego',
    radio_signal_strength_dbm: 'Poziom sygnału radiowego koncentratora widziany przez nadajnik [dBm]',
    radio_last_report: 'Data ostatniego odczytu nadajnika w koncentratorze format BCD',
    radio_used: 'Przeznaczenie nadajnika: antena ceramiczna, PCB..., zalany, niezalany...',
    radio_firmware: 'Firmware ID nadajnika radiowego',
    radio_sabotage: 'Przepływ / stan sabotaży / godzinowy procentowy czas aktywności CPU w nadajniku radiowym',
    document_type: 'Typ dokumentu',
    config_statuses: 'Opis Statusów konfiguracji',
    debug_statuses: 'Opis Statusów debugowych',
    debug_reset_time: 'Opis Statusów debugowych - czas od ostatniego resetu',
    alert_id: 'ID raportu alarmowego',
    alert_type: 'Przyczyna alarmu',
    alert_count:
        'Ilość wystąpień podsumowywanego alarmu pomiędzy wygenerowaniem raportu alarmowego i bieżącego podsumowania',
    alert_duration:
        'Łączny czas występowania alarmów od wygenerowania raportu alarmowego do utworzenia bieżącego podsumowania [min]',
    alert_1_date: 'Data wystąpienia alarmu 1',
    alert_1_duration: 'Czas trwania alarmu 1 [sek]',
    alert_2_date: 'Data wystąpienia alarmu 2',
    alert_2_duration: 'Czas trwania alarmu 2 [sek]',
    alert_3_date: 'Data wystąpienia alarmu 3',
    alert_3_duration: 'Czas trwania alarmu 3 [sek]',
    alert_end_date: 'data/czas ustąpienia ostatniego sabotażu',
    alert_count_mechanic: 'Licznik ilości raportów alarmu mechanicznego',
    alert_count_magnetic: 'Licznik ilości raportów alarmu magnetycznego',
    alert_count_on_demand: 'Licznik ilości raportów na żądanie',
    alert_prealerts_today: 'Ilość prealarmów od początku doby',
    user_id: 'ID użytkownika. Wartość 0 oznacza brak ID.',
    sabotage: 'Stan sabotaży',
    ip1: 'IP1',
    ip2: 'IP2',
    ip3: 'IP3',
    ip4: 'IP4',
    port1: 'Port 1',
    port2: 'Port 2',
    port3: 'Port 3',
    port4: 'Port 4',
    gsm_roaming: 'Zezwolenie na Roaming',
    apn_name: 'APN Name',
    apn_user: 'APN User',
    apn_password: 'APN Password',
    sms_wait: 'Wait For SMS Month Days',
    phone1: 'Tel Number 1',
    phone2: 'Tel Number 2',
    phone3: 'Tel Number 3',
    sms_center: 'SMS Center Number',
    rx_phone1: 'Rx Tel Number 1',
    rx_phone2: 'Rx Tel Number 2',
    waterworks_number: 'Numer Kolejny Wodociągowy - NKW',
    dest_type: 'Typ anteny (Przeznaczenie licznka)',
    watermeter_type: 'Typ wodomierza',
    input_type: 'Konfiguracja wejść / cewek',
    output_type: 'Typ / rodzaj transmisji modułu komórkowego / LoRa',
    communication_type: 'Typ komunikacji',
    communication_per_day: 'Rezerwa',
    normal_report_period: 'Czas pomiędzy próbami wysłania raportu zwykłego lub podsumowującego [h]',
    normal_report_attempt: 'Ilość prób wysłania raportu zwykłego lub podsumowującego',
    alarm_report_period: 'Czas pomiędzy próbami wysłania raportu alarmowego [h]',
    alarm_report_attempt: 'Ilość prób wysłania raportu alarmowego',
    stable_non_active_time_sab_mechanical:
        'Czas stabilnej nieaktywności przed końcem doby sabotażu otwarcia pokrywki',
    stable_non_active_time_sab_mechanical_2:
        'Czas stabilnej nieaktywności przed końcem doby sabotażu oderwania od ściany',
    sab_stable_check_time_mechanical: 'Czas na stabilizację wejścia sabotażu otwarcia pokrywki',
    sab_stable_check_time_mechanical_2: 'Czas na stabilizację wejścia sabotażu oderwania od ściany',
    sab_stable_check_time_magnetic: 'Czas oczekiwanie na ustabilizowanie się wejścia sabotażowego magnetycznego [h]',
    min_impulse_length: 'Minimalna długość impulsu [ms]',
    virtual_concentrator_number: 'Numer koncentratora wirtualnego',
    virtual_window_number: 'Numer okna wirtualnego (jednego ze 120)',
    virtual_concentrator_quantity: 'Ilość koncentratorów wirtualnych na koncentratorze fizycznym',
    transmitter_quantity: 'Ilość monitorowanych nadajników',
    transmitters: 'Dane urządzeń',
    gsm_battery_voltage: 'Napięcie baterii koncentratora: U = x * 0,05 V',
    regular_battery_voltage: 'Napięcie baterii zwykłej koncentratora: U = x * 0,05 V',
    external_voltage: 'Napięcie zewnętrzne koncentratora: U = x * 0,05 V',
    sabotage_entry_status: 'Statusy wejść sabotażowych',
    h_position: 'Pozycja H',
    v_position: 'Pozycja V / Dobowy czas aktywności CPU [%]',
    altitude: 'Wysokość [m] / Prąd zliczony przez płytkę pomiarową [Ah]',
    number: 'Nr nadajnika radiowego',
    communication_status: 'Status komunikacji z nadajnikiem',
    communication_attempts: 'Liczba prób komunikacji pozostała do wylogowania',
    radio_concentrator_signal: 'Poziom sygnału radio nadajnika widziany przez koncentrator [-dBm]',
    radio_transmitter_signal: 'Poziom sygnału radio koncentratora widziany przez nadajnik [-dBm]',
    lte_bts_band: 'Info o pasmie LTE i nr BTSa / Operator sieci komórkowej',
    module_type: 'Rodzaj transmisji / Typ modułu komórkowego',
    gsm_band_cat_m1: 'Pasma LTE pracy w trybie CatM1',
    gsm_band_cat_nb: 'Pasma LTE pracy w trybie NB',
    activity_index: 'Indeksy aktywności modułu LoRa',
    activity_period: 'Liczba okien czasowych',
    mmc_mnc_mim_card_operator_code: "Kod  MCC-MNC operatora karty MIM",
    mmc_mnc_mim_card_operator_code_at_report_sending: "Kod MCC-MNC operatora komórkowego w czasie wysyłania raportu",
    battery_info: "Typ / producent baterii",
    battery_capacity: "Pojemność baterii [mAh]",
    supercapacitor_info: "Typ / producent superkondensatora",
    supercapacitor_capacity: "Pojemność superkondensatora [mAh]",
    service_data: "Serwis - data",
    services_count: "Ilość wszystkich serwisów",
    info1: "Pole informacyjne 1",
    info2: "Pole informacyjne 2",
    info3: "Pole informacyjne 3",
    info4: "Pole informacyjne 4",
    info5: "Pole informacyjne 5",
    info6: "Pole informacyjne 6",
    info7: "Pole informacyjne 7",
    info8: "Pole informacyjne 8",
    info9: "Pole informacyjne 9",
    info10: "Pole informacyjne 10",
};
