import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import Snackbar from '../../Snackbar/Snackbar';
import { axiosInstance } from '../../../utils/axiosInstance';
import { endpoint, labels, snackbarMsg, snackbarVariant } from '../../../utils/constants';
import EncryptV11 from './EncryptV11/EncryptV11';

const styles = {
    livePreviewContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    livePreviewInput: {
        width: '90vw',
    },
    buttonSend: {
        margin: '1rem',
        width: '20vw',
        background: 'green',
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
};

class TestField extends Component {
    state = {
        value: '',
        snackbarData: {
            isSnackbarOpen: false,
            message: '',
            errorInfo: '',
            variant: '',
        },
        isCleared: false,
    };

    sendData = () => {
        axiosInstance
            .post(endpoint.TEST_PACKAGE, {
                sim: '500000000',
                data: this.state.value,
            })
            .then(() => {
                this.handleSnackbar(true, snackbarMsg.PACKAGE_HAS_BEEN_SENT, '', snackbarVariant.SUCCESS);
            })
            .catch(err => {
                console.error('errTestField', err);
                this.handleSnackbar(true, snackbarMsg.ERROR, err.message, snackbarVariant.ERROR);
            });
    };

    handleIsSnackbarOpen = () => {
        this.setState(state => ({
            snackbarData: {
                isSnackbarOpen: false,
                message: state.snackbarData.message,
                errorInfo: state.snackbarData.errorInfo,
                variant: state.snackbarData.variant,
            },
        }));
    };

    handleChange = e => {
        this.setState({ value: e.target.value.trim() });
    };

    handleResetFieldsButton = () => {
        this.setState({
            value: '',
        });
    };

    handleEncryptedV11Value = value => {
        this.setState({ value: value.trim() });
    };

    handleSnackbar = (isSnackbarOpen, message, errorInfo, variant) => {
        this.setState({
            snackbarData: {
                isSnackbarOpen,
                message,
                errorInfo,
                variant,
            },
        });
    };

    render() {
        const { classes, isV11Selected } = this.props;
        const { snackbarData, value } = this.state;

        return (
            <article className={classes.livePreviewContainer}>
                {isV11Selected && (
                    <EncryptV11
                        handleEncryptedV11Value={this.handleEncryptedV11Value}
                        handleSnackbar={this.handleSnackbar}
                    />
                )}
                <TextField
                    label={isV11Selected ? labels.PASTE_ENCRYPTED_PACKAGE_DATA : labels.PASTE_PACKAGE_DATA}
                    multiline={true}
                    minRows={2}
                    maxRows={4}
                    variant="outlined"
                    value={value}
                    onChange={this.handleChange}
                    className={classes.livePreviewInput}
                    data-cy="test-package"
                />
                <div className={classes.buttonsContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.sendData}
                        className={classes.buttonSend}>
                        WYŚLIJ WKLEJONĄ TREŚĆ PAKIETU
                    </Button>
                    <Button variant="contained" color="secondary" onClick={this.handleResetFieldsButton}>
                        reset
                    </Button>
                </div>

                <Snackbar snackbarData={snackbarData} handleIsSnackbarOpen={this.handleIsSnackbarOpen} />
            </article>
        );
    }
}

export default withStyles(styles)(TestField);
