import React, { Component } from 'react';
import { withStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import TableWrapper from '../Tables/TableWrapper';
import AutocompleteBar from '../Tables/AutocompleteBar';
import {
    changeDiacriticToStandard,
    sortItemsAlphabetically,
    capitalize,
    cloneObjByJSON,
    errorHandling,
    getBooleanFromString,
    getToken,
    removeLocalStorageItems,
} from '../../utils/helpers';
import { axiosInstance, cancelToken } from '../../utils/axiosInstance';
import { PackagesProvider } from '../../context/PackagesContext';
import CountPackages from '../CountPackages/CountPackages';
import FilterPackages from '../FilterPackages/FilterPackages';
import PopupModal from '../PopupModal/PopupModal';
import {
    endpoint,
    filterStatuses,
    filterStatusesNoDiacritic,
    localStorageKey,
    reportTypes,
    waterpipesAll,
} from '../../utils/constants';
import TitleHeader from '../TitleHeader/TitleHeader';

const styles = {
    searchBarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingBottom: '.5rem',
    },
    buttonRefresh: {
        marginRight: '2rem',
        minWidth: '100px',
    },
    buttonsRefreshCountPackagesContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '50%',
        transform: 'translateX(-50%)',
        width: '19rem',
        alignItems: 'center',
    },
    resetFieldsButton: {
        position: 'relative',
        top: '.9rem',
        marginLeft: '1.8rem',
        marginBottom: '.9rem',
    },
    checkboxContainer: {
        position: 'relative',
        top: '.9rem',
        marginLeft: '.4rem',
        marginBottom: '.5rem',
    },
    checkbox: {
        paddingRight: '.25rem',
    },
};

class Packages extends Component {
    state = {
        loading: false,
        statuses: [
            filterStatuses.ALL,
            filterStatuses.NEW,
            filterStatuses.PENDING,
            filterStatuses.FINISHED,
            filterStatuses.FINISHED_OFF,
            filterStatuses.FINISHED_WITHOUT_OFF,
            filterStatuses.PARSER_ERROR,
            filterStatuses.DATE_ERROR,
            filterStatuses.GATEWAY_ERROR,
            filterStatuses.PIPELINE_ERROR,
            filterStatuses.PROTOCOL_ERROR,
            filterStatuses.DUPLICATES,
            filterStatuses.NOT_PARSED,
            filterStatuses.DECRYPTION_ERROR,
        ],
        protocolsToSelect: [],
        waterpipes: [],
        page: 1,
        chunk: 100,
        protocol: '',
        content: {
            data: [],
            headers: {},
            communicator: [],
        },
        searchFields: {
            id: '',
            sms: '',
            sim: '',
            dok: '',
            com: '',
        },
        selectedWaterpipe: '',
        selectedStatus: '',
        isServerError: false,
        clearSearchField: false,
        isComponentMounted: false,
        isDuplicateChecked: true,
        isShowOnlyOfflineChecked: true,
        isShowOnlyOfflineDisabled: true,
        liveObjData: {
            isOfflinePackageFound: false,
            text: '',
            isCheckClicked: false,
        },
    };

    componentDidMount() {
        if (!getToken()) {
            return <Redirect to="/login" />;
        }

        this.cancelHttpReq = cancelToken.source();
        this.setStateFromLocalStorage();
        removeLocalStorageItems(['isParserErrorLinkClicked']);
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedWaterpipe } = this.state;
        if (prevState.selectedWaterpipe !== selectedWaterpipe) {
            this.handleStatusNewRemoved();
        }
    }

    getStatuses = () => ({
        nowe: 'new',
        oczekujace: 'pending',
        zakonczone: 'done',
        zakonczoneOff: 'done_off',
        zakonczoneBezOff: 'done_wo_off',
        bladParsowania: 'parser_error',
        bladDaty: 'date_error',
        bladBramki: 'untrusted_gateway',
        bladWodociagu: 'non_affiliated_waterpipe',
        bladProtokolu: 'bad_protocol',
        duplikaty: 'duplicate',
        niesparsowane: 'not_parsed',
        wszystkie: 'all',
        bladDekodowania: 'decrypt_error',
    });

    getPackagesData = () => {
        const {
            page,
            chunk,
            protocol,
            selectedWaterpipe,
            selectedStatus,
            searchFields: { id, sms, dok, sim, com },
            isDuplicateChecked,
            isShowOnlyOfflineChecked,
        } = this.state;
        this.toggleLoading(true);

        const selectWaterpipes = () => {
            if (selectedWaterpipe === waterpipesAll.name) {
                return '';
            }
            return selectedWaterpipe;
        };
        axiosInstance
            .get(endpoint.PACKAGES, {
                params: {
                    chunk,
                    page,
                    waterpipe: selectWaterpipes(),
                    id,
                    data: sms,
                    document_type: dok === reportTypes.ALL ? '' : dok,
                    sim,
                    communicator: com,
                    status: selectedStatus,
                    protocol,
                    duplicate_hide: isDuplicateChecked,
                    offline: isShowOnlyOfflineChecked ? 2 : null,
                },

                cancelToken: this.cancelHttpReq.token,
            })
            .then(content => {
                this.setState({
                    loading: false,
                    waterpipes: content.data.filters.waterpipes.data,
                    protocolsToSelect: this.getProtocolsTypeArr(content.data.filters.protocols.data),
                    content: {
                        headers: content.data.headers,
                        data: content.data.data,
                    },
                    isComponentMounted: true,
                });
            })
            .catch(err => {
                errorHandling(err, this);
            });
    };

    handleFilterPackages = (phrase, searchFieldType, isEnterPressed) => {
        localStorage.setItem(`packagesSearch${capitalize(searchFieldType)}`, phrase);
        this.setState(
            state => this.changeSearchFields(searchFieldType, phrase, state),
            () => {
                if (isEnterPressed) {
                    this.getPackagesData();
                }
            }
        );
    };

    handleChangePage = page => {
        localStorage.setItem(localStorageKey.PACKAGES_PAGE, page);
        this.setState({ page }, this.getPackagesData);
    };

    handleStatusFilter = filterValue => {
        localStorage.removeItem(localStorageKey.PACKAGES_PAGE);
        if (getBooleanFromString(localStorage.getItem(localStorageKey.IS_SHOW_ONLY_OFFLINE_CHECKED))) {
            this.setState({ isShowOnlyOfflineChecked: true });
        }

        if (filterValue === 'Zakończone OFF') {
            const tempIsShowOnlyOfflineChecked = getBooleanFromString(
                localStorage.getItem(localStorageKey.IS_SHOW_ONLY_OFFLINE_CHECKED)
            );
            this.setState(
                {
                    selectedStatus: 'done_off',
                    page: 1,
                    isShowOnlyOfflineChecked: false,
                    isShowOnlyOfflineDisabled: true,
                },
                () => {
                    localStorage.setItem(localStorageKey.PACKAGES_SELECTED_STATUS, this.state.selectedStatus);
                    localStorage.setItem(localStorageKey.PACKAGES_SELECTED_STATUS_NAME, filterValue);
                    if (tempIsShowOnlyOfflineChecked) {
                        localStorage.setItem(localStorageKey.IS_SHOW_ONLY_OFFLINE_CHECKED, true);
                    }
                    this.getPackagesData();
                }
            );
        } else if (filterValue === 'Zakończone bez OFF') {
            const tempIsShowOnlyOfflineChecked = getBooleanFromString(
                localStorage.getItem(localStorageKey.IS_SHOW_ONLY_OFFLINE_CHECKED)
            );

            this.setState(
                {
                    selectedStatus: 'done_wo_off',
                    page: 1,
                    isShowOnlyOfflineChecked: false,
                    isShowOnlyOfflineDisabled: true,
                },
                () => {
                    localStorage.setItem(localStorageKey.PACKAGES_SELECTED_STATUS, this.state.selectedStatus);
                    localStorage.setItem(localStorageKey.PACKAGES_SELECTED_STATUS_NAME, filterValue);
                    if (tempIsShowOnlyOfflineChecked) {
                        localStorage.setItem(localStorageKey.IS_SHOW_ONLY_OFFLINE_CHECKED, true);
                    }
                    this.getPackagesData();
                }
            );
        } else if (filterValue === waterpipesAll.name) {
            this.setState(
                {
                    selectedStatus: '',
                    page: 1,
                    isShowOnlyOfflineDisabled: false,
                },
                () => {
                    localStorage.setItem(localStorageKey.PACKAGES_SELECTED_STATUS, this.state.selectedStatus);
                    localStorage.setItem(localStorageKey.PACKAGES_SELECTED_STATUS_NAME, filterValue);
                    this.getPackagesData();
                }
            );
        } else {
            this.setState(
                {
                    selectedStatus: this.getStatuses()[this.getTransformedSelectString(filterValue)],
                    page: 1,
                    isShowOnlyOfflineDisabled: false,
                },
                () => {
                    localStorage.setItem(localStorageKey.PACKAGES_SELECTED_STATUS, this.state.selectedStatus);
                    localStorage.setItem(localStorageKey.PACKAGES_SELECTED_STATUS_NAME, filterValue);
                    this.getPackagesData();
                }
            );
        }
    };

    getTransformedSelectString = selectString => {
        const strWithoutDiacritic = changeDiacriticToStandard(selectString);
        if (strWithoutDiacritic === filterStatusesNoDiacritic.PARSER_ERROR) {
            return 'bladParsowania';
        }
        if (strWithoutDiacritic === filterStatusesNoDiacritic.DATE_ERROR) {
            return 'bladDaty';
        }
        if (strWithoutDiacritic === filterStatusesNoDiacritic.GATEWAY_ERROR) {
            return 'bladBramki';
        }
        if (strWithoutDiacritic === filterStatusesNoDiacritic.PIPELINE_ERROR) {
            return 'bladWodociagu';
        }
        if (strWithoutDiacritic === filterStatusesNoDiacritic.PROTOCOL_ERROR) {
            return 'bladProtokolu';
        }
        if (strWithoutDiacritic === filterStatusesNoDiacritic.FINISHED_OFF) {
            return 'zakonczoneOff';
        }
        if (strWithoutDiacritic === filterStatusesNoDiacritic.FINISHED_WITHOUT_OFF) {
            return 'zakonczoneOff';
        }
        if (strWithoutDiacritic === filterStatusesNoDiacritic.DECRYPTION_ERROR) {
            return 'bladDekodowania';
        }

        return changeDiacriticToStandard(selectString);
    };

    handleWaterpipeFilter = waterpipe => {
        const { selectedStatus } = this.state;

        this.setLocalStorage(localStorageKey.PACKAGES_SELECTED_WATERPIPE, waterpipe);
        this.setLocalStorage(localStorageKey.PACKAGES_SELECTED_WATERPIPE_NAME, waterpipe);
        localStorage.removeItem(localStorageKey.PACKAGES_PAGE);

        if (waterpipe === waterpipesAll.name) {
            this.setState(
                {
                    selectedWaterpipe: '',
                    page: 1,
                },
                () => {
                    this.getPackagesData();
                }
            );
        } else {
            this.setState(
                {
                    selectedWaterpipe: waterpipe,
                    page: 1,
                },
                () => {
                    if (selectedStatus !== 'new') {
                        this.getPackagesData();
                    }
                }
            );
        }
    };

    toggleLoading = isLoading => {
        this.setState({ loading: isLoading });
    };

    handleClearedSearchField = () => {
        this.setState({ clearSearchField: false });
    };

    setLocalStorage = (key, value) => {
        localStorage.setItem(key, value);
    };

    setStateFromLocalStorage = () => {
        const selectedWaterpipe = localStorage.getItem(localStorageKey.PACKAGES_SELECTED_WATERPIPE);
        const selectedStatus = localStorage.getItem(localStorageKey.PACKAGES_SELECTED_STATUS);
        const page = localStorage.getItem(localStorageKey.PACKAGES_PAGE);
        const protocol = localStorage.getItem(localStorageKey.PACKAGES_SELECTED_PROTOCOL);
        const id = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_ID);
        const sms = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_SMS);
        const dok = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_DOK);
        const sim = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_SIM);
        const com = localStorage.getItem(localStorageKey.PACKAGES_SEARCH_COM);
        const isDuplicateChecked = getBooleanFromString(
            localStorage.getItem(localStorageKey.IS_DUPLICATE_CHECKED)
        );
        const isShowOnlyOfflineChecked = getBooleanFromString(
            localStorage.getItem(localStorageKey.IS_SHOW_ONLY_OFFLINE_CHECKED)
        );

        this.setState(
            {
                selectedWaterpipe: selectedWaterpipe || '',
                selectedStatus: selectedStatus || '',
                page: +page ? +page : 1,
                protocol: protocol || '',
                searchFields: {
                    id,
                    sms,
                    dok,
                    sim,
                    com,
                },
                isDuplicateChecked,
                isShowOnlyOfflineChecked,
                isShowOnlyOfflineDisabled: selectedStatus === 'done_off',
            },
            this.getPackagesData
        );
    };

    isParserErrorLinkClicked = () => localStorage.getItem('isParserErrorLinkClicked');

    handleRefreshButton = () => {
        this.forceUpdate();
        this.setStateFromLocalStorage();
    };

    handleResetFieldsButton = () => {
        removeLocalStorageItems([
            'isParserErrorLinkClicked',
            'packagesPage',
            'packagesSearchId',
            'packagesSearchSms',
            'packagesSearchSim',
            'packagesSearchCom',
        ]);
        this.setState(
            state => ({
                searchFields: {
                    id: '',
                    sms: '',
                    sim: '',
                    dok: state.searchFields.dok,
                    com: '',
                },
                page: 1,
                clearSearchField: true,
            }),
            this.getPackagesData
        );
    };

    handleStatusNewRemoved = () => {
        const { selectedWaterpipe, selectedStatus } = this.state;

        if (selectedWaterpipe === filterStatuses.ALL || selectedWaterpipe === '') {
            this.setState({
                statuses: [
                    filterStatuses.ALL,
                    filterStatuses.NEW,
                    filterStatuses.PENDING,
                    filterStatuses.FINISHED,
                    filterStatuses.FINISHED_OFF,
                    filterStatuses.FINISHED_WITHOUT_OFF,
                    filterStatuses.PARSER_ERROR,
                    filterStatuses.DATE_ERROR,
                    filterStatuses.GATEWAY_ERROR,
                    filterStatuses.PIPELINE_ERROR,
                    filterStatuses.PROTOCOL_ERROR,
                    filterStatuses.DUPLICATES,
                    filterStatuses.NOT_PARSED,
                    filterStatuses.DECRYPTION_ERROR,
                ],
            });
        } else {
            this.setState(
                {
                    statuses: [
                        filterStatuses.ALL,
                        filterStatuses.PENDING,
                        filterStatuses.FINISHED,
                        filterStatuses.FINISHED_OFF,
                        filterStatuses.FINISHED_WITHOUT_OFF,
                        filterStatuses.PARSER_ERROR,
                        filterStatuses.DATE_ERROR,
                        filterStatuses.GATEWAY_ERROR,
                        filterStatuses.PIPELINE_ERROR,
                        filterStatuses.PROTOCOL_ERROR,
                        filterStatuses.DUPLICATES,
                        filterStatuses.NOT_PARSED,
                        filterStatuses.DECRYPTION_ERROR,
                    ],
                },
                () => {
                    if (selectedStatus === 'new') {
                        this.handleStatusFilter(filterStatuses.ALL);
                    }
                }
            );
        }
    };

    getProtocolsTypeArr = protocolTypes => [waterpipesAll.name].concat(protocolTypes.map(i => i.name));

    handleProtocolTypeFilter = protocolType => {
        localStorage.removeItem(localStorageKey.PACKAGES_PAGE);
        localStorage.setItem(localStorageKey.PACKAGES_SELECTED_PROTOCOL, protocolType.substr(9));
        localStorage.setItem(localStorageKey.PACKAGES_SELECTED_PROTOCOL_NAME, protocolType);

        this.setState(
            {
                protocol: protocolType.substr(9),
                page: 1,
            },

            this.getPackagesData
        );
    };

    changeSearchFields = (searchFieldType, phrase, state) => {
        const copyState = cloneObjByJSON(state);
        copyState.searchFields[searchFieldType] = phrase;
        copyState.page = 1;
        return copyState;
    };

    handleCheckbox = () => {
        this.setState(
            state => ({ isDuplicateChecked: !state.isDuplicateChecked }),
            () => {
                this.setLocalStorage(localStorageKey.IS_DUPLICATE_CHECKED, this.state.isDuplicateChecked);
                this.getPackagesData();
            }
        );
    };

    handleIsShowOnlyOfflineChecked = () => {
        this.setState(
            state => ({
                isShowOnlyOfflineChecked: !state.isShowOnlyOfflineChecked,
            }),
            () => {
                this.setLocalStorage(
                    localStorageKey.IS_SHOW_ONLY_OFFLINE_CHECKED,
                    this.state.isShowOnlyOfflineChecked
                );
                this.getPackagesData();
            }
        );
    };

    getPackageInLiveObject = id => {
        axiosInstance
            .get(endpoint.LIVE_CHECK, {
                params: {
                    id,
                },
                cancelToken: this.cancelHttpReq.token,
            })
            .then(({ data }) => {
                this.showIsPackageInLiveObject(data);
            })
            .catch(err => {
                errorHandling(err, this);
            });
    };

    showIsPackageInLiveObject = text => {
        if (text === 'Package not found') {
            this.setState({
                liveObjData: {
                    isOfflinePackageFound: false,
                    text,
                    isCheckClicked: true,
                },
            });
        } else if (text === 'SIM is incorrect or missing') {
            this.setState({
                liveObjData: {
                    isOfflinePackageFound: false,
                    text,
                    isCheckClicked: true,
                },
            });
        } else if (text === 'No API KEY for waterpipe') {
            this.setState({
                liveObjData: {
                    isOfflinePackageFound: false,
                    text,
                    isCheckClicked: true,
                },
            });
        } else if (text === 'Error! Package does not exist in database') {
            this.setState({
                liveObjData: {
                    isOfflinePackageFound: false,
                    text,
                    isCheckClicked: true,
                },
            });
        } else {
            this.setState({
                liveObjData: {
                    isOfflinePackageFound: true,
                    text,
                    isCheckClicked: true,
                },
            });
        }
    };

    handleResetPackageInLiveObject = () => {
        this.setState({
            liveObjData: {
                isOfflinePackageFound: false,
                text: '',
                isCheckClicked: false,
            },
        });
    };

    render() {
        const { classes } = this.props;
        const {
            statuses,
            content,
            page,
            chunk,
            protocol,
            waterpipes,
            loading,
            isServerError,
            selectedWaterpipe,
            selectedStatus,
            clearSearchField,
            isComponentMounted,
            protocolsToSelect,
            searchFields: { id, sms, dok, sim, com },
            isDuplicateChecked,
            isShowOnlyOfflineChecked,
            isShowOnlyOfflineDisabled,
            liveObjData,
        } = this.state;

        const operations = { header: 'Parser', row: 'parse' };
        const waterpipesOptions = sortItemsAlphabetically(waterpipes);
        waterpipesOptions.unshift(waterpipesAll.name);

        return (
            <div>
                <PopupModal
                    liveObjData={liveObjData}
                    handleResetPackageInLiveObject={this.handleResetPackageInLiveObject}
                />
                <TitleHeader title={'kolektor'} />
                <div className={classes.buttonsRefreshCountPackagesContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleRefreshButton}
                        className={classes.buttonRefresh}>
                        Odśwież
                    </Button>
                    <CountPackages
                        selectedWaterpipe={selectedWaterpipe}
                        selectedStatus={selectedStatus}
                        protocol={protocol}
                        id={id}
                        data={sms}
                        document_type={dok}
                        sim={sim}
                        com={com}
                        isCountDuplicate={isDuplicateChecked}
                        isCountShowOnlyOffline={isShowOnlyOfflineChecked}
                    />
                </div>

                <article className={classes.inputContainer}>
                    <AutocompleteBar
                        label="Wodociąg"
                        placeholder="Wszystkie"
                        options={waterpipesOptions}
                        handleFilter={this.handleWaterpipeFilter}
                        localStorageKey="packagesSelectedWaterpipeName"
                        value={selectedWaterpipe}
                        isWaterpipe
                    />

                    <AutocompleteBar
                        label="Status"
                        placeholder="Wszystkie"
                        options={statuses}
                        handleFilter={this.handleStatusFilter}
                        localStorageKey="packagesSelectedStatusName"
                        value={selectedStatus}
                        isStatus
                    />

                    <AutocompleteBar
                        label="Protokół"
                        placeholder="Wszystkie"
                        options={protocolsToSelect}
                        handleFilter={this.handleProtocolTypeFilter}
                        localStorageKey="packagesSelectedProtocolName"
                        value={protocol}
                        isProtocol
                    />

                    <FilterPackages
                        handleFilterPackages={this.handleFilterPackages}
                        clearSearchField={clearSearchField}
                        clearedSearchField={this.handleClearedSearchField}
                    />
                    <FormControlLabel
                        className={classes.checkboxContainer}
                        control={
                            <Checkbox
                                classes={{ root: classes.checkbox }}
                                checked={isDuplicateChecked}
                                onChange={this.handleCheckbox}
                                color="primary"
                            />
                        }
                        label="Ukryj duplikaty"
                        disabled={selectedStatus !== ''}
                    />

                    <FormControlLabel
                        className={classes.checkboxContainer}
                        control={
                            <Checkbox
                                classes={{ root: classes.checkbox }}
                                checked={!!isShowOnlyOfflineChecked}
                                disabled={!!isShowOnlyOfflineDisabled}
                                onChange={this.handleIsShowOnlyOfflineChecked}
                                color="primary"
                            />
                        }
                        label="Tylko offline"
                    />

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.handleResetFieldsButton}
                        className={classes.resetFieldsButton}>
                        reset
                    </Button>
                </article>

                <PackagesProvider
                    value={{
                        packagesData: {
                            selectedWaterpipe,
                            selectedStatus,
                            id,
                            sms,
                            dok,
                        },
                        handleRefreshButton: this.handleRefreshButton,
                        waterpipes,
                        isPackageInLiveObject: this.getPackageInLiveObject,
                    }}>
                    <TableWrapper
                        isThrobberActive={loading}
                        changePage={this.handleChangePage}
                        selectedPage={page}
                        chunk={chunk}
                        content={content}
                        headMinWidth
                        showParseColumn
                        operations={operations}
                        isServerError={isServerError}
                        isComponentMounted={isComponentMounted}
                    />
                </PackagesProvider>
            </div>
        );
    }
}

Packages.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Packages);
