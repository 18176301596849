import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import ProtocolTypeConverter from './ProtocolTypeConverter/ProtocolTypeConverter';
import { toLittleEndian } from '../../../utils/helpers';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '50vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

export const headerV10fieldsToLittleEndian = [
    'hubNumber',
    'GSMSessionCounter',
    'GSMOrGPRProperCommunicationAmount',
    'GSMorGPRSTimeAmountToTurnOn',
    'radioModuleTimeAmountToTurnOn',
    'hubFirmWareID',
    'hubOrPipelineTransmitterNum',
    'radioTransmitterFirmwareID',
    'radioTransmitterBatteryUsage',
];

class HeaderV10 extends Component {
    state = {
        protocolType: '',
        transmissionChannel: '',
        customerNumber: '',
        hubNumber: '',
        year: '',
        month: '',
        GSMLevel: '',
        GSMSessionCounter: '',
        GSMOrGPRProperCommunicationAmount: '',
        GSMorGPRSTimeAmountToTurnOn: '',
        radioModuleTimeAmountToTurnOn: '',
        buffersReportAmount: '',
        hubPurpose: '',
        hubFirmWareID: '',
        hubTemperature: '',
        batteryHubPercentageLevel: '',
        hubBatteryVoltage: '',
        GSMLevelOnFirstReportProviding: '',
        measuringChannelNum: '',
        hubOrPipelineTransmitterNum: '',
        radioTransmitterStabilisedVoltage: '',
        studyingState: '',
        firstSolenoidSignalQuality: '',
        secondSolenoidSignalQuality: '',
        thirdSolenoidSignalQuality: '',
        transmitterPurpose: '',
        radioTransmitterFirmwareID: '',
        radioTransmitterTemperature: '',
        radioTransmitterBatteryUsage: '',
        radioTransmitterBatteryVoltage: '',
    };

    componentDidMount() {
        this.setHeaderProtocolType();
    }

    componentDidUpdate(prevProps) {
        const { protocolType, schemeV10Header, selectedScheme, clearSelectedScheme } = this.props;
        if (prevProps.protocolType !== protocolType) {
            this.setHeaderProtocolType();
        }
        if (prevProps.schemeV10Header !== schemeV10Header) {
            this.setState({ ...schemeV10Header });
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
            this.setHeaderProtocolType();
        }
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value.toUpperCase() }, () => {
            this.props.getPackagesData(this.setLittleEndianInLivePreview({ ...this.state }), 'header');
        });
    };

    onSubmit = () => {
        this.props.saveHeaderFormState(this.state);
        return false;
    };

    setHeaderProtocolType = () => {
        const { protocolType } = this.props;
        this.setState({ protocolType: this.changeToHex(protocolType) });
        this.handleChange('protocolType', this.changeToHex(protocolType));
    };

    changeToHex = value => value.charCodeAt(0).toString(16);

    clearState = () => {
        this.setState({
            protocolType: '',
            transmissionChannel: '',
            customerNumber: '',
            hubNumber: '',
            year: '',
            month: '',
            GSMLevel: '',
            GSMSessionCounter: '',
            GSMOrGPRProperCommunicationAmount: '',
            GSMorGPRSTimeAmountToTurnOn: '',
            radioModuleTimeAmountToTurnOn: '',
            buffersReportAmount: '',
            hubPurpose: '',
            hubFirmWareID: '',
            hubTemperature: '',
            batteryHubPercentageLevel: '',
            hubBatteryVoltage: '',
            GSMLevelOnFirstReportProviding: '',
            measuringChannelNum: '',
            hubOrPipelineTransmitterNum: '',
            radioTransmitterStabilisedVoltage: '',
            studyingState: '',
            firstSolenoidSignalQuality: '',
            secondSolenoidSignalQuality: '',
            thirdSolenoidSignalQuality: '',
            transmitterPurpose: '',
            radioTransmitterFirmwareID: '',
            radioTransmitterTemperature: '',
            radioTransmitterBatteryUsage: '',
            radioTransmitterBatteryVoltage: '',
        });
    };

    setLittleEndianInLivePreview = value => {
        headerV10fieldsToLittleEndian.forEach(e => {
            value[e] = toLittleEndian(value[e]);
        });
        return value;
    };

    render() {
        const { classes, isCleared } = this.props;
        const {
            protocolType,
            transmissionChannel,
            customerNumber,
            hubNumber,
            year,
            month,
            GSMLevel,
            GSMSessionCounter,
            GSMOrGPRProperCommunicationAmount,
            GSMorGPRSTimeAmountToTurnOn,
            radioModuleTimeAmountToTurnOn,
            buffersReportAmount,
            hubPurpose,
            hubFirmWareID,
            hubTemperature,
            batteryHubPercentageLevel,
            hubBatteryVoltage,
            GSMLevelOnFirstReportProviding,
            measuringChannelNum,
            hubOrPipelineTransmitterNum,
            radioTransmitterStabilisedVoltage,
            studyingState,
            firstSolenoidSignalQuality,
            secondSolenoidSignalQuality,
            thirdSolenoidSignalQuality,
            transmitterPurpose,
            radioTransmitterFirmwareID,
            radioTransmitterTemperature,
            radioTransmitterBatteryUsage,
            radioTransmitterBatteryVoltage,
        } = this.state;

        return (
            <div>
                <ValidatorForm
                    className={classes.root}
                    onSubmit={this.onSubmit}
                    onError={errors => console.error(errors)}>
                    <TextValidator
                        label="Rodzaj Protokołu: odpowiednik hex znaków ascii [0,6-9,A-Z,a-z]"
                        className={classes.margin}
                        value={protocolType}
                        variant="outlined"
                        errorMessages={['Niepoprawny format']}
                        required
                        disabled
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={transmissionChannel}
                        onChange={value => this.handleChange('transmissionChannel', value)}
                        label="Nr kanału transmisyjnego: "
                        labelHex="przedział [0x01 - 0x05]"
                        validators={['transmissionChannel']}
                        interval={['00', '05']}
                        length={2}
                        dataCy="transmissionChannel"
                    />

                    <ProtocolTypeConverter
                        value={customerNumber}
                        onChange={value => this.handleChange('customerNumber', value)}
                        label="Numer odbiorcy: "
                        labelHex="przedział [0x20 - 0x7E]"
                        validators={['customerNumber']}
                        interval={['20', '7E']}
                        length={2}
                        dataCy="customerNumber"
                    />

                    <ProtocolTypeConverter
                        value={hubNumber}
                        onChange={value => this.handleChange('hubNumber', value)}
                        label="Numer koncentratora: "
                        labelHex="przedział [0x00000000 - 0x99999999]"
                        validators={['hubNumber']}
                        interval={['00000000', '99999999']}
                        length={8}
                        dataCy="hubNumber"
                    />

                    <ProtocolTypeConverter
                        value={year}
                        onChange={value => this.handleChange('year', value)}
                        label="Rok - format BCD: "
                        labelHex="przedział [0x00 - 0x99]"
                        validators={['headerIsYear']}
                        interval={[0, 99]}
                        length={2}
                        dataCy="year"
                    />

                    <ProtocolTypeConverter
                        value={month}
                        onChange={value => this.handleChange('month', value)}
                        label="Miesiąc - format BCD: "
                        labelHex="przedział [0x01 - 0x12]"
                        validators={['headerIsMonth']}
                        interval={[1, 12]}
                        length={2}
                        dataCy="month"
                    />

                    <ProtocolTypeConverter
                        value={GSMLevel}
                        onChange={value => this.handleChange('GSMLevel', value)}
                        label="Poziom sygnału GSM: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['GSMLevel']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="GSMLevel"
                    />

                    <ProtocolTypeConverter
                        value={GSMSessionCounter}
                        onChange={value => this.handleChange('GSMSessionCounter', value)}
                        label="Licznik sesji GSM: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['GSMSessionCounter']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="GSMSessionCounter"
                    />

                    <ProtocolTypeConverter
                        value={GSMOrGPRProperCommunicationAmount}
                        onChange={value => this.handleChange('GSMOrGPRProperCommunicationAmount', value)}
                        label="Ilość poprawnych komunikacji przez moduł GSM/GPRS: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['GSMOrGPRProperCommunicationAmount']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="GSMOrGPRProperCommunicationAmount"
                    />

                    <ProtocolTypeConverter
                        value={GSMorGPRSTimeAmountToTurnOn}
                        onChange={value => this.handleChange('GSMorGPRSTimeAmountToTurnOn', value)}
                        label="Czas włączenia modułu GSM/GPRS w koncentratorze [min]: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['GSMorGPRSTimeAmountToTurnOn']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="GSMorGPRSTimeAmountToTurnOn"
                    />

                    <ProtocolTypeConverter
                        value={radioModuleTimeAmountToTurnOn}
                        onChange={value => this.handleChange('radioModuleTimeAmountToTurnOn', value)}
                        label="Czas włączenia modułu radiowego w koncentratorze [min]: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['radioModuleTimeAmountToTurnOn']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="radioModuleTimeAmountToTurnOn"
                    />

                    <ProtocolTypeConverter
                        value={buffersReportAmount}
                        onChange={value => this.handleChange('buffersReportAmount', value)}
                        label="Ilość raportów w buforze: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['buffersReportAmount']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="buffersReportAmount"
                    />

                    <ProtocolTypeConverter
                        value={hubPurpose}
                        onChange={value => this.handleChange('hubPurpose', value)}
                        label="Przeznaczenie koncentratora: antena świnka, PCB, konektor, zalany, itp.: "
                        labelHex="przedział [0x21 - 0x7E]"
                        validators={['hubPurpose']}
                        interval={['21', '7E']}
                        length={2}
                        dataCy="hubPurpose"
                    />

                    <ProtocolTypeConverter
                        value={hubFirmWareID}
                        onChange={value => this.handleChange('hubFirmWareID', value)}
                        label="Firmware ID koncentratora: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['hubFirmWareID']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="hubFirmWareID"
                    />

                    <ProtocolTypeConverter
                        value={hubTemperature}
                        onChange={value => this.handleChange('hubTemperature', value)}
                        label="Temperatura koncentratora, od -127 do +127 stopni C - kodowanie U2: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['hubTemperature']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="hubTemperature"
                    />

                    <ProtocolTypeConverter
                        value={batteryHubPercentageLevel}
                        onChange={value => this.handleChange('batteryHubPercentageLevel', value)}
                        label="Procentowy stan baterii koncentratora: "
                        labelHex="przedział [0x00 - 0x64]"
                        validators={['batteryHubPercentageLevel']}
                        interval={['00', '64']}
                        length={2}
                        dataCy="batteryHubPercentageLevel"
                    />

                    <ProtocolTypeConverter
                        value={hubBatteryVoltage}
                        onChange={value => this.handleChange('hubBatteryVoltage', value)}
                        label="Napięcie baterii koncentratora: U = x * 0,05 V: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['hubBatteryVoltage']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="hubBatteryVoltage"
                    />

                    <ProtocolTypeConverter
                        value={GSMLevelOnFirstReportProviding}
                        onChange={value => this.handleChange('GSMLevelOnFirstReportProviding', value)}
                        label="Poziom GSM przy pierwszej próbie wysłania bieżącego raportu: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['GSMLevelOnFirstReportProviding']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="GSMLevelOnFirstReportProviding"
                    />

                    <ProtocolTypeConverter
                        value={measuringChannelNum}
                        onChange={value => this.handleChange('measuringChannelNum', value)}
                        label="Nr kanału pomiarowego: "
                        labelHex="przedział [0x00 - 0x01]"
                        validators={['measuringChannelNum']}
                        interval={['00', '01']}
                        length={2}
                        dataCy="measuringChannelNum"
                    />

                    <TextValidator
                        label="Nr nadajnika radiowego na wodomierzu / nr koncentratora: 8 znaków [liczba long w zakresie 00000000 - 99999999]"
                        className={classes.margin}
                        value={hubOrPipelineTransmitterNum}
                        onChange={e => this.handleChange('hubOrPipelineTransmitterNum', e.target.value)}
                        variant="outlined"
                        errorMessages={['Niepoprawny format']}
                        validators={['matchRegexp:^([0-9]){8}$']}
                        required
                        data-cy="hubOrPipelineTransmitterNum"
                    />

                    <ProtocolTypeConverter
                        value={radioTransmitterStabilisedVoltage}
                        onChange={value => this.handleChange('radioTransmitterStabilisedVoltage', value)}
                        label="Napięcie stabilizowane nadajnika radiowego: U = x * 0,05 V: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['radioTransmitterStabilisedVoltage']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="radioTransmitterStabilisedVoltage"
                    />

                    <ProtocolTypeConverter
                        value={studyingState}
                        onChange={value => this.handleChange('studyingState', value)}
                        label="Stan 'uczenia się': "
                        labelHex="przedział [0x00 - 0x64]"
                        validators={['studyingState']}
                        interval={['00', '64', '78']}
                        length={2}
                        dataCy="studyingState"
                    />

                    <ProtocolTypeConverter
                        value={firstSolenoidSignalQuality}
                        onChange={value => this.handleChange('firstSolenoidSignalQuality', value)}
                        label="Jakość sygnału cewki 1: J = 400 + x * 10: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['firstSolenoidSignalQuality']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="firstSolenoidSignalQuality"
                    />

                    <ProtocolTypeConverter
                        value={secondSolenoidSignalQuality}
                        onChange={value => this.handleChange('secondSolenoidSignalQuality', value)}
                        label="Jakość sygnału cewki 2: J = 400 + x * 10: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['secondSolenoidSignalQuality']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="secondSolenoidSignalQuality"
                    />

                    <ProtocolTypeConverter
                        value={thirdSolenoidSignalQuality}
                        onChange={value => this.handleChange('thirdSolenoidSignalQuality', value)}
                        label="Jakość sygnału cewki 3: J = 400 + x * 10: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['thirdSolenoidSignalQuality']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="thirdSolenoidSignalQuality"
                    />

                    <ProtocolTypeConverter
                        value={transmitterPurpose}
                        onChange={value => this.handleChange('transmitterPurpose', value)}
                        label="Przeznaczenie nadajnika: antena ceramiczna, PCB..., zalany, niezalany...: "
                        labelHex="przedział [0x21 - 0x7E]"
                        validators={['transmitterPurpose']}
                        interval={['21', '7E']}
                        length={2}
                        dataCy="transmitterPurpose"
                    />

                    <ProtocolTypeConverter
                        value={radioTransmitterFirmwareID}
                        onChange={value => this.handleChange('radioTransmitterFirmwareID', value)}
                        label="Firmware ID nadajnika radiowego: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['radioTransmitterFirmwareID']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="radioTransmitterFirmwareID"
                    />

                    <ProtocolTypeConverter
                        value={radioTransmitterTemperature}
                        onChange={value => this.handleChange('radioTransmitterTemperature', value)}
                        label="Temperatura nadajnika radiowego: od -127 do +127 stopni C - kodowanie U2: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['radioTransmitterTemperature']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="radioTransmitterTemperature"
                    />

                    <ProtocolTypeConverter
                        value={radioTransmitterBatteryUsage}
                        onChange={value => this.handleChange('radioTransmitterBatteryUsage', value)}
                        label="Zużycie baterii nadajnika radiowego [mAh]: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['radioTransmitterBatteryUsage']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="radioTransmitterBatteryUsage"
                    />

                    <ProtocolTypeConverter
                        value={radioTransmitterBatteryVoltage}
                        onChange={value => this.handleChange('radioTransmitterBatteryVoltage', value)}
                        label="Napięcie baterii nadajnika radiowego: U = x * 0,05 V: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['radioTransmitterBatteryVoltage']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="radioTransmitterBatteryVoltage"
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        value="Submit"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

export default withStyles(styles)(HeaderV10);
