import React, { useEffect, useState } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import { setDefaultHyphenDateRange } from '../../utils/helpers';
import { inputField } from '../../utils/constants';
import theme from '../../themes/theme';

const styles = {
    dateField: {
        margin: `${theme.margins.textField.top} ${theme.margins.textField.all} 0 ${theme.margins.textField.all}`,
        '& .MuiInput-input': {
            paddingBottom: 10,
            marginTop: -2,
        },
        '& label': {
            marginTop: -6,
        },
    },
};

const DateRange = ({ classes: { dateField }, setIsAnyError, setDateFrom, setDateTo }) => {
    const [rangeDateFrom, setRangeDateFrom] = useState(setDefaultHyphenDateRange(30).amountOfDaysBefore);
    const [rangeDateTo, setRangeDateTo] = useState(setDefaultHyphenDateRange().present);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const clearErrors = () => {
        setIsError(false);
        setErrorMessage('');
    };

    const handleDateFromNotLaterThenDateTo = () => {
        setIsError(true);
        setErrorMessage('"Data do" jest wcześniejsza');
    };

    const setDateFromNotLaterThenDateTo = (field, incomingDate) => {
        const incomingDateTimestamp = new Date(incomingDate).getTime();
        const dateFromTimestamp = new Date(rangeDateFrom).getTime();
        const dateToTimestamp = new Date(rangeDateTo).getTime();

        if (field === inputField.FROM) {
            if (incomingDateTimestamp > dateToTimestamp) handleDateFromNotLaterThenDateTo();
            return incomingDate;
        }
        if (field === inputField.TO) {
            if (incomingDateTimestamp < dateFromTimestamp) handleDateFromNotLaterThenDateTo();
            return incomingDate;
        }
        return null;
    };

    const handleDate = (e, field) => {
        const { value } = e.target;

        if (value && field === inputField.FROM) {
            setRangeDateFrom(value);
            setDateFromNotLaterThenDateTo(field, value);
        }
        if (value && field === inputField.TO) {
            setRangeDateFrom(setDefaultHyphenDateRange(30, value).amountOfDaysBefore);
            setRangeDateTo(value);
            clearErrors();
        }
    };

    useEffect(() => {
        setIsAnyError(isError);
    }, [isError]);

    useEffect(() => {
        setDateFrom(rangeDateFrom);
        setDateTo(rangeDateTo);

        if (rangeDateFrom < rangeDateTo) {
            clearErrors();
        }
    }, [rangeDateFrom, rangeDateTo]);

    return (
        <>
            <TextField
                label="Data od"
                type="date"
                className={dateField}
                value={rangeDateFrom}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => handleDate(e, inputField.FROM)}
                error={isError}
                helperText={errorMessage}
            />
            <TextField
                label="Data do"
                type="date"
                className={dateField}
                value={rangeDateTo}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => handleDate(e, inputField.TO)}
                error={isError}
                helperText={errorMessage}
            />
        </>
    );
};

export default withStyles(styles)(DateRange);
