import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import DecimalV10 from './DecimalV10';
import AsciiV10 from './AsciiV10';
import { convertHexToDec } from '../../../../utils/helpers';

const styles = {
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    containerForms: {
        width: '90vw',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    containerConverted: {
        width: '90vw',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    textValidator: {
        margin: '.7rem 0',
        width: '50vw',
    },
};

class ProtocolTypeConverter extends Component {
    state = {
        isCleared: false,
    };

    componentDidMount() {
        this.setCustomValidator();
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule(this.props.validators[0]);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isCleared !== this.props.isCleared) this.setState({ isCleared: this.props.isCleared });
    }

    handleChange = value => {
        const { onChange } = this.props;
        onChange(value);
    };

    onChangeTextValidatorHandler = e => {
        this.handleChange(e.target.value);
    };

    isAscii = () => {
        const { interval } = this.props;
        const intervalEnd = 1;
        return this.isBCD() ? interval[intervalEnd] >= 32 : convertHexToDec(interval[intervalEnd]) >= 32;
    };

    isBCD = () => this.isDate();

    isDate = () => {
        const { validators } = this.props;
        return (
            validators[0] === 'isYear' ||
            validators[0] === 'isMonth' ||
            validators[0] === 'isDay' ||
            validators[0] === 'isHour' ||
            validators[0] === 'isMinute' ||
            validators[0] === 'isSecond'
        );
    };

    setCustomValidator = () => {
        const { interval } = this.props;
        const intervalBegin = 0;
        const intervalEnd = 1;
        const intervalOptionalElement = 2;

        if (this.isDate()) {
            this.setValidatorForBCDFormat(intervalBegin, intervalEnd);
        } else if (interval.length === 3) {
            this.setValidatorForAdditional0x78(intervalBegin, intervalEnd, intervalOptionalElement);
        } else if (interval.length === 5) {
            this.setValidatorForAlertCause();
        } else {
            this.setValidatorForHexInterval(intervalBegin, intervalEnd);
        }
    };

    setValidatorForBCDFormat = (intervalBegin, intervalEnd) => {
        const { interval, validators, length } = this.props;
        ValidatorForm.addValidationRule(
            validators[0],
            value =>
                value >= interval[intervalBegin] && value <= interval[intervalEnd] && value.length === length
        );
    };

    setValidatorForAdditional0x78 = (intervalBegin, intervalEnd, intervalOptionalElement) => {
        const { interval, validators, length } = this.props;
        ValidatorForm.addValidationRule(
            validators[0],
            value =>
                ((convertHexToDec(value) >= convertHexToDec(interval[intervalBegin]) &&
                    convertHexToDec(value) <= convertHexToDec(interval[intervalEnd])) ||
                    value === interval[intervalOptionalElement]) &&
                value.length === length
        );
    };

    setValidatorForAlertCause = () => {
        const { interval, validators, length } = this.props;
        ValidatorForm.addValidationRule(validators[0], value => {
            const alertTypeM = interval[0];
            const alertTypeG = interval[1];
            const alertTypeN = interval[2];
            const alertTypeS = interval[3];
            const alertTypeO = interval[4];

            return (
                (value === alertTypeM ||
                    value === alertTypeG ||
                    value === alertTypeN ||
                    value === alertTypeS ||
                    value === alertTypeO) &&
                value.length === length
            );
        });
    };

    setValidatorForHexInterval = (intervalBegin, intervalEnd) => {
        const { interval, validators, length } = this.props;
        ValidatorForm.addValidationRule(
            validators[0],
            value =>
                convertHexToDec(value) >= convertHexToDec(interval[intervalBegin]) &&
                convertHexToDec(value) <= convertHexToDec(interval[intervalEnd]) &&
                value.length === length
        );
    };

    render() {
        const { classes, label, labelHex, validators, value, dataCy } = this.props;

        const { isCleared } = this.state;

        return (
            <div className={classes.margin}>
                <div className={classes.containerForms}>
                    <TextValidator
                        key={isCleared}
                        className={classes.textValidator}
                        variant="outlined"
                        value={value}
                        onChange={this.onChangeTextValidatorHandler}
                        label={`${label}${labelHex}`}
                        validators={validators}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy={dataCy}
                    />
                    <DecimalV10
                        hexValue={value}
                        label={label}
                        handleChange={this.handleChange}
                        isBCD={this.isBCD}
                    />
                    {this.isAscii() && (
                        <AsciiV10
                            hexValue={value}
                            label={label}
                            handleChange={this.handleChange}
                            isBCD={this.isBCD}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ProtocolTypeConverter);
