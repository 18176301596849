import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { axiosInstance } from '../../utils/axiosInstance';
import { isAdminRole } from '../../utils/helpers';
import Snackbar from '../Snackbar/Snackbar';
import TestField from './TestField/TestField';
import { endpoint, snackbarMsg, snackbarVariant } from '../../utils/constants';

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: '5rem',
    },
    button: {
        margin: '1rem',
        width: '15vw',
    },
    livePreviewContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    livePreviewInput: {
        width: '90vw',
    },
    livePreviewButton: {
        width: '15vw',
        margin: '1rem',
    },
    templateContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '80vw',
    },
    templateInput: {
        width: '40vw',
    },
};

class GeneratorInputs extends Component {
    state = {
        templateValue: '',
        livePreviewContent: {},
        getLivePreviewHeader: '',
        snackbarData: {
            isSnackbarOpen: false,
            message: '',
            errorInfo: '',
            variant: '',
        },
    };

    componentDidUpdate(prevProps) {
        const { content, selectedReportType, selectedProtocolType, selectedScheme } = this.props;
        if (content !== prevProps.content) {
            this.setLivePreview();
        }
        if (
            selectedReportType !== prevProps.selectedReportType ||
            selectedScheme !== prevProps.selectedScheme
        ) {
            this.preserveHeaderInLivePreview();
        }
        if (selectedProtocolType !== prevProps.selectedProtocolType) {
            this.clearAllLivePreview();
        }
    }

    createScheme = event => {
        if (event.nativeEvent.type === 'keyup' && event.nativeEvent.key !== 'Enter') {
            return;
        }
        const { templateValue } = this.state;
        const { selectedReportType, selectedProtocolType, isSchemeSaved } = this.props;

        axiosInstance
            .post(endpoint.SCHEME_CREATE, {
                type: selectedReportType,
                protocol: selectedProtocolType.match(/(\d+)/)[0],
                name: templateValue,
                scheme: JSON.stringify({
                    templateName: this.state.templateValue,
                    templateContent: this.state.livePreviewContent,
                }),
            })
            .then(() => {
                this.setState(
                    {
                        templateValue: '',
                        snackbarData: {
                            isSnackbarOpen: true,
                            message: snackbarMsg.TEMPLATE_HAS_BEEN_ADDED,
                            errorInfo: '',
                            variant: snackbarVariant.SUCCESS,
                        },
                    },
                    isSchemeSaved
                );
            })
            .catch(err => {
                if (err.message === undefined) {
                    return;
                }
                if (err.response.status === 401) {
                    this.props.history.push(`/login`);
                }
                if (err.message) {
                    console.error('errorGenerator', err.message);
                    this.setState({
                        snackbarData: {
                            isSnackbarOpen: true,
                            message: snackbarMsg.ERROR,
                            errorInfo: err.message,
                            variant: snackbarVariant.ERROR,
                        },
                    });
                }
            });
    };

    handleTemplateValue = event => {
        this.setState({ templateValue: event.target.value });
    };

    setGetLivePreview = () => {
        const { livePreviewContent } = this.state;
        const getStringFromLivePreviewContent = () => {
            const objKeysArr = Object.keys(livePreviewContent);
            return [...objKeysArr]
                .sort()
                .map(element => livePreviewContent[element])
                .reduce((sum, current) => sum.concat(Object.values(current)), [])
                .join('');
        };

        this.setState({
            getLivePreviewHeader: getStringFromLivePreviewContent(),
        });
    };

    setLivePreview = () => {
        const copyLivePreviewContentState = {
            ...this.state.livePreviewContent,
        };
        const contentPackagesTypes = Object.keys(this.props.content);
        contentPackagesTypes.forEach(packageType => {
            copyLivePreviewContentState[packageType] = {
                ...this.props.content[packageType],
            };
        });

        this.setState({ livePreviewContent: copyLivePreviewContentState }, () => this.setGetLivePreview());
    };

    preserveHeaderInLivePreview = () => {
        this.props.clearLivePreview();
        this.setState(
            prevState => {
                const copyState = { ...this.state };
                copyState.livePreviewContent = undefined;
                copyState.livePreviewContent = {
                    header: {
                        ...prevState.livePreviewContent.header,
                    },
                };

                return { ...copyState };
            },

            () => this.setGetLivePreview()
        );
    };

    clearAllLivePreview = () => {
        this.props.clearLivePreview(true);
        this.setState(
            { livePreviewContent: {} },

            () => this.setGetLivePreview()
        );
    };

    handleIsSnackbarOpen = () => {
        this.setState(state => ({
            snackbarData: {
                isSnackbarOpen: false,
                message: state.snackbarData.message,
                errorInfo: state.snackbarData.errorInfo,
                variant: state.snackbarData.variant,
            },
        }));
    };

    render() {
        const { classes, sendData, isV11Selected } = this.props;
        const { snackbarData } = this.state;

        return (
            <div className={classes.root}>
                <article className={classes.livePreviewContainer}>
                    {!isV11Selected && (
                        <>
                            <TextField
                                label="Live Preview..."
                                multiline={true}
                                minRows={2}
                                maxRows={4}
                                variant="outlined"
                                value={this.state.getLivePreviewHeader}
                                onKeyUp={this.handleEnter}
                                className={classes.livePreviewInput}
                                data-cy="livePreview"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={sendData}
                                className={classes.livePreviewButton}>
                                Wyślij
                            </Button>
                        </>
                    )}
                    <TestField sendData={sendData} isV11Selected={isV11Selected} />
                </article>

                {isAdminRole() && !isV11Selected && (
                    <article className={classes.templateContainer}>
                        <TextField
                            label="Nazwa"
                            variant="outlined"
                            value={this.state.templateValue}
                            onChange={this.handleTemplateValue}
                            className={classes.templateInput}
                            onKeyUp={this.createScheme}
                            inputProps={{ 'data-cy': 'schemeName' }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.createScheme}
                            data-cy="saveSchemeButton">
                            Zapisz jako szablon
                        </Button>
                    </article>
                )}
                <Snackbar snackbarData={snackbarData} handleIsSnackbarOpen={this.handleIsSnackbarOpen} />
            </div>
        );
    }
}

GeneratorInputs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GeneratorInputs);
