import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

class SummaryBodyV9 extends React.Component {
    state = {
        summedUpAlertSummary: '',
        summaryDate: '',
        alertSummaryReportID: '',
        magneticAlertsReportsCounter: '',
        mechanicalAlertsReportsCounter: '',
        onDemandAlertsReportsCounter: '',
        lastSabotageCounterState: '',
        sabotageFlow: '',
        summedUpAlertSummaryAmount: '',
        alertsOccurrenceInGeneral: '',
        firmWareVersion: '',
        firstAlertOccurrenceDate: '',
        firstAlertDuration: '',
        secondAlertOccurrenceDate: '',
        secondAlertDuration: '',
        thirdAlertOccurrenceDate: '',
        thirdAlertDuration: '',
        lastSabotageResolveDate: '',
        GSMLevelOnFirstReportProviding: '',
        reserve: '',
    };

    componentDidMount() {
        const { schemeV9SummaryBody, onSetProtocolType } = this.props;
        onSetProtocolType('X');
        this.setState({ ...schemeV9SummaryBody });
    }

    componentDidUpdate(prevProps) {
        const { schemeV9SummaryBody, selectedScheme, clearSelectedScheme } = this.props;
        if (
            schemeV9SummaryBody &&
            Object.keys(schemeV9SummaryBody).length > 0 &&
            prevProps.schemeV9SummaryBody !== schemeV9SummaryBody
        ) {
            this.setState(schemeV9SummaryBody);
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
        }
    }

    handleChange = (key, event) => {
        this.setState({ [key]: event.target.value }, () =>
            this.props.getPackagesData(this.state, `packageBody${this.props.number}`)
        );
    };

    onSubmit = () => {
        this.props.saveSelectedFormState(this.props.number, this.state);
        return false;
    };

    clearState = () => {
        this.setState({
            summedUpAlertSummary: '',
            summaryDate: '',
            alertSummaryReportID: '',
            magneticAlertsReportsCounter: '',
            mechanicalAlertsReportsCounter: '',
            onDemandAlertsReportsCounter: '',
            lastSabotageCounterState: '',
            sabotageFlow: '',
            summedUpAlertSummaryAmount: '',
            alertsOccurrenceInGeneral: '',
            firmWareVersion: '',
            firstAlertOccurrenceDate: '',
            firstAlertDuration: '',
            secondAlertOccurrenceDate: '',
            secondAlertDuration: '',
            thirdAlertOccurrenceDate: '',
            thirdAlertDuration: '',
            lastSabotageResolveDate: '',
            GSMLevelOnFirstReportProviding: '',
            reserve: '',
        });
    };

    render() {
        const { classes } = this.props;
        const {
            summedUpAlertSummary,
            summaryDate,
            alertSummaryReportID,
            magneticAlertsReportsCounter,
            mechanicalAlertsReportsCounter,
            onDemandAlertsReportsCounter,
            lastSabotageCounterState,
            sabotageFlow,
            summedUpAlertSummaryAmount,
            alertsOccurrenceInGeneral,
            firmWareVersion,
            firstAlertOccurrenceDate,
            firstAlertDuration,
            secondAlertOccurrenceDate,
            secondAlertDuration,
            thirdAlertOccurrenceDate,
            thirdAlertDuration,
            lastSabotageResolveDate,
            GSMLevelOnFirstReportProviding,
            reserve,
        } = this.state;
        return (
            <div>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={summedUpAlertSummary}
                        onChange={e => this.handleChange('summedUpAlertSummary', e)}
                        label="Rodzaj podsumowywanego alarmu: 1 znak [M,G] np. M"
                        validators={['matchRegexp:^([GM])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="summedUpAlertSummary"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={summaryDate}
                        onChange={e => this.handleChange('summaryDate', e)}
                        label="Dzień, godz, min, sek utworzenia podsumowania: 8 znaków [0-9] np.21212121"
                        validators={['matchRegexp:^([0-9]{8})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="summaryDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertSummaryReportID}
                        onChange={e => this.handleChange('alertSummaryReportID', e)}
                        label="ID raportu alarmowego (dla którego zostało utworzone to podsumowanie): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertSummaryReportID"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={magneticAlertsReportsCounter}
                        onChange={e => this.handleChange('magneticAlertsReportsCounter', e)}
                        label="Licznik ilości raportów alarmu magnetycznego (dla momentu utworzenia podsumowania): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="magneticAlertsReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={mechanicalAlertsReportsCounter}
                        onChange={e => this.handleChange('mechanicalAlertsReportsCounter', e)}
                        label="Licznik ilości raportów alarmu mechanicznego (dla momentu utworzenia podsumowania): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="mechanicalAlertsReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={onDemandAlertsReportsCounter}
                        onChange={e => this.handleChange('onDemandAlertsReportsCounter', e)}
                        label="Licznik ilości raportów na żądanie (dla momentu utworzenia podsumowania): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="onDemandAlertsReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={lastSabotageCounterState}
                        onChange={e => this.handleChange('lastSabotageCounterState', e)}
                        label="Stan licznika na godz ustąpienia ostatniego sabotażu [0,001 m3]: 9 znaków [000000000-999999999] np. 123456789"
                        validators={['matchRegexp:^([0-9]{9})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="lastSabotageCounterState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageFlow}
                        onChange={e => this.handleChange('sabotageFlow', e)}
                        label="Przepływ / stan sabotaży: 1 znak [+,-,m,M,g,G,d,D] np. +"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageFlow"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={summedUpAlertSummaryAmount}
                        onChange={e => this.handleChange('summedUpAlertSummaryAmount', e)}
                        label="Ilość wystąpień podsumowywanego alarmu pomiędzy wygenerowaniem raportu
                    alarmowego i bieżącego podsumowania: 4 znaki [0x0000-0xFFFF] np. 0001"
                        validators={['matchRegexp:^([0-9A-F]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="summedUpAlertSummaryAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertsOccurrenceInGeneral}
                        onChange={e => this.handleChange('alertsOccurrenceInGeneral', e)}
                        label="Łączny czas występowania alarmów od wygenerowania raportu alarmowego
                    do utworzenia bieżącego podsumowania [sek]: 6 znaków [0x0000-0xFFFF] np. 000001"
                        validators={['matchRegexp:^([0-9A-F]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertsOccurrenceInGeneral"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firmWareVersion}
                        onChange={e => this.handleChange('firmWareVersion', e)}
                        label="Wersja firmware: 8 znaków [0-9,a-z,A-Z,inne znaki ASCII] np. 012..xcP"
                        validators={['matchRegexp:^(.){8}$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firmWareVersion"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firstAlertOccurrenceDate}
                        onChange={e => this.handleChange('firstAlertOccurrenceDate', e)}
                        label="Rok, miesiąc, dzień, godzina, minuta, sekunda wystąpienia alarmu 1 alarmu 1: 12 znaków [0-9] np.190819063551"
                        validators={['matchRegexp:^([0-9]{12})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firstAlertOccurrenceDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firstAlertDuration}
                        onChange={e => this.handleChange('firstAlertDuration', e)}
                        label="Czas trwania alarmu 1: 6 znaków [0x0000-0xFFFF] np. 000001"
                        validators={['matchRegexp:^([0-9A-F]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firstAlertDuration"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={secondAlertOccurrenceDate}
                        onChange={e => this.handleChange('secondAlertOccurrenceDate', e)}
                        label="Data / czas powstania alarmu 2: 12 znaków [0-9] np.190819063551"
                        validators={['matchRegexp:^([0-9]{12})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="secondAlertOccurrenceDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={secondAlertDuration}
                        onChange={e => this.handleChange('secondAlertDuration', e)}
                        label="Czas trwania alarmu 2: 6 znaków [0x0000-0xFFFF] np. 000001"
                        validators={['matchRegexp:^([0-9A-F]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="secondAlertDuration"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={thirdAlertOccurrenceDate}
                        onChange={e => this.handleChange('thirdAlertOccurrenceDate', e)}
                        label="Data / czas powstania alarmu 3: 12 znaków [0-9] np. 190819063551"
                        validators={['matchRegexp:^([0-9]{12})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="thirdAlertOccurrenceDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={thirdAlertDuration}
                        onChange={e => this.handleChange('thirdAlertDuration', e)}
                        label="Czas trwania alarmu 3: 6 znaków [0x0000-0xFFFF] np. 000001"
                        validators={['matchRegexp:^([0-9A-F]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="thirdAlertDuration"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={lastSabotageResolveDate}
                        onChange={e => this.handleChange('lastSabotageResolveDate', e)}
                        label="Data / czas ustąpienia ostatniego sabotażu: 12 znaków [0-9] np.190819063551"
                        validators={['matchRegexp:^([0-9]{12})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="lastSabotageResolveDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={GSMLevelOnFirstReportProviding}
                        onChange={e => this.handleChange('GSMLevelOnFirstReportProviding', e)}
                        label="Poziom GSM przy pierwszej próbie wysłania bieżącego raportu: 1 znak [0-9,A-W] np. 1"
                        validators={['matchRegexp:^([0-9A-W])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="GSMLevelOnFirstReportProviding"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={reserve}
                        onChange={e => this.handleChange('reserve', e)}
                        label="Rezerwa: 1 znak [x] np. x"
                        validators={['matchRegexp:^([A-Za-z0-9]{1})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="reserve"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

SummaryBodyV9.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SummaryBodyV9);
