import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import ProtocolTypeConverter from './ProtocolTypeConverter/ProtocolTypeConverter';
import { toLittleEndian } from '../../../utils/helpers';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

export const reportBodyV10fieldsToLittleEndian = [
    'counterState',
    'totalDurationMechanicalSabotage',
    'totalDurationMagneticSabotage',
    'dailyReverseFlowCounter',
    'firstIncrement',
    'secondIncrement',
    'thirdIncrement',
    'fourthIncrement',
    'fifthIncrement',
    'sixthIncrement',
    'seventhIncrement',
    'eighthIncrement',
    'ninthIncrement',
    'tenthIncrement',
    'eleventhIncrement',
    'twelfthIncrement',
    'thirteenthIncrement',
    'fourteenthIncrement',
    'fifteenthIncrement',
    'sixteenthIncrement',
    'seventeenthIncrement',
    'eighteenthIncrement',
    'nineteenthIncrement',
    'twentiesIncrement',
    'twentyFirstIncrement',
    'twentySecondIncrement',
    'twentyThirdsIncrement',
];

class ReportBodyV10 extends Component {
    state = {
        day: '',
        counterState: '',
        sabotageState: '',
        dailyCounterMechanicalSabotage: '',
        totalDurationMechanicalSabotage: '',
        dailyCounterMagneticSabotage: '',
        totalDurationMagneticSabotage: '',
        dailyReverseFlowCounter: '',
        firstIncrement: '',
        sabotageStateForFirstIncrement: '',
        secondIncrement: '',
        sabotageStateForSecondIncrement: '',
        thirdIncrement: '',
        sabotageStateForThirdIncrement: '',
        fourthIncrement: '',
        sabotageStateForFourthIncrement: '',
        fifthIncrement: '',
        sabotageStateForFifthIncrement: '',
        sixthIncrement: '',
        sabotageStateForSixthIncrement: '',
        seventhIncrement: '',
        sabotageStateForSeventhIncrement: '',
        eighthIncrement: '',
        sabotageStateForEighthIncrement: '',
        ninthIncrement: '',
        sabotageStateForNinthIncrement: '',
        tenthIncrement: '',
        sabotageStateForTenthIncrement: '',
        eleventhIncrement: '',
        sabotageStateForEleventhIncrement: '',
        twelfthIncrement: '',
        sabotageStateForTwelfthIncrement: '',
        thirteenthIncrement: '',
        sabotageStateForThirteenthIncrement: '',
        fourteenthIncrement: '',
        sabotageStateForFourteenthIncrement: '',
        fifteenthIncrement: '',
        sabotageStateForFifteenthIncrement: '',
        sixteenthIncrement: '',
        sabotageStateForSixteenthIncrement: '',
        seventeenthIncrement: '',
        sabotageStateForSeventeenthIncrement: '',
        eighteenthIncrement: '',
        sabotageStateForEighteenthIncrement: '',
        nineteenthIncrement: '',
        sabotageStateForNineteenthIncrement: '',
        twentiesIncrement: '',
        sabotageStateForTwentiesIncrement: '',
        twentyFirstIncrement: '',
        sabotageStateForTwentyFirstIncrement: '',
        twentySecondIncrement: '',
        sabotageStateForTwentySecondIncrement: '',
        twentyThirdsIncrement: '',
        sabotageStateForTwentyThirdsIncrement: '',
        mechanicalSabotagePrealarmsAmount: '',
        magneticSabotagePrealarmsAmount: '',
        radioTransmitterSignalPower: '',
        lastRadioHubReadingDate: '',
        lastRadioHubReadingYear: '',
        lastRadioHubReadingMonth: '',
        lastRadioHubReadingDay: '',
        lastRadioHubReadingHour: '',
        lastRadioHubReadingMinute: '',
        lastRadioHubReadingSecond: '',
        reserve: '',
    };

    componentDidMount() {
        const { schemeV10ReportBody, onSetProtocolType } = this.props;
        onSetProtocolType('d');
        this.setState({ ...schemeV10ReportBody });
    }

    componentDidUpdate(prevProps) {
        const { schemeV10ReportBody, selectedScheme, clearSelectedScheme } = this.props;
        if (
            schemeV10ReportBody &&
            Object.keys(schemeV10ReportBody).length > 0 &&
            prevProps.schemeV10ReportBody !== schemeV10ReportBody
        ) {
            this.setState(schemeV10ReportBody);
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
        }
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value.toUpperCase() }, () => {
            this.props.getPackagesData(
                this.setLittleEndianInLivePreview({ ...this.state }),
                `packageBody${this.props.number}`
            );
        });
    };

    setLittleEndianInLivePreview = value => {
        reportBodyV10fieldsToLittleEndian.forEach(e => {
            value[e] = toLittleEndian(value[e]);
        });
        return value;
    };

    onSubmit = () => {
        this.props.saveSelectedFormState(this.props.number, this.state);
        return false;
    };

    clearState = () => {
        this.setState({
            day: '',
            counterState: '',
            sabotageState: '',
            dailyCounterMechanicalSabotage: '',
            totalDurationMechanicalSabotage: '',
            dailyCounterMagneticSabotage: '',
            totalDurationMagneticSabotage: '',
            dailyReverseFlowCounter: '',
            firstIncrement: '',
            sabotageStateForFirstIncrement: '',
            secondIncrement: '',
            sabotageStateForSecondIncrement: '',
            thirdIncrement: '',
            sabotageStateForThirdIncrement: '',
            fourthIncrement: '',
            sabotageStateForFourthIncrement: '',
            fifthIncrement: '',
            sabotageStateForFifthIncrement: '',
            sixthIncrement: '',
            sabotageStateForSixthIncrement: '',
            seventhIncrement: '',
            sabotageStateForSeventhIncrement: '',
            eighthIncrement: '',
            sabotageStateForEighthIncrement: '',
            ninthIncrement: '',
            sabotageStateForNinthIncrement: '',
            tenthIncrement: '',
            sabotageStateForTenthIncrement: '',
            eleventhIncrement: '',
            sabotageStateForEleventhIncrement: '',
            twelfthIncrement: '',
            sabotageStateForTwelfthIncrement: '',
            thirteenthIncrement: '',
            sabotageStateForThirteenthIncrement: '',
            fourteenthIncrement: '',
            sabotageStateForFourteenthIncrement: '',
            fifteenthIncrement: '',
            sabotageStateForFifteenthIncrement: '',
            sixteenthIncrement: '',
            sabotageStateForSixteenthIncrement: '',
            seventeenthIncrement: '',
            sabotageStateForSeventeenthIncrement: '',
            eighteenthIncrement: '',
            sabotageStateForEighteenthIncrement: '',
            nineteenthIncrement: '',
            sabotageStateForNineteenthIncrement: '',
            twentiesIncrement: '',
            sabotageStateForTwentiesIncrement: '',
            twentyFirstIncrement: '',
            sabotageStateForTwentyFirstIncrement: '',
            twentySecondIncrement: '',
            sabotageStateForTwentySecondIncrement: '',
            twentyThirdsIncrement: '',
            sabotageStateForTwentyThirdsIncrement: '',
            mechanicalSabotagePrealarmsAmount: '',
            magneticSabotagePrealarmsAmount: '',
            radioTransmitterSignalPower: '',
            lastRadioHubReadingDate: '',
            lastRadioHubReadingYear: '',
            lastRadioHubReadingMonth: '',
            lastRadioHubReadingDay: '',
            lastRadioHubReadingHour: '',
            lastRadioHubReadingMinute: '',
            lastRadioHubReadingSecond: '',
            reserve: '',
        });
    };

    render() {
        const { classes, isCleared } = this.props;
        const {
            day,
            counterState,
            sabotageState,
            dailyCounterMechanicalSabotage,
            totalDurationMechanicalSabotage,
            dailyCounterMagneticSabotage,
            totalDurationMagneticSabotage,
            dailyReverseFlowCounter,
            firstIncrement,
            sabotageStateForFirstIncrement,
            secondIncrement,
            sabotageStateForSecondIncrement,
            thirdIncrement,
            sabotageStateForThirdIncrement,
            fourthIncrement,
            sabotageStateForFourthIncrement,
            fifthIncrement,
            sabotageStateForFifthIncrement,
            sixthIncrement,
            sabotageStateForSixthIncrement,
            seventhIncrement,
            sabotageStateForSeventhIncrement,
            eighthIncrement,
            sabotageStateForEighthIncrement,
            ninthIncrement,
            sabotageStateForNinthIncrement,
            tenthIncrement,
            sabotageStateForTenthIncrement,
            eleventhIncrement,
            sabotageStateForEleventhIncrement,
            twelfthIncrement,
            sabotageStateForTwelfthIncrement,
            thirteenthIncrement,
            sabotageStateForThirteenthIncrement,
            fourteenthIncrement,
            sabotageStateForFourteenthIncrement,
            fifteenthIncrement,
            sabotageStateForFifteenthIncrement,
            sixteenthIncrement,
            sabotageStateForSixteenthIncrement,
            seventeenthIncrement,
            sabotageStateForSeventeenthIncrement,
            eighteenthIncrement,
            sabotageStateForEighteenthIncrement,
            nineteenthIncrement,
            sabotageStateForNineteenthIncrement,
            twentiesIncrement,
            sabotageStateForTwentiesIncrement,
            twentyFirstIncrement,
            sabotageStateForTwentyFirstIncrement,
            twentySecondIncrement,
            sabotageStateForTwentySecondIncrement,
            twentyThirdsIncrement,
            sabotageStateForTwentyThirdsIncrement,
            mechanicalSabotagePrealarmsAmount,
            magneticSabotagePrealarmsAmount,
            radioTransmitterSignalPower,
            lastRadioHubReadingYear,
            lastRadioHubReadingMonth,
            lastRadioHubReadingDay,
            lastRadioHubReadingHour,
            lastRadioHubReadingMinute,
            lastRadioHubReadingSecond,
            reserve,
        } = this.state;

        return (
            <div>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={day}
                        onChange={value => this.handleChange('day', value)}
                        label="Dzień - format BCD: "
                        labelHex="przedział [0x01 - 0x31]"
                        validators={['isDay']}
                        interval={[1, 31]}
                        length={2}
                        dataCy="day"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={counterState}
                        onChange={value => this.handleChange('counterState', value)}
                        label="Stan licznika na godz. 1:00 [0,001 m3]: "
                        labelHex="przedział (0 - 999 999 999) [0x00000000 - 3B9AC9FF]"
                        validators={['counterState']}
                        interval={['00000000', '3B9AC9FF']}
                        length={8}
                        dataCy="counterState"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageState}
                        onChange={value => this.handleChange('sabotageState', value)}
                        label="Bieżący stan sabotaży: "
                        labelHex="przedział [0x00 - 0x1F]"
                        validators={['sabotageState']}
                        interval={['00', '1F']}
                        length={2}
                        dataCy="sabotageState"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={dailyCounterMechanicalSabotage}
                        onChange={value => this.handleChange('dailyCounterMechanicalSabotage', value)}
                        label="Dobowy licznik ilości sabotażu mechanicznego: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['dailyCounterMechanicalSabotage']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="dailyCounterMechanicalSabotage"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={totalDurationMechanicalSabotage}
                        onChange={value => this.handleChange('totalDurationMechanicalSabotage', value)}
                        label="Łączny czas trwania sabotażu mechanicznego [s]: "
                        labelHex="przedział [0x000000 - 0xFFFFFF] little endian"
                        validators={['totalDurationMechanicalSabotage']}
                        interval={['000000', 'FFFFFF']}
                        length={6}
                        dataCy="totalDurationMechanicalSabotage"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={dailyCounterMagneticSabotage}
                        onChange={value => this.handleChange('dailyCounterMagneticSabotage', value)}
                        label="Dobowy Licznik ilości sabotażu magnetycznego: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['dailyCounterMagneticSabotage']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="dailyCounterMagneticSabotage"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={totalDurationMagneticSabotage}
                        onChange={value => this.handleChange('totalDurationMagneticSabotage', value)}
                        label="Łączny czas trwania sabotażu magnetycznego [s]: "
                        labelHex="przedział [0x000000 - 0xFFFFFF] little endian"
                        validators={['totalDurationMagneticSabotage']}
                        interval={['000000', 'FFFFFF']}
                        length={6}
                        dataCy="totalDurationMagneticSabotage"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={dailyReverseFlowCounter}
                        onChange={value => this.handleChange('dailyReverseFlowCounter', value)}
                        label="Licznik dobowego przepływu wstecznego: "
                        labelHex="przedział (0 - 4 294 967 295) [0x00000000 - 0xFFFFFFFF] little endian"
                        validators={['dailyReverseFlowCounter']}
                        interval={['00000000', 'FFFFFFFF']}
                        length={8}
                        dataCy="dailyReverseFlowCounter"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={firstIncrement}
                        onChange={value => this.handleChange('firstIncrement', value)}
                        label="Przyrost 1 z godz. 2:00 (pomiędzy godz. 1:00 a 2:00) [litry]: "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['firstIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="firstIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForFirstIncrement}
                        onChange={value => this.handleChange('sabotageStateForFirstIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 1: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForFirstIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForFirstIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={secondIncrement}
                        onChange={value => this.handleChange('secondIncrement', value)}
                        label="Przyrost 2 z godz. 3:00 (pomiędzy godz. 2:00 a 3:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['secondIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="secondIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForSecondIncrement}
                        onChange={value => this.handleChange('sabotageStateForSecondIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 2: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForSecondIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForSecondIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={thirdIncrement}
                        onChange={value => this.handleChange('thirdIncrement', value)}
                        label="Przyrost 3 z godz. 4:00 (pomiędzy godz. 3:00 a 4:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['thirdIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="thirdIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForThirdIncrement}
                        onChange={value => this.handleChange('sabotageStateForThirdIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 3: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForThirdIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForThirdIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={fourthIncrement}
                        onChange={value => this.handleChange('fourthIncrement', value)}
                        label="Przyrost 4 z godz. 5:00 (pomiędzy godz. 4:00 a 5:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['fourthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="fourthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForFourthIncrement}
                        onChange={value => this.handleChange('sabotageStateForFourthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 4: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForFourthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForFourthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={fifthIncrement}
                        onChange={value => this.handleChange('fifthIncrement', value)}
                        label="Przyrost 5 z godz. 6:00 (pomiędzy godz. 5:00 a 6:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['fifthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="fifthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForFifthIncrement}
                        onChange={value => this.handleChange('sabotageStateForFifthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 5: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForFifthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForFifthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sixthIncrement}
                        onChange={value => this.handleChange('sixthIncrement', value)}
                        label="Przyrost 6 z godz. 7:00 (pomiędzy godz. 6:00 a 7:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['sixthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="sixthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForSixthIncrement}
                        onChange={value => this.handleChange('sabotageStateForSixthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 6: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForSixthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForSixthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={seventhIncrement}
                        onChange={value => this.handleChange('seventhIncrement', value)}
                        label="Przyrost 7 z godz. 8:00 (pomiędzy godz. 7:00 a 8:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['seventhIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="seventhIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForSeventhIncrement}
                        onChange={value => this.handleChange('sabotageStateForSeventhIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 7: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForSeventhIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForSeventhIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={eighthIncrement}
                        onChange={value => this.handleChange('eighthIncrement', value)}
                        label="Przyrost 8 z godz. 9:00 (pomiędzy godz. 8:00 a 9:00):  "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['eighthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="eighthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForEighthIncrement}
                        onChange={value => this.handleChange('sabotageStateForEighthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 8: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForEighthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForEighthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={ninthIncrement}
                        onChange={value => this.handleChange('ninthIncrement', value)}
                        label="Przyrost 9 z godz. 10:00 (pomiędzy godz. 9:00 a 10:00):  "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['ninthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="ninthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForNinthIncrement}
                        onChange={value => this.handleChange('sabotageStateForNinthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 9: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForNinthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForNinthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={tenthIncrement}
                        onChange={value => this.handleChange('tenthIncrement', value)}
                        label="Przyrost 10 z godz. 11:00 (pomiędzy godz. 10:00 a 11:00):  "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['tenthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="tenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForTenthIncrement}
                        onChange={value => this.handleChange('sabotageStateForTenthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 10: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForTenthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForTenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={eleventhIncrement}
                        onChange={value => this.handleChange('eleventhIncrement', value)}
                        label="Przyrost 11 z godz. 12:00 (pomiędzy godz. 11:00 a 12:00):  "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['eleventhIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="eleventhIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForEleventhIncrement}
                        onChange={value => this.handleChange('sabotageStateForEleventhIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 11: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForEleventhIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForEleventhIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={twelfthIncrement}
                        onChange={value => this.handleChange('twelfthIncrement', value)}
                        label="Przyrost 12 z godz. 13:00 (pomiędzy godz. 12:00 a 13:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['twelfthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="twelfthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForTwelfthIncrement}
                        onChange={value => this.handleChange('sabotageStateForTwelfthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 12: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForTwelfthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForTwelfthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={thirteenthIncrement}
                        onChange={value => this.handleChange('thirteenthIncrement', value)}
                        label="Przyrost 13 z godz. 14:00 (pomiędzy godz. 13:00 a 14:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['thirteenthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="thirteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForThirteenthIncrement}
                        onChange={value => this.handleChange('sabotageStateForThirteenthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 13: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForThirteenthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForThirteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={fourteenthIncrement}
                        onChange={value => this.handleChange('fourteenthIncrement', value)}
                        label="Przyrost 14 z godz. 15:00 (pomiędzy godz. 14:00 a 15:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['fourteenthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="fourteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForFourteenthIncrement}
                        onChange={value => this.handleChange('sabotageStateForFourteenthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 14: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForFourteenthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForFourteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={fifteenthIncrement}
                        onChange={value => this.handleChange('fifteenthIncrement', value)}
                        label="Przyrost 15 z godz. 16:00 (pomiędzy godz. 15:00 a 16:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['fifteenthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="fifteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForFifteenthIncrement}
                        onChange={value => this.handleChange('sabotageStateForFifteenthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 15: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForFifteenthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForFifteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sixteenthIncrement}
                        onChange={value => this.handleChange('sixteenthIncrement', value)}
                        label="Przyrost 16 z godz. 17:00 (pomiędzy godz. 16:00 a 17:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['sixteenthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="sixteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForSixteenthIncrement}
                        onChange={value => this.handleChange('sabotageStateForSixteenthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 16: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForSixteenthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForSixteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={seventeenthIncrement}
                        onChange={value => this.handleChange('seventeenthIncrement', value)}
                        label="Przyrost 17 z godz. 18:00 (pomiędzy godz. 17:00 a 18:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['seventeenthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="seventeenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForSeventeenthIncrement}
                        onChange={value => this.handleChange('sabotageStateForSeventeenthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 17: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForSeventeenthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForSeventeenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={eighteenthIncrement}
                        onChange={value => this.handleChange('eighteenthIncrement', value)}
                        label="Przyrost 18 z godz. 19:00 (pomiędzy godz. 18:00 a 19:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['eighteenthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="eighteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForEighteenthIncrement}
                        onChange={value => this.handleChange('sabotageStateForEighteenthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 18: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForEighteenthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForEighteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={nineteenthIncrement}
                        onChange={value => this.handleChange('nineteenthIncrement', value)}
                        label="Przyrost 19 z godz. 20:00 (pomiędzy godz. 19:00 a 20:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['nineteenthIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="nineteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForNineteenthIncrement}
                        onChange={value => this.handleChange('sabotageStateForNineteenthIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 19: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForNineteenthIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForNineteenthIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={twentiesIncrement}
                        onChange={value => this.handleChange('twentiesIncrement', value)}
                        label="Przyrost 20 z godz. 21:00 (pomiędzy godz. 20:00 a 21:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['twentiesIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="twentiesIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForTwentiesIncrement}
                        onChange={value => this.handleChange('sabotageStateForTwentiesIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 20: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForTwentiesIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForTwentiesIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={twentyFirstIncrement}
                        onChange={value => this.handleChange('twentyFirstIncrement', value)}
                        label="Przyrost 21 z godz. 22:00 (pomiędzy godz. 21:00 a 22:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['twentyFirstIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="twentyFirstIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForTwentyFirstIncrement}
                        onChange={value => this.handleChange('sabotageStateForTwentyFirstIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 21: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForTwentyFirstIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForTwentyFirstIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={twentySecondIncrement}
                        onChange={value => this.handleChange('twentySecondIncrement', value)}
                        label="Przyrost 22 z godz. 23:00 (pomiędzy godz. 22:00 a 23:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['twentySecondIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="twentySecondIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForTwentySecondIncrement}
                        onChange={value => this.handleChange('sabotageStateForTwentySecondIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 22: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForTwentySecondIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForTwentySecondIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={twentyThirdsIncrement}
                        onChange={value => this.handleChange('twentyThirdsIncrement', value)}
                        label="Przyrost 23 z godz. 24:00 (pomiędzy godz. 23:00 a 24:00): "
                        labelHex="przedział (0 - 65635) [0x0000 - 0xFFFF] little endian"
                        validators={['twentyThirdsIncrement']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="twentyThirdsIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={sabotageStateForTwentyThirdsIncrement}
                        onChange={value => this.handleChange('sabotageStateForTwentyThirdsIncrement', value)}
                        label="Kierunek przepływu/ liczniki sabotaży dla przyrostu 23: "
                        labelHex="przedział [0x00 - 0x7F]"
                        validators={['sabotageStateForTwentyThirdsIncrement']}
                        interval={['00', '7F']}
                        length={2}
                        dataCy="sabotageStateForTwentyThirdsIncrement"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={mechanicalSabotagePrealarmsAmount}
                        onChange={value => this.handleChange('mechanicalSabotagePrealarmsAmount', value)}
                        label="Ilość prealarów sabotażu mechanicznego: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['mechanicalSabotagePrealarmsAmount']}
                        interval={['00', 'FF']}
                        length={4}
                        dataCy="mechanicalSabotagePrealarmsAmount"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={magneticSabotagePrealarmsAmount}
                        onChange={value => this.handleChange('magneticSabotagePrealarmsAmount', value)}
                        label="Ilość prealarów sabotażu magnetycznego: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['magneticSabotagePrealarmsAmount']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="magneticSabotagePrealarmsAmount"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={radioTransmitterSignalPower}
                        onChange={value => this.handleChange('radioTransmitterSignalPower', value)}
                        label="Siła sygnału nadajnika radiowego: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['radioTransmitterSignalPower']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="radioTransmitterSignalPower"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastRadioHubReadingYear}
                        onChange={value => this.handleChange('lastRadioHubReadingYear', value)}
                        label="Rok ostatniego odczytu nadajnika w koncentratorze format BCD: "
                        labelHex="przedział [0x00 - 0x99]"
                        validators={['isYear']}
                        interval={[0, 99]}
                        length={2}
                        dataCy="lastRadioHubReadingYear"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastRadioHubReadingMonth}
                        onChange={value => this.handleChange('lastRadioHubReadingMonth', value)}
                        label="Miesiąc ostatniego odczytu nadajnika w koncentratorze format BCD: "
                        labelHex="przedział [0x01 - 0x12]"
                        validators={['isMonth']}
                        interval={[1, 12]}
                        length={2}
                        dataCy="lastRadioHubReadingMonth"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastRadioHubReadingDay}
                        onChange={value => this.handleChange('lastRadioHubReadingDay', value)}
                        label="Dzień ostatniego odczytu nadajnika w koncentratorze format BCD: "
                        labelHex="przedział [0x01 - 0x31]"
                        validators={['isDayLast']}
                        interval={[1, 31]}
                        length={2}
                        dataCy="lastRadioHubReadingDay"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastRadioHubReadingHour}
                        onChange={value => this.handleChange('lastRadioHubReadingHour', value)}
                        label="Godzina ostatniego odczytu nadajnika w koncentratorze format BCD: "
                        labelHex="przedział [0x00 - 0x23]"
                        validators={['isHour']}
                        interval={[0, 23]}
                        length={2}
                        dataCy="lastRadioHubReadingHour"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastRadioHubReadingMinute}
                        onChange={value => this.handleChange('lastRadioHubReadingMinute', value)}
                        label="Minuta ostatniego odczytu nadajnika w koncentratorze format BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isMinute']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="lastRadioHubReadingMinute"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={lastRadioHubReadingSecond}
                        onChange={value => this.handleChange('lastRadioHubReadingSecond', value)}
                        label="Sekunda ostatniego odczytu nadajnika w koncentratorze format BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isSecond']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="lastRadioHubReadingSecond"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={reserve}
                        onChange={value => this.handleChange('reserve', value)}
                        label="rezerwa - 'x': "
                        labelHex="[0x78]"
                        validators={['reserve']}
                        interval={[78, 78]}
                        length={2}
                        dataCy="reserve"
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        value="Submit"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

export default withStyles(styles)(ReportBodyV10);
