import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

class SummaryBodyV8 extends Component {
    state = {
        summedUpAlertSummaryType: '',
        summaryDate: '',
        alertSummaryReportID: '',
        magneticAlertsReportsCounter: '',
        mechanicalAlertsReportsCounter: '',
        onDemandAlertsReportsCounter: '',
        lastSabotageCounterState: '',
        sabotageFlow: '',
        hubSabotageState: '',
        summedUpAlertOccurrence: '',
        summedUpAlertSummaryAmount: '',
        hubPrealarmsAmount: '',
        firstAlertOccurrenceDate: '',
        firstAlertDuration: '',
        secondAlertOccurrenceDate: '',
        secondAlertDuration: '',
        thirdAlertOccurrenceDate: '',
        thirdAlertDuration: '',
        lastSabotageResolveDate: '',
        reserve: '',
    };

    componentDidMount() {
        const { schemeV8SummaryBody, onSetProtocolType } = this.props;
        onSetProtocolType('Y');
        this.setState({ ...schemeV8SummaryBody });
    }

    componentDidUpdate(prevProps) {
        const { schemeV8SummaryBody, selectedScheme, clearSelectedScheme } = this.props;
        if (
            schemeV8SummaryBody &&
            Object.keys(schemeV8SummaryBody).length > 0 &&
            prevProps.schemeV8SummaryBody !== schemeV8SummaryBody
        ) {
            this.setState(schemeV8SummaryBody);
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
        }
    }

    handleChange = (key, event) => {
        this.setState({ [key]: event.target.value }, () =>
            this.props.getPackagesData(this.state, `packageBody${this.props.number}`)
        );
    };

    onSubmit = () => {
        this.props.saveSelectedFormState(this.props.number, this.state);
        return false;
    };

    clearState = () => {
        this.setState({
            summedUpAlertSummaryType: '',
            summaryDate: '',
            alertSummaryReportID: '',
            magneticAlertsReportsCounter: '',
            mechanicalAlertsReportsCounter: '',
            onDemandAlertsReportsCounter: '',
            lastSabotageCounterState: '',
            sabotageFlow: '',
            hubSabotageState: '',
            summedUpAlertOccurrence: '',
            summedUpAlertSummaryAmount: '',
            hubPrealarmsAmount: '',
            firstAlertOccurrenceDate: '',
            firstAlertDuration: '',
            secondAlertOccurrenceDate: '',
            secondAlertDuration: '',
            thirdAlertOccurrenceDate: '',
            thirdAlertDuration: '',
            lastSabotageResolveDate: '',
            reserve: '',
        });
    };

    render() {
        const { classes } = this.props;
        const {
            summedUpAlertSummaryType,
            summaryDate,
            alertSummaryReportID,
            magneticAlertsReportsCounter,
            mechanicalAlertsReportsCounter,
            onDemandAlertsReportsCounter,
            lastSabotageCounterState,
            sabotageFlow,
            hubSabotageState,
            summedUpAlertSummaryAmount,
            summedUpAlertOccurrence,
            hubPrealarmsAmount,
            firstAlertOccurrenceDate,
            firstAlertDuration,
            secondAlertOccurrenceDate,
            secondAlertDuration,
            thirdAlertOccurrenceDate,
            thirdAlertDuration,
            lastSabotageResolveDate,
            reserve,
        } = this.state;

        return (
            <div>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={summedUpAlertSummaryType}
                        onChange={e => this.handleChange('summedUpAlertSummaryType', e)}
                        label="Rodzaj podsumowywanego alarmu: 1 znak [M,G,S,O] np. M"
                        validators={['matchRegexp:^([GMOS])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="summedUpAlertSummaryType"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={summaryDate}
                        onChange={e => this.handleChange('summaryDate', e)}
                        label="Dzień, godz, min, sek utworzenia podsumowania: 8 znaków [0-9] np.12345678"
                        validators={['matchRegexp:^([0-9]{8})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="summaryDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertSummaryReportID}
                        onChange={e => this.handleChange('alertSummaryReportID', e)}
                        label="ID raportu alarmowego (dla którego zostało utworzone to podsumowanie): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertSummaryReportID"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={magneticAlertsReportsCounter}
                        onChange={e => this.handleChange('magneticAlertsReportsCounter', e)}
                        label="Licznik ilości raportów alarmu magnetycznego (dla momentu utworzenia podsumowania): 4 znaki [0000-9999 albo X] np. 1234"
                        validators={['matchRegexp:^([0-9xX]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="magneticAlertsReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={mechanicalAlertsReportsCounter}
                        onChange={e => this.handleChange('mechanicalAlertsReportsCounter', e)}
                        label="Licznik ilości raportów alarmu mechanicznego (dla momentu utworzenia podsumowania): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="mechanicalAlertsReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={onDemandAlertsReportsCounter}
                        onChange={e => this.handleChange('onDemandAlertsReportsCounter', e)}
                        label="Licznik ilości raportów na żądanie (dla momentu utworzenia podsumowania): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="onDemandAlertsReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={lastSabotageCounterState}
                        onChange={e => this.handleChange('lastSabotageCounterState', e)}
                        label="Stan licznika na godz ustąpienia ostatniego sabotażu [0,001 m3]: 9 znaków [000000000-999999999 albo XXXXXXXXX] np. 123456789"
                        validators={['matchRegexp:^([0-9xX]{9})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="lastSabotageCounterState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageFlow}
                        onChange={e => this.handleChange('sabotageFlow', e)}
                        label="Przepływ / stan sabotaży w nadajniku radiowym: 1 znak [+,-,m,M,g,G,d,D,X] np. m"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D]|[xX])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageFlow"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubSabotageState}
                        onChange={e => this.handleChange('hubSabotageState', e)}
                        label="Stan sabotaży w koncentratorze: 1 znak [M,O,D,B] np. M"
                        validators={['matchRegexp:^([MODB])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubSabotageState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={summedUpAlertSummaryAmount}
                        onChange={e => this.handleChange('summedUpAlertSummaryAmount', e)}
                        label="Ilość wystąpień podsumowywanego alarmu pomiędzy wygenerowaniem raportu alarmowego i bieżącego podsumowania: 3 znaki [0x000-0xFFF] np. FFF"
                        validators={['matchRegexp:^([0-9A-F000-FFF]{3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="summedUpAlertSummaryAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={summedUpAlertOccurrence}
                        onChange={e => this.handleChange('summedUpAlertOccurrence', e)}
                        label="Łączny czas występowania alarmów od wygenerowania raportu alarmowego do utworzenia bieżącego podsumowania [min]: 4 znaki [0x0000-0xFFFF] np. FFFF"
                        validators={['matchRegexp:^([0-9A-F000-FFF]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="summedUpAlertOccurrence"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubPrealarmsAmount}
                        onChange={e => this.handleChange('hubPrealarmsAmount', e)}
                        label="Ilość prealarmów w nadajniku radiowym od początku doby: 1 znak [0-9,a-z,A-K] np. 1"
                        validators={['matchRegexp:^([0-9A-Ka-z])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubPrealarmsAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firstAlertOccurrenceDate}
                        onChange={e => this.handleChange('firstAlertOccurrenceDate', e)}
                        label="Data / czas powstania alarmu 1: 6 znaków [0-9,a-z,A-Z] np.12abAB"
                        validators={['matchRegexp:^([0-9A-Za-z]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firstAlertOccurrenceDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firstAlertDuration}
                        onChange={e => this.handleChange('firstAlertDuration', e)}
                        label="Czas trwania alarmu 1: 6 znaków [0x000000-0xFFFFFF] np. FFFFFF"
                        validators={['matchRegexp:^([0-9A-F000000-FFFFFF]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firstAlertDuration"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={secondAlertOccurrenceDate}
                        onChange={e => this.handleChange('secondAlertOccurrenceDate', e)}
                        label="Data / czas powstania alarmu 2: 6 znaków [0-9,a-z,A-Z] np.12abAB"
                        validators={['matchRegexp:^([0-9A-Za-z]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="secondAlertOccurrenceDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={secondAlertDuration}
                        onChange={e => this.handleChange('secondAlertDuration', e)}
                        label="Czas trwania alarmu 2: 6 znaków [0x000000-0xFFFFFF] np. FFFFFF"
                        validators={['matchRegexp:^([0-9A-F000000-FFFFFF]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="secondAlertDuration"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={thirdAlertOccurrenceDate}
                        onChange={e => this.handleChange('thirdAlertOccurrenceDate', e)}
                        label="Data / czas powstania alarmu 3: 6 znaków [0-9,a-z,A-Z] np.12abAB"
                        validators={['matchRegexp:^([0-9A-Za-z]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="thirdAlertOccurrenceDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={thirdAlertDuration}
                        onChange={e => this.handleChange('thirdAlertDuration', e)}
                        label="Czas trwania alarmu 3: 6 znaków [0x000000-0xFFFFFF] np. FFFFFF"
                        validators={['matchRegexp:^([0-9A-F000000-FFFFFF]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="thirdAlertDuration"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={lastSabotageResolveDate}
                        onChange={e => this.handleChange('lastSabotageResolveDate', e)}
                        label="Data / czas ustąpienia ostatniego sabotażu: 6 znaków [0-9,a-z,A-Z] np.12abAB"
                        validators={['matchRegexp:^([0-9A-Za-z]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="lastSabotageResolveDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={reserve}
                        onChange={e => this.handleChange('reserve', e)}
                        label="Rezerwa: 3 znaki [x] np. xxx"
                        validators={['matchRegexp:^([x]{3})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="reserve"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        value="Submit"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

SummaryBodyV8.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SummaryBodyV8);
