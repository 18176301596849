import React, { forwardRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { communicatorsType, communicatorsTypePl, localStorageKey } from '../../../utils/constants';
import theme from '../../../themes/theme';
import PopupModal from '../../PopupModal/PopupModal';

const useStyles = makeStyles({
    cellsContainer: {
        borderBottom: 'none',
        display: 'flex',
        flexDirection: 'column',
        background: '#f5f5f5',
        padding: '.7rem 0',
        border: `1px solid ${theme.colors.border}`,
    },
    cellRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: 20,
    },
    cell: {
        display: 'flex',
        fontSize: '.9rem',
        padding: 0,
        margin: 5,
        marginRight: 50,
    },
    cellNumbers: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'auto',
        maxHeight: '25vh'
    },
    cellNumber: {
        margin: 0,
    },
});

const ListConfigHeader = (props, ref) => {
    const MAX_COMMUNICATOR_NUMBERS_TO_SHOW = 10;

    const { cell, cellRow, cellsContainer, cellNumbers, cellNumber } = useStyles();
    const [listConfigHeaders, setListConfigHeaders] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [communicatorNumbers, setCommunicatorNumbers] = useState([]);
    const [numberOfCommunicatorNumbers, setNumberOfCommunicatorNumbers] = useState(0);

    useEffect(() => {
        setListConfigHeaders(getListConfigHeaders());
    }, []);

    useEffect(() => {
        if (listConfigHeaders.communicatorsList) {
            const communicators = listConfigHeaders?.communicatorsList.split(',');
            setCommunicatorNumbers(communicators)
            setNumberOfCommunicatorNumbers(communicators.length)
        }
    }, [listConfigHeaders]);


    const getListConfigHeaders = () => {
        const configList = JSON.parse(localStorage.getItem(props.storageKey));

        const getSelectedWaterpipeName = JSON.parse(
            localStorage.getItem(localStorageKey.WATERPIPES_LIST)
        ).filter(waterpipesList => waterpipesList.id === configList.waterpipeId)[0]?.name;

        function getCommunicatorTransaltion(comunicatorType) {
            if (comunicatorType === communicatorsType.COUPLED_COMMUNICATOR) {
                return communicatorsTypePl.COUPLED_COMMUNICATOR;
            }
            if (comunicatorType === communicatorsType.COMMUNICATOR) {
                return communicatorsTypePl.COMMUNICATOR;
            }
            if (comunicatorType === communicatorsType.CONCENTRATOR) {
                return communicatorsTypePl.CONCENTRATOR;
            }

            return 'komunikator nieznany';
        }

        return {
            waterpipe: getSelectedWaterpipeName,
            waterpipeSign: configList.waterpipeSign,
            datesRangeFrom: configList.dateFrom,
            datesRangeTo: configList.dateTo,
            communicatorType: getCommunicatorTransaltion(configList.type),
            communicatorsFrom: configList.numbersFrom,
            communicatorsTo: configList.numbersTo,
            communicatorsList: configList.numbers,
            favoriteReportName: configList.selectedFavoriteReport?.name,
            channel: configList.channel,
        };
    };

    const renderListOfCommunicatorNumbers = () => {
        if (!listConfigHeaders?.communicatorsList) {
            return null;
        }

        return communicatorNumbers.map((item, index) => {
            if (index >= MAX_COMMUNICATOR_NUMBERS_TO_SHOW) {
                return null;
            }

            const isLastCommunicationNumber = index + 1 === numberOfCommunicatorNumbers;
            const isMaxShownCommunicationNumber = index + 1 === MAX_COMMUNICATOR_NUMBERS_TO_SHOW;

            if (isLastCommunicationNumber) {
                return (
                    <p className={cellNumber} key={index}>
                        {item}
                    </p>
                );
            }

            if (isMaxShownCommunicationNumber) {
                return (
                    <p className={cellNumber} key={index}>
                        {item}... [ <a href="#/test-generated-list-v2" onClick={() => setShowModal(true)}>pokaż więcej</a> ]
                    </p>
                );
            }

            return (
                <p className={cellNumber} key={index}>
                    {item}, &nbsp;
                </p>
            );
        });
    };


    return (
        <section className={cellsContainer} ref={ref}>
            {listConfigHeaders?.favoriteReportName && (
                <div className={cellRow}>
                    <div className={cell}>
                        <b>ULUBIONE ZESTAWIENIE:&nbsp;</b>
                        {listConfigHeaders.favoriteReportName}
                    </div>
                </div>
            )}
            <div className={cellRow}>
                <div className={cell}>
                    <b>WODOCIĄG:&nbsp;</b>
                    {listConfigHeaders?.waterpipe}{' '}
                    {listConfigHeaders?.waterpipeSign ? `(${listConfigHeaders?.waterpipeSign})` : ''}
                </div>
                <div className={cell}>
                    <b>OD:&nbsp;</b>
                    {listConfigHeaders?.datesRangeFrom} &nbsp;
                    <b>DO:&nbsp;</b>
                    {listConfigHeaders?.datesRangeTo}
                </div>
                {listConfigHeaders?.channel ? (
                    <div className={cell}>
                        <b>KANAŁ:&nbsp;</b>
                        {listConfigHeaders?.channel}
                    </div>
                ) : (
                    <div className={cell}>
                        <b>TYP:&nbsp;</b>
                        {listConfigHeaders?.communicatorType}
                    </div>
                )}
                {listConfigHeaders?.communicatorsFrom && (
                    <div className={cell}>
                        <b>OD:&nbsp;</b> {listConfigHeaders.communicatorsFrom}&nbsp;
                        <b>DO:&nbsp;</b>
                        {listConfigHeaders.communicatorsTo}
                    </div>
                )}
                {listConfigHeaders?.communicatorsList && (
                    <div className={cell}>
                        <b>LISTA NUMERÓW:&nbsp;</b>
                        <div className={cellNumbers}>
                            {renderListOfCommunicatorNumbers()}
                        </div>
                        <PopupModal
                            data={listConfigHeaders.communicatorsList.replaceAll(',', ', ')}
                            openModal={showModal}
                            setOpenModal={setShowModal}
                            headerText="Lista numerów"
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

export default forwardRef(ListConfigHeader);
