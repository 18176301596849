import React, { useLayoutEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from '../EnhancedTableHead/EnhancedTableHead';
import {
    getComparator,
    getObjectKeyByValue,
    isColumnHighlighted,
    isObject,
    isValueBelow10,
    stableSort,
} from '../../../utils/helpers';
import ListConfigHeader from '../ListConfigHeader/ListConfigHeader';
import ExpandableComponent from './ExpandableComponent/ExpandableComponent';
import { columnTypes, localStorageKey, tabHeadOrderProdList } from '../../../utils/constants';

const useStyles = makeStyles({
    root: {
        paddingTop: '3rem',
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: '8.6rem',
    },
    container: props => ({
        maxHeight: `calc(100vh - 10rem - ${props.listNumbersContainerHeight}px)`,
    }),
    table: {
        minWidth: '46.875rem',
    },
    tableCell: {
        padding: 0,
        borderBottom: 'none',
        borderRight: '1px solid #f5f5f5',
    },
    tableCellRed: {
        color: 'red',
    },
});

export default function List({
    order,
    orderBy,
    handleRequestSort,
    getSorTableData,
    listDataReadingLastVal,
    generatedListData,
    headCells,
}) {
    const [listNumbersContainerHeight, setListNumbersContainerHeight] = useState(0);
    const classes = useStyles({ listNumbersContainerHeight });
    const { tableCell, tableCellRed, table, container, root, paper } = classes;
    const [expandedRows, setExpandedRows] = useState([]);

    let lastReadingsId;

    const cellNumbersRef = useRef(null);

    const getClassName = (row, index, value) => {
        if (getObjectKeyByValue(row, index) === 'report_gsm_level' && isValueBelow10(value)) {
            return [tableCell, tableCellRed].join(' ');
        }

        return tableCell;
    };

    const isFieldTypeZeroToEmptySpace = fieldType => {
        const zeroToEmptySpaceFields = [
            columnTypes.REPORT_PLUS,
            columnTypes.REPORT_MINUS,
            columnTypes.REPORT_M,
            columnTypes.REPORT_CAPITAL_M,
            columnTypes.REPORT_G,
            columnTypes.REPORT_CAPITAL_G,
            columnTypes.REPORT_D,
            columnTypes.REPORT_CAPITAL_D,
            columnTypes.PARSER_ERROR,
        ];
        return zeroToEmptySpaceFields.includes(fieldType);
    };

    const changeZeroToEmptySpace = columnType => isFieldTypeZeroToEmptySpace(columnType);

    const getValue = (value, columnType) => {
        function isValueTheLastReading(val) {
            return isObject(val);
        }

        if (changeZeroToEmptySpace(columnType) && +value === 0) return ' ';
        if (isValueTheLastReading(value)) {
            value = value?.lastReading;
        }
        return value;
    };

    const setCellValues = (row, indexRow) => {
        const isGeneratedListData = Object.keys(generatedListData).length > 0;
        return Object.values(row).map((value, indexVal) => {
            const columnType = Object.keys(row)[indexVal];

            if (columnTypes.REPORT_READINGS === columnType) {
                lastReadingsId = value.reportId;
            }

            if (columnType === columnTypes.REPORT_READINGS || columnType === columnTypes.REPORT_SABOTAGE) {
                return (
                    isGeneratedListData && (
                        <ExpandableComponent
                            key={indexVal}
                            indexRow={indexRow}
                            listDataReadingLastVal={listDataReadingLastVal}
                            generatedListData={generatedListData}
                            value={value}
                            getValue={getValue}
                            columnType={columnType}
                            expandedRows={expandedRows}
                            setExpandedRows={setExpandedRows}
                            lastReadingsId={lastReadingsId}
                        />
                    )
                );
            }

            return (
                <TableCell
                    key={indexVal}
                    align="center"
                    size="small"
                    className={getClassName(row, indexVal, value, indexRow)}
                    style={isColumnHighlighted(indexRow)}>
                    {getValue(value, columnType)}
                </TableCell>
            );
        });
    };

    useLayoutEffect(() => {
        setListNumbersContainerHeight(cellNumbersRef.current.clientHeight);
    }, []);

    const getOrderedData = () => ({
        headCells: () =>
            headCells().sort(
                (a, b) => tabHeadOrderProdList.indexOf(a.label) - tabHeadOrderProdList.indexOf(b.label)
            ),
        tableData: getSorTableData().map(item =>
            Object.keys(item)
                .sort((a, b) => tabHeadOrderProdList.indexOf(a) - tabHeadOrderProdList.indexOf(b))
                .reduce((acc, key) => {
                    acc[key] = item[key];
                    return acc;
                }, {})
        ),
    });

    return (
        <div className={root}>
            <ListConfigHeader ref={cellNumbersRef} storageKey={localStorageKey.GENERATED_PROD_LIST_DATA} />
            <Paper className={paper}>
                <TableContainer className={container}>
                    <Table stickyHeader className={table} size="small">
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={getSorTableData().length}
                            headCells={getOrderedData().headCells}
                        />

                        <TableBody>
                            {stableSort(getOrderedData().tableData, getComparator(order, orderBy)).map(
                                (row, indexRow) => (
                                    <TableRow key={indexRow}>{setCellValues(row, indexRow)}</TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}
