/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { withStyles } from '@material-ui/styles';
import CopyModal from '../../CopyModal/CopyModal';
import {
    endpoint,
    responseCode,
    tableTranslations,
    snackbarMsg,
    snackbarVariant,
    listTypesId,
    listTypesNames,
    columnTypes,
    tabHeadToSymbolsToTitles,
    tabHeadToSymbols,
    TEMPLATE_PREFIX,
    localStorageKey,
    snackbarDefaultValue,
} from '../../../utils/constants';
import { translateCommunicatorTypePl } from '../../../utils/helpers';
import { axiosInstance } from '../../../utils/axiosInstance';
import Snackbar from '../../Snackbar/Snackbar';
import PaginationRows from '../../Pagination/PaginationRows';
import editIcon from '../../../assets/img/edit-icon.svg';
import theme from '../../../themes/theme';

const styles = {
    container: {
        position: 'relative',
        height: 'calc(100% - 50px - 1rem)',
    },
    table: {
        height: '100%',
        fontFamily: theme.font.family,
        position: 'relative',
    },
    tableHeader: {
        borderBottom: 'none',
        background: theme.colors.panelBackground,
        border: `1px solid ${theme.colors.border}`,
        display: 'flex',
        padding: 20,
    },
    tableName: {
        fontWeight: theme.font.weight.bold,
        marginLeft: 10,
        width: '100%',
    },
};

const FavoriteReportsTable = ({ data, templateType, classes: { container, tableHeader, tableName } }) => {
    const [tableData, setTableData] = useState(null);
    const [confirmedData, setConfirmedData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    const [snackbarData, setSnackbarData] = useState(snackbarDefaultValue);
    const PAGINATION_SIZES = [10, 50, 100, 500, 1000];
    const [paginationSize, setPaginationSize] = useState(50);

    const columnsList = [
        {
            field: columnTypes.ORDINAL_NUMBER,
            width: 60,
        },
        {
            field: columnTypes.TEMPLATE_NAME,
        },
        {
            field: columnTypes.TEMPLATE_DESCRIPTION,
        },
        {
            field: columnTypes.TEMPLATE_NUMBERS,
        },
        {
            field: columnTypes.TEMPLATE_CREATED_AT,
            width: 170,
        },
        {
            field: columnTypes.TEMPLATE_WATERPIPE_NAME,
            width: 150,
        },
        {
            field: columnTypes.TEMPLATE_WATERPIPE_SIGN,
            width: 80,
        },
        {
            field: columnTypes.TEMPLATE_CHANNEL,
            width: 80,
        },
        {
            field: columnTypes.TEMPLATE_ACTION,
            width: 70,
        },
    ];

    const onCellClicked = params => {
        const { data } = params.node;
        if (params.column.colId === columnTypes.TEMPLATE_ACTION && typeof window !== 'undefined') {
            window.open(`${window.location.origin}/#/list-template/edit/${data.id}`, '_self');
        } else {
            setModalData(
                params.value?.length === 0 || !params.value ? null : translateCommunicatorTypePl(params.value)
            );
            setShowModal(true);
        }
    };

    const setCellStyle = params => {
        if (params.colDef.field === columnTypes.TEMPLATE_ACTION) {
            return {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            };
        }
        return {};
    };

    const cellRenderer = params => {
        if (params.colDef.field === columnTypes.TEMPLATE_ACTION) {
            const actionField = document.createElement('div');
            actionField.style.display = 'flex';
            actionField.style.alignItems = 'center';
            actionField.style.justifyContent = 'center';
            actionField.innerHTML = `<img src=${editIcon} style="width: 20px; height: 20px; cursor: pointer;"/>`;

            return actionField;
        }
        return null;
    };

    const onGridReady = params => setGridApi(params.api);

    const changePaginationSize = event => {
        setPaginationSize(+event.target.value);
    };

    const updateData = () => {
        const { waterpipeName, waterpipeSign, ...updatedData } = confirmedData;
        const putData = {
            waterpipe_id: updatedData.waterpipeId,
            id: updatedData.id,
            name: updatedData.name,
            description: updatedData.description,
            channel: updatedData.channel,
            template_type: updatedData.templateType,
            numbers: updatedData.numbers,
        };

        axiosInstance
            .put(`${endpoint.LIST_TEMPLATE_UPDATE}/${confirmedData.id}`, putData)
            .then(res => {
                if (res.status === responseCode.SUCCESS) {
                    setTableData(tableData =>
                        tableData.map(data => (data.id === confirmedData.id ? { ...confirmedData } : data))
                    );

                    setSnackbarData({
                        isSnackbarOpen: true,
                        message: snackbarMsg.LIST_TEMPLATE_HAS_BEEN_EDITED,
                        errorInfo: '',
                        variant: snackbarVariant.SUCCESS,
                    });
                }
            })
            .catch(e => {
                console.error('Error while editing a report:', e);

                setSnackbarData({
                    isSnackbarOpen: true,
                    message: snackbarMsg.ERROR_CONNECTION,
                    errorInfo: '',
                    variant: snackbarVariant.ERROR,
                });
            });
    };

    useEffect(() => {
        const changedData = JSON.parse(localStorage.getItem(localStorageKey.FAVORITE_REPORT_EDITED));

        if (changedData) {
            setConfirmedData(changedData);
            localStorage.removeItem(localStorageKey.FAVORITE_REPORT_EDITED);
        }
    }, []);

    const deleteData = () => {
        axiosInstance
            .delete(`${endpoint.LIST_TEMPLATE_DELETE}/${confirmedData.id}`)
            .then(res => {
                if (res.status === responseCode.SUCCESS) {
                    setTableData(tableData => tableData.filter(data => data.id !== confirmedData.id));

                    setSnackbarData({
                        isSnackbarOpen: true,
                        message: snackbarMsg.LIST_TEMPLATE_HAS_BEEN_DELETED,
                        errorInfo: '',
                        variant: snackbarVariant.SUCCESS,
                    });
                }
            })
            .catch(e => {
                console.error('Error while deleting a report:', e);

                setSnackbarData({
                    isSnackbarOpen: true,
                    message: snackbarMsg.ERROR_CONNECTION,
                    errorInfo: '',
                    variant: snackbarVariant.ERROR,
                });
            });
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
            const columnState = JSON.parse(localStorage.getItem(localStorageKey.LIST_TEMPLATES_COLUMN_STATE));
            if (columnState) {
                gridApi.columnModel.applyColumnState({ state: columnState });
            }
        }
    }, [gridApi]);

    useEffect(() => {
        if (gridApi) {
            if (!tableData) {
                gridApi.showLoadingOverlay();
            } else if (tableData?.length) {
                gridApi.hideOverlay();
            }
        }
    }, [gridApi, tableData]);

    useEffect(() => {
        setTableData(data);
    }, [data]);

    useEffect(() => {
        const confirmedDataLength = Object.keys(confirmedData).length;
        if (confirmedDataLength) {
            if (confirmedData?.error) {
                setSnackbarData({
                    isSnackbarOpen: true,
                    message: snackbarMsg.ERROR_CONNECTION,
                    errorInfo: '',
                    variant: snackbarVariant.ERROR,
                });
            } else if (confirmedDataLength === 1) {
                deleteData();
            } else {
                updateData();
            }
        }
    }, [confirmedData]);

    const handleIsSnackbarOpen = () => {
        setSnackbarData(snackbarData => ({
            isSnackbarOpen: false,
            message: snackbarData.message,
            errorInfo: snackbarData.errorInfo,
            variant: snackbarData.variant,
        }));
    };

    const handleReturn = () => {
        setShowModal(false);
    };

    const onSortChanged = params => {
        const columnState = params.columnApi.getColumnState();
        localStorage.setItem(localStorageKey.LIST_TEMPLATES_COLUMN_STATE, JSON.stringify(columnState));
        params.api.refreshCells();
    };

    const onFilterChanged = params => {
        params.api.refreshCells();
    };

    return (
        <div className={container}>
            {showModal && <CopyModal modalData={modalData} handleReturn={handleReturn} />}
            <div className={tableHeader}>
                <div className={tableName}>{`Ulubione zestawienia (${
                    templateType === listTypesId.TEST_LIST
                        ? listTypesNames.TEST_LIST
                        : listTypesNames.PRODUCTION_LIST
                })`}</div>
            </div>
            <div className={'ag-theme-alpine'} style={styles.table}>
                <AgGridReact
                    onSortChanged={onSortChanged}
                    onFilterChanged={onFilterChanged}
                    localeText={tableTranslations}
                    rowData={tableData}
                    suppressCellSelection
                    onCellClicked={onCellClicked}
                    onGridReady={onGridReady}
                    paginationPageSize={paginationSize}
                    pagination
                    tooltipShowDelay={0}
                    enableCellTextSelection
                    suppressDragLeaveHidesColumns
                    accentedSort>
                    {columnsList.map(column => (
                        <AgGridColumn
                            key={column.field}
                            headerName={
                                column.field === columnTypes.ORDINAL_NUMBER
                                    ? tabHeadToSymbols.ordinal_number
                                    : tabHeadToSymbolsToTitles[TEMPLATE_PREFIX + column.field]
                            }
                            headerTooltip={
                                tabHeadToSymbolsToTitles[
                                    column.field !== columnTypes.ORDINAL_NUMBER
                                        ? TEMPLATE_PREFIX + column.field
                                        : column.field
                                ]
                            }
                            field={column.field}
                            cellRenderer={column.field === columnTypes.TEMPLATE_ACTION && cellRenderer}
                            cellStyle={setCellStyle}
                            suppressSizeToFit={!!column.width}
                            width={column.width}
                            tooltipValueGetter={params =>
                                column.field === columnTypes.TEMPLATE_NAME ||
                                column.field === columnTypes.TEMPLATE_DESCRIPTION ||
                                column.field === columnTypes.TEMPLATE_NUMBERS
                                    ? params.value
                                    : ''
                            }
                            resizable
                            sortable={
                                column.field !== columnTypes.TEMPLATE_ACTION &&
                                column.field !== columnTypes.ORDINAL_NUMBER
                            }
                            filter={
                                column.field !== columnTypes.TEMPLATE_ACTION &&
                                column.field !== columnTypes.ORDINAL_NUMBER
                            }
                            valueGetter={params => {
                                if (column.field === columnTypes.ORDINAL_NUMBER) {
                                    return params.node.rowIndex + 1;
                                }

                                return params.data[column.field];
                            }}
                        />
                    ))}
                </AgGridReact>
                <PaginationRows
                    paginationSizes={PAGINATION_SIZES}
                    paginationSize={paginationSize}
                    changePaginationSize={changePaginationSize}
                />
                <Snackbar snackbarData={snackbarData} handleIsSnackbarOpen={handleIsSnackbarOpen} />
            </div>
        </div>
    );
};

export default withStyles(styles)(FavoriteReportsTable);
