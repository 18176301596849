const theme = {
    font: {
        family: 'Roboto, Helvetica, Arial, sans-serif',
        weight: {
            normal: 400,
            bold: 700,
        },
    },
    margins: {
        textField: {
            all: '1rem',
            top: '1.4rem',
            bottom: '0.8rem',
        },
        button: {
            all: '2rem',
        },
    },
    colors: {
        link: '#1E90FF',
        background: '#f8f8f8',
        panelBackground: '#f5f5f5',
        border: '#babfc7',
        main: '#3f51b5',
        success: '#59E370',
        white: '#ffffff',
        tableTabs: {
            tab_1: '#0000FF',
            tab_2: '#FF0000',
            tab_3: '#008000',
            tab_4: '#FFFF00',
            tab_5: '#800080',
            opacity: '30',
        },
    },
    mediaBreakpoints: {
        md: '@media (max-width: 768px)',
        md_waterpipe: '@media (max-width: 1450px)',
        sm_waterpipe: '@media (max-width: 946px)',
    },
};

export default theme;
