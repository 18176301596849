import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Close from '@material-ui/icons/Close';
import theme from '../../themes/theme';
import copyIcon from '../../assets/img/copy-regular.svg';
import { tabHeadToSymbols } from '../../utils/constants';

const UNITS = {
    BORDER_RADIUS: {
        amount: 0.6,
        type: 'rem',
    },
    PADDING: {
        amount: 0.5,
        type: 'rem',
    },
    OPACITY: 0.4,
    closeButton: {
        position: 20,
        shadowSize: 5,
        shadowOpacity: 0.1,
    },
};

const useStyles = makeStyles({
    modalContainer: {
        zIndex: 999999,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundColor: `rgba(0,0,0,${UNITS.OPACITY})`,
    },
    modalBox: {
        backgroundColor: theme.colors.white,
        borderRadius: `${UNITS.BORDER_RADIUS.amount}${UNITS.BORDER_RADIUS.type}`,
        padding: `${UNITS.PADDING.amount}${UNITS.PADDING.type} ${
            UNITS.PADDING.amount * 3
        }${UNITS.PADDING.type}
        ${UNITS.PADDING.amount * 3}${UNITS.PADDING.type} ${UNITS.PADDING.amount * 3}${UNITS.PADDING.type}`,
        position: 'relative',
        minWidth: '14rem',
        maxWidth: '70vw',
    },

    closeButton: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        borderStyle: 'none',
        cursor: 'pointer',
        backgroundColor: theme.colors.white,
        boxShadow: `0 0 ${UNITS.closeButton.shadowSize}px ${UNITS.closeButton.shadowSize}px rgba(0,0,0,${UNITS.closeButton.shadowOpacity})`,
        height: `${UNITS.closeButton.position * 2}px`,
        width: `${UNITS.closeButton.position * 2}px`,
        right: `-${UNITS.closeButton.position}px`,
        top: `-${UNITS.closeButton.position}px`,
        '&:hover': {
            backgroundColor: theme.colors.panelBackground,
        },
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    notifyText: {
        position: 'absolute',
        width: 'fit-content',
        left: 0,
        top: '-60px',
        padding: `${UNITS.PADDING.amount}${UNITS.PADDING.type}`,
        color: theme.colors.white,
        borderRadius: `${UNITS.BORDER_RADIUS.amount}${UNITS.BORDER_RADIUS.type}`,
        backgroundColor: theme.colors.success,
    },
    contentContainer: {
        position: 'relative',
        display: 'flex',
        overflowWrap: 'anywhere',
        whiteSpace: 'pre-wrap',
        borderRadius: `${UNITS.BORDER_RADIUS.amount}${UNITS.BORDER_RADIUS.type}`,
        padding: `${UNITS.PADDING.amount * 2}${UNITS.PADDING.type} ${
            UNITS.PADDING.amount * 3
        }${UNITS.PADDING.type}`,
        backgroundColor: theme.colors.panelBackground,
        margin: 0,
    },
    copyButton: {
        backgroundColor: theme.colors.panelBackground,
        borderRadius: `${UNITS.BORDER_RADIUS.amount}${UNITS.BORDER_RADIUS.type}`,
        width: '2rem',
        borderStyle: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.colors.border,
        },
    },
    icon: {
        width: '80%',
        height: '80%',
    },
    sabotageTagClass: {
        display: 'flex',
        borderRadius: `${UNITS.BORDER_RADIUS.amount}${UNITS.BORDER_RADIUS.type}`,
        padding: `${UNITS.PADDING.amount / 2}${UNITS.PADDING.type} ${
            UNITS.PADDING.amount
        }${UNITS.PADDING.type}`,
        backgroundColor: theme.colors.panelBackground,
    },
    titleAndSabotageTagWrap: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
    },
});

const CopyModal = ({ handleReturn, modalData, sabotageTag }) => {
    const {
        modalContainer,
        modalBox,
        closeButton,
        contentContainer,
        icon,
        notifyText,
        headerContainer,
        copyButton,
        sabotageTagClass,
        titleAndSabotageTagWrap,
    } = useStyles();

    const contentToCopy = useRef(null);
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyButton = () => {
        navigator.clipboard.writeText(contentToCopy.current.innerHTML);
        setIsCopied(true);
    };

    return (
        <div className={modalContainer} onClick={handleReturn}>
            <div className={modalBox} onClick={e => e.stopPropagation()}>
                {isCopied && <p className={notifyText}>Skopiowane!</p>}
                <button className={closeButton} onClick={handleReturn}>
                    <Close />
                </button>
                <div className={headerContainer}>
                    <div className={titleAndSabotageTagWrap}>
                        <p>Zawartość komórki</p>
                        {sabotageTag && (
                            <>
                                <p style={{ marginLeft: '10px' }}>{`${tabHeadToSymbols.report_sabotage}:`}</p>
                                <p className={sabotageTagClass}>{sabotageTag}</p>
                            </>
                        )}
                    </div>
                    <button onClick={handleCopyButton} className={copyButton}>
                        <img src={copyIcon} className={icon} />
                    </button>
                </div>
                <p ref={contentToCopy} className={contentContainer}>
                    {modalData || 'Pusta komórka'}
                </p>
            </div>
        </div>
    );
};

export default CopyModal;
