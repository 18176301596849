import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

class ReportBodyV8 extends Component {
    state = {
        day: '',
        counterState: '',
        sabotageState: '',
        multiplierIncrement: '',
        firstIncrement: '',
        sabotageStateForFirstIncrement: '',
        secondIncrement: '',
        sabotageStateForSecondIncrement: '',
        thirdIncrement: '',
        sabotageStateForThirdIncrement: '',
        fourthIncrement: '',
        sabotageStateForFourthIncrement: '',
        fifthIncrement: '',
        sabotageStateForFifthIncrement: '',
        networkQualityIndicator: '',
        mechanicalSabotagePrealarmsAmount: '',
        magneticSabotagePrealarmsAmount: '',
        radioTransmitterSignalPower: '',
        lastRadioHubReadingDate: '',
    };

    componentDidMount() {
        const { schemeV8ReportBody, onSetProtocolType } = this.props;
        onSetProtocolType('b');

        this.setState({ ...schemeV8ReportBody });
    }

    componentDidUpdate(prevProps) {
        const { schemeV8ReportBody, selectedScheme, clearSelectedScheme } = this.props;
        if (
            schemeV8ReportBody &&
            Object.keys(schemeV8ReportBody).length > 0 &&
            prevProps.schemeV8ReportBody !== schemeV8ReportBody
        ) {
            this.setState(schemeV8ReportBody);
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
        }
    }

    handleChange = (key, event) => {
        this.setState({ [key]: event.target.value }, () =>
            this.props.getPackagesData(this.state, `packageBody${this.props.number}`)
        );
    };

    onSubmit = () => {
        this.props.saveSelectedFormState(this.props.number, this.state);
        return false;
    };

    clearState = () => {
        this.setState({
            day: '',
            counterState: '',
            sabotageState: '',
            multiplierIncrement: '',
            firstIncrement: '',
            sabotageStateForFirstIncrement: '',
            secondIncrement: '',
            sabotageStateForSecondIncrement: '',
            thirdIncrement: '',
            sabotageStateForThirdIncrement: '',
            fourthIncrement: '',
            sabotageStateForFourthIncrement: '',
            fifthIncrement: '',
            sabotageStateForFifthIncrement: '',
            networkQualityIndicator: '',
            mechanicalSabotagePrealarmsAmount: '',
            magneticSabotagePrealarmsAmount: '',
            radioTransmitterSignalPower: '',
            lastRadioHubReadingDate: '',
        });
    };

    render() {
        const { classes } = this.props;
        const {
            day,
            counterState,
            sabotageState,
            multiplierIncrement,
            firstIncrement,
            sabotageStateForFirstIncrement,
            secondIncrement,
            sabotageStateForThirdIncrement,
            sabotageStateForSecondIncrement,
            thirdIncrement,
            fourthIncrement,
            sabotageStateForFourthIncrement,
            fifthIncrement,
            sabotageStateForFifthIncrement,
            networkQualityIndicator,
            mechanicalSabotagePrealarmsAmount,
            magneticSabotagePrealarmsAmount,
            radioTransmitterSignalPower,
            lastRadioHubReadingDate,
        } = this.state;

        return (
            <div>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={day}
                        onChange={e => this.handleChange('day', e)}
                        label="Dzień: 2 znaki [01-31] np. 12"
                        validators={['matchRegexp:^((0[1-9])|(1[1-9])|(2[1-9])|(3[0-1])|(1[0-3]))$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="day"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={counterState}
                        onChange={e => this.handleChange('counterState', e)}
                        label="Stan licznika na godz. 4:00 [0,001 m3]: 9 znaków [000000000-999999999] np. 123456789"
                        validators={['matchRegexp:^([0-9]{9})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="counterState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageState}
                        onChange={e => this.handleChange('sabotageState', e)}
                        label="Przepływ / stan sabotaży: 1 znak [+,-,m,M,g,G,d,D,E] np. E"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D]|[E])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={multiplierIncrement}
                        onChange={e => this.handleChange('multiplierIncrement', e)}
                        label="Mnożnik przyrostów - potęga liczby 10: 1 znak [0-4, E] np.1"
                        validators={['matchRegexp:^([0-4E])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="multiplierIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={firstIncrement}
                        onChange={e => this.handleChange('firstIncrement', e)}
                        label="Przyrost 1 z godz. 8:00 (pomiędzy godz. 4:00 a 8:00): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^(([0-9]){4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="firstIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForFirstIncrement}
                        onChange={e => this.handleChange('sabotageStateForFirstIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 1: 1 znak [+,-,m,M,g,G,d,D,E] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D]|[E])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForFirstIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={secondIncrement}
                        onChange={e => this.handleChange('secondIncrement', e)}
                        label="Przyrost 2 z godz. 12:00 (pomiędzy godz. 8:00 a 12:00): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^(([0-9]){4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="secondIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForSecondIncrement}
                        onChange={e => this.handleChange('sabotageStateForSecondIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 2: 1 znak [+,-,m,M,g,G,d,D,E] np. g"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D]|[E])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForSecondIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={thirdIncrement}
                        onChange={e => this.handleChange('thirdIncrement', e)}
                        label="Przyrost 3 z godz. 16:00 (pomiędzy godz. 12:00 a 16:00): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^(([0-9]){4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="thirdIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForThirdIncrement}
                        onChange={e => this.handleChange('sabotageStateForThirdIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 3: 1 znak [+,-,m,M,g,G,d,D,E] np. d"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D]|[E])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForThirdIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={fourthIncrement}
                        onChange={e => this.handleChange('fourthIncrement', e)}
                        label="Przyrost 4 z godz. 20:00 (pomiędzy godz. 16:00 a 20:00): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^(([0-9]){4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="fourthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForFourthIncrement}
                        onChange={e => this.handleChange('sabotageStateForFourthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 4: 1 znak [+,-,m,M,g,G,d,D,E] np. m"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D]|[E])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForFourthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={fifthIncrement}
                        onChange={e => this.handleChange('fifthIncrement', e)}
                        label="Przyrost 5 z godz. 24:00 (pomiędzy godz. 20:00 a 24:00): 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^(([0-9]){4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="fifthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={sabotageStateForFifthIncrement}
                        onChange={e => this.handleChange('sabotageStateForFifthIncrement', e)}
                        label="Przepływ / stan sabotaży dla przyrostu 5: 1 znak [+,-,m,M,g,G,d,D,E] np. M"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D]|[E])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="sabotageStateForFifthIncrement"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={networkQualityIndicator}
                        onChange={e => this.handleChange('networkQualityIndicator', e)}
                        label="Wskaźnik jakości sieci: 1 znak [0-9,a-z,A-K] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-K]|[a-z]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="networkQualityIndicator"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={mechanicalSabotagePrealarmsAmount}
                        onChange={e => this.handleChange('mechanicalSabotagePrealarmsAmount', e)}
                        label="Ilość prealarmów sabotażu mechanicznego: 1 znak [0-9,a-z,A-K] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-K]|[a-z]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="mechanicalSabotagePrealarmsAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={magneticSabotagePrealarmsAmount}
                        onChange={e => this.handleChange('magneticSabotagePrealarmsAmount', e)}
                        label="Ilość prealarmów sabotażu magnetycznego: 1 znak [0-9,a-z,A-K] np. 1"
                        validators={['matchRegexp:^([0-9]|[A-K]|[a-z]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="magneticSabotagePrealarmsAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={radioTransmitterSignalPower}
                        onChange={e => this.handleChange('radioTransmitterSignalPower', e)}
                        label="Siła sygnału nadajnika radiowego: 1 znak [0-6,E] np. 1"
                        validators={['matchRegexp:^([0-6E]?)$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="radioTransmitterSignalPower"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={lastRadioHubReadingDate}
                        onChange={e => this.handleChange('lastRadioHubReadingDate', e)}
                        label="Data / czas ostatniego odczytu nadajnika w koncentratorze: : 6 znaków [0-9,a-z,A-Z] np. 123456"
                        validators={['matchRegexp:^([0-9A-Za-z]{6})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="lastRadioHubReadingDate"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        value="Submit"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

ReportBodyV8.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportBodyV8);
