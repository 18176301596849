import React from 'react';
import { createRoot } from 'react-dom/client';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import GlobalStyles from './themes/GlobalStyles';
import App from './App';

const options = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '10rem',
    transition: transitions.FADE,
    type: 'error',
};
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <>
        <GlobalStyles />
        <AlertProvider template={AlertTemplate} {...options}>
            <App />
        </AlertProvider>
    </>
);
