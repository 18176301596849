/* eslint-disable no-restricted-globals */
import React, { useState, useContext, forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import ColumnsSettingsItem from './ColumnsSettingsItem/ColumnsSettingsItem';
import FileExporter from './FileExporter/FileExporter';
import CountPipelineDocs from '../../CountPipelineDocs/CountPipelineDocs';
import AutocompleteBar from '../../Tables/AutocompleteBar';
import TableSearchBar from '../../Tables/TableSearchBar';
import docsContext from '../../../context/DocsContext';
import {
    docsSearchValue,
    fetchStatuses,
    fileExporterTypes,
    listTypes,
    listTypesNames,
    localStorageKey,
    testListVersion,
} from '../../../utils/constants';
import theme from '../../../themes/theme';

const useStyles = makeStyles({
    waterpipeSettingsPanel: {
        position: 'relative',
        borderBottom: 'none',
        background: theme.colors.panelBackground,
        border: `1px solid ${theme.colors.border}`,
        display: 'flex',
        [theme.mediaBreakpoints.md_waterpipe]: {
            flexDirection: 'column',
        },
    },
    listSettingsPanel: {
        position: 'relative',
        borderBottom: 'none',
        background: theme.colors.panelBackground,
        border: `1px solid ${theme.colors.border}`,
        display: 'flex',
        [theme.mediaBreakpoints.md]: {
            flexDirection: 'column',
        },
    },
    waterpipeSettingsOptions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'end',
        flex: 1,
        width: '100%',
        [theme.mediaBreakpoints.md_waterpipe]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
    },
    listSettingsOptions: {
        display: 'flex',
        justifyContent: 'right',
        alignSelf: 'end',
        flex: 1,
        width: '100%',
        [theme.mediaBreakpoints.md]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
    },
    waterpipeTableName: {
        fontWeight: theme.font.weight.bold,
        marginLeft: 25,
        marginRight: 25,
        display: 'flex',
        alignItems: 'center',
        [theme.mediaBreakpoints.md_waterpipe]: {
            justifyContent: 'center',
            marginLeft: 0,
        },
    },
    listTableName: {
        fontWeight: theme.font.weight.bold,
        marginLeft: 25,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.mediaBreakpoints.md]: {
            justifyContent: 'center',
            marginLeft: 0,
        },
    },
    filters: {
        display: 'flex',
        justifyContent: 'right',
        alignSelf: 'center',
        marginRight: '1rem',
        gap: '2rem',
    },
    reset: {
        alignSelf: 'center',
    },
    filterWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '2rem',
        [theme.mediaBreakpoints.sm_waterpipe]: {
            alignItems: 'center',
            flexDirection: 'column',
        },
    },
    processingText: {
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
    },
});

const SettingsPanel = (props, ref) => {
    const {
        columnsDefs,
        setColumnHide,
        gridApi,
        listType,
        hideExport,
        hideColumnsSettings,
        hideFilters,
        listVersion,
        match,
        settingsPanelPosition,
        tableHeaderName,
        isTable,
        isFetching,
    } = props;

    const {
        filterWrapper,
        filters,
        reset,
        listSettingsPanel,
        waterpipeSettingsPanel,
        waterpipeSettingsOptions,
        listSettingsOptions,
        waterpipeTableName,
        listTableName,
        processingText,
    } = useStyles();

    const [isDataProcessing, setIsDataProcessing] = useState(false);

    const docsProps = useContext(docsContext);

    const LIST_NAME =
        listType === listTypes.WATERPIPE_LIST
            ? `${tableHeaderName} `
            : `Zestawienie ${
                  listType === listTypes.TEST_LIST ? listTypesNames.TEST_LIST : listTypesNames.PRODUCTION_LIST
              }${listVersion === testListVersion.V2 ? ' V2' : ''}`;

    return (
        <div className={hideFilters ? listSettingsPanel : waterpipeSettingsPanel} ref={ref}>
            <p className={hideFilters ? listTableName : waterpipeTableName}>{LIST_NAME}</p>
            <div className={hideFilters ? listSettingsOptions : waterpipeSettingsOptions}>
                {!hideFilters && (
                    <div className={filterWrapper}>
                        <CountPipelineDocs
                            match={match}
                            history={history}
                            reportType={docsProps.reportType}
                        />
                        <div className={filters}>
                            <AutocompleteBar
                                label="Typ raportu"
                                options={docsProps.options}
                                handleFilter={docsProps.handleFilter}
                                value={docsProps.reportType}
                                checkType={docsProps.reportType}
                                localStorageKey={localStorageKey.DOCS_REPORT_TYPE}
                                isTable
                            />
                            <TableSearchBar
                                placeholder="Komunikator"
                                searchValue={docsSearchValue.COMMUNICATOR}
                                clearSearchField={docsProps.clearSearchField}
                                clearedSearchField={docsProps.clearedSearchField}
                                handleSearch={docsProps.handleSearchCommunicator}
                                isTable={isTable}
                            />
                            <TableSearchBar
                                placeholder="ID pakietu"
                                searchValue={docsSearchValue.PACKAGE_ID}
                                clearSearchField={docsProps.clearSearchField}
                                clearedSearchField={docsProps.clearedSearchField}
                                handleSearch={docsProps.handleSearchPackageId}
                                isTable={isTable}
                            />
                            <Button
                                className={reset}
                                variant="contained"
                                color="secondary"
                                onClick={docsProps.handleResetFieldsButton}>
                                reset
                            </Button>
                        </div>
                    </div>
                )}
                {isDataProcessing && <p className={processingText}>{fetchStatuses.exporting}</p>}
                {!hideExport && !isFetching && (
                    <>
                        <FileExporter
                            gridApi={gridApi}
                            type={fileExporterTypes.XLS}
                            listName={LIST_NAME}
                            setIsDataProcessing={setIsDataProcessing}
                        />
                        <FileExporter
                            gridApi={gridApi}
                            type={fileExporterTypes.CSV}
                            listName={LIST_NAME}
                            setIsDataProcessing={setIsDataProcessing}
                        />
                    </>
                )}
                {!hideColumnsSettings && !isFetching && (
                    <ColumnsSettingsItem
                        changeStyle={hideFilters}
                        columnsDefs={columnsDefs}
                        setColumnHide={setColumnHide}
                        panelPosition={settingsPanelPosition}
                    />
                )}
            </div>
        </div>
    );
};

export default forwardRef(SettingsPanel);
