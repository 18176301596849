import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import appContext from '../../context/AppContext';
import { listTypesId, submenuElementList } from '../../utils/constants';
import authComponentContext from '../../context/AuthComponentContext';

const useStyles = makeStyles({
    menu: {
        marginTop: '2.19rem',
    },
});

export const Submenu = ({ isSubmenu, setIsSubmenu }) => {
    const { menu } = useStyles();
    const history = useHistory();
    const AppContext = useContext(appContext);
    const AuthComponentContext = useContext(authComponentContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = submenuElement => {
        setAnchorEl(null);
        setIsSubmenu(null);
        AppContext.handleListsSubmenu(submenuElement);
        history.push(
            `/lists/${
                submenuElement === submenuElementList.FIRST_ELEMENT
                    ? listTypesId.PRODUCTION_LIST
                    : listTypesId.TEST_LIST
            }`
        );
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsSubmenu(null);
    };

    useEffect(() => {
        setAnchorEl(isSubmenu);
        AuthComponentContext.setIsSubmenuOpen(isSubmenu);
    }, [isSubmenu]);

    return (
        <Menu className={menu} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={() => handleClick(submenuElementList.FIRST_ELEMENT)}>
                Zestawienie produkcyjne
            </MenuItem>
            <MenuItem onClick={() => handleClick(submenuElementList.SECOND_ELEMENT)}>
                Zestawienie testowe
            </MenuItem>
        </Menu>
    );
};

export default Submenu;
