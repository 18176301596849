/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { withStyles, TextField } from '@material-ui/core';
import { communicatorFieldType, inputField, listTypes, localStorageKey } from '../../utils/constants';
import { setMinimalAboveZero, trimValueTo8Char } from '../../utils/helpers';

const styles = {
    container: {
        marginTop: '1.4rem',
    },
    field: {
        margin: '0 1rem',
    },
};

const CommunicatorsRange = ({
    classes: { container, field },
    isFocusOnCommunicatorsRange,
    setFocusOnCommunicatorsFieldType,
    setIsCommunicatorsRangeEmpty,
    isCommunicatorsListEmpty,
    setIsAnyError,
    setNumbersFrom,
    setNumbersTo,
    listType,
}) => {
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');
    const [isToValueFocus, setIsToValueFocus] = useState(false);
    const [isFromValueFocus, setIsFromValueFocus] = useState(false);
    const [isError, setIsError] = useState(false);
    const [helperMessage, setHelperMessage] = useState('');

    const isTestListActive = () => listType === listTypes.TEST_LIST;

    const handleErrorAbove135 = () => {
        setError('Przedział powyżej 135');
    };

    const clearError = () => {
        setIsError(false);
        setHelperMessage('');
    };

    const setError = msg => {
        setIsError(true);
        setHelperMessage(msg);
    };

    const clearLocalStorage = key => {
        localStorage.removeItem(key);
    };

    const isNumber = value => {
        if (isNaN(Number(value))) {
            setError('Tylko cyfry');
            return true;
        }

        return false;
    };

    const setFieldValue = (fieldName, value) => {
        if (isNumber(value)) {
            return;
        }

        if (fieldName === inputField.FROM) {
            setFromValue(value);
            if (value === '') {
                clearLocalStorage(localStorageKey.COMMUNICATOR_RANGE_FROM_VALUE);
            }
        }

        if (fieldName === inputField.TO) {
            setToValue(value);

            if (value === '') {
                clearLocalStorage(localStorageKey.COMMUNICATOR_RANGE_TO_VALUE);
            }
        }
    };

    const handleInputValue = (e, fieldName) => {
        let { value } = e.target;

        isValueRangeAbove135();

        if (value.length === 8 && Number(value) < 1) {
            value = setMinimalAboveZero();
        }
        setFieldValue(fieldName, trimValueTo8Char(value));
    };

    const setValueToCommunicatorFormat = fieldName => {
        function setEvaluatedIncrement(val) {
            const evaluateIncrement = Number(val.slice(-3)) + 45;
            const setValueEnding = () => {
                if (evaluateIncrement.toString().length < 3) {
                    return `0${evaluateIncrement}`;
                }
                if (evaluateIncrement > 999) {
                    return '999';
                }
                return evaluateIncrement;
            };

            const setValueBegin = val.slice(0, 5);
            return `${setValueBegin}${setValueEnding()}`;
        }

        if (fieldName === inputField.FROM) {
            setFromValue(fromValue);
            localStorage.setItem(localStorageKey.COMMUNICATOR_RANGE_FROM_VALUE, fromValue);
        }
        if (fieldName === inputField.TO) {
            setToValue(setEvaluatedIncrement(fromValue));
            localStorage.setItem(localStorageKey.COMMUNICATOR_RANGE_FROM_VALUE, fromValue);
            localStorage.setItem(
                localStorageKey.COMMUNICATOR_RANGE_TO_VALUE,
                setEvaluatedIncrement(fromValue)
            );
        }
    };

    const handleInputFocus = fieldName => {
        if (fieldName === inputField.FROM) {
            setIsFromValueFocus(true);
            setIsToValueFocus(false);
        }
        if (fieldName === inputField.TO) {
            setIsToValueFocus(true);
            setIsFromValueFocus(false);
        }

        if (isCommunicatorsListEmpty) {
            setFocusOnCommunicatorsFieldType(communicatorFieldType.COMMUNICATORS_RANGE, false);
        }
    };

    const handleIsCommunicatorsRangeEmpty = () => {
        const IsCommunicatorsRangeEmpty = fromValue.length === 0 && toValue.length === 0;
        setIsCommunicatorsRangeEmpty(IsCommunicatorsRangeEmpty);
    };

    const isValueRangeAbove135 = fieldName => {
        function isBothFields8Characters() {
            return fromValue.length === 8 && toValue.length === 8;
        }

        if (isBothFields8Characters()) {
            if (!isTestListActive()) {
                const fromNumber = Number(fromValue);
                const toNumber = Number(toValue);
                const countRange = Math.abs(fromNumber - toNumber);

                if (countRange > 135) {
                    handleErrorAbove135(fieldName);
                } else {
                    clearError();
                }
            } else {
                clearError();
            }
        }
    };

    const handleOneFieldIsEmpty = () => {
        if (!fromValue.length || !toValue.length) setError('8 cyfr');
    };

    const handleBothFieldsAreEmpty = () => {
        if (!fromValue && !toValue) clearError();
    };

    const handleFromValueIsHigherThanToValue = () => {
        if (Number(fromValue) > Number(toValue)) {
            setError('Od jest większe niż Do');
        } else {
            clearError();
        }
    };

    const handleLocalStorageIfFocusToValue = () => {
        if (isToValueFocus) {
            localStorage.setItem(localStorageKey.COMMUNICATOR_RANGE_TO_VALUE, toValue);
        }
    };

    const isCommunicatorRangeFromLocalStorage = () => {
        if (!isError && toValue && fromValue) {
            setIsAnyError(false);
        }
    };

    const handleIsBothFields8Characters = () => {
        if (fromValue.length === 8 && toValue.length === 8) return;
        setError('8 cyfr na obu polach');
    };

    useEffect(() => {
        const fromValueLocalStorage = localStorage.getItem(localStorageKey.COMMUNICATOR_RANGE_FROM_VALUE);
        const toValueLocalStorage = localStorage.getItem(localStorageKey.COMMUNICATOR_RANGE_TO_VALUE);

        if (fromValueLocalStorage) {
            setFromValue(fromValueLocalStorage);
            setNumbersFrom(fromValueLocalStorage);
        }
        if (toValueLocalStorage) {
            setToValue(toValueLocalStorage);
            setNumbersTo(toValueLocalStorage);
        }
    }, []);

    useEffect(() => {
        if (fromValue.length >= 8 && isFromValueFocus) {
            setValueToCommunicatorFormat(inputField.TO);
        }
        if (toValue.length >= 8 && isToValueFocus) {
            setValueToCommunicatorFormat(inputField.FROM);
        }

        handleIsCommunicatorsRangeEmpty();
        handleOneFieldIsEmpty();
        handleFromValueIsHigherThanToValue();
        handleLocalStorageIfFocusToValue();
        isValueRangeAbove135();
        isCommunicatorRangeFromLocalStorage();
        handleIsBothFields8Characters();
        handleBothFieldsAreEmpty();

        setNumbersFrom(fromValue);
        setNumbersTo(toValue);
    }, [fromValue, toValue]);

    useEffect(() => {
        setIsAnyError(isError);
    }, [isError]);

    return (
        <section className={container}>
            <TextField
                label="Od"
                placeholder="np. 12345678"
                type="text"
                className={field}
                InputLabelProps={{
                    shrink: true,
                }}
                error={isError}
                helperText={helperMessage}
                onChange={e => handleInputValue(e, inputField.FROM)}
                value={fromValue}
                onFocus={() => handleInputFocus(inputField.FROM)}
                onMouseEnter={() => handleInputFocus(inputField.FROM)}
                disabled={isFocusOnCommunicatorsRange}
            />
            <TextField
                label="Do"
                placeholder="np. 12345678"
                type="text"
                className={field}
                InputLabelProps={{
                    shrink: true,
                }}
                error={isError}
                helperText={helperMessage}
                onChange={e => handleInputValue(e, inputField.TO)}
                value={toValue}
                onFocus={() => handleInputFocus(inputField.TO)}
                onMouseEnter={() => handleInputFocus(inputField.TO)}
                disabled={isFocusOnCommunicatorsRange}
            />
        </section>
    );
};

export default withStyles(styles)(CommunicatorsRange);
