import React, { Component } from 'react';
import PipelinesConfig from '../PipelinesConfig/PipelinesConfig';

class Pipelines extends Component {
    render() {
        return (
            <div>
                <PipelinesConfig {...this.props} />
            </div>
        );
    }
}

export default Pipelines;
