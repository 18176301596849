/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import DropdownSelect from '../DropdownSelect/DropdownSelect';

export default forwardRef((props, ref) => {
    const [selectedWaterpipes, setSelectedWaterpipes] = useState([]);

    useImperativeHandle(ref, () => ({
        doesFilterPass(params) {
            const reportRecipient =
                params.data.report_recipient?.length === 1
                    ? `0${params.data.report_recipient}`
                    : params.data.report_recipient;
            return selectedWaterpipes.filter(waterpipe => waterpipe === reportRecipient)?.length;
        },

        isFilterActive() {
            return selectedWaterpipes.length;
        },
    }));

    useEffect(() => {
        props.filterChangedCallback();
    }, [selectedWaterpipes]);

    const onChange = (_, selectedValues) => {
        setSelectedWaterpipes(
            selectedValues
                .map(value =>
                    typeof value.value === 'object' ? value.value.map(val => val.sign) : value.value
                )
                .flat()
        );
    };

    return <DropdownSelect data={props?.waterpipes} onChange={onChange} />;
});
