import React from 'react';

const packagesContext = React.createContext({
    packagesData: {
        selectedWaterpipe: '',
        selectedStatus: '',
        id: '',
        sms: '',
        dok: '',
    },
    handleRefreshButton: null,
    isPackageInLiveObject: null,
});

export const PackagesProvider = packagesContext.Provider;
export default packagesContext;
