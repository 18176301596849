/* eslint-disable chai-friendly/no-unused-expressions */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Events, animateScroll as scroll } from 'react-scroll';

const styles = {
    chevron: {
        margin: '0 1rem',
        position: 'fixed',
        background: 'rgba(87, 84, 78, .5)',
        top: '90%',
        right: '5rem',
        transform: 'translateY(-50%)',
        '&:hover': {
            background: 'rgba(87, 84, 78, .8)',
        },
        zIndex: 1000,
    },
};

class goTopButton extends Component {
    state = {
        isButtonVisible: false,
    };

    componentDidMount() {
        this.registerScrollEvent();
        this.setListenScrollPosition();
    }

    componentWillUnmount() {
        this.unregisterScrollEvent();
    }

    registerScrollEvent = () => {
        Events.scrollEvent.register('begin');
        Events.scrollEvent.register('end');
    };

    unregisterScrollEvent = () => {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    };

    setListenScrollPosition = () => {
        let scrollPosition = 0;
        let ticking = false;

        const throttleListenScrollEventForOptimisation = () => {
            window.requestAnimationFrame(() => {
                ticking = false;
            });
        };

        window.addEventListener('scroll', () => {
            scrollPosition = window.scrollY;
            this.showGoTopButton(scrollPosition);
            if (!ticking) {
                throttleListenScrollEventForOptimisation();
                ticking = true;
            }
        });
    };

    showGoTopButton = scrollPosition => {
        scrollPosition > 160
            ? this.setState({ isButtonVisible: true })
            : this.setState({ isButtonVisible: false });
    };

    scrollToTop = () => {
        scroll.scrollToTop();
    };

    render() {
        const { classes } = this.props;
        const { isButtonVisible } = this.state;

        return (
            isButtonVisible && (
                <Fab size="medium" className={classes.chevron} onClick={this.scrollToTop}>
                    <KeyboardArrowUpIcon />
                </Fab>
            )
        );
    }
}

export default withStyles(styles)(goTopButton);
