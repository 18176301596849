/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Button from '@material-ui/core/Button';
import LoadingOverlay from 'react-loading-overlay';
import { axiosInstance, cancelToken } from '../../utils/axiosInstance';
import Snackbar from '../Snackbar/Snackbar';
import Modal from '../Modal/Modal';
import GoBackButton from '../GoBackButton/GoBackButton';
import TitleHeader from '../TitleHeader/TitleHeader';
import { endpoint, snackbarMsg, snackbarVariant } from '../../utils/constants';

LoadingOverlay.propTypes = undefined;

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '3rem 0 3rem 0',
        padding: '0 2.5rem 1rem 2.5rem',
    },
    mainHeader: {
        margin: '2rem 0',
        textAlign: 'center',
        fontSize: '1.6rem',
        color: '#595652',
    },
    textField: {
        marginLeft: '1rem',
        marginRight: '1rem',
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    background: {
        background: '#d9dadb',
        height: '60%',
        marginBottom: '4rem',
        width: '95%',
        margin: '0 auto',
        position: 'relative',
    },
    textFieldContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: '2rem',
    },
    dataBaseFieldContainer: {
        width: '60%',
    },
    localhostFieldContainer: {
        width: '33%',
    },
    checkboxContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    configTextContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    loginFieldContainer: {
        width: '16rem',
    },
    checkboxItem: {
        flexDirection: 'column',
        width: '30%',
    },
    button: {
        margin: '1rem',
        padding: '0.6rem 3rem',
        borderRadius: 0,
    },
    input: {
        display: 'none',
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
        left: '78%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    checkboxInnerBlock: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    checkboxParagraph: {
        margin: 0,
        padding: 0,
    },
    titleButtonContainer: {
        margin: '2rem 0',
        width: '100%',
        position: 'relative',
    },
    loadingOverlay: {
        height: '6rem',
    },
};

class PipelinesConfig extends React.Component {
    state = {
        name: '',
        domain: '',
        sign: '',
        weight: '',
        protocols: [],
        database: '',
        localhost: '',
        login: '',
        password: '',
        snackbarData: {
            isSnackbarOpen: false,
            message: '',
            errorInfo: '',
            variant: '',
        },
        isSpinnerActive: false,
        isModalOpen: false,
        modalMessage: '',
        modalType: '',
        isThrobberActive: false,
        apiKey: '',
    };

    componentDidMount() {
        this.handleGetWaterpipeData();
    }

    componentWillUnmount() {
        if (this.cancelHttpReq !== undefined) {
            this.cancelHttpReq.cancel();
        }
    }

    getWaterPipeId = () => {
        const { match } = this.props;
        let waterPipeId;
        if (match !== undefined && match.params.id !== undefined) {
            waterPipeId = match.params.id;
        }
        return waterPipeId;
    };

    handleChange = (event, name) => {
        this.setState({ [name]: event.target.value });
    };

    handleProtocolChange = event => {
        const id = parseInt(event.target.value);
        const listId = this.state.protocols.findIndex(p => p.id === id);
        const isProtocolSelected = this.state.protocols[listId].selected;
        this.setState(prevState => {
            const prevStateCopy = Object.assign(prevState);
            prevStateCopy.protocols[listId].selected = !isProtocolSelected;
            return { protocols: prevStateCopy.protocols };
        });
    };

    handleGetWaterpipeData = () => {
        const id = this.getWaterPipeId();
        const { history } = this.props;
        this.cancelHttpReq = cancelToken.source();
        axiosInstance
            .get(endpoint.PROTOCOLS_SHOW, {
                cancelToken: this.cancelHttpReq.token,
            })
            .then(response => {
                this.setState({ protocols: response.data.data });
            })
            .then(() => {
                if (id) {
                    axiosInstance
                        .get(`${endpoint.WATERPIPE}/${id}`, {
                            cancelToken: this.cancelHttpReq.token,
                        })
                        .then(response => {
                            const { protocols } = this.state;
                            const {
                                data,
                                data: {
                                    name,
                                    domain,
                                    sign,
                                    weight,
                                    db_name,
                                    db_host,
                                    db_login,
                                    db_password,
                                    api_key,
                                },
                            } = response;
                            data.protocols.map(p => {
                                const id = protocols.findIndex(protocol => p === protocol.id);
                                if (id !== -1) {
                                    protocols[id].selected = true;
                                }
                            });
                            this.setState({
                                name,
                                domain,
                                sign,
                                protocols,
                                weight,
                                database: db_name,
                                localhost: db_host,
                                login: db_login,
                                password: db_password,
                                apiKey: api_key || '',
                            });
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                history.push(`/login`);
                            }
                        });
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    history.push(`/login`);
                }
            });
    };

    handleSubmit = () => {
        const { history } = this.props;
        const { name, domain, sign, weight, database, localhost, login, protocols, password, apiKey } =
            this.state;
        const waterpipeId = this.getWaterPipeId();
        const apiPoint =
            waterpipeId !== undefined ? `${endpoint.WATERPIPE_EDIT}/${waterpipeId}` : endpoint.WATERPIPE_ADD;
        this.setState({ isThrobberActive: true });
        axiosInstance
            .post(
                apiPoint,
                {
                    name,
                    domain,
                    sign,
                    weight,
                    protocols: protocols.filter(p => p.selected).map(p => p.id),
                    db_name: database,
                    db_host: localhost,
                    db_login: login,
                    db_password: password,
                    api_key: apiKey,
                },
                {
                    cancelToken: this.cancelHttpReq.token,
                }
            )
            .then(response => {
                this.setState({
                    snackbarData: {
                        isSnackbarOpen: true,
                        message: response.data,
                        variant: 'success',
                    },
                    isSpinnerActive: false,
                    isModalOpen: false,
                    modalMessage: '',
                    modalType: '',
                    isThrobberActive: false,
                });
            })
            .catch(error => {
                if (error.response === undefined) {
                    this.setState({
                        snackbarData: {
                            isSnackbarOpen: true,
                            message: snackbarMsg.ERROR,
                            errorInfo: error.message,
                            variant: snackbarVariant.ERROR,
                        },
                        isSpinnerActive: false,
                        isModalOpen: false,
                        modalMessage: '',
                        modalType: '',
                        isThrobberActive: false,
                    });
                } else if (error.response.status === 401) {
                    history.push(`/login`);
                } else if (error.response.status === 500) {
                    this.setState({
                        snackbarData: {
                            isSnackbarOpen: true,
                            message: snackbarMsg.ERROR,
                            errorInfo: error.message,
                            variant: snackbarVariant.ERROR,
                            isThrobberActive: false,
                        },
                        isSpinnerActive: false,
                        isModalOpen: false,
                        modalMessage: '',
                        modalType: '',
                        isThrobberActive: false,
                    });
                } else {
                    this.setState({
                        isSpinnerActive: false,
                        isModalOpen: false,
                        modalMessage: '',
                        modalType: '',
                        isThrobberActive: false,
                    });
                }
            });
    };

    handleDelete = () => {
        const waterpipeId = this.getWaterPipeId();
        const apiPoint = `${endpoint.WATERPIPE_DELETE}/${waterpipeId}`;
        axiosInstance
            .post(apiPoint)
            .then(response =>
                this.setState({
                    snackbarData: {
                        isSnackbarOpen: true,
                        message: response.data,
                        variant: 'success',
                        isSpinnerActive: false,
                        isModalOpen: false,
                        modalMessage: '',
                        modalType: '',
                    },
                })
            )
            .catch(error => {
                if (error.response === undefined) {
                    this.setState({
                        snackbarData: {
                            isSnackbarOpen: true,
                            message: snackbarMsg.ERROR,
                            errorInfo: error.message,
                            variant: snackbarVariant.ERROR,
                        },
                        isSpinnerActive: false,
                        isModalOpen: false,
                        modalMessage: '',
                        modalType: '',
                    });
                } else if (error.response.status === 401) {
                    history.push(`/login`);
                } else if (error.response.status === 500) {
                    this.setState({
                        snackbarData: {
                            isSnackbarOpen: true,
                            message: snackbarMsg.ERROR,
                            errorInfo: error.message,
                            variant: snackbarVariant.ERROR,
                        },
                        isSpinnerActive: false,
                        isModalOpen: false,
                        modalMessage: '',
                        modalType: '',
                    });
                } else {
                    this.setState({
                        isSpinnerActive: false,
                        isModalOpen: false,
                        modalMessage: '',
                        modalType: '',
                    });
                }
            });
    };

    handleIsSnackbarOpen = () => {
        if (this.state.snackbarData.variant !== 'error') {
            this.props.history.push(`/pipelines`);
        }
        this.setState({
            snackbarData: {
                isSnackbarOpen: false,
                message: '',
                errorInfo: '',
                variant: '',
            },
        });
    };

    handleAnswer = answer => {
        const { modalType } = this.state;
        const handleYesAnswer = () => {
            if (modalType === 'submit') {
                this.handleSubmit();
            }
            if (modalType === 'delete') {
                this.handleDelete();
            }
        };

        const handleNoAnswer = () => {
            this.clearModal();
        };

        return answer ? handleYesAnswer() : handleNoAnswer(false);
    };

    handleModal = (event, actionType) => {
        event.preventDefault();

        const setModal = (message, type) => {
            this.setState({
                isModalOpen: true,
                modalMessage: message,
                modalType: type,
            });
        };

        if (actionType === 'submit') {
            setModal('Czy na pewno chcesz zapisać wodociąg?', actionType);
        }
        if (actionType === 'delete') {
            setModal('Czy na pewno chcesz usunąć wodociąg?', actionType);
        }
    };

    clearModal = () => {
        this.setState({
            isSpinnerActive: false,
            isModalOpen: false,
            modalMessage: '',
            modalType: '',
        });
    };

    render() {
        const { classes, history } = this.props;
        const {
            name,
            domain,
            sign,
            weight,
            protocols,
            database,
            localhost,
            login,
            password,
            snackbarData,
            isModalOpen,
            isSpinnerActive,
            modalMessage,
            isThrobberActive,
            apiKey,
        } = this.state;
        return (
            <article>
                <div className={classes.titleButtonContainer}>
                    <GoBackButton hashRouterHistory={history} goBackToExactPage="/pipelines" />
                    <TitleHeader title={`konfiguracja wodociągu (${name || ''})`} />
                </div>
                <LoadingOverlay
                    active={isThrobberActive}
                    spinner
                    text="Wodociąg jest dodawany..."
                    styles={{
                        content: base => ({
                            ...base,
                            marginLeft: '50%',
                            margin: '4%',
                            transform: 'translateX(-50%)',
                        }),
                    }}>
                    <section className={classes.background}>
                        <form className={classes.container} noValidate autoComplete="off">
                            <article className={classes.textFieldContainer}>
                                <TextField
                                    id="name"
                                    label="Nazwa"
                                    autoComplete="off"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    variant="filled"
                                    htmlFor="name"
                                    value={name}
                                    onChange={e => this.handleChange(e, 'name')}
                                />

                                <TextField
                                    id="domain"
                                    label="Domena"
                                    autoComplete="off"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    variant="filled"
                                    htmlFor="domain"
                                    value={domain}
                                    onChange={e => this.handleChange(e, 'domain')}
                                />

                                <TextField
                                    id="sign"
                                    label="Znak rozdzielnicy"
                                    autoComplete="off"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    variant="filled"
                                    htmlFor="sign"
                                    value={sign}
                                    onChange={e => this.handleChange(e, 'sign')}
                                />

                                <TextField
                                    id="weight"
                                    label="Waga(-10...10)"
                                    autoComplete="off"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    InputProps={{
                                        maxLength: 10,
                                        minLength: -10,
                                    }}
                                    variant="filled"
                                    htmlFor="weight"
                                    type="number"
                                    value={weight}
                                    onChange={e => this.handleChange(e, 'weight')}
                                />
                            </article>

                            <article>
                                <article className={classes.checkboxContainer}>
                                    {protocols.map(protocol => (
                                        <article key={protocol.id} className={classes.checkboxItem}>
                                            <article className={classes.checkboxInnerBlock}>
                                                <Checkbox
                                                    value={protocol.id.toString()}
                                                    checked={!!protocol.selected}
                                                    color="default"
                                                    htmlFor={protocol.name}
                                                    onChange={this.handleProtocolChange}
                                                />
                                                <p className={classes.checkboxParagraph}>{protocol.name}</p>
                                            </article>
                                        </article>
                                    ))}
                                </article>
                            </article>

                            <article className={classes.configTextContainer}>
                                <TextField
                                    id="database"
                                    label="Nazwa bazy danych"
                                    autoComplete="off"
                                    className={classNames(
                                        classes.textField,
                                        classes.dense,
                                        classes.dataBaseFieldContainer
                                    )}
                                    margin="dense"
                                    variant="filled"
                                    htmlFor="database"
                                    value={database}
                                    onChange={e => this.handleChange(e, 'database')}
                                />

                                <TextField
                                    id="localhost"
                                    label="Host"
                                    autoComplete="off"
                                    className={classNames(
                                        classes.textField,
                                        classes.dense,
                                        classes.localhostFieldContainer
                                    )}
                                    margin="dense"
                                    variant="filled"
                                    htmlFor="localhost"
                                    value={localhost}
                                    onChange={e => this.handleChange(e, 'localhost')}
                                />
                            </article>

                            <article className={classes.configTextContainer}>
                                <TextField
                                    id="login"
                                    label="Nazwa użytkownika bazy danych"
                                    autoComplete="off"
                                    className={classNames(
                                        classes.textField,
                                        classes.dense,
                                        classes.loginFieldContainer
                                    )}
                                    margin="dense"
                                    variant="filled"
                                    htmlFor="login"
                                    value={login}
                                    onChange={e => this.handleChange(e, 'login')}
                                />

                                <TextField
                                    id="password"
                                    label="Hasło do bazy danych"
                                    autoComplete="off"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    variant="filled"
                                    type="password"
                                    htmlFor="password"
                                    value={password}
                                    onChange={e => this.handleChange(e, 'password')}
                                />
                                <TextField
                                    id="apiKey"
                                    label="API KEY"
                                    autoComplete="off"
                                    className={classNames(classes.textField, classes.dense)}
                                    margin="dense"
                                    variant="filled"
                                    htmlFor="password"
                                    value={apiKey}
                                    onChange={e => this.handleChange(e, 'apiKey')}
                                />
                            </article>
                            <article className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    type="button"
                                    onClick={event => this.handleModal(event, 'submit')}>
                                    Zapisz
                                </Button>
                                {this.getWaterPipeId() ? (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        type="button"
                                        onClick={event => this.handleModal(event, 'delete')}>
                                        Usuń
                                    </Button>
                                ) : (
                                    ''
                                )}
                            </article>
                        </form>
                    </section>
                </LoadingOverlay>
                <Snackbar snackbarData={snackbarData} handleIsSnackbarOpen={this.handleIsSnackbarOpen} />
                {isModalOpen && (
                    <Modal
                        isSpinnerActive={isSpinnerActive}
                        isModalOpen={isModalOpen}
                        handleAnswer={this.handleAnswer}
                        modalMessage={modalMessage}
                    />
                )}
            </article>
        );
    }
}

PipelinesConfig.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PipelinesConfig);
