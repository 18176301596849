import React, { Component } from 'react';

export default class MultiLineField extends Component {
    state = {
        isExpanded: false,
    };

    render() {
        return (
            <div
                onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
                style={
                    this.state.isExpanded
                        ? { cursor: 'pointer' }
                        : {
                              cursor: 'pointer',
                              height: '1.1rem',
                              overflow: 'hidden',
                          }
                }>
                {this.props.children}
            </div>
        );
    }
}
