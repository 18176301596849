import React, { useEffect, useState } from 'react';
import AutocompleteBar from '../Tables/AutocompleteBar';
import { communicatorsTypePl } from '../../utils/constants';
import { translateCommunicatorType, translateCommunicatorTypePl } from '../../utils/helpers';

const CommunicatorTypeList = ({ type, setType }) => {
    const [selectedCommunicatorType, setSelectedCommunicatorType] = useState('');

    useEffect(() => {
        if (type) {
            setSelectedCommunicatorType(translateCommunicatorTypePl(type));
        }
    }, [type]);

    const communicatorTypeList = [
        communicatorsTypePl.COMMUNICATOR,
        communicatorsTypePl.COUPLED_COMMUNICATOR,
        communicatorsTypePl.CONCENTRATOR,
    ];

    useEffect(() => {
        setSelectedCommunicatorType(communicatorsTypePl.COMMUNICATOR);
    }, []);

    useEffect(() => {
        setType(translateCommunicatorType(selectedCommunicatorType));
    }, [selectedCommunicatorType]);

    return (
        <AutocompleteBar
            label="Wybierz typ"
            options={communicatorTypeList}
            handleFilter={setSelectedCommunicatorType}
            value={selectedCommunicatorType}
            isWaterpipe
            wider
            lowerZIndex
        />
    );
};

export default CommunicatorTypeList;
