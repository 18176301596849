import React from 'react';

const Reading = ({ row }) => (
    <div>
        <span>{parseFloat(row.data).toFixed(3)}</span>
        <span>{row.sabotage}</span>
    </div>
);

export default Reading;
