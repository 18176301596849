import React from 'react';
import { withStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const styles = {
    chevron: {
        margin: '0 1rem',
        position: 'absolute',
        background: 'rgba(87, 84, 78, .5)',
        top: '50%',
        left: '5rem',
        transform: 'translateY(-50%)',
        '&:hover': {
            background: 'rgba(87, 84, 78, .8)',
        },
    },
};

const goBackButton = ({ classes, hashRouterHistory }) => {
    const handleGoBack = () => {
        hashRouterHistory.goBack();
    };

    return (
        <Fab size="medium" className={classes.chevron} onClick={handleGoBack}>
            <ChevronLeft />
        </Fab>
    );
};

export default withStyles(styles)(goBackButton);
