import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { tabHeadToSymbols, tabHeadToSymbolsToTitles } from '../../../utils/constants';

const useStyles = makeStyles({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableCell: {
        borderRight: '1px solid #f5f5f5',
        padding: 0,
        paddingLeft: '.3rem',
    },
});

function EnhancedTableHead({ order, orderBy, onRequestSort, headCells }) {
    const { visuallyHidden, tableCell } = useStyles();
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const getTranslatedTitleHead = title => ({
        symbol: tabHeadToSymbols[title],
        title: tabHeadToSymbolsToTitles[title],
    });

    return (
        <TableHead>
            <TableRow>
                {headCells().map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        size="small"
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={tableCell}
                        title={getTranslatedTitleHead(headCell.label).title}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            {getTranslatedTitleHead(headCell.label).symbol}
                            {orderBy === headCell.id ? (
                                <span className={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;
