import React from 'react';
import { Typography, withStyles } from '@material-ui/core';

const styles = {
    headerContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    mainHeader: {
        margin: '2rem 0',
        textAlign: 'center',
        fontSize: '1.6rem',
        color: '#595652',
    },
};

const TitleHeader = ({ classes, title }) => (
    <div className={classes.headerContainer}>
        <Typography className={classes.mainHeader} variant="overline">
            {title}
        </Typography>
    </div>
);

export default withStyles(styles)(TitleHeader);
