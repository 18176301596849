import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';
import { toLittleEndian } from '../../../utils/helpers';
import ProtocolTypeConverter from './ProtocolTypeConverter/ProtocolTypeConverter';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

export const alertBodyV10fieldsToLittleEndian = [
    'alertReportID',
    'magneticAlertReportsCounter',
    'mechanicalAlertReportsCounter',
    'onDemandAlertReportsCounter',
    'alertCounterState',
];

class AlertBodyV10 extends Component {
    state = {
        alertCause: '',
        alertDay: '',
        alertHour: '',
        alertMinute: '',
        alertSecond: '',
        alertReportID: '',
        magneticAlertReportsCounter: '',
        mechanicalAlertReportsCounter: '',
        onDemandAlertReportsCounter: '',
        alertCounterState: '',
        radioTransmitterSabotageState: '',
        hubSabotageState: '',
        radioTransmitterPrealarmsAmount: '',
        IMEI: '',
        IMSI: '',
        ICCID: '',
        reserve: '',
    };

    componentDidMount() {
        const { schemeV10AlertBody, onSetProtocolType } = this.props;
        onSetProtocolType('D');
        this.setState({ ...schemeV10AlertBody });
    }

    componentDidUpdate(prevProps) {
        const { schemeV10AlertBody, selectedScheme, clearSelectedScheme } = this.props;
        if (
            schemeV10AlertBody &&
            Object.keys(schemeV10AlertBody).length > 0 &&
            prevProps.schemeV10AlertBody !== schemeV10AlertBody
        ) {
            this.setState({ ...schemeV10AlertBody });
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
        }
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value.toUpperCase() }, () =>
            this.props.getPackagesData(
                this.setLittleEndianInLivePreview({ ...this.state }),
                `packageBody${this.props.number}`
            )
        );
    };

    onSubmit = () => {
        this.props.saveSelectedFormState(this.props.number, this.state);
        return false;
    };

    clearState = () => {
        this.setState({
            alertCause: '',
            alertDay: '',
            alertHour: '',
            alertMinute: '',
            alertSecond: '',
            alertReportID: '',
            magneticAlertReportsCounter: '',
            mechanicalAlertReportsCounter: '',
            onDemandAlertReportsCounter: '',
            alertCounterState: '',
            radioTransmitterSabotageState: '',
            hubSabotageState: '',
            radioTransmitterPrealarmsAmount: '',
            IMEI: '',
            IMSI: '',
            ICCID: '',
            reserve: '',
        });
    };

    setLittleEndianInLivePreview = value => {
        alertBodyV10fieldsToLittleEndian.forEach(e => {
            value[e] = toLittleEndian(value[e]);
        });
        return value;
    };

    render() {
        const { classes, isCleared } = this.props;
        const {
            alertCause,
            alertDay,
            alertHour,
            alertMinute,
            alertSecond,
            alertReportID,
            magneticAlertReportsCounter,
            mechanicalAlertReportsCounter,
            onDemandAlertReportsCounter,
            alertCounterState,
            radioTransmitterSabotageState,
            hubSabotageState,
            radioTransmitterPrealarmsAmount,
            IMEI,
            IMSI,
            ICCID,
            reserve,
        } = this.state;
        return (
            <div>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertCause}
                        onChange={value => this.handleChange('alertCause', value)}
                        label="Przyczyna alarmu: "
                        labelHex="wartości [0x4D, 0x47, 0x4E, 0x53, 0x4F]"
                        validators={['alertCause']}
                        interval={['4D', '47', '4E', '53', '4F']}
                        length={2}
                        dataCy="alertCause"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertDay}
                        onChange={value => this.handleChange('alertDay', value)}
                        label="Dzień powstania alarmu BCD: "
                        labelHex="przedział [0x01 - 0x31]"
                        validators={['isDay']}
                        interval={[1, 31]}
                        length={2}
                        dataCy="alertDay"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertHour}
                        onChange={value => this.handleChange('alertHour', value)}
                        label="Godzina powstania alarmu BCD: "
                        labelHex="przedział [0x00 - 0x23]"
                        validators={['isHour']}
                        interval={[0, 23]}
                        length={2}
                        dataCy="alertHour"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertMinute}
                        onChange={value => this.handleChange('alertMinute', value)}
                        label="Minuta powstania alarmu BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isMinute']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="alertMinute"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertSecond}
                        onChange={value => this.handleChange('alertSecond', value)}
                        label="Sekunda powstania alarmu BCD: "
                        labelHex="przedział [0x00 - 0x59]"
                        validators={['isSecond']}
                        interval={[0, 59]}
                        length={2}
                        dataCy="alertSecond"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertReportID}
                        onChange={value => this.handleChange('alertReportID', value)}
                        label="ID raportu alarmowego: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['alertReportID']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="alertReportID"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={magneticAlertReportsCounter}
                        onChange={value => this.handleChange('magneticAlertReportsCounter', value)}
                        label="Licznik liczby raportów alarmu magnetycznego: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['magneticAlertReportsCounter']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="magneticAlertReportsCounter"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={mechanicalAlertReportsCounter}
                        onChange={value => this.handleChange('mechanicalAlertReportsCounter', value)}
                        label="Licznik liczby raportów alarmu mechanicznego: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['mechanicalAlertReportsCounter']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="mechanicalAlertReportsCounter"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={onDemandAlertReportsCounter}
                        onChange={value => this.handleChange('onDemandAlertReportsCounter', value)}
                        label="Licznik liczby raportów na żądanie: "
                        labelHex="przedział [0x0000 - 0xFFFF]"
                        validators={['onDemandAlertReportsCounter']}
                        interval={['0000', 'FFFF']}
                        length={4}
                        dataCy="onDemandAlertReportsCounter"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={alertCounterState}
                        onChange={value => this.handleChange('alertCounterState', value)}
                        label="Stan licznika na godz. powstania alarmu [0,001 m3]: "
                        labelHex="przedział (0 - 999 999 999) [0x00000000 - 3B9AC9FF]"
                        validators={['alertCounterState']}
                        interval={['00000000', '3B9AC9FF']}
                        length={8}
                        dataCy="alertCounterState"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={radioTransmitterSabotageState}
                        onChange={value => this.handleChange('radioTransmitterSabotageState', value)}
                        label="Przepływ / stan sabotaży w nadajniku radiowym: "
                        labelHex="przedział [0x00 - 0x1F]"
                        validators={['radioTransmitterSabotageState']}
                        interval={['00', '1F', '78']}
                        length={2}
                        dataCy="radioTransmitterSabotageState"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={hubSabotageState}
                        onChange={value => this.handleChange('hubSabotageState', value)}
                        label="Stan sabotaży w koncentrat. bit0-zdjęcie pokrywki, bit1-oderwanie od ściany: "
                        labelHex="przedział [0x00 - 0x03]"
                        validators={['hubSabotageState']}
                        interval={['00', '03', '78']}
                        length={2}
                        dataCy="hubSabotageState"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={radioTransmitterPrealarmsAmount}
                        onChange={value => this.handleChange('radioTransmitterPrealarmsAmount', value)}
                        label="Liczba prealarmów w nadajniku radiowym od początku doby: "
                        labelHex="przedział [0x00 - 0xFF]"
                        validators={['radioTransmitterPrealarmsAmount']}
                        interval={['00', 'FF']}
                        length={2}
                        dataCy="radioTransmitterPrealarmsAmount"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={IMEI}
                        onChange={value => this.handleChange('IMEI', value)}
                        label="IMEI: "
                        labelHex="przedział [0x30-0x39] 15hex"
                        validators={['IMEI']}
                        interval={['303030303030303030303030303030', '393939393939393939393939393939']}
                        length={30}
                        dataCy="IMEI"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={IMSI}
                        onChange={value => this.handleChange('IMSI', value)}
                        label="IMSI: "
                        labelHex="przedział [0x30-0x39] 15hex"
                        validators={['IMSI']}
                        interval={['303030303030303030303030303030', '393939393939393939393939393939']}
                        length={30}
                        dataCy="IMEI"
                    />

                    <ProtocolTypeConverter
                        isCleared={isCleared}
                        value={ICCID}
                        onChange={value => this.handleChange('ICCID', value)}
                        label="ICCID: "
                        labelHex="przedział [0x30-0x7A] 20hex"
                        validators={['ICCID']}
                        interval={[
                            '3030303030303030303030303030303030303030',
                            '7A7A7A7A7A7A7A7A7A7A7A7A7A7A7A7A7A7A7A7A',
                        ]}
                        length={40}
                        dataCy="IMEI"
                    />

                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={reserve}
                        onChange={e => this.handleChange('reserve', e.target.value)}
                        label="Rezerwa 'x': 27 razy [0x78]"
                        validators={['matchRegexp:^(78){27}$']}
                        errorMessages={['Niepoprawny format']}
                        data-cy="reserve"
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        value="Submit"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

export default withStyles(styles)(AlertBodyV10);
