import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@material-ui/core/Button';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '90vw',
    },
    margin: {
        margin: '.7rem 0',
        width: '90vw',
    },
    submitButton: {
        width: '10rem',
        margin: '0 auto',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
};

class AlertBodyV8 extends Component {
    state = {
        alertCause: '',
        alertDate: '',
        alertReportID: '',
        magneticAlertReportsCounter: '',
        mechanicalAlertReportsCounter: '',
        onDemandAlertReportsCounter: '',
        alertCounterState: '',
        radioTransmitterSabotageState: '',
        hubSabotageState: '',
        radioTransmitterPrealarmsAmount: '',
        IMEI: '',
        IMSI: '',
        ICCID: '',
        reserve: '',
    };

    componentDidMount() {
        const { schemeV8AlertBody, onSetProtocolType } = this.props;
        onSetProtocolType('B');
        this.setState({ ...schemeV8AlertBody });
    }

    componentDidUpdate(prevProps) {
        const { schemeV8AlertBody, selectedScheme, clearSelectedScheme } = this.props;
        if (
            schemeV8AlertBody &&
            Object.keys(schemeV8AlertBody).length > 0 &&
            prevProps.schemeV8AlertBody !== schemeV8AlertBody
        ) {
            this.setState({ ...schemeV8AlertBody });
        }
        if (selectedScheme === '- PUSTY -') {
            this.clearState();
            clearSelectedScheme();
        }
    }

    handleChange = (key, event) => {
        this.setState({ [key]: event.target.value }, () =>
            this.props.getPackagesData(this.state, `packageBody${this.props.number}`)
        );
    };

    onSubmit = () => {
        this.props.saveSelectedFormState(this.props.number, this.state);
        return false;
    };

    clearState = () => {
        this.setState({
            alertCause: '',
            alertDate: '',
            alertReportID: '',
            magneticAlertReportsCounter: '',
            mechanicalAlertReportsCounter: '',
            onDemandAlertReportsCounter: '',
            alertCounterState: '',
            radioTransmitterSabotageState: '',
            hubSabotageState: '',
            radioTransmitterPrealarmsAmount: '',
            IMEI: '',
            IMSI: '',
            ICCID: '',
            reserve: '',
        });
    };

    render() {
        const { classes } = this.props;
        const {
            alertCause,
            alertDate,
            alertReportID,
            magneticAlertReportsCounter,
            mechanicalAlertReportsCounter,
            onDemandAlertReportsCounter,
            alertCounterState,
            radioTransmitterSabotageState,
            hubSabotageState,
            radioTransmitterPrealarmsAmount,
            IMEI,
            reserve,
            IMSI,
            ICCID,
        } = this.state;

        return (
            <div>
                <ValidatorForm className={classes.root} onSubmit={this.onSubmit}>
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertCause}
                        onChange={e => this.handleChange('alertCause', e)}
                        label="Przyczyna alarmu: 1 znak [G,M,N,S,O] np. G"
                        validators={['matchRegexp:^([GNMOS])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertCause"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertDate}
                        onChange={e => this.handleChange('alertDate', e)}
                        label="Dzień, godz., min., sek. powstania alarmu: 8 znaków [0-9] np.11121315"
                        validators={['matchRegexp:^([0-9]{8})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertDate"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertReportID}
                        onChange={e => this.handleChange('alertReportID', e)}
                        label="ID raportu alarmowego: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertReportID"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={magneticAlertReportsCounter}
                        onChange={e => this.handleChange('magneticAlertReportsCounter', e)}
                        label="Licznik liczby raportów alarmu magnetycznego: 4 znaki [0000-9999 albo XXXX] np. 1234"
                        validators={['matchRegexp:^([0-9xX]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="magneticAlertReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={mechanicalAlertReportsCounter}
                        onChange={e => this.handleChange('mechanicalAlertReportsCounter', e)}
                        label="Licznik liczby raportów alarmu mechanicznego: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="mechanicalAlertReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={onDemandAlertReportsCounter}
                        onChange={e => this.handleChange('onDemandAlertReportsCounter', e)}
                        label="Licznik liczby raportów na żądanie: 4 znaki [0000-9999] np. 1234"
                        validators={['matchRegexp:^([0-9]{4})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="onDemandAlertReportsCounter"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={alertCounterState}
                        onChange={e => this.handleChange('alertCounterState', e)}
                        label="Stan licznika na godz. powstania alarmu [0,001 m3]: 9 znaków [000000000-999999999 albo XXXXXXXXX] np. 123456789"
                        validators={['matchRegexp:^([0-9xX]{9})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="alertCounterState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={radioTransmitterSabotageState}
                        onChange={e => this.handleChange('radioTransmitterSabotageState', e)}
                        label="Przepływ / stan sabotaży w nadajniku radiowym: 1 znak [+,-,m,M,g,G,d,D,x,X] np. m"
                        validators={['matchRegexp:^([+]|[-]|[M]|([m])|[g]|[G]|[d]|[D]|[xX])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="radioTransmitterSabotageState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={hubSabotageState}
                        onChange={e => this.handleChange('hubSabotageState', e)}
                        label="Stan sabotaży w koncentratorze: 1 znak [M,O,D,x] np. M"
                        validators={['matchRegexp:^([MODx])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="hubSabotageState"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={radioTransmitterPrealarmsAmount}
                        onChange={e => this.handleChange('radioTransmitterPrealarmsAmount', e)}
                        label="Liczba prealarmów w nadajniku radiowym od początku doby: 1 znak [0-9,a-z,A-K] np. 1"
                        validators={['matchRegexp:^([0-9A-Ka-z])$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="radioTransmitterPrealarmsAmount"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={IMEI}
                        onChange={e => this.handleChange('IMEI', e)}
                        label="IMEI: 15 znaków [0-9] np. 123456789012345"
                        validators={['matchRegexp:^([0-9]{15})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="IMEI"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={IMSI}
                        onChange={e => this.handleChange('IMSI', e)}
                        label="IMSI: 15 znaków [0-9] np. 123456789012345"
                        validators={['matchRegexp:^([0-9]{15})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="IMSI"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={ICCID}
                        onChange={e => this.handleChange('ICCID', e)}
                        label="ICCID: 20 znaków [0-9] np. 12345678901234567890"
                        validators={['matchRegexp:^([0-9]{20})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="ICCID"
                    />
                    <TextValidator
                        className={classes.margin}
                        variant="outlined"
                        value={reserve}
                        onChange={e => this.handleChange('reserve', e)}
                        label="Rezerwa: 2 znaki [x] np. xx"
                        validators={['matchRegexp:^([x]{2})$']}
                        errorMessages={['Niepoprawny format']}
                        required
                        data-cy="reserve"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        value="Submit"
                        className={classes.submitButton}>
                        Walidacja
                    </Button>
                </ValidatorForm>
            </div>
        );
    }
}

AlertBodyV8.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlertBodyV8);
