/* eslint-disable default-param-last */
import { messages } from '../data/Messages';
import {
    columnTypes,
    communicatorsType,
    communicatorsTypePl,
    listTypesId,
    reportTypes,
    localStorageKey,
    transmittersFields,
} from './constants';

export const changeDiacriticToStandard = stringTocheck =>
    stringTocheck
        .toLowerCase()
        .split('')
        .reduce((sum, current) => {
            const regexp = /[ąćęłńóśźż]/gi;
            const isDiacritic = current.match(regexp);
            if (isDiacritic) {
                const unicodeIndex = current.charCodeAt(current);
                if (unicodeIndex === 261) {
                    current = String.fromCharCode(97);
                }
                if (unicodeIndex === 263) {
                    current = String.fromCharCode(99);
                }
                if (unicodeIndex === 281) {
                    current = String.fromCharCode(101);
                }
                if (unicodeIndex === 322) {
                    current = String.fromCharCode(108);
                }
                if (unicodeIndex === 324) {
                    current = String.fromCharCode(110);
                }
                if (unicodeIndex === 243) {
                    current = String.fromCharCode(111);
                }
                if (unicodeIndex === 347) {
                    current = String.fromCharCode(115);
                }
                if (unicodeIndex === 378) {
                    current = String.fromCharCode(122);
                }
                if (unicodeIndex === 380) {
                    current = String.fromCharCode(122);
                }
            }
            return sum + current;
        }, '');

export const sortArrayByObjectKey = (tableData, propToSort) => {
    const copyTableData = Array.isArray(tableData) ? [...tableData] : Object.values(tableData);
    return copyTableData.sort((eleFirst, eleSecond) => {
        const getEleFirstTime = new Date(eleFirst[propToSort]).getTime();
        const getEleSecondTime = new Date(eleSecond[propToSort]).getTime();
        return getEleSecondTime - getEleFirstTime;
    });
};

export const getToken = () => {
    const token = localStorage.getItem(localStorageKey.TOKEN);
    return token ? JSON.parse(token) : null;
};

export const getUrlLastPart = () => {
    const url = window.location.hash;
    const urlSlashSplit = url.split('/');
    return urlSlashSplit[urlSlashSplit.length - 1];
};

export const createRangeArr = (arg1, arg2) => {
    const arr = [];
    for (; arg1 < arg2; arg1++) {
        arr.push(arg1);
    }
    return arr;
};

export const getArrayFromObject = obj => {
    const headerData = Object.keys(obj);
    return headerData.map(e => obj[e]);
};

export const convertHexToDec = hexValue => parseInt(hexValue, 16);

export const toLittleEndian = value => {
    const littleEndian = [];
    let j = 0;
    if (value.length % 2 !== 0) {
        value = value.replace(/^/, '0');
    }

    for (let i = 0; i < value.length; ) {
        littleEndian.push(value.substring(j, i + 2));
        j += 2;
        i += 2;
    }

    return littleEndian.reverse().join('');
};

export const getSchemeId = (schemeList, selectedScheme) =>
    schemeList.filter(i => i.name === selectedScheme)[0].id;

export const cloneObjByJSON = obj => JSON.parse(JSON.stringify(obj));

export const isAdminRole = () => {
    const userRole = localStorage.getItem(localStorageKey.USER_ROLE);
    return userRole === 'admin';
};

export const getRandomNumbers = (length = 1) => {
    const randomNumber = Math.floor(Math.random() * 100000000000000000);

    const set20numbers = num => {
        const addNumbersToEqual20Characters = `${num}${num.slice(-(20 - num.length))}`;
        return addNumbersToEqual20Characters.slice(-length);
    };

    if (length === 1) {
        return set20numbers(randomNumber.toString());
    }
    return set20numbers(randomNumber.toString());
};

export const getRandomHexNumbers = numLength => {
    const hexNum = [];
    const num = [];
    const getHexPrefix = decNum => `3${decNum}`;
    const getRandomNumber = () => Math.floor(Math.random() * 10);
    for (let i = 0; i < numLength; i++) {
        const randomNum = getRandomNumber();
        hexNum.push(getHexPrefix(randomNum));
        num.push(randomNum);
    }

    return { hex: hexNum.join(''), dec: num.join('') };
};

export const errorHandling = (err, reference) => {
    if (err.message === undefined) {
        return;
    }
    if (err.response === undefined) {
        return;
    }
    if (err.response.status === 401 && reference.props.history !== undefined) {
        reference.props.history.push(`/login`);
    }
    if (err.message) {
        reference.setState({ isServerError: true });
    }
};

export const getVisualDividedNumber = num => {
    const splitNum = num.toString().split('').reverse();
    const divided = splitNum.reduce((total, item, i) => {
        if (i % 3 === 0) {
            item += ' ';
        }
        return total.concat(item);
    }, []);
    return divided.reverse().join('');
};

export const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const removeLocalStorageItems = items => {
    items.forEach(i => {
        localStorage.removeItem(i);
    });
};

export const getBooleanFromString = val => val === 'true';

export const getLinkByDocumentType = documentType => {
    if (documentType === 'Alarmy') {
        return 'alarm';
    }
    if (documentType === 'Raporty') {
        return 'report';
    }
    return 'summary';
};

export const getSelectFieldByDocumentType = documentType => {
    if (documentType === 'Alarmy') {
        return 'alarm';
    }
    if (documentType === 'Raporty') {
        return 'raport';
    }
    return 'podsumowanie';
};

export const saveCurrentUrl = () => {
    const isCurrentURL = localStorage.getItem(localStorageKey.CURRENT_URL);
    if (!isCurrentURL) {
        const currentURL = window.location.href;
        localStorage.setItem(localStorageKey.CURRENT_URL, currentURL);
    }
};

export const isAsterisk = item => {
    if (item === 'woda *') {
        return 'woda';
    }
    return item;
};

export const getValuesTypesForTest = i => {
    if (i === 0) {
        return 'MAX';
    }
    if (i === 1) {
        return 'AVERAGE';
    }
    if (i === 2) {
        return 'MIN';
    }
    return null;
};

export const setDefaultHyphenDateRange = (amountOfDaysBefore = 0, dateValue) => {
    const presentDate = dateValue ? new Date(dateValue) : new Date();
    const setAmountOfDaysBefore = new Date(presentDate - amountOfDaysBefore * 24 * 60 * 60 * 1000);

    function getHyphenDateFormat(date) {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const nonZeroIndexedMonth = date.getMonth() + 1;
        const month = nonZeroIndexedMonth < 10 ? `0${nonZeroIndexedMonth}` : nonZeroIndexedMonth;
        const year = date.getUTCFullYear();
        return `${year}-${month}-${day}`;
    }

    return {
        present: getHyphenDateFormat(presentDate),
        amountOfDaysBefore: getHyphenDateFormat(setAmountOfDaysBefore),
    };
};

export const trimValueTo8Char = value => {
    const maxCharactersAmount = 8;
    if (value.length > maxCharactersAmount) {
        return value.slice(0, maxCharactersAmount);
    }
    return value;
};

export const setMinimalAboveZero = () => '00000001';

export const dayAmountRange = (value1, value2) => Math.abs(value1 - value2) / 1000 / 60 / 60 / 24;

export const removeSpaces = value => value.replace(/\s/g, '');

export const getPresentYear = () => {
    const date = new Date();
    return date.getUTCFullYear();
};

export const descendingComparator = (a, b, orderBy) => {
    function compareObjOrderBy() {
        if (b[orderBy].lastReading < a[orderBy].lastReading) {
            return -1;
        }
        if (b[orderBy].lastReading > a[orderBy].lastReading) {
            return 1;
        }
        return 0;
    }

    if (orderBy === columnTypes.REPORT_READINGS && compareObjOrderBy() !== 0) {
        return compareObjOrderBy();
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy) =>
    order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
};

export const getObjectKeyByValue = (obj, index) => {
    const objKeys = Object.keys(obj);
    return objKeys[index];
};

export const isValueBelow10 = val => Number(val) < 10;

export const isColumnHighlighted = indexRow => {
    const rowNumber = indexRow + 1;

    if (rowNumber % 5 === 0) {
        return { background: '#f5f5f5' };
    }
    return { background: '#fff' };
};

export const sortItemsAlphabetically = items =>
    items.reduce((total, item) => total.concat(item.name).sort((a, b) => a.localeCompare(b)), []);

export const isObject = val => {
    if (val === null) {
        return false;
    }
    return typeof val === 'object';
};

export const deepObjCopy = obj => {
    const copyObj = JSON.stringify(obj);
    return JSON.parse(copyObj);
};

export const isEncryptedPackage = data => {
    const regex = /^(65|67)(\w+)/;
    return regex.test(data);
};

export const translateCommunicatorType = communicatorType => {
    if (communicatorType === communicatorsTypePl.COMMUNICATOR) {
        return communicatorsType.COMMUNICATOR;
    }
    if (communicatorType === communicatorsTypePl.COUPLED_COMMUNICATOR) {
        return communicatorsType.COUPLED_COMMUNICATOR;
    }
    if (communicatorType === communicatorsTypePl.CONCENTRATOR) {
        return communicatorsType.CONCENTRATOR;
    }
    return null;
};

export const translateCommunicatorTypePl = communicatorType => {
    switch (communicatorType) {
        case communicatorsType.COMMUNICATOR:
            return communicatorsTypePl.COMMUNICATOR;
        case communicatorsType.COUPLED_COMMUNICATOR:
            return communicatorsTypePl.COUPLED_COMMUNICATOR;
        case communicatorsType.CONCENTRATOR:
            return communicatorsTypePl.CONCENTRATOR;
        default:
            return communicatorType;
    }
};

export const getDate = (date, shortFormat) => {
    const formattedDate = new Date(date - new Date(date).getTimezoneOffset() * 60000)
        .toISOString()
        .replace('T', '-');

    if (shortFormat) {
        return formattedDate.slice(0, 10);
    }

    return formattedDate.slice(0, 19).replace(/-/g, '_').replace(/:/g, '_');
};

export const getListType = () => {
    if (typeof window !== 'undefined') {
        const templateType = +window.location.hash.slice(-1);

        if (templateType === listTypesId.TEST_LIST || templateType === listTypesId.PRODUCTION_LIST) {
            return templateType;
        }
    }

    return listTypesId.TEST_LIST;
};

export const convertToPolish = slug => {
    switch (slug) {
        case reportTypes.REPORTS:
            return reportTypes.REPORTS_PL;
        case reportTypes.ALARMS:
            return reportTypes.ALARMS_PL;
        case reportTypes.SUMMARIES:
            return reportTypes.SUMMARIES_PL;
        case reportTypes.MINUTE_REPORTS:
            return reportTypes.MINUTE_REPORTS_PL;
        case reportTypes.CONFIGS:
            return reportTypes.CONFIGS_PL;
        case reportTypes.CON_MAPS:
            return reportTypes.CON_MAPS_PL;
        case reportTypes.CON_CONFIGS:
            return reportTypes.CON_CONFIGS_PL;
        default:
            return '';
    }
};

export const convertToEnglish = slug => {
    switch (slug) {
        case reportTypes.REPORTS_PL:
            return reportTypes.REPORTS;
        case reportTypes.ALARMS_PL:
            return reportTypes.ALARMS;
        case reportTypes.SUMMARIES_PL:
            return reportTypes.SUMMARIES;
        case reportTypes.MINUTE_REPORTS_PL:
            return reportTypes.MINUTE_REPORTS;
        case reportTypes.CONFIGS_PL:
            return reportTypes.CONFIGS;
        case reportTypes.CON_MAPS_PL:
            return reportTypes.CON_MAPS;
        case reportTypes.CON_CONFIGS_PL:
            return reportTypes.CON_CONFIGS;
        default:
            return '';
    }
};

export const sortByProperty = (array, property, sortType) =>
    array.sort((a, b) => {
        const isAscending = sortType === 'asc';
        const isString = typeof a[property] === 'string';

        const aProp = isString ? a[property]?.toUpperCase() : a[property];
        const bProp = isString ? b[property]?.toUpperCase() : b[property];

        if (isAscending ? aProp > bProp : aProp < bProp) {
            return 1;
        }
        if (isAscending ? aProp < bProp : aProp > bProp) {
            return -1;
        }

        return 0;
    });

export const getTransmittersMessages = (field, n) =>
    field === transmittersFields.number || field === transmittersFields.communication_status
        ? `${messages[field]} ${n + 1}`
        : messages[field];

export const dateDiff = (startDate, endDate) => Math.abs((new Date(startDate) - new Date(endDate)) / 36e5);

export const clearLocalStorage = () => {
    const authorizationKeys = [
        localStorageKey.TOKEN,
        localStorageKey.USER_ROLE,
        localStorageKey.PACKAGES_SEARCH_PHRASE,
        localStorageKey.PACKAGES_PAGE,
        localStorageKey.IS_PARSER_ERROR_LINK_CLICKED,
        localStorageKey.PACKAGES_SELECTED_WATERPIPE,
        localStorageKey.PACKAGES_SELECTED_STATUS,
        localStorageKey.PACKAGES_SELECTED_STATUS_NAME,
        localStorageKey.PARSER_ERROR_PACKAGE_DATA,
        localStorageKey.PARSER_ERROR_PACKAGE_DATE_CREATED,
    ];

    authorizationKeys.forEach(item => localStorage.removeItem(item));
};
