/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Collapse } from '@mui/material';
import tableIcon from '../../../../assets/img/table-solid.svg';
import theme from '../../../../themes/theme';

const styles = () => ({
    settingsItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 25px 0 25px',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    },
    settingsIcon: {
        width: 30,
        height: 30,
        marginRight: 10,
        pointerEvents: 'none',
    },
    checkboxPanel: {
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
    checkboxBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        height: '2.2rem',
    },
    checkboxBoxSpecial: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.colors.border}`,
        marginLeft: 10,
    },
    columnsPanel: {
        width: 800,
        backgroundColor: theme.colors.background,
        border: `1px solid ${theme.colors.border}`,
        '@media (max-width: 800px)': {
            width: '100%',
        },
    },
    collapseContent: {
        height: 600,
        overflowY: 'scroll',
    },
    styledLabel: {
        marginLeft: 5,
    },
    collapseWrapper: {
        position: 'absolute',
        right: 2,
        zIndex: 2,
    },
});

const ColumnsSettingsItem = ({
    classes: {
        settingsItem,
        settingsIcon,
        checkboxPanel,
        checkboxBox,
        checkboxBoxSpecial,
        columnsPanel,
        collapseContent,
        styledLabel,
        collapseWrapper,
    },
    columnsDefs,
    setColumnHide,
    panelPosition,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(isOpen => !isOpen);

    const changeOneCheckbox = index => {
        setColumnHide(columnsDefs.map((col, i) => (index === i ? { ...col, hide: !col.hide } : col)));
    };

    const changeAllCheckboxes = () => {
        setColumnHide(
            columnsDefs.map(col => ({
                ...col,
                hide: columnsDefs.every(column => !column.hide),
            }))
        );
    };

    const renderCheckboxes = () => {
        if (columnsDefs?.length) {
            return columnsDefs
                .filter(column => column?.headerTooltip)
                .map((column, index) => (
                    <div key={index} className={checkboxBox}>
                        <input
                            type="checkbox"
                            checked={!column.hide}
                            onChange={() => changeOneCheckbox(index)}
                        />
                        <p className={styledLabel}>{`${column.headerName} (${column.headerTooltip})`}</p>
                    </div>
                ));
        }

        return null;
    };

    return (
        <>
            <div className={settingsItem} onClick={toggleOpen}>
                <img className={settingsIcon} src={tableIcon} />
                <p>{'Ukryj/pokaż kolumny'}</p>
            </div>

            <div
                className={collapseWrapper}
                style={{
                    top: panelPosition,
                }}>
                <Collapse className={columnsPanel} in={isOpen} timeout={700}>
                    <div className={collapseContent}>
                        <div className={checkboxBoxSpecial}>
                            <input
                                type="checkbox"
                                checked={columnsDefs?.every(column => !column.hide)}
                                onChange={changeAllCheckboxes}
                            />
                            <p className={styledLabel}>{'Zaznacz wszystkie'}</p>
                        </div>
                        <div className={checkboxPanel}>{renderCheckboxes()}</div>
                    </div>
                </Collapse>
            </div>
        </>
    );
};

export default withStyles(styles)(ColumnsSettingsItem);
