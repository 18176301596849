/* eslint-disable camelcase */
import { createGlobalStyle } from 'styled-components';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const tab_1 = '#cfcfff';
const tab_2 = '#ffcfcf';
const tab_3 = '#cfe7cf';
const tab_4 = '#ffffcf';
const tab_5 = '#e7cfe7';

export default createGlobalStyle`

@font-face {
    font-family: 'Montserrat', arial;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat-v13-latin/montserrat-v13-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('../fonts/montserrat-v13-latin/montserrat-v13-latin-regular.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('../fonts/montserrat-v13-latin/montserrat-v13-latin-regular.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/montserrat-v13-latin/montserrat-v13-latin-regular.woff')
            format('woff'),
        /* Modern Browsers */
            url('../fonts/montserrat-v13-latin/montserrat-v13-latin-regular.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/montserrat-v13-latin/montserrat-v13-latin-regular.svg#Montserrat')
            format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Nunito Sans', arial;
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-200.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans ExtraLight'), local('NunitoSans-ExtraLight'),
        url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-200.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-200.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-200.woff')
            format('woff'),
        /* Modern Browsers */
            url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-200.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-200.svg#NunitoSans')
            format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Nunito Sans', arial;
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
        url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-600.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */
            url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-600.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-600.woff')
            format('woff'),
        /* Modern Browsers */
            url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-600.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../fonts/nunito-sans-v4-latin/nunito-sans-v4-latin-600.svg#NunitoSans')
            format('svg'); /* Legacy iOS */
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', arial, sans-serif;
}

body::-webkit-scrollbar{
    display: none;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.container-fluid {
    position: relative;
    background-color: #37bbef;
}

.collector-pagination {
    margin: 2rem 0 2rem 0;
    display: flex;
    justify-content: flex-end;
    button {
        background-color: lightgray;
        margin: 0.2rem;
        font-size: 1rem;
        color: gray;
        border-radius: 0;
    }
    button:hover {
        background-color: orange;
    }
    button:first-child,
    button:last-child {
        background-color: transparent;
        font-weight: 600;
    }
}

a:hover {
    text-decoration: none;
    color: black;
}

table tr:nth-child(odd) td {
    background: lightgray;
}
table tr:nth-child(even) td {
    background: #fff;
}

div.Navbar-content-8 {
    padding: 0 !important;
}

.ag-theme-alpine {
    .ag-row:hover {
        background-color: #e8f4fe !important;
    }

    .ag-cell,
    .ag-full-width-row .ag-cell-wrapper.ag-row-group {
        padding-left: 8px;
        padding-right: 8px;
    }

    .ag-cell {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-height: 40px;
        border-right: 1px solid #dde2eb !important;
    }

    .ag-header-cell {
        padding-left: 9px;
        padding-right: 9px;
        cursor: pointer;
    }

    .ag-tooltip {
        overflow-wrap: break-word;
        max-width: 700px;
        height: fit-content;
    }

    .cell-header-tabs {
        .ag-header-cell-text {
            margin: 0 auto;
        }
    }

    .cell-header-tab_1 {
        color: ${tab_1};
    }

    .cell-header-tab_2 {
        color: ${tab_2};
    }

    .cell-header-tab_3 {
        color: ${tab_3};
    }

    .cell-header-tab_4 {
        color: ${tab_4};
    }

    .cell-header-tab_5 {
        color: ${tab_5};
    }
}


`;
