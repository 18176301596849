import React, { useState, useEffect, useContext, forwardRef } from 'react';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/styles';
import PackagesContext from '../../../context/PackagesContext';
import { createRangeArr } from '../../../utils/helpers';
import PaginationButton from './PaginationButton';
import docsContext from '../../../context/DocsContext';
import PaginationRows from '../PaginationRows';
import theme from '../../../themes/theme';

const useStyles = makeStyles({
    paginationContainer: {
        display: 'flex',
        alignItems: 'center',
        borderStyle: 'solid',
        borderColor: `${theme.colors.border}`,
        borderWidth: '1px',
        padding: '0.4rem 0.8rem',
        justifyContent: 'space-between',
        marginTop: '-1px',
    },
    pagination: {
        height: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        gap: '5px',
        flexDirection: 'row',
    },
    chevron: {
        marginTop: '2px',
    },
});

const TableReportsPagination = (props, ref) => {
    const [page, setPage] = useState(1);
    const [pagesToSelect, setPagesToSelect] = useState(1);

    const getPackagesContext = useContext(PackagesContext);
    const docProps = useContext(docsContext);

    const { paginationContainer, pagination, chevron } = useStyles();

    const { selectedPage, paginationSizes, paginationSize, changePaginationSize } = props;

    const checkIsDecramentingPages = () => page > pagesToSelect + 4;

    const incrementPagesToSelect = () => {
        if (page > pagesToSelect + 9) {
            setPagesToSelect(pagesToSelect + 5);
        } else if (page > pagesToSelect + 8) {
            setPagesToSelect(pagesToSelect + 4);
        } else if (page > pagesToSelect + 7) {
            setPagesToSelect(pagesToSelect + 3);
        } else if (page > pagesToSelect + 6) {
            setPagesToSelect(pagesToSelect + 2);
        } else if (page > pagesToSelect + 5) {
            setPagesToSelect(pagesToSelect + 1);
        }
    };

    const decrementPagesToSelect = () => {
        const isPagesToSelectEqZero = subtraction => subtraction === 0;
        if (pagesToSelect === 1) {
            return;
        }
        if (page < pagesToSelect + 4) {
            if (isPagesToSelectEqZero(pagesToSelect - 1)) {
                return;
            }
            setPagesToSelect(pagesToSelect - 1);
        }
        if (page < pagesToSelect + 3) {
            if (isPagesToSelectEqZero(pagesToSelect - 2)) {
                setPagesToSelect(pagesToSelect - 1);
                return;
            }
            setPagesToSelect(pagesToSelect - 2);
        }
        if (page < pagesToSelect + 2) {
            if (isPagesToSelectEqZero(pagesToSelect - 3)) {
                setPagesToSelect(pagesToSelect - 2);
                return;
            }
            setPagesToSelect(pagesToSelect - 3);
        }
        if (page < pagesToSelect + 1) {
            if (isPagesToSelectEqZero(pagesToSelect - 4)) {
                setPagesToSelect(pagesToSelect - 3);
                return;
            }
            setPagesToSelect(pagesToSelect - 4);
        }
    };

    useEffect(() => {
        if (checkIsDecramentingPages()) {
            incrementPagesToSelect();
        } else {
            decrementPagesToSelect();
        }
    }, [page]);

    const getPagesArr = pageNr => {
        const pageCounter = pageNr + 10;
        return createRangeArr(pageNr, pageCounter);
    };

    const pushPageButtonHandler = button => {
        if (!button) {
            return;
        }
        docProps.changePageHandler(button);
        setPage(button);
    };

    useEffect(() => {
        if (docProps !== undefined) {
            setPage(1);
        }
    }, [docProps]);

    useEffect(() => {
        setPage(1);
    }, [
        getPackagesContext.packagesData.selectedWaterpipe,
        getPackagesContext.packagesData.selectedStatus,
        getPackagesContext.packagesData.id,
        getPackagesContext.packagesData.sms,
        getPackagesContext.packagesData.dok,
    ]);

    useEffect(() => {
        setPage(selectedPage);
    });

    const setPaginationButtons = () =>
        getPagesArr(pagesToSelect).map(buttonNumber => (
            <PaginationButton
                key={`${buttonNumber}_pg_btn`}
                color={buttonNumber === page ? 'selected' : 'primary'}
                handlePageChange={() => pushPageButtonHandler(buttonNumber)}>
                {buttonNumber}
            </PaginationButton>
        ));

    return (
        <div className={paginationContainer} ref={ref}>
            <PaginationRows
                paginationSizes={paginationSizes}
                paginationSize={paginationSize}
                changePaginationSize={changePaginationSize}
                isTableReports
                goToFirstPage={pushPageButtonHandler}
            />
            <div className={pagination}>
                <PaginationButton
                    className={chevron}
                    handlePageChange={() => pushPageButtonHandler(page - 1)}>
                    <ChevronLeft className={chevron} />
                </PaginationButton>
                {setPaginationButtons()}
                <PaginationButton
                    className={chevron}
                    handlePageChange={() => pushPageButtonHandler(page + 1)}>
                    <ChevronRight className={chevron} />
                </PaginationButton>
            </div>
        </div>
    );
};

export default forwardRef(TableReportsPagination);
