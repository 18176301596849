import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import LoginPage from './components/Login/LoginPage';
import Pipelines from './components/Nav/Pipelines';
import PipelinesList from './components/Nav/PipelinesList';
import Packages from './components/Nav/Packages';
import Docs from './components/Nav/Docs';
import Sms from './components/Nav/Sms';
import Home from './components/Nav/Home';
import Error from './components/Nav/Error';
import PrivateRoute from './components/Login/PrivateRoute';
import Generator from './components/Generator/Generator';
import UsersList from './components/Nav/UsersList';
import ProtocolsList from './components/Nav/ProtocolsList';
import GatewaysList from './components/Nav/GatewaysList';
import { isAdminRole } from './utils/helpers';
import Snow from './utils/Snow';
import { AppProvider } from './context/AppContext';
import ListGenerator from './components/GeneratedList/ListGenerator';
import TestListGenerator from './components/GeneratedList/TestListGenerator';
import TestListGeneratorV2 from './components/GeneratedList/TestListGeneratorV2';
import ListTemplates from './components/ListTemplates/ListTemplates';
import ListForms from './components/Nav/Lists/ListForms';
import FavoriteReportEdit from './components/FavoriteReports/FavoriteReportEdit/FavoriteReportEdit';
import TestListOrdered from './components/TestListOrdered/TestListOrdered';
import { endpoint, localStorageKey } from './utils/constants';
import { axiosInstance } from './utils/axiosInstance';

const styles = {
    pathLink: {
        marginBottom: '0.625rem',
    },
};

const App = ({ classes, alert: { show } }) => {
    const [submenuElement, setSubmenuElement] = useState(null);
    const [, forceRerender] = useState({});

    const handleLogin = (login, password, isExternal) => {
        axiosInstance
            .post(
                endpoint.USER_LOGIN,
                {
                    login,
                    password,
                    is_external: isExternal,
                },
                {
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(response => {
                if (response.data.status === 'success') {
                    localStorage.setItem(localStorageKey.TOKEN, JSON.stringify(response.data.auth_token));
                    localStorage.setItem(localStorageKey.USER_ROLE, response.data.role);
                    forceRerender(response);
                }
            })
            .catch(() => show('NIEPRAWIDŁOWE DANE '));
    };

    const isSnowing = () => {
        const getEnvValue = process.env.REACT_APP_IS_SNOWING;
        const isWinter = () => {
            const getMonth = new Date().getMonth();
            return getMonth === 11 || getMonth === 0;
        };

        return getEnvValue === 'true' && isWinter();
    };

    const handleListsSubmenu = element => {
        setSubmenuElement(element);
    };

    return (
        <HashRouter>
            <AppProvider
                value={{
                    handleListsSubmenu,
                    setSubmenuElement,
                    submenuElement,
                }}>
                <div>
                    {isSnowing() && <Snow />}

                    <Switch>
                        <Route
                            handleLogin={handleLogin}
                            className={classes.pathLink}
                            exact
                            path="/login"
                            render={props => <LoginPage {...props} handleLogin={handleLogin} />}
                        />
                        <PrivateRoute
                            exact
                            strict
                            className={classes.pathLink}
                            path="/pipelines"
                            component={props => <PipelinesList {...props} showWaterpipeLink />}
                        />
                        {isAdminRole() && (
                            <PrivateRoute
                                exact
                                strict
                                className={classes.pathLink}
                                path="/pipelines/add"
                                component={Pipelines}
                            />
                        )}
                        {isAdminRole() && (
                            <PrivateRoute
                                exact
                                strict
                                className={classes.pathLink}
                                path="/pipeline/edit/:id"
                                component={Pipelines}
                            />
                        )}
                        <PrivateRoute
                            strict
                            className={classes.pathLink}
                            path="/pipeline/:id"
                            component={Docs}
                        />
                        <PrivateRoute className={classes.pathLink} path="/packages" component={Packages} />
                        <PrivateRoute className={classes.pathLink} path="/error/:id" component={Error} />
                        <PrivateRoute className={classes.pathLink} path="/docs" component={Docs} />
                        <PrivateRoute className={classes.pathLink} path="/generator" component={Generator} />
                        <PrivateRoute className={classes.pathLink} path="/sms" component={Sms} />
                        <PrivateRoute
                            className={classes.pathLink}
                            path="/gateways"
                            component={GatewaysList}
                        />
                        <PrivateRoute className={classes.pathLink} path="/users" component={UsersList} />
                        <PrivateRoute
                            className={classes.pathLink}
                            path="/protocols"
                            component={ProtocolsList}
                        />
                        <PrivateRoute
                            className={classes.pathLink}
                            path="/protocols"
                            component={ProtocolsList}
                        />
                        <PrivateRoute className={classes.pathLink} path="/lists/:id" component={ListForms} />
                        <PrivateRoute
                            className={classes.pathLink}
                            path="/generated-list"
                            component={ListGenerator}
                        />
                        <PrivateRoute
                            className={classes.pathLink}
                            path="/test-generated-list"
                            component={TestListGenerator}
                        />
                        <PrivateRoute
                            className={classes.pathLink}
                            path="/test-generated-list-v2"
                            component={TestListGeneratorV2}
                        />
                        <PrivateRoute
                            className={classes.pathLink}
                            path="/test-ordered-list"
                            component={TestListOrdered}
                        />
                        <PrivateRoute
                            className={classes.pathLink}
                            path="/list-templates/:id"
                            component={ListTemplates}
                        />
                        <PrivateRoute
                            className={classes.pathLink}
                            path="/list-template/edit/:id"
                            component={FavoriteReportEdit}
                        />
                        <PrivateRoute className={classes.pathLink} exact strict path="/" component={Home} />
                        <Redirect from="*" to="/" />
                    </Switch>
                </div>
            </AppProvider>
        </HashRouter>
    );
};

export default withStyles(styles)(withAlert()(App));
