/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import {
    columnTypes,
    endpoint,
    localStorageKey,
    testListVersion,
    TEST_DATA_PATH,
    waterpipeWater,
} from '../../utils/constants';
import { sortByProperty } from '../../utils/helpers';
import TestList from './List/TestList';

export default function ListGenerator() {
    const ORDER = {
        direction: 'asc',
        by: 'calories',
    };
    const [generatedListData, setGeneratedListData] = useState({});
    const [reportData, setReportData] = useState([]);
    const [reportTableHeader, setReportTableHeader] = useState([]);
    const [reportHeadCells, setReportHeadCells] = useState([]);
    const [isErrorLoadingData, setIsErrorLoadingData] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [waterpipes, setWaterpipes] = useState([]);
    const [isFetchingAdditionalData, setIsFetchingAdditionalData] = useState(false);

    const getHeadCells = () => [
        {
            id: columnTypes.ORDINAL_NUMBER,
            label: columnTypes.ORDINAL_NUMBER,
        },
        ...reportHeadCells,
    ];

    const setError = error => {
        console.error('Error list', error);
        setIsErrorLoadingData(true);
        setGeneratedListData([]);
    };

    const getDataEndpoint = (testListId, testDataFile) =>
        testDataFile
            ? `${TEST_DATA_PATH}/${testDataFile}.json`
            : `${endpoint.TEST_REPORT_V2_GET}/${testListId}`;

    const getGeneratedList = () => {
        const testListId = JSON.parse(localStorage.getItem(localStorageKey.GENERATED_TEST_LIST_DATA_V2))?.id;

        setIsFetching(true);
        axiosInstance
            .get(getDataEndpoint(testListId))
            .then(data => {
                const testData = {
                    dataAlarmWithPrefix: [],
                    dataReportWithPrefix: data.data,
                };
                setGeneratedListData(testData);
            })
            .catch(err => setError(err))
            .finally(() => setIsFetching(false));
    };

    const getReportData = () => {
        const reportData = generatedListData.dataReportWithPrefix;
        setReportData(reportData);
    };

    const setPartsOfTableHeader = () => {
        if (reportData.length) {
            const headerNames = Object.keys(reportData[0]);
            setReportTableHeader(headerNames);
        }
    };

    const setTableHeader = () => {
        if (reportTableHeader.length) {
            setReportHeadCells(
                reportTableHeader.map(cellName => ({
                    id: cellName,
                    label: cellName,
                }))
            );
        }
    };

    useEffect(() => {
        getGeneratedList();
    }, []);

    useEffect(() => {
        if (
            Object.keys(generatedListData).length &&
            Object.keys(generatedListData.dataReportWithPrefix).length
        ) {
            getReportData();
        }
    }, [generatedListData.dataReportWithPrefix]);

    useEffect(() => {
        setPartsOfTableHeader();
    }, [reportData]);

    useEffect(() => {
        setTableHeader();
    }, [reportTableHeader]);

    const createWaterpipesArray = waterpipesList =>
        sortByProperty(
            waterpipesList?.map(waterpipe => ({
                label: waterpipe.name,
                value: waterpipe.sign,
            })),
            'label',
            'asc'
        );

    useEffect(() => {
        setIsFetchingAdditionalData(true);
        axiosInstance
            .get(endpoint.WATERPIPES)
            .then(res => {
                const waterpipesList = res?.data?.data?.filter(
                    waterpipe => waterpipe.sign !== waterpipeWater.sign
                );
                const prodWaterpipes = waterpipesList.filter(waterpipe => !waterpipe.is_test);
                const testWaterpipes = waterpipesList.filter(waterpipe => waterpipe.is_test);

                setWaterpipes([
                    {
                        label: 'wodociągi produkcyjne',
                        value: prodWaterpipes,
                        children: createWaterpipesArray(prodWaterpipes),
                    },
                    {
                        label: 'wodociągi testowe',
                        value: testWaterpipes,
                        children: createWaterpipesArray(testWaterpipes),
                    },
                    {
                        label: waterpipeWater.name,
                        value: waterpipeWater.sign,
                    },
                ]);
            })
            .catch(err => setError(err))
            .finally(() => setIsFetchingAdditionalData(false));
    }, []);

    return (
        <TestList
            version={testListVersion.V2}
            order={ORDER.direction}
            orderBy={ORDER.by}
            generatedListData={generatedListData}
            headCells={getHeadCells}
            waterpipes={waterpipes}
            isErrorLoadingData={isErrorLoadingData}
            isFetching={isFetching || isFetchingAdditionalData}
        />
    );
}
