import React from 'react';
import { Redirect, Route } from 'react-router';
import AuthComponent from './AuthComponent';
import { getToken, saveCurrentUrl } from '../../utils/helpers';

const PrivateRoute = ({ token, component: Component, ...rest }) => {
    const setRedirection = path => {
        saveCurrentUrl();
        return (
            <div>
                <Redirect from={path} to="/login" />
            </div>
        );
    };

    return (
        <Route
            {...rest}
            render={props =>
                getToken() ? <AuthComponent component={Component} {...props} /> : setRedirection(props.path)
            }
        />
    );
};

export default PrivateRoute;
