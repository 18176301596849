import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Pagination from '../Pagination/Pagination';

const theme = createTheme({
    typography: {
        useNextVariants: true,
    },
});

class TablePagination extends Component {
    handleClick(page) {
        this.props.changePage(page);
    }

    render() {
        const { selectedPage } = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Pagination click={page => this.handleClick(page)} selectedPage={selectedPage} />
            </MuiThemeProvider>
        );
    }
}

export default TablePagination;
