/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable chai-friendly/no-unused-expressions */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { AgGridReact } from 'ag-grid-react';
import { isObject, getUrlLastPart, convertToPolish, getTransmittersMessages } from '../../utils/helpers';
import {
    columnTypes,
    localStorageKey,
    tableTranslations,
    listTypes,
    historyActions,
    reportTypes,
    transmittersFields,
    columnEventTypes,
} from '../../utils/constants';
import theme from '../../themes/theme';
import SettingsPanel from '../GeneratedList/SettingsPanel/SettingsPanel';
import { messages } from '../../data/Messages';
import TableReportsPagination from '../Pagination/TableReportsPagination/TableReportsPagination';
import CopyModal from '../CopyModal/CopyModal';
import docsContext from '../../context/DocsContext';

const TABLE_STYLE = {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    position: 'relative',
};
const ALIGN_RIGHT = {
    display: 'flex',
    justifyContent: 'end',
};

const useStyles = makeStyles({
    root: {
        marginTop: '1.1rem',
        height: '100%',
    },
    errorMessage: {
        color: 'red',
        fontWeight: 'bold',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        userSelect: 'none',
    },
    innerWideParagraphStyle: {
        whiteSpace: 'pre-wrap',
        minWidth: 130,
    },
    innerParagraphStyle: {
        whiteSpace: 'pre-wrap',
        minWidth: 90,
    },
    pTagStyle: {
        margin: 0,
        height: '2.1rem',
        display: 'flex',
        alignItems: 'center',
        color: 'black',
    },
});

export default function TableReports({ content, isServerError, headerText, isComponentMounted, match }) {
    const PAGINATION_SIZES = [10, 50, 100, 500, 1000];
    const NAV_HEIGHT = '70px';

    const [gridApi, setGridApi] = useState(null);
    const [columnsDefs, setColumnsDefs] = useState([]);
    const [data, setData] = useState([]);
    const [settingsPanelPosition, setSettingsPanelPosition] = useState(50);
    const settingsPanelRef = useRef(null);
    const paginationPanelRef = useRef(null);
    const [tableHeight, setTableHeight] = useState();
    const [locationKeys, setLocationKeys] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [previousUrl, setPreviousUrl] = useState('');
    const history = useHistory();
    const {
        selectedPage,
        changePageHandler,
        changePaginationAndChunkSize,
        chunk,
        loadData,
        setIsLoading,
        reportType,
    } = useContext(docsContext);

    const { root, errorMessage, pTagStyle, innerWideParagraphStyle, innerParagraphStyle } = useStyles();

    useEffect(
        () =>
            history.listen(location => {
                if (history.action === historyActions.PUSH) {
                    setLocationKeys([location.key]);
                }
                if (history.action === historyActions.POP) {
                    if (locationKeys[1] === location.key) {
                        setLocationKeys(([_, ...keys]) => keys);
                        loadData(prev => ({
                            ...prev,
                            reportType: getUrlLastPart(),
                            page: 1,
                        }));
                    } else {
                        setLocationKeys(keys => [location.key, ...keys]);
                    }
                }
            }),
        [locationKeys]
    );

    useEffect(() => {
        if (gridApi) {
            const localStorageCols = JSON.parse(
                localStorage.getItem(getTableTypeKey(convertToPolish(reportType)))
            );

            if (!localStorageCols) {
                setColumnsDefs(
                    Object.entries(content.headers).map(headerName => {
                        const toolTip = getToolTip(headerName[0]);
                        return {
                            headerName: headerName[1],
                            field: headerName[0],
                            key: `${headerName[0]}_id_key`,
                            cellRenderer:
                                (headerName[0] === columnTypes.READINGS ||
                                    headerName[0] === columnTypes.FLOWS ||
                                    headerName[0] === columnTypes.TRANSMITTERS) &&
                                cellRenderer,
                            headerTooltip: toolTip,
                            tooltipValueGetter: params => tooltipValueGetter(params, headerName[0]),
                            cellStyle: setCellStyle(headerName[0]),
                            resizable: true,
                            hide: false,
                            autoHeight:
                                headerName[0] === columnTypes.READINGS ||
                                headerName[0] === columnTypes.FLOWS ||
                                headerName[0] === columnTypes.TRANSMITTERS,
                        };
                    })
                );
            } else {
                setColumnDefsFromLocalStorage();
            }
            setData(
                content.data.map(val => ({
                    ...val,
                    readings_last: null,
                    flows_last: null,
                    transmitters_last: null,
                }))
            );
        }
    }, [content]);

    const setCellStyle = headerType => {
        switch (headerType) {
            case columnTypes.DEBUG_RESET_TIME:
                return ALIGN_RIGHT;
            case columnTypes.ALERT_MECHANIC_DURATION:
                return ALIGN_RIGHT;
            case columnTypes.READINGS:
                return ALIGN_RIGHT;
            default:
                return {};
        }
    };

    useEffect(() => {
        gridApi && gridApi.showLoadingOverlay();
    }, [gridApi]);

    useEffect(() => {
        if (isComponentMounted) {
            gridApi && gridApi.showLoadingOverlay();
        }
    }, [isComponentMounted]);

    useEffect(() => {
        if (gridApi) {
            if (data.length) {
                gridApi.hideOverlay();
            } else {
                gridApi.showNoRowsOverlay();
            }
        }
    }, [data]);

    useEffect(() => {
        setSettingsPanelPosition(settingsPanelRef.current.clientHeight);
        setTableHeight(
            `calc(${window.innerHeight}px - ${settingsPanelRef.current.clientHeight}px - ${paginationPanelRef.current.clientHeight}px - ${NAV_HEIGHT})`
        );

        const updateWindowDimensions = () => {
            const settingsPanelHeight = settingsPanelRef.current.clientHeight;
            const paginationPanelHeight = paginationPanelRef.current.clientHeight;
            const newHeight = `calc(${window.innerHeight}px - ${settingsPanelHeight}px - ${paginationPanelHeight}px - ${NAV_HEIGHT})`;
            setTableHeight(newHeight);
            setSettingsPanelPosition(settingsPanelHeight);
        };

        window.addEventListener('resize', updateWindowDimensions);

        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    const onGridReady = params => {
        setGridApi(params.api);
    };

    const autoSizeColumns = params => {
        const localStorageColumnDefs = JSON.parse(
            localStorage.getItem(getTableTypeKey(convertToPolish(reportType)))
        );

        if (params && !localStorageColumnDefs) {
            if (reportType === reportTypes.MINUTE_REPORTS || reportType === reportTypes.CON_MAPS) {
                params.api.sizeColumnsToFit();
            } else {
                params.columnApi.autoSizeAllColumns();
            }

            const changedColDefs = params.columnApi.columnModel.columnDefs.map(column => ({
                ...column,
                width: params.columnApi.columnModel
                    .getColumnState()
                    .filter(col => col.colId === column.field)[0]?.width,
            }));

            saveColumnSettings(changedColDefs);
        }
    };

    const getToolTip = field => {
        let tooltip = '';
        Object.entries(messages).forEach(val => {
            if (field === val[0]) {
                tooltip = val[1];
            }
        });
        return tooltip;
    };

    const changeRowStyle = () => gridApi.redrawRows();

    const formatCpuActivityMessage = (cpuActivity) => {
        if (cpuActivity === '0.0') {
            return '[Błąd pomiaru CPU]';
        }

        if (cpuActivity !== null) {
            return `[${cpuActivity}%]`;
        }

        return '';
    }

    const getValue = (columnValue, columnType) => {
        if (columnType === columnTypes.READINGS) {
            if (!(columnValue instanceof HTMLElement)) {
                const cpuActivityMessage = formatCpuActivityMessage(columnValue[0]?.cpu_activity)

                return document.createTextNode(
                    columnValue?.length ? `${columnValue[0]?.data} ${columnValue[0]?.sabotage} ${cpuActivityMessage}` : ''
                );
            }
            return columnValue;
        }

        if (columnType === columnTypes.FLOWS) {
            if (isObject(columnValue) && !(columnValue instanceof HTMLElement)) {
                return document.createTextNode(`total: ${columnValue.total}`);
            }
            return columnValue;
        }

        if (columnType === columnTypes.TRANSMITTERS) {
            if (columnValue?.length && !(columnValue instanceof HTMLElement)) {
                return document.createTextNode('Rozwiń');
            }
            return columnValue;
        }
        return null;
    };

    const cellRenderer = params => {
        if (
            params.colDef.field === columnTypes.READINGS ||
            params.colDef.field === columnTypes.FLOWS ||
            params.colDef.field === columnTypes.TRANSMITTERS
        ) {
            const cellPanel = document.createElement('p');
            cellPanel.style.cursor = 'pointer';
            cellPanel.style.color = theme.colors.link;
            cellPanel.style.margin = 0;
            cellPanel.appendChild(getValue(params?.value ?? data, params.colDef.field));
            return cellPanel;
        }

        return null;
    };

    const getExpandedReadingValue = (rowId, columnType) => {
        const [dataValues] = data.filter(row => row.id === rowId);
        const readings = dataValues?.readings;
        const flows = dataValues?.flows;
        const transmitters = dataValues?.transmitters;
        const cellPanel = document.createElement('div');
        cellPanel.style.cursor = 'pointer';

        if (columnType === columnTypes.FLOWS) {
            if (flows) {
                const pTagTotal = document.createElement('p');
                pTagTotal.textContent = `total: ${flows.total}`;
                pTagTotal.classList.add(pTagStyle);
                cellPanel.appendChild(pTagTotal);
                Object.entries(flows).forEach(flow => {
                    if (flow[0] !== 'total') {
                        const key = flow[0];
                        const value = flow[1];
                        const pTag = document.createElement('p');
                        pTag.classList.add(pTagStyle);
                        pTag.textContent = `${key}: ${value}`;
                        cellPanel.appendChild(pTag);
                    }
                });
            }
            return cellPanel;
        }

        if (columnType === columnTypes.READINGS) {
            if (readings) {
                readings.forEach(item => {
                    const pTag = document.createElement('p');
                    pTag.classList.add(pTagStyle);
                    const cpuActivityMessage = formatCpuActivityMessage(item.cpu_activity)

                    pTag.textContent = `${item.data} ${item.sabotage} ${cpuActivityMessage}`;
                    cellPanel.appendChild(pTag);
                });
            }
            return cellPanel;
        }

        if (columnType === columnTypes.TRANSMITTERS) {
            if (transmitters.length) {
                transmitters.forEach((item, transmitterIndex) => {
                    const pTag = document.createElement('p');
                    pTag.classList.add(pTagStyle);
                    Object.keys(item).forEach((field, index) => {
                        const currentField = Object.keys(item)[index];
                        const innerParagraph = document.createElement('p');
                        innerParagraph.textContent = `${item[field].header}: ${item[field].value}`;
                        innerParagraph.classList.add(
                            currentField === transmittersFields.number
                                ? innerWideParagraphStyle
                                : innerParagraphStyle
                        );
                        innerParagraph.title = getTransmittersMessages(currentField, transmitterIndex);
                        pTag.appendChild(innerParagraph);
                    });

                    cellPanel.appendChild(pTag);
                });
            }
            return cellPanel;
        }

        return null;
    };

    const onCellClicked = params => {
        const columnType = params.colDef.field;
        const rowNode = params.node;

        if (columnType === columnTypes.READINGS) {
            const reportReadingsValue = params.data.readings;
            const reportReadingsValueLast = params.data.readings_last
                ? params.data.readings_last
                : getExpandedReadingValue(params.data.id, columnTypes.READINGS);
            rowNode.setDataValue(columnTypes.READINGS, reportReadingsValueLast);
            params.data.readings_last = reportReadingsValue;
            if (!(reportReadingsValueLast instanceof HTMLElement)) {
                setShowModal(true);
                setModalData(reportReadingsValueLast.map(item => `${item.data}${item.sabotage} ${formatCpuActivityMessage(item.cpu_activity)}`).join(', '));
            }
        }

        if (columnType === columnTypes.FLOWS) {
            const flowsValue = params.data.flows;
            const flowsValueLast = params.data.flows_last
                ? params.data.flows_last
                : getExpandedReadingValue(params.data.id, columnTypes.FLOWS);
            rowNode.setDataValue(columnTypes.FLOWS, flowsValueLast);
            params.data.flows_last = flowsValue;
            if (!(flowsValueLast instanceof HTMLElement)) {
                setShowModal(true);
                const formatedData = () => {
                    const arr = Object.entries(flowsValueLast)
                        .filter(item => item[0] !== 'total')
                        .map(item => `${item[0]}: ${item[1]}`);

                    arr.unshift(
                        Object.entries(flowsValueLast)
                            .filter(item => item[0] === 'total')
                            .map(item => `${item[0]}: ${item[1]}`)[0]
                    );
                    return arr.join(', ');
                };
                setModalData(formatedData());
            }
        }

        if (columnType === columnTypes.TRANSMITTERS) {
            const transmittersValue = params.data.transmitters;
            const transmittersValueLast = params.data.transmitters_last
                ? params.data.transmitters_last
                : getExpandedReadingValue(params.data.id, columnTypes.TRANSMITTERS);
            rowNode.setDataValue(columnTypes.TRANSMITTERS, transmittersValueLast);
            params.data.transmitters_last = transmittersValue;
            if (!(transmittersValueLast instanceof HTMLElement)) {
                setShowModal(true);
                setModalData(
                    transmittersValueLast.map(
                        item =>
                            `${Object.keys(item)
                                .map(field => `${item[field].header}: ${item[field].value}`)
                                .join(', ')} `
                    )
                );
            }

            params.api.columnModel.columnApi.autoSizeColumn(columnTypes.TRANSMITTERS);
        }

        if (
            columnType !== columnTypes.FLOWS &&
            columnType !== columnTypes.READINGS &&
            columnType !== columnTypes.TRANSMITTERS
        ) {
            setShowModal(true);
            setModalData(params.value);
        }
    };

    const setStyle = params => {
        const indexRow = params.node.rowIndex;
        const rowNumber = indexRow + 1;
        const selectedPackageId = sessionStorage.getItem(localStorageKey.SELECTED_PACKAGE_ID);
        if (selectedPackageId === params.data.package_id) {
            return {
                background: 'rgba(230, 178, 198, 100)',
            };
        }
        if (rowNumber % 5 === 0) {
            return { background: '#f5f5f5' };
        }
        return { background: '#fff' };
    };

    const setColumnHide = params => {
        if (gridApi) {
            params.forEach(column => {
                gridApi.columnModel.columnApi.setColumnVisible(column.field, !column.hide);
            });
            saveColumnSettings(params);
        }
    };

    const getTableTypeKey = type => {
        let storageKey;

        switch (type) {
            case reportTypes.REPORTS_PL:
                storageKey = localStorageKey.COLUMN_DEFS_TABLE_REPORTS;
                break;
            case reportTypes.ALARMS_PL:
                storageKey = localStorageKey.COLUMN_DEFS_TABLE_ALARMS;
                break;
            case reportTypes.SUMMARIES_PL:
                storageKey = localStorageKey.COLUMN_DEFS_TABLE_SUMMARY;
                break;
            case reportTypes.MINUTE_REPORTS_PL:
                storageKey = localStorageKey.COLUMN_DEFS_TABLE_MINUTE_REPORTS;
                break;
            case reportTypes.CONFIGS_PL:
                storageKey = localStorageKey.COLUMN_DEFS_TABLE_CONFIG_REPORTS;
                break;
            case reportTypes.CON_MAPS_PL:
                storageKey = localStorageKey.COLUMN_DEFS_TABLE_CON_MAPS;
                break;
            case reportTypes.CON_CONFIGS_PL:
                storageKey = localStorageKey.COLUMN_DEFS_TABLE_CON_CONFIGS;
                break;
            default:
                return '';
        }

        return storageKey;
    };

    const saveColumnSettings = params => {
        const localStorageKey = getTableTypeKey(convertToPolish(reportType));
        if (localStorageKey) {
            localStorage.setItem(localStorageKey, JSON.stringify(params));
            setColumnsDefs(params);
        }
    };

    const tooltipValueGetter = (params, headerName) =>
        headerName === columnTypes.TRANSMITTERS ? '' : params.colDef.headerTooltip;

    useEffect(() => {
        localStorage.setItem(localStorageKey.PAGINATION_SIZE_TABLE_REPORTS, chunk);
        gridApi && gridApi.paginationSetPageSize(+chunk);
    }, [chunk]);

    useEffect(() => {
        setPreviousUrl(getUrlLastPart());
    }, []);

    const setColumnDefsFromLocalStorage = () => {
        const toParse = localStorage.getItem(getTableTypeKey(convertToPolish(reportType)));
        let localStorageColumnDefs;
        if (toParse !== 'undefined') {
            localStorageColumnDefs = JSON.parse(toParse);
        }

        if (localStorageColumnDefs) {
            localStorageColumnDefs.map(
                col =>
                    (col.cellRenderer =
                        (col.field === columnTypes.READINGS ||
                            col.field === columnTypes.FLOWS ||
                            col.field === columnTypes.TRANSMITTERS) &&
                        cellRenderer)
            );
            localStorageColumnDefs.map(col => {
                col.tooltipValueGetter = params => tooltipValueGetter(params, col.field);
                delete col.pivot;
                delete col.rowGroup;
                return col;
            });

            setColumnsDefs(localStorageColumnDefs);
        }
    };

    const onColumnChange = event => {
        if (
            (event?.type === columnEventTypes.resize && event?.finished) ||
            event?.type === columnEventTypes.move
        ) {
            if (gridApi && reportType === previousUrl) {
                saveColumnSettings(gridApi.columnModel.getColumnDefs());
            }
            setPreviousUrl(reportType);
        }
    };

    const handleReturn = () => {
        setShowModal(false);
    };

    const isExportAvailable =
        reportType === reportTypes.REPORTS ||
        reportType === reportTypes.ALARMS ||
        reportType === reportTypes.SUMMARIES;

    return (
        <div className={root}>
            {showModal && <CopyModal modalData={modalData} handleReturn={handleReturn} />}
            <SettingsPanel
                ref={settingsPanelRef}
                gridApi={gridApi}
                columnsDefs={columnsDefs}
                listType={listTypes.WATERPIPE_LIST}
                setColumnHide={setColumnHide}
                tableHeaderName={headerText}
                match={match}
                settingsPanelPosition={settingsPanelPosition}
                hideExport={!isExportAvailable}
                isTable
            />
            <div className={'ag-theme-alpine'} style={{ ...TABLE_STYLE, height: tableHeight }}>
                <AgGridReact
                    localeText={tableTranslations}
                    paginationPageSize={chunk}
                    rowData={data}
                    columnDefs={columnsDefs}
                    suppressCellSelection
                    suppressColumnVirtualisation
                    suppressPaginationPanel
                    pagination
                    tooltipShowDelay={0}
                    onGridReady={onGridReady}
                    onCellClicked={onCellClicked}
                    onColumnMoved={onColumnChange}
                    onColumnResized={onColumnChange}
                    onRowDataChanged={params => {
                        autoSizeColumns(params);
                        setIsLoading(false);
                    }}
                    onSortChanged={changeRowStyle}
                    onFilterChanged={changeRowStyle}
                    getRowStyle={setStyle}
                    enableCellTextSelection
                    suppressDragLeaveHidesColumns
                />
                <TableReportsPagination
                    ref={paginationPanelRef}
                    changePage={changePageHandler}
                    selectedPage={selectedPage}
                    paginationSizes={PAGINATION_SIZES}
                    paginationSize={chunk}
                    changePaginationSize={changePaginationAndChunkSize}
                />
                {isServerError && <p className={errorMessage}>{tableTranslations.errorLoadingData}</p>}
            </div>
        </div>
    );
}
