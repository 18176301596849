import React, { useState, useEffect, memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = {
    close: {
        padding: '1rem',
        color: 'white',
    },
    error: {
        background: 'red',
    },
    success: {
        background: 'green',
    },

    spanContainer: {
        width: '600px',
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    icon: {
        marginRight: '1.5rem',
    },
    italicParagraph: {
        fontStyle: 'italic',
    },
};

const snackbar = ({ classes, snackbarData, handleIsSnackbarOpen }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [errorInfo, setErrorInfo] = useState('');
    const [variant, setVariant] = useState('');

    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
    };

    const Icon = variantIcon[variant];

    useEffect(() => {
        setOpen(snackbarData.isSnackbarOpen);
        setMessage(snackbarData.message);
        setErrorInfo(snackbarData.errorInfo);
        setVariant(snackbarData.variant);
    }, [snackbarData]);

    const handleClose = () => {
        handleIsSnackbarOpen();
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <SnackbarContent
                    className={classes[variant]}
                    message={
                        <span id="message-id" className={classes.spanContainer}>
                            {variantIcon[variant] ? <Icon className={classes.icon} /> : null}
                            <p>
                                {`${message} `}
                                <span className={classes.italicParagraph}>{errorInfo}</span>
                            </p>
                        </span>
                    }
                    action={[
                        <IconButton
                            data-cy="buttonCloseSnackbar"
                            key="close"
                            className={classes.close}
                            onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </div>
    );
};

export default withStyles(styles)(memo(snackbar));
